import { TBrowserPermissions } from 'lib/common/contexts/RequirementsContext';

import PanelContent from '../constants/PanelContent';
import PANELS from '../constants/Panels';
import IPanel from '../types/Panel';

// Dialpad testing scenarios https://cloud-wave.atlassian.net/wiki/spaces/PROD/pages/1666973800/Dialpad

const getPanelContent = ({
  currentlyInConference,
  isPermissionGranted,
  initialConnectionDisconnected,
  isDialpadTransfer,
  onActiveCall,
  activeCallName,
  isSoftphone,
  hasConnectingInboundVoiceTask,
  isMultiPartyConference,
  hasMaxConferenceConnections,
  isTaskInactive,
  isCallInACW
}: {
  currentlyInConference: boolean;
  isPermissionGranted: (permission: TBrowserPermissions) => boolean;
  initialConnectionDisconnected?: boolean;
  isDialpadTransfer?: boolean;
  onActiveCall: boolean;
  activeCallName?: string;
  isSoftphone: boolean;
  hasConnectingInboundVoiceTask: boolean;
  isMultiPartyConference: boolean;
  hasMaxConferenceConnections: boolean;
  isTaskInactive: boolean;
  isCallInACW: boolean;
}): IPanel | null => {
  if (!isPermissionGranted('microphone') && !isSoftphone) {
    return PANELS()[PanelContent.MICROPHONE_ACCESS_ERROR];
  }

  if (hasConnectingInboundVoiceTask) {
    return PANELS()[PanelContent.INCOMING_VOICE_TASK_ERROR];
  }

  if (initialConnectionDisconnected && isDialpadTransfer) {
    return PANELS()[PanelContent.CONFERENCE_TRANSFER_CONNECTION_ERROR];
  }

  if (currentlyInConference && isDialpadTransfer && !isMultiPartyConference) {
    return PANELS({ maxAttendeesNumber: isMultiPartyConference ? 6 : 3 })[
      PanelContent.CONFERENCE_TRANSFER_MAX_ATTENDEES_ERROR
    ];
  }

  if (currentlyInConference && isDialpadTransfer && isMultiPartyConference && hasMaxConferenceConnections) {
    return PANELS({ maxAttendeesNumber: isMultiPartyConference ? 6 : 3 })[
      PanelContent.CONFERENCE_TRANSFER_MAX_ATTENDEES_ERROR
    ];
  }

  if (currentlyInConference && !isDialpadTransfer) {
    return PANELS()[PanelContent.CONFERENCE_HANGUP_INFO];
  }

  if (onActiveCall && !isDialpadTransfer) {
    return PANELS({ dialNumber: activeCallName })[PanelContent.CALL_HANGUP_INFO];
  }

  if (isTaskInactive && isDialpadTransfer) {
    return PANELS()[PanelContent.CURRENT_TASK_ENDED];
  }

  if (isCallInACW) {
    return PANELS()[PanelContent.CALL_IN_ACW];
  }

  return null;
};

export default getPanelContent;
