import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { useBooleanState } from 'webrix/hooks';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import Tooltip from '../Tooltip';

import './checkbox.scss';

interface ICheckBox {
  checked?: boolean;
  initialValue?: boolean;
  onChange: (_: boolean) => any;
  label?: string;
  className?: string;
  disabled?: boolean;
  id?: string;
  checkedIcon?: IconDefinition;
  tooltip?: string;
  tooltipDelay?: number;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  small?: boolean;
  subtitle?: string;
}

export default function Checkbox({
  checked: checkedProp,
  initialValue,
  onChange,
  label,
  className,
  disabled,
  id,
  checkedIcon,
  tooltip,
  tooltipDelay = 1000,
  tooltipPlacement = 'bottom',
  small,
  subtitle
}: ICheckBox) {
  const { value: localChecked, toggle: toggleChecked } = useBooleanState(initialValue);
  const checked = checkedProp !== void 0 ? checkedProp : localChecked;

  const handleChange = () => {
    const newChecked = !checked;

    onChange?.(newChecked);

    toggleChecked();
  };

  const stopEventPropagate = (e) => {
    e.stopPropagation();
  };

  const onButtonClick = (e) => {
    //@ts-ignore Hacky way to distinguish between a keyboard press and a button press. React unfortunately does not give us another way of doing this.
    if (e.target.tagName.toLowerCase() === 'button') {
      return handleChange();
    }

    stopEventPropagate(e);
  };

  const checkboxEl = (
    <button
      role="checkbox"
      aria-checked={checked}
      aria-label={label}
      className={cx('checkbox', 'no-styles-button', { 'checkbox--disabled': disabled }, className)}
      data-testid={id}
      onClick={onButtonClick}
    >
      <Text
        type="label"
        className={cx('checkbox__input', { 'checkbox__input--checked': checked, 'checkbox__input--small': small })}
        data-testid={`${id}__label`}
      >
        <input
          role="checkbox"
          data-testid={`${id}-${checkedIcon ? 'custom-icon' : 'check'}`}
          id={label ? `checkbox-${id}` : ''}
          onClick={stopEventPropagate}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
        />
        {checked && (
          <Icon size={small ? 10 : 16} icon={checkedIcon || faCheck} className="checkbox__tick" color="white" />
        )}
      </Text>
      <div className="checkbox__label" onClick={handleChange}>
        {label && <Text htmlFor={label ? `checkbox-${id}` : ''}>{label}</Text>}
        {subtitle && <Text type="label">{subtitle}</Text>}
      </div>
    </button>
  );

  return tooltip ? (
    <Tooltip enterDelay={tooltipDelay} title={tooltip} placement={tooltipPlacement} ariaHidden>
      {checkboxEl}
    </Tooltip>
  ) : (
    checkboxEl
  );
}
