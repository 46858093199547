import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';

import ClickableIcon from 'lib/common/components/ClickableIcon';

import { useLayout } from '../../contexts/layout/LayoutContext';
import useStyles from './styles';

export default function DatePicker({ id, ...props }: KeyboardDatePickerProps) {
  const { isSoftphone } = useLayout();
  const classes = useStyles({ isSoftphone: isSoftphone });

  return (
    <KeyboardDatePicker
      {...props}
      inputVariant="outlined"
      id={id}
      KeyboardButtonProps={{
        'aria-label': 'change date',
        disableRipple: true,
        size: 'small'
      }}
      className={classes.root}
      keyboardIcon={<ClickableIcon aria-label="Date picker" icon={faCalendar} />}
      inputProps={{
        'data-testid': id,
        className: classes.input,
        'aria-label': props['aria-label'] || props.label?.toString() || props.placeholder,
        'aria-disabled': props.disabled
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot
        }
      }}
      InputLabelProps={{
        classes: {
          root: classes.inputLabelRoot
        }
      }}
    />
  );
}
