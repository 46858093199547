import { useState } from 'react';

import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';

import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import LayoutColumnsIcon from 'lib/common/components/LayoutColumnsIcon';
import Modal from 'lib/common/components/Modal';
import Text from 'lib/common/components/Text';

import WORKSPACE_LAYOUT from 'lib/common/constants/workspaceLayouts';

import './choose-layout-modal.scss';

const LAYOUT_SET_KEY = 'first-layout-set';

export default function ChooseLayoutModal() {
  const {
    state: { defaultWorkspaceLayout },
    actions: { setDefaultWorkspaceLayout }
  } = usePreferencesContext();
  const { getStorageItem, setStorageItem } = useLocalStorage();

  const [layoutChosen, setLayoutChosen] = useState(Boolean(getStorageItem(LAYOUT_SET_KEY)));

  const onClose = () => {
    setStorageItem(LAYOUT_SET_KEY, 'true');
    setLayoutChosen(true);
  };

  return (
    <Modal
      open={!layoutChosen}
      hideSecondaryButton
      primaryButtonText="Close"
      title="Default Workspace Layout"
      onClose={onClose}
      onSave={onClose}
    >
      <div className="choose-layout-modal">
        <Text type="body" className="mb-30">
          Choose a default layout to use on <strong>larger screens</strong>. The two column layout will always be used
          on smaller screens. Columns can be resized.
        </Text>
        <div className="choose-layout-modal__options">
          <button
            aria-label="Choose 3 column layout"
            data-testid="choose-layout-modal-option-three-column"
            className="choose-layout-modal__options__option"
            onClick={() => setDefaultWorkspaceLayout(WORKSPACE_LAYOUT.THREE_COLUMNS)}
          >
            <LayoutColumnsIcon large selected={defaultWorkspaceLayout === WORKSPACE_LAYOUT.THREE_COLUMNS} />
          </button>
          <button
            aria-label="Choose 2 column layout"
            data-testid="choose-layout-modal-option-two-column"
            className="choose-layout-modal__options__option"
            onClick={() => setDefaultWorkspaceLayout(WORKSPACE_LAYOUT.TWO_COLUMNS)}
          >
            <LayoutColumnsIcon large twoColumns selected={defaultWorkspaceLayout === WORKSPACE_LAYOUT.TWO_COLUMNS} />
          </button>
        </div>
        <Text type="body" className="mt-30">
          You can change the default layout at any time in the preferences window.
        </Text>
      </div>
    </Modal>
  );
}
