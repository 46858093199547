import EventEmitter from 'lib/common/utils/EventEmitter';

import THandleContactChange from '../types/HandleContactChange';

export default function destroyTask({
  contact,
  handleContactChange
}: {
  contact?: connect.Contact;
  handleContactChange: THandleContactChange;
}) {
  if (!contact) {
    return;
  }

  handleContactChange({ contact, isDestroy: true });

  EventEmitter.emit('onContactEnded', contact);
}
