import getTaskType from 'lib/common/contexts/ContactContext/utils/getTaskType';
import taskBuilder from 'lib/common/contexts/ContactContext/utils/taskBuilder/index';
import { THasPermission } from 'lib/common/contexts/PermissionsContext/Provider';

import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPE from 'lib/common/constants/contactTypes';

import ChatTask from 'lib/common/types/ChatTask';
import TChatTask from 'lib/common/types/ChatTask';
import Task from 'lib/common/types/Task';
import TTask from 'lib/common/types/Task';

export default async function newTask({
  contact,
  status,
  hasPermission,
  getTasks
}: {
  contact?: connect.Contact;
  status?: ValueOf<typeof CONTACT_STATES>;
  hasPermission: THasPermission;
  getTasks: () => (Task | ChatTask)[];
}): Promise<(TChatTask | TTask)[] | null> {
  if (!contact) {
    return null;
  }

  const taskType = getTaskType(contact);

  if (!taskType) {
    return null;
  }

  if (taskType === CONTACT_TYPE.CHAT) {
    const newChatTask = await taskBuilder.newChatTask({
      hasPermission,
      contact,
      status
    });

    if (!newChatTask) {
      return getTasks();
    }
    return [newChatTask, ...getTasks()];
  }

  const baseTask = taskBuilder.newBaseTask(contact, status);
  if (!baseTask) {
    return getTasks();
  }

  return [baseTask, ...getTasks()];
}
