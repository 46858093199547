import { RecordingState } from '@cloud-wave/neon-common-lib';

import type { TConfig } from 'lib/common/types/Config';

interface IArgs {
  config: TConfig;
  fetch_: (url: string, options: any) => Promise<any>;
  contactId: string;
  action: keyof typeof RecordingState;
}

export default async function updateRecordingState({ config, contactId, fetch_, action }: IArgs) {
  const endpoint = `${config.AGENT_SERVICE_V2_URL}/contact/${contactId}/recording-status`;

  return fetch_(endpoint, {
    body: JSON.stringify({ action }),
    method: 'PUT'
  });
}
