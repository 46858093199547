import { faXmark } from '@fortawesome/pro-regular-svg-icons';

import { useContactContext } from 'lib/common/contexts/ContactContext';

import Button from 'lib/common/components/Button';

import TaskFooter from '../TaskFooter';
import TaskFooterRow from '../TaskFooterRow';
import TransferFooterButtons from '../TransferFooterButtons';

interface IOutboundPreviewTaskProps {
  taskId: string;
  connectionValue: string;
}

export default function OutboundPreviewTask({ taskId, connectionValue }: IOutboundPreviewTaskProps) {
  const {
    actions: { hangupAgent }
  } = useContactContext();

  return (
    <TaskFooter>
      <TaskFooterRow isSingleRow>
        <TransferFooterButtons taskId={taskId} connectionValue={connectionValue} />
        <Button
          icon={faXmark}
          styleType="DANGER"
          round
          size="medium"
          onClick={() => hangupAgent(taskId)}
          preventDoubleClick
          tooltip="End the task"
          asyncAction
          successTimeoutSeconds={0}
        />
      </TaskFooterRow>
    </TaskFooter>
  );
}
