import { faSave } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import AccordionGroup from 'lib/common/components/AccordionGroup';
import Button from 'lib/common/components/Button';
import HeadingLabel from 'lib/common/components/HeadingLabel';
import useCreateAgentTask from 'lib/common/components/molecules/CreateAgentTask/useCreateAgentTask';

import styles from './softphone-create-agent-task.module.scss';

export default function SoftphoneCreateAgentTask({
  name: defaultName = '',
  description: defaultDescription = '',
  onClose
}) {
  const { blocks, saveDisabled, createTask } = useCreateAgentTask({
    defaultName,
    defaultDescription,
    open: true
  });

  return (
    <div className={styles['softphone-create-agent-task']}>
      <AccordionGroup defaultOpenIndex={0} onlyOneOpen>
        {blocks.map(({ content, className, ...block }, index) => (
          <AccordionGroup.Accordion
            key={index}
            className={cx(className, { 'mb-auto': index === blocks.length - 1 })}
            ariaLabel={`Toggle ${block.title} accordion`}
            title={
              <HeadingLabel
                noMargin
                smallTitle
                primary={block.title}
                secondary={!block.optional && block.summaryAbbreviated}
                optional={block.optional}
              />
            }
          >
            {content}
          </AccordionGroup.Accordion>
        ))}
      </AccordionGroup>
      <section aria-label="footer" className="create-agent-task__buttons">
        <Button styleType="SECONDARY" onClick={onClose}>
          Cancel
        </Button>
        <Button
          icon={faSave}
          disabled={saveDisabled}
          onClick={createTask}
          onSuccess={onClose}
          asyncAction
          preventDoubleClick
        >
          Save
        </Button>
      </section>
    </div>
  );
}
