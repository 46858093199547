import WRAP_UP_CODE_TYPES from 'lib/common/constants/ACW/wrapUpCodeTypes';

import IWrapUpCode from 'lib/common/types/WrapUpCode';

export default function isSaveButtonDisabled({
  hasAgentOutcomesPermission,
  queueWrapUpCodes,
  taskIsMonitoring,
  ACWIsSkippable,
  wrapUpCodeName,
  notes,
  notesAreRequired
}: {
  hasAgentOutcomesPermission: boolean;
  queueWrapUpCodes: IWrapUpCode[];
  taskIsMonitoring: boolean;
  ACWIsSkippable: boolean;
  wrapUpCodeName?: IWrapUpCode;
  notes?: string;
  notesAreRequired: boolean;
}) {
  if (taskIsMonitoring || !hasAgentOutcomesPermission) {
    return false;
  }

  // Ignore all groups and check if there are any codes for the queue
  const hasQueueWrapUpCodes = queueWrapUpCodes.some((wrapUpCode) => wrapUpCode.type === WRAP_UP_CODE_TYPES.CODE);

  if (!ACWIsSkippable && notesAreRequired) {
    // If the agent cannot skip ACW, they must either select a wrap up code (if there are any) or notes
    return hasQueueWrapUpCodes ? !wrapUpCodeName : !notes;
  }

  if (!ACWIsSkippable) {
    // If the agent cannot skip ACW, notes are not required and there are no wrap codes, they can effectively skip
    return hasQueueWrapUpCodes ? !wrapUpCodeName : false;
  }

  return !notes && !wrapUpCodeName;
}
