import { Buffer } from 'buffer';

import { NEONNOW_BRANDING } from '@cloud-wave/neon-common-lib';

import { ImageType } from 'lib/common/constants/imageBlockFields';

import { TConfig } from 'lib/common/types/Config';

export function padZero(num: number) {
  return num < 10 ? `0${num}` : num;
}

export function getFileUrl(fileKey: string, config: TConfig) {
  return `${config.BRAND_S3_DOMAIN}/${fileKey}`;
}

export function getDefaultBrandImageUrl(type: ImageType) {
  const defaultImage = NEONNOW_BRANDING[type];
  const base64data = Buffer.from(defaultImage).toString('base64');
  return `data:image/svg+xml;base64,${base64data}`;
}
