import { Permissions, TPermissions } from '@cloud-wave/neon-common-lib';

import { TConnectPermissions, TPermissionTypes } from 'lib/common/types/Permissions';

export const getCanUseRichText = (
  isConnectWebChat: boolean,
  hasPermission: ({
    type,
    permission
  }: {
    type?: TPermissionTypes;
    permission?: TPermissions | TConnectPermissions | null;
  }) => boolean
) => {
  if (isConnectWebChat) {
    return hasPermission({
      type: 'tenant',
      permission: Permissions.CHAT_RICH_TEXT
    });
  }

  return false;
};
