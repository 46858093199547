import { ReactElement, useEffect, useState } from 'react';

import {
  faAddressBook,
  faBuilding,
  faEnvelope,
  faHeadset,
  faIdBadge,
  faMobile
} from '@fortawesome/pro-regular-svg-icons';
import { ReactComponent as TeamsLogo } from 'assets/vectors/teams.svg';
import cx from 'classnames';

import { DirectoryType, TAgentContact, TContact, TContactType, TDirectoryType } from '@cloud-wave/neon-common-lib';
import { ContactType } from '@cloud-wave/neon-common-lib/common';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Avatar from 'lib/common/components/Avatar';
import ClickToCall from 'lib/common/components/ClickToCall';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import { getContactPresence } from 'lib/common/api/getContactPresence';
import getStatusIconColour from 'lib/common/utils/presence/getStatusIconColour';

import Popover from '../../../Popover';
import getAvatarNameFromContact from '../../utils/getAvatarNameFromContact';

import COLOURS from 'css/export-vars.module.scss';

import styles from './contact-popover.module.scss';

const STATUS_UNKNOWN = 'Status Unknown';
const DESKTOP_ICON_SIZE = 15;
const SOFTPHONE_ICON_SIZE = 14;

const JobTitleDisplay = ({ contact, isSoftphone }: { contact: TContact | TAgentContact; isSoftphone: boolean }) => {
  if (contact.type === ContactType.AGENT || !contact.jobTitle) {
    return null;
  }

  return (
    <div className={cx(styles['contact-popover__title'], styles['contact-popover__section'])}>
      <Icon tooltip="Job Title" icon={faIdBadge} size={isSoftphone ? SOFTPHONE_ICON_SIZE : DESKTOP_ICON_SIZE} />
      <Text type={isSoftphone ? 'small' : void 0}>{contact.jobTitle}</Text>
    </div>
  );
};

function ContactHeaderDisplay({
  name,
  status,
  showPresence,
  contact,
  isSoftphone
}: {
  name: string;
  status?: string;
  showPresence: boolean;
  contact: TContact | TAgentContact;
  isSoftphone: boolean;
}) {
  const statusColour = status && showPresence ? getStatusIconColour(status) : void 0;
  const textColour = statusColour === COLOURS.white ? 'darkGrey' : statusColour;

  return (
    <div className={cx(styles['contact-popover__heading'])}>
      <Avatar
        name={getAvatarNameFromContact(contact)}
        size={isSoftphone ? Avatar.Sizes.SMALL : Avatar.Sizes.MEDIUM}
        statusColour={statusColour ? COLOURS[statusColour] : void 0}
      />
      <span className={styles['contact-popover__details__name']}>
        <Text bold className={styles['contact-popover__details__name__text']}>
          {name}
        </Text>
        {showPresence && (
          <Text
            className={styles['contact-popover__details__name__text']}
            type={'extraSmall'}
            color={textColour}
            medium={!isSoftphone}
          >
            {status}
          </Text>
        )}
      </span>
    </div>
  );
}

function ContactTypeDisplay({
  contactDirectory,
  contactType,
  isSoftphone
}: {
  contactDirectory?: {
    type: TDirectoryType;
    name?: string | undefined;
    presence?: boolean | undefined;
  } | null;
  contactType: TContactType;
  isSoftphone: boolean;
}) {
  const isTeamsContact =
    contactDirectory?.type === DirectoryType.MICROSOFT_ENTRA_ID && contactDirectory?.presence === true;

  const displayInfo = {
    [ContactType.PERSONAL]: {
      contactSource: <Text>Personal Contact</Text>,
      icon: faMobile,
      overrideIcon: null
    },
    [ContactType.EXTERNAL_DIRECTORY]: {
      contactSource: (
        <>
          <Text medium type={isSoftphone ? 'small' : void 0}>
            {contactDirectory?.name}
          </Text>
          <Text type="extraSmall">{isTeamsContact ? 'Teams' : 'External'} Contact</Text>
        </>
      ),
      icon: faAddressBook,
      overrideIcon: isTeamsContact ? <TeamsLogo className={styles['contact-popover__type__teams-logo']} /> : null
    },
    [ContactType.ORGANISATION]: {
      contactSource: <Text type={isSoftphone ? 'small' : void 0}>Organisation Contact</Text>,
      icon: faBuilding,
      overrideIcon: null
    },
    [ContactType.AGENT]: {
      contactSource: <Text type={isSoftphone ? 'small' : void 0}>Agent</Text>,
      icon: faHeadset,
      overrideIcon: null
    }
  };

  const { icon, overrideIcon, contactSource } = displayInfo[contactType];

  return (
    <div className={cx(styles['contact-popover__type'], styles['contact-popover__section'])}>
      {overrideIcon || (
        <Icon icon={icon} size={isSoftphone ? SOFTPHONE_ICON_SIZE : DESKTOP_ICON_SIZE} tooltip="Contact Type" />
      )}
      <div className="contact-popover__type__info">{contactSource}</div>
    </div>
  );
}

function ContactDetailsDisplay({
  contact,
  handleOnCallClick,
  isSoftphone
}: {
  contact: TContact | TAgentContact;
  handleOnCallClick: () => void;
  isSoftphone: boolean;
}) {
  if (contact.type === ContactType.AGENT || (!contact.email && !contact.phoneNumber)) {
    return null;
  }

  const { email, phoneNumber } = contact;

  return (
    <div className={cx(styles['contact-popover__details'], styles['contact-popover__section'])}>
      {email && (
        <div className={cx(styles['contact-popover__details__detail'])}>
          <Text href={`mailto:${email}`} className="next-to full-width" type={isSoftphone ? 'small' : void 0}>
            <Icon
              className={cx('mr-10', styles['contact-popover__details__detail__icon'])}
              color={'primary'}
              icon={faEnvelope}
              size={isSoftphone ? SOFTPHONE_ICON_SIZE : DESKTOP_ICON_SIZE}
              tooltip="Email Address"
            />
            <Text type="inline" ellipsis>
              {email}
            </Text>
          </Text>
        </div>
      )}
      {phoneNumber && (
        <div className={cx(styles['contact-popover__details__detail'])}>
          <ClickToCall
            showIcon
            textProps={{
              ellipsis: true,
              type: isSoftphone ? 'small' : void 0
            }}
            onClick={handleOnCallClick}
            iconSize={isSoftphone ? SOFTPHONE_ICON_SIZE : DESKTOP_ICON_SIZE}
          >
            {phoneNumber}
          </ClickToCall>
        </div>
      )}
    </div>
  );
}

const ContactPopover = ({
  contact,
  fullName,
  contactDirectory,
  anchor,
  handleOnCallClick
}: {
  contact: TContact | TAgentContact;
  fullName: string;
  contactDirectory?: {
    type: TDirectoryType;
    name?: string | undefined;
    presence?: boolean | undefined;
  } | null;
  anchor?: ReactElement | null;
  handleOnCallClick: () => void;
}) => {
  const [status, setStatus] = useState<string | undefined>(STATUS_UNKNOWN);
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();

  const { isSoftphone } = useLayout();

  const showPresence = contactDirectory?.presence || contact.type === ContactType.AGENT;

  const loadStatus = async () => {
    if (contact.type === ContactType.AGENT) {
      contact.presenceStatus && setStatus(contact.presenceStatus);
      return;
    }

    if (!contactDirectory?.presence) {
      return;
    }

    const { contactId } = contact;
    const { availability } = await getContactPresence({ contactId, config, fetch_ });

    availability && availability !== 'PresenceUnknown' && setStatus(availability);
  };

  const unloadStatus = () => {
    if (contact.type === ContactType.AGENT) {
      return;
    }

    setStatus(STATUS_UNKNOWN);
  };

  useEffect(() => {
    if (contact.type !== ContactType.AGENT || !contact.presenceStatus) {
      return;
    }

    setStatus(contact.presenceStatus);
  }, []);

  return (
    <Popover anchor={anchor} withTail transitionalProps={{ onEntered: loadStatus, onExited: unloadStatus }}>
      <div className={cx(styles['contact-popover'])} data-testid={'contact-popover'}>
        <ContactHeaderDisplay
          name={fullName}
          contact={contact}
          status={status}
          showPresence={showPresence}
          isSoftphone={isSoftphone}
        />
        <div className={cx(styles['contact-popover__info'])}>
          <JobTitleDisplay contact={contact} isSoftphone={isSoftphone} />
          <ContactTypeDisplay
            contactDirectory={contactDirectory}
            contactType={contact.type}
            isSoftphone={isSoftphone}
          />
          <ContactDetailsDisplay handleOnCallClick={handleOnCallClick} contact={contact} isSoftphone={isSoftphone} />
        </div>
      </div>
    </Popover>
  );
};

export default ContactPopover;
