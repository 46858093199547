import { useEffect, useState } from 'react';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import queueEndpointTypes from 'lib/common/constants/queueEndpointTypes';

import getInstanceDirectories from 'lib/common/api/getInstanceDirectories';
import getInstanceQueues from 'lib/common/api/getInstanceQueues';
import getInstanceQuickConnects from 'lib/common/api/getInstanceQuickConnects';
import connectGetter from 'lib/common/utils/connectGetter';
import filterDisabledQueues from 'lib/common/utils/filterDisabledQueues';

import getMaxContactsForQueues from '../../api/getMaxContactsForQueues';
import { useAgentContext } from '../AgentContext';
import Context from './Context';

const getQueueIdFromArn = (queue) => queue.queueId.split('/').pop();

function InstanceProvider({ children }) {
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const { agent } = useAgentContext();

  const [instanceQueues, setInstanceQueues] = useState([]);
  const [instanceQuickConnects, setInstanceQuickConnects] = useState([]);
  const [instanceMaxContacts, setInstanceMaxContacts] = useState({});
  const [instanceDirectories, setInstanceDirectories] = useState({});

  const setMaxContacts = async (fetchedQueues) => {
    if (!Array.isArray(fetchedQueues)) {
      return;
    }

    const routingProfileQueues = connectGetter(agent, 'getRoutingProfile')?.queues || [];
    const defaultOutboundQueue = connectGetter(agent, 'getRoutingProfile')?.defaultOutboundQueue;
    if (defaultOutboundQueue && !routingProfileQueues.some((queue) => queue.queueId === defaultOutboundQueue.queueId)) {
      routingProfileQueues.push(defaultOutboundQueue);
    }
    const queueIds = routingProfileQueues?.map(getQueueIdFromArn);

    if (!queueIds) {
      return;
    }

    const filteredQueueIds = fetchedQueues
      .filter(({ Id, QueueType }) => queueIds.includes(Id) && QueueType === queueEndpointTypes.QUEUE)
      .map(({ Id }) => Id);

    const response = await getMaxContactsForQueues({ fetch_, config, queueIds: filteredQueueIds });

    setInstanceMaxContacts(response);
  };

  const setQueueInformation = async () => {
    const response = await getInstanceQueues({ fetch_, config });
    const filteredQueues = filterDisabledQueues({
      allQueues: response,
      disabledQueuePrefix: config.DISABLED_QUEUE_PREFIX
    });

    setInstanceQueues(filteredQueues);
    setMaxContacts(filteredQueues);
  };

  const setQuickConnects = async () => {
    const response = await getInstanceQuickConnects({ fetch_, config });
    setInstanceQuickConnects(response);
  };

  const setDirectories = async () => {
    const response = await getInstanceDirectories({ fetch_, config });
    setInstanceDirectories(response);
  };

  useEffect(() => {
    setQueueInformation();
    setQuickConnects();
    setDirectories();
  }, []);

  return (
    <Context.Provider
      value={{
        instanceQueues,
        instanceQuickConnects,
        instanceMaxContacts,
        instanceDirectories
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default InstanceProvider;
