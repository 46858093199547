import { RefObject } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { IModule, Permissions } from '@cloud-wave/neon-common-lib';
import { PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';
import { SOFTPHONE_PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

// components
import { useModulesContext } from 'lib/common/contexts/ModulesContext';

import AuthorisedRoute from 'lib/common/components/AuthorisedRoute';
import DesktopOnly from 'lib/common/components/DesktopOnly';
import NotFound from 'lib/common/components/NotFound';
import PageModule from 'lib/common/components/PageModule';
import RedirectIfSoftphone from 'lib/common/components/atoms/RedirectIfSoftphone';
import AgentStats from 'lib/common/components/molecules/AgentStats';
import Dialpad from 'lib/common/components/molecules/Dialpad';
import Preferences from 'lib/common/components/molecules/Preferences';

import TUser from 'lib//common/types/User';
import { SoftphoneLayout } from 'lib/common/layouts';
import EmailView from 'lib/pages/EmailView';
import Directory from 'lib/pages/softphone/Directory';
import InteractionHistory from 'lib/pages/softphone/InteractionHistory';
// pages
import Landing from 'lib/pages/softphone/Landing';
import Task from 'lib/pages/softphone/Task';

interface ISoftphoneLayoutRoute {
  user: TUser;
  isReversedLayout: boolean;
  contentRef: RefObject<HTMLDivElement>;
}

function withUser(user, Component, extraProps = {}) {
  return (props) => <Component {...props} user={user} {...extraProps} />;
}

export default function SoftphoneRoutes({ user, isReversedLayout, contentRef }: ISoftphoneLayoutRoute) {
  const {
    state: { pageModules }
  } = useModulesContext();

  return (
    <SoftphoneLayout user={user} isReversedLayout={isReversedLayout} contentRef={contentRef}>
      <Switch>
        <Redirect exact from={SOFTPHONE_PAGE_ROUTES.BASE} to={SOFTPHONE_PAGE_ROUTES.LANDING} />
        <Route exact path={SOFTPHONE_PAGE_ROUTES.LANDING} render={withUser(user, Landing)} />
        <AuthorisedRoute
          permissionType="tenant"
          permission={Permissions.CALL_HISTORY}
          exact
          path={SOFTPHONE_PAGE_ROUTES.INTERACTION_HISTORY}
          render={withUser(user, InteractionHistory)}
        />
        <Route exact path={SOFTPHONE_PAGE_ROUTES.AGENT_STATS} render={withUser(user, AgentStats)} />
        <Route exact path={SOFTPHONE_PAGE_ROUTES.DIALPAD} render={withUser(user, Dialpad)} />
        <Route exact path={SOFTPHONE_PAGE_ROUTES.DIRECTORY} render={withUser(user, Directory)} />
        <Route exact path={SOFTPHONE_PAGE_ROUTES.TASK} render={withUser(user, Task)} />
        <Route exact path={SOFTPHONE_PAGE_ROUTES.PREFERENCES} render={withUser(user, Preferences)} />
        <Route exact path={PAGE_ROUTES.EMAIL} component={EmailView} />
        {pageModules.map((pageModule: IModule, i) => (
          <Route
            render={(props) => <PageModule {...props} pageModuleProps={pageModule} user={user} />}
            path={`/${pageModule.pathName}`}
            key={i}
          />
        ))}
        {/* To handle race condition where both routers are being simultaneously rendered causing an infinite loop */}
        <Route path={PAGE_ROUTES.WORKSPACE}>
          <RedirectIfSoftphone to="/" />
        </Route>
        <Route
          path={[
            PAGE_ROUTES.CUSTOMER_PROFILES,
            PAGE_ROUTES.SETTINGS,
            PAGE_ROUTES.REPORTS_WALLBOARD,
            PAGE_ROUTES.REPORTS_ANALYTICS,
            PAGE_ROUTES.REPORTS_PERFORMANCE_TODAY
          ]}
          component={DesktopOnly}
        />
        <Route component={NotFound} />
      </Switch>
    </SoftphoneLayout>
  );
}
