import { useEffect } from 'react';

import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Modal from 'lib/common/components/Modal';

import TransferToQueue from './TransferToQueue';
import useTransferToQueue from './useTransferToQueue';

interface ITransferQueue {
  open: boolean;
  connectionValue: string;
  taskId: string;
  onClose: () => void;
}

const TransferToQueueModal = ({ open, onClose, connectionValue, taskId }: ITransferQueue) => {
  const { isSoftphone } = useLayout();
  const { onSave, selectedEndpoint, setSelectedEndpoint, endpoints } = useTransferToQueue({ taskId });
  const {
    state: { isModalOpen },
    actions: { openModal, updateModalOptions, closeModal }
  } = useSoftphoneModalContext();

  useEffect(() => {
    if (!isSoftphone || !open) {
      return;
    }

    if (!isModalOpen) {
      openModal({
        title: 'Queue Transfer',
        content: (
          <TransferToQueue
            connectionValue={connectionValue}
            setSelectedEndpoint={setSelectedEndpoint}
            selectedEndpoint={selectedEndpoint}
            endpoints={endpoints}
          />
        ),
        onSave: onSave,
        saveDisabled: !selectedEndpoint?.value,
        primaryButtonText: 'Transfer',
        onClose,
        onSuccess: () => {
          onClose();
          closeModal();
        }
      });
    }
  }, [isSoftphone, open]);

  useEffect(() => {
    if (!isModalOpen) {
      return;
    }

    updateModalOptions({ saveDisabled: !selectedEndpoint?.value, onSave });
  }, [onSave]);

  // Close the modal if we're on softphone (desktop will just be unmounted) when this is unmounted
  useEffect(() => closeModal, []);

  // Modals on Softphone are rendered by function calls and nothing needs to be rendered here
  if (isSoftphone) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSave={onSave}
      onSuccess={onClose}
      title="Transfer to Another Queue"
      saveDisabled={!selectedEndpoint?.value}
      primaryButtonText="Transfer"
    >
      <TransferToQueue
        connectionValue={connectionValue}
        setSelectedEndpoint={setSelectedEndpoint}
        selectedEndpoint={selectedEndpoint}
        endpoints={endpoints}
      />
    </Modal>
  );
};

export default TransferToQueueModal;
