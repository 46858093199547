import { useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import _isEmpty from 'lodash.isempty';

import { isNumberValid } from '@cloud-wave/neon-common-lib';
import { PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

import { useAgentContext } from 'lib/common/contexts/AgentContext';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useRequirementsContext } from 'lib/common/contexts/RequirementsContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import useIsSmallSoftphone from 'lib/common/hooks/useIsSmallSoftphone';

import PhoneInput from 'lib/common/components/PhoneInput';
import ColouredPanel from 'lib/common/components/atoms/ColouredPanel';

import CONTACT_STATES from 'lib/common/constants/contactStates';

import {
  getAgentVoiceConnection,
  getAllActiveConnections,
  isActiveConference,
  isConference,
  isInitialConnectionDisconnected,
  isMultiPartyConferenceEnabled
} from 'lib/common/utils/conferenceConnections';
import connectGetter from 'lib/common/utils/connectGetter';
import getUserName from 'lib/common/utils/getUserName';
import toast from 'lib/common/utils/toast/toast';

import Actions from './components/Actions';
import NumberPad from './components/NumberPad';
import ERROR_MESSAGES from './constants/ErrorMessages';
import EMERGENCY_NUMBERS from './constants/emergencyNumbers';
import IDialpadProps from './types/Dialpad';
import IPanel from './types/Panel';
import getInputWidthAndFontSize from './utils/getInputWidthAndFontSize';
import getNationalNumber from './utils/getNationalNumber';
import getPanelContent from './utils/getPanelContent';
import playDtmfTones from './utils/playDtmfTones';
import sendDigitsOverConnection from './utils/sendDigitsOverConnection';

import styles from './dialpad.module.scss';

export default function Dialpad({ isDialpadTransfer, closeModal, pushToTask }: IDialpadProps) {
  const isSmallSoftphone = useIsSmallSoftphone();

  const [dialNumber, setDialNumber] = useState('');
  const [isDialling, setIsDialling] = useState(false);
  const { isSoftphone } = useLayout();
  const {
    actions: { makeOutboundCall, onTransfer, setLastDialedNumber, getActiveCallTask, getSelectedTask },
    state: { lastDialedNumber, onActiveCall, tasks, hasConnectingInboundVoiceTask, isCallInACW }
  } = useContactContext();

  const selectedTask = getSelectedTask();
  const isTaskInactive = Boolean(isDialpadTransfer && (!selectedTask || selectedTask?.status === CONTACT_STATES.ACW));
  const { contact, profile, connectionValue } = getActiveCallTask() || {};
  const activeCallName = profile && profile.firstName ? getUserName(profile) : connectionValue;
  const { agent } = useAgentContext();
  const { isPermissionGranted } = useRequirementsContext();
  const currentlyInConference = contact ? isActiveConference(contact) : false;
  const initialConnectionDisconnected = contact && isConference(contact) && isInitialConnectionDisconnected(contact);
  const isMultiPartyConference = contact ? isMultiPartyConferenceEnabled(contact) : false;
  const hasMaxConferenceConnections = contact ? getAllActiveConnections(contact).length === 5 : false;

  const panel = useMemo<IPanel | null>(
    () =>
      getPanelContent({
        currentlyInConference,
        initialConnectionDisconnected,
        isPermissionGranted,
        isDialpadTransfer,
        onActiveCall,
        activeCallName,
        isSoftphone,
        hasConnectingInboundVoiceTask,
        isMultiPartyConference,
        hasMaxConferenceConnections,
        isTaskInactive,
        isCallInACW
      }),
    [initialConnectionDisconnected, isPermissionGranted, tasks, isTaskInactive]
  );

  const phoneInputRef = useRef(null);
  const phoneInputInnerRef = useRef<HTMLInputElement>(null);

  // Ignore country code -- emergency numbers are agnostic
  const isEmergencyNumber = EMERGENCY_NUMBERS.includes(getNationalNumber(dialNumber));

  useEffect(() => {
    if (!lastDialedNumber || isDialpadTransfer) {
      return;
    }

    // @ts-ignore
    phoneInputRef?.current?.handleValueChange(lastDialedNumber);
  }, []);

  const sendDtmf = async (digit: string) => {
    const isCallConnected = connectGetter(contact, 'isConnected');

    if (!(contact && isCallConnected)) {
      return;
    }

    const connection = getAgentVoiceConnection(contact);
    await sendDigitsOverConnection(connection, digit);
  };

  const onDigitPress = (digit: string) => {
    playDtmfTones(digit);

    // @ts-ignore
    phoneInputRef?.current?.handleValueChange(`${phoneInputRef?.current?.state.value}${digit}`);
    phoneInputInnerRef?.current?.focus();
    sendDtmf(digit);
  };

  const onHandleChange = (number) => {
    setDialNumber(number);
  };

  const handleBackspace = () => {
    const {
      //@ts-ignore
      state: { value },
      //@ts-ignore
      handleValueChange
    } = phoneInputRef?.current || {};

    const updatedNumber = value?.length > 1 ? value.slice(0, -1) : '';

    handleValueChange(updatedNumber);
  };

  const onDialPadTransfer = async () => {
    if (!isNumberValid(dialNumber)) {
      return toast('error', ERROR_MESSAGES.INVALID_NUMBER);
    }

    await playDtmfTones(dialNumber);
    await onTransfer(dialNumber);
  };

  const onDialActionSuccess = () => {
    closeModal?.();

    pushToTask?.(PAGE_ROUTES.WORKSPACE);
  };

  const onDialPadCall = async () => {
    if (!isEmergencyNumber && !isNumberValid(dialNumber)) {
      return toast('error', ERROR_MESSAGES.INVALID_NUMBER);
    }

    await playDtmfTones(dialNumber);

    if (!isEmergencyNumber) {
      setLastDialedNumber(dialNumber);
    }

    await makeOutboundCall(isEmergencyNumber ? getNationalNumber(dialNumber) : dialNumber);

    closeModal?.();

    return pushToTask?.(PAGE_ROUTES.WORKSPACE);
  };

  const handleEnterKeyPress = async () => {
    if (isDialling || _isEmpty(dialNumber) || (panel && !panel?.canMakeOutboundCall)) {
      return;
    }

    setIsDialling(true);

    if (isDialpadTransfer) {
      await onDialPadTransfer();

      onDialActionSuccess();

      return setIsDialling(false);
    }

    await onDialPadCall();

    setIsDialling(false);
  };

  const disabled = isTaskInactive && isDialpadTransfer;

  return (
    <div className={styles['dialpad']}>
      {panel && (
        <ColouredPanel type={panel.type} className={styles['dialpad__panel']} id={panel?.id}>
          {panel.content}
        </ColouredPanel>
      )}
      <div className={cx(styles['dialpad__container'], { [styles['dialpad__container--softphone']]: isSoftphone })}>
        <div className={styles['dialpad__container__number-input']}>
          <PhoneInput
            {...getInputWidthAndFontSize(isSoftphone, isSmallSoftphone)}
            aria-label="Phone number"
            ref={phoneInputRef}
            inputRef={phoneInputInnerRef}
            initialValue={dialNumber}
            onChange={onHandleChange}
            onEnterKeyPress={handleEnterKeyPress}
            testId="dialpad-phone-input"
            countries={connectGetter(agent, 'getDialableCountries') || []}
            autoFocus
            disabled={disabled}
            variant="standard"
          />
        </div>
        <NumberPad disabled={disabled} onDigitPress={onDigitPress} isSoftphone={isSoftphone} />
        <Actions
          disabled={disabled}
          panel={panel}
          onDialPadTransfer={onDialPadTransfer}
          dialNumber={dialNumber}
          isDialpadTransfer={isDialpadTransfer}
          onDialPadCall={onDialPadCall}
          isSoftphone={isSoftphone}
          onDialActionSuccess={onDialActionSuccess}
          handleBackspace={handleBackspace}
          isDialling={isDialling}
          isEmergencyNumber={isEmergencyNumber}
        />
      </div>
    </div>
  );
}
