import 'react-phone-input-2/lib/style.css';

import PreferencesAudioDevices from 'lib/common/components/molecules/Preferences/components/PreferencesAudioDevices';
import PreferencesEmail from 'lib/common/components/molecules/Preferences/components/PreferencesEmail';
import PreferencesGeneral from 'lib/common/components/molecules/Preferences/components/PreferencesGeneral';
import PreferencesWorkspace from 'lib/common/components/molecules/Preferences/components/PreferencesWorkspace';

import './styles/preferences.scss';

const Preferences = () => {
  return (
    <div className="preferences">
      <PreferencesGeneral />
      <PreferencesAudioDevices />
      <PreferencesWorkspace />
      <PreferencesEmail />
    </div>
  );
};

export default Preferences;
