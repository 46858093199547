import { ReactNode } from 'react';

import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import Icon from 'lib/common/components/Icon';

import Text from '../Text';
import { ITextProps } from '../types';

import textStyles from '../text.module.scss';

export default function LearnMore({
  children,
  className,
  learnMoreText,
  ...props
}: ITextProps & { children: string; learnMoreText: ReactNode }) {
  return (
    <Text
      {...props}
      className={cx('flex', className)}
      suffix={
        <Icon
          size={17}
          className={cx(textStyles['text__icon'], 'ml-10')}
          icon={faQuestionCircle}
          tooltip={learnMoreText}
        />
      }
    >
      {children}
    </Text>
  );
}
