import { LogEvents, logger } from 'lib/common/components/LoggerController';

import toast from 'lib/common/utils/toast';

const SOFTPHONE_ERROR_IGNORE_LIST = [connect.SoftphoneErrorTypes.MICROPHONE_NOT_SHARED];

export default function registerAgentHandlers(agent: connect.Agent) {
  agent.onSoftphoneError((error: connect.SoftphoneError) => {
    if (SOFTPHONE_ERROR_IGNORE_LIST.includes(error.errorType as connect.SoftphoneErrorTypes)) {
      return;
    }

    toast(
      'error',
      `Something went wrong with this call (${error.errorType}). Try reloading the page if you see this more than once.`
    );

    logger.error(LogEvents.CONNECT_SOFTPHONE_ERROR, error);
  });
}
