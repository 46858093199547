import SEARCHABLE_ATTRIBUTES from 'lib/common/constants/searchableProfileAttributes';

import connectGetter from './connectGetter';

export default function getMatchableAttribute(contact?: connect.Contact) {
  if (!contact) {
    return null;
  }

  if (connectGetter(contact, 'getType') === connect.ContactType.VOICE) {
    const activeInitialConnection = connectGetter(contact, 'getActiveInitialConnection');

    return {
      phoneNumber: connectGetter(activeInitialConnection, 'getEndpoint')?.phoneNumber as string
    };
  }

  const contactAttributes = connectGetter(contact, 'getAttributes') || {};

  const matchingAttribute = SEARCHABLE_ATTRIBUTES.find((attribute) => contactAttributes[attribute]?.value);

  return matchingAttribute ? { [matchingAttribute]: contactAttributes[matchingAttribute].value } : null;
}
