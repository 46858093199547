import Text from 'lib/common/components/Text';
import TaskReferences from 'lib/common/components/atoms/TaskReferences';
import ACWOutcome from 'lib/common/components/molecules/ACW/components/ACWOutcome';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import QueueNameAndTimer from 'lib/pages/AgentWorkspace/components/TaskDetail/components/QueueNameAndTimer';

import CONTACT_STATES from 'lib/common/constants/contactStates';

import Task from 'lib/common/types/Task';

import TaskDetailHeader from './TaskDetailHeader';

import '../styles/agent-task.scss';

export default function AgentTask({ status, description, taskId, connectionValue, contact, time, queueName }: Task) {
  if (status !== CONTACT_STATES.CONNECTED) {
    return null;
  }

  return (
    <div className="agent-task">
      <TaskDetailHeader>{connectionValue}</TaskDetailHeader>
      <div className="agent-task__container">
        <QueueNameAndTimer queueName={queueName} time={time} />
        <ACWOutcome />
        <Text>{description || "This task doesn't have a description."}</Text>
        <TaskReferences contact={contact} />
      </div>
      <TaskFooter.AgentTask taskId={taskId} connectionValue={connectionValue} />
    </div>
  );
}
