import { faInfoCircle, far } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { IModule } from '@cloud-wave/neon-common-lib';

import TTask from 'lib/common/types/Task';

import ModulesIFrame from '../components/ModulesIFrame';
import { TTab } from '../types/TTab';

import styles from '../styles/modules.module.scss';

const DEFAULT_ICON = 'faBookmark';

function getTabIcon(icon?: string) {
  if (!icon) {
    return far[DEFAULT_ICON];
  }

  return far[icon] || far[DEFAULT_ICON];
}

export default function getTabs({
  modules,
  renderDefaultComponent,
  selectedTask,
  hasDefaultTab,
  selectedTab,
  setSelectedTab
}: {
  modules: Partial<IModule>[];
  renderDefaultComponent?: () => JSX.Element;
  selectedTask?: TTask;
  hasDefaultTab: boolean;
  selectedTab: number;
  setSelectedTab: (arg0: number) => void;
}): TTab[] {
  const ModuleTabs = modules.map((module, index) => ({
    id: module.moduleId || index.toString(),
    title: module.name,
    icon: getTabIcon(module.icon),
    render: ({ selected }: { selected?: boolean }) => (
      <ModulesIFrame
        selected={Boolean(selected)}
        selectedTask={selectedTask}
        source={module.source as string}
        id={module.moduleId || index.toString()}
        title={module.name || ''}
      />
    )
  }));

  const defaultTabs = hasDefaultTab
    ? [
        {
          id: 'info',
          title: 'Info',
          icon: faInfoCircle,
          render: ({ selected }: { selected?: boolean }) => (
            <div data-testid="default-module-content" className={cx(styles['modules__content'], { hide: !selected })}>
              {renderDefaultComponent?.()}
            </div>
          )
        }
      ]
    : [];

  return [...defaultTabs, ...ModuleTabs].map((tab, index) => ({
    ...tab,
    isSelected: selectedTab === index,
    onClick: () => setSelectedTab(index)
  }));
}
