import cx from 'classnames';
import _isEmpty from 'lodash.isempty';
import _omit from 'lodash.omit';
import isURL from 'validator/lib/isURL';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Accordion from 'lib/common/components/Accordion';
import Badge from 'lib/common/components/Badge';
import { TBadgeType } from 'lib/common/components/Badge/Badge';
import Text from 'lib/common/components/Text';

import { FUSION_DATA_BLOCK_TYPES } from 'lib/common/constants/fusionDataBlockTypes';

import { getFusionDataFromMap } from 'lib/common/utils/fusionData/getFusionDataFromMap';
import { ContactAttributesValue } from 'lib/common/utils/getMappedContactAttributes';

import './contact-attributes.scss';

const Intents = ({ intents, isSoftphone }) => {
  if (!intents?.length) {
    return null;
  }

  return (
    <>
      <div
        className={cx('intents__container', {
          ['intents__container--softphone']: isSoftphone
        })}
        data-testid="intents-container-testId"
      >
        <Text type="small" bold color="white" inline>
          Intents:
        </Text>
        {intents.map((intent) => (
          <div
            className={cx('intents__content', {
              ['intents__content--softphone']: isSoftphone
            })}
            key={intent}
          >
            {intent?.trim()}
          </div>
        ))}
      </div>
    </>
  );
};

const Transcripts = ({ transcript, isSoftphone }) => {
  if (!transcript?.length) {
    return null;
  }

  return (
    <div
      className={cx('transcript__container', {
        ['transcript__container--softphone']: isSoftphone
      })}
      data-testid="transcript-container-testId"
    >
      <Accordion title="Transcript" small defaultOpen>
        {transcript.map((transcript) => (
          <div
            className={cx('transcript__content', {
              ['transcript__content--softphone']: isSoftphone
            })}
            key={transcript}
          >
            {transcript?.trim()}
          </div>
        ))}
      </Accordion>
    </div>
  );
};
const DynamicValueDisplay = ({
  attribute
}: {
  attribute: {
    label: string;
    badgeType?: string;
    data: unknown;
  };
}) => {
  const { data, badgeType } = attribute;
  if (!data || !(typeof data === 'string')) {
    //should never get here but if someone messes up a parameter by setting it to "CW-NEON_"
    return null;
  }

  if (badgeType) {
    return <Badge label={data} type={badgeType as TBadgeType} />;
  }
  if (isURL(data as string)) {
    return (
      <Text type="link" className="dynamic-attributes__value__link" rel="noreferrer" href={data as string}>
        {data}
      </Text>
    );
  }
  return <span className="dynamic-attributes__value">{data}</span>;
};

const DynamicAttributes = ({
  dynamicAttributes,
  isSoftphone
}: {
  dynamicAttributes: Record<string, ContactAttributesValue>;
  isSoftphone?: boolean;
}) => {
  if (_isEmpty(dynamicAttributes)) {
    return null;
  }

  const dynamicAttributesToMap = Object.entries(dynamicAttributes).map(([key, attribute]) =>
    getFusionDataFromMap(key, attribute?.value)
  );
  const dynamicAttributeDetails = dynamicAttributesToMap.filter(({ type }) => type === FUSION_DATA_BLOCK_TYPES.DYNAMIC);

  if (!dynamicAttributeDetails.length) {
    return null;
  }

  return (
    <div
      className={cx('dynamic-attributes__container', {
        ['dynamic-attributes__container--softphone']: isSoftphone
      })}
      data-testid="dynamic-attributes-container-testId"
    >
      {dynamicAttributeDetails.map((attribute, index) => {
        const { label } = attribute;

        return (
          <div key={`${label}-${index}`}>
            <span className="dynamic-attributes__label">{label}:</span>
            <DynamicValueDisplay attribute={attribute} />
          </div>
        );
      })}
    </div>
  );
};

interface IContactAttributes {
  attributes: Record<string, ContactAttributesValue> | undefined;
}

export default function ContactAttributes({ attributes }: IContactAttributes) {
  const { isSoftphone } = useLayout();
  const dynamicAttributes = attributes ? _omit(attributes, ['intents', 'transcript']) : {};

  return (
    <div className="contact-attributes">
      <DynamicAttributes dynamicAttributes={dynamicAttributes} isSoftphone={isSoftphone} />
      <Intents intents={attributes?.intents?.value} isSoftphone={isSoftphone} />
      <Transcripts transcript={attributes?.transcript?.value} isSoftphone={isSoftphone} />
    </div>
  );
}
