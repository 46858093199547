import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

import Column from 'lib/common/components/atoms/Column';
import ResizableGroup from 'lib/common/components/molecules/ResizableGroup';

import TaskDetail from '../../TaskDetail';
import TaskList from '../../TaskList/TaskList';
import COLUMN_IDS from '../constants/columnIds';
import LAYOUTS from '../constants/layouts';
import NO_TASK_PLACEHOLDERS from '../constants/noTaskPlaceholders';
import TASK_FILTER_OPTIONS from '../constants/taskFilterOptions';
import get2ColLayoutId from '../utils/get2ColLayoutId';

export default function TasksAndControls({
  selectedTask,
  tasks,
  filter,
  selectedTaskId,
  setSelectedTaskId,
  onFilterTasks,
  setCreateAgentTaskOpen,
  setSendSmsModalOpen,
  setCreateTaskDefaults,
  user
}) {
  return (
    <ResizableGroup
      data-testid="tasks-and-controls-group"
      containerClassName="agent-workspace"
      layoutOptions={LAYOUTS.TASKS_AND_CONTROLS}
      vertical
    >
      <Column minWidth={250} key={get2ColLayoutId(COLUMN_IDS.TASKS)} vertical>
        <TaskList
          tasks={tasks}
          selectedFilter={filter}
          selectedTaskId={selectedTaskId}
          setSelectedTaskId={setSelectedTaskId}
          onFilter={onFilterTasks}
          setCreateAgentTaskOpen={setCreateAgentTaskOpen}
          setSendSmsModalOpen={setSendSmsModalOpen}
          filterOptions={TASK_FILTER_OPTIONS}
          noTasksPlaceholder={NO_TASK_PLACEHOLDERS[filter.value || 'default']}
        />
      </Column>
      <Column
        key={get2ColLayoutId(COLUMN_IDS.CONTROLS)}
        minimisedIcon={faCircleInfo}
        minWidth={150}
        isResizable
        vertical
        className="pb-0"
      >
        <TaskDetail
          selectedTask={selectedTask}
          setCreateAgentTaskOpen={setCreateAgentTaskOpen}
          setCreateTaskDefaults={setCreateTaskDefaults}
          user={user}
        />
      </Column>
    </ResizableGroup>
  );
}
