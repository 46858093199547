import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';

import { TUpdateAgentPreferenceBodySchema } from '@cloud-wave/neon-common-lib';

import Button from 'lib/common/components/Button';

import styles from '../email-signature-modal.module.scss';

const ViewEmailSignatureActions = ({
  updatePreference,
  setEditMode
}: {
  setEditMode: (value: boolean) => void;
  updatePreference: (agentPreference: TUpdateAgentPreferenceBodySchema) => Promise<void>;
}) => {
  return (
    <div className={styles['email-signature-modal__actions']}>
      <Button
        data-testid="edit-signature-button"
        size="small"
        icon={faEdit}
        styleType="SECONDARY"
        onClick={() => {
          setEditMode(true);
        }}
      />
      <Button
        data-testid="delete-signature-button"
        size="small"
        icon={faTrash}
        styleType="SECONDARY_DANGER"
        asyncAction
        onClick={async () => await updatePreference({ emailSignature: '' })}
      />
    </div>
  );
};

export default ViewEmailSignatureActions;
