import { createContext, useContext } from 'react';

import { ConstValues } from '@cloud-wave/neon-common-lib';

import { DIRECTORY_TAB } from 'lib/common/components/molecules/Directory/constants/directoryTabs';

export const TransferDestination = {
  ALL: 'ALL',
  AGENTS: 'AGENTS'
} as const;
export type TTransferDestination = ConstValues<typeof TransferDestination>;

export const OverlayType = {
  DIRECTORY: 'DIRECTORY',
  DIALPAD: 'DIALPAD',
  PREFERENCES: 'PREFERENCES'
} as const;
export type TOverlayType = ConstValues<typeof OverlayType>;

export type TOverlayState =
  | {
      currentOverlay: null;
      overlayProps: null;
    }
  | {
      currentOverlay: TOverlayType;
      overlayProps: null;
    }
  | {
      currentOverlay: typeof OverlayType.DIRECTORY;
      overlayProps: { transferTo?: TTransferDestination; initialTab?: DIRECTORY_TAB };
    }
  | {
      currentOverlay: typeof OverlayType.DIALPAD;
      overlayProps: { isDialpadTransfer?: boolean };
    };

type TOverlayActions = {
  openOverlay: <T extends TOverlayType>(
    type: T,
    overlayProps?: Extract<TOverlayState, { currentOverlay: T }>['overlayProps']
  ) => void;
  closeOverlay: () => void;
};

type TOverlayContext = TOverlayState & TOverlayActions & { overlayOpen: boolean };

const Context = createContext<TOverlayContext>({
  openOverlay: () => {},
  closeOverlay: () => {},

  overlayOpen: false,
  currentOverlay: null,
  overlayProps: null
});

export default Context;

export const useOverlayContext = () => useContext(Context);
