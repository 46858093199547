import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';

import { useConfigContext } from 'lib/core/config';

import { useAgentContext } from 'lib/common/contexts/AgentContext';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';

import useHookForm from 'lib/common/hooks/useHookForm';

import connectGetter from 'lib/common/utils/connectGetter';
import getPhoneNumberFromContact from 'lib/common/utils/getPhoneNumberFromContact';

import sendSms from './api/sendSms';
import SmsBlock from './components/SmsBlock';
import sendSmsSchema from './schema/sendSmsSchema';

const DEFAULT_CHARACTER_LIMIT = 160;

export default function useSendSms() {
  const {
    state: { tasks, selectedTaskId }
  } = useContactContext();
  const [templatedResponsesOpen, setTemplatedResponsesOpen] = useState(false);
  const { agent } = useAgentContext();
  const { config } = useConfigContext();
  const {
    actions: { updateModalOptions }
  } = useSoftphoneModalContext();
  const { useForm } = useHookForm();
  const [loading, setLoading] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [hasPassedCharacterLimit, setHasPassedCharacterLimit] = useState(false);
  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);

  const outboundSmsDefaultValues = {
    phoneNumber: getPhoneNumberFromContact(selectedTask?.contact),
    message: ''
  };
  const messageCharacterLimit = config?.OUTBOUND_SMS?.messageCharacterLimit || DEFAULT_CHARACTER_LIMIT;

  useEffect(() => {
    if (characterCount > messageCharacterLimit) {
      return setHasPassedCharacterLimit(true);
    }

    setHasPassedCharacterLimit(false);
  }, [characterCount]);

  const {
    control,
    setValue,
    formState: { errors, isValid },
    reset,
    clearErrors,
    getValues,
    trigger
  } = useForm({
    defaultValues: outboundSmsDefaultValues,
    mode: 'onBlur',
    resolver: yupResolver(sendSmsSchema)
  });

  const onAddTemplateContent = (templateContent: string) => {
    const { message } = getValues();
    const newMessage = `${message} ${templateContent}`;
    setValue('message', newMessage);
    setCharacterCount(newMessage.length);
    trigger('message');
    setTemplatedResponsesOpen(false);
  };

  const handleSendSms = async () => {
    setLoading(true);
    const { phoneNumber, message } = getValues();
    const agentUserID = connectGetter(agent, 'getConfiguration')?.username;

    await sendSms(config?.OUTBOUND_SMS, {
      phoneNumber,
      message,
      agentUserID
    });

    setLoading(false);
  };

  const handleResetSmsForm = () => {
    reset(outboundSmsDefaultValues);

    setCharacterCount(0);
  };

  const isDisabled = !isValid || hasPassedCharacterLimit;

  useEffect(() => {
    updateModalOptions({
      onCancel: templatedResponsesOpen ? () => setTemplatedResponsesOpen(false) : void 0,
      secondaryButtonText: templatedResponsesOpen ? 'Back' : 'Cancel'
    });
  }, [templatedResponsesOpen]);

  return {
    sendSmsForm: (
      <SmsBlock
        onAddTemplateContent={onAddTemplateContent}
        errors={errors}
        clearErrors={clearErrors}
        control={control}
        loading={loading}
        setValue={setValue}
        setCharacterCount={setCharacterCount}
        characterCount={characterCount}
        messageCharacterLimit={messageCharacterLimit}
        hasPassedCharacterLimit={hasPassedCharacterLimit}
        trigger={trigger}
        templatedResponsesOpen={templatedResponsesOpen}
        setTemplatedResponsesOpen={setTemplatedResponsesOpen}
      />
    ),
    handleSendSms,
    isDisabled,
    handleResetSmsForm,
    templatedResponsesOpen
  };
}
