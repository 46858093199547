import cx from 'classnames';

import Avatar from 'lib/common/components/Avatar';
import { Actions } from 'lib/common/components/Profile';
import Text from 'lib/common/components/Text';

import TProfile from 'lib/common/types/CustomerProfile';
import getUserName from 'lib/common/utils/getUserName';

interface IHeader {
  profile?: TProfile | null;
  profileNotes?: string;
  onDelete: () => void;
  onEdit: () => void;
}

const Header = ({ profile, profileNotes, onDelete, onEdit }: IHeader) => {
  const name = getUserName({ ...profile, fallback: 'Customer' });

  return (
    <div className={cx('customer-profile__header')}>
      <div className="customer-profile__header__username">
        <Avatar name={name} />
        <Text type="heading2">{name}</Text>
      </div>
      <Actions
        showExternalLink
        profile={profile}
        profileNotes={profileNotes}
        showOnCall={false}
        onDelete={onDelete}
        onEdit={onEdit}
      />
    </div>
  );
};

export default Header;
