import { faArrowUpLeft, faCog, faSignature } from '@fortawesome/pro-regular-svg-icons';

import { useAgentPreferencesContext } from 'lib/common/contexts/AgentPreferenceContext/Context';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import ClickableIcon from 'lib/common/components/ClickableIcon/ClickableIcon';
import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';
import Popover from 'lib/common/components/molecules/Popover';

import './overrides.scss';

const DEFAULT_POPOVER_OFFSET = 10;

interface EmailSignatureMenuProps {
  onClick: (signature: string) => void;
}

export const EmailSignatureMenu = ({ onClick }: EmailSignatureMenuProps) => {
  const { isSoftphone } = useLayout();
  const {
    state: {
      agentPreference: { emailSignature }
    },
    actions: { setPreferenceModalOpen }
  } = useAgentPreferencesContext();
  const offsets = isSoftphone ? { offset: 0, offsetLeft: 0 } : { offset: 20, offsetLeft: 0 };

  const disableInsert = !emailSignature || emailSignature === '';

  return (
    <Popover
      anchor={<ClickableIcon icon={faSignature} onClick={() => {}} tooltip="Email Signature" />}
      reverse
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      offset={-1 * (offsets.offset + DEFAULT_POPOVER_OFFSET)}
      offsetLeft={offsets.offsetLeft}
    >
      {({ onClose }) => (
        <div>
          <PopoverMenuItem
            text={'Insert Signature'}
            icon={faArrowUpLeft}
            disabled={disableInsert}
            onClick={() => {
              if (emailSignature) {
                onClick?.(emailSignature);
              }
              onClose?.();
            }}
          />
          <PopoverMenuItem
            text={'Manage'}
            icon={faCog}
            onClick={() => {
              onClose();
              setPreferenceModalOpen(true);
            }}
          />
        </div>
      )}
    </Popover>
  );
};
