import { useEffect, useMemo, useState } from 'react';

import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import _orderBy from 'lodash.orderby';
import isURL from 'validator/lib/isURL';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useAgentContext } from 'lib/common/contexts/AgentContext';
import { useInstanceContext } from 'lib/common/contexts/InstanceContext';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import getDateFormat from 'lib/common/utils/getDateFormat';

import createTask from './api/createTask';
import TaskDateTime from './components/TaskDateTime';
import TaskInformation from './components/TaskInformation';
import TaskLocation from './components/TaskLocation';
import TaskReferences from './components/TaskReferences';

export type IReference = { id: string; name: string; url: string };

export enum Tab {
  QUEUE = 'Queue',
  AGENT = 'Agent'
}

interface IUseCreateAgentTaskProps {
  defaultName: string;
  defaultDescription: string;
  open: boolean;
}

interface AgentType {
  label: string;
  value: string;
}

export default function useCreateAgentTask({ defaultName, defaultDescription, open }: IUseCreateAgentTaskProps) {
  const { agent } = useAgentContext();
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const { instanceQueues } = useInstanceContext();
  const [name, setName] = useState(defaultName);
  const [references, setReferences] = useState<IReference[]>([]);
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.QUEUE);
  const [description, setDescription] = useState(defaultDescription);
  const [selectedEntity, setSelectedEntity] = useState<any>(null);
  const [scheduledDateTime, setScheduledDateTime] = useState<any>(null);
  const [scheduledDateTimeFieldValues, setScheduledDateTimeFieldValues] = useState<{ date?: Date; time?: Date }>({});
  const [agentsLoading, setAgentsLoading] = useState(false);
  const [invalidDateTimeError, setInvalidDateTimeError] = useState<string>('');
  const [agentOptions, setAgentOptions] = useState<AgentType[]>([]);

  const fetchAgents = async () => {
    setAgentsLoading(true);
    setAgentOptions([]);

    const AGENT_URL = `${config.AGENT_SERVICE_URL}/contacts?tenantId=${config.TENANT_ID}&includeAgents=true`;
    const { items: contacts } = await (await fetch_(AGENT_URL)).json();

    const agents = _orderBy(
      contacts.filter((agent) => agent?.objectIdentifier?.endsWith?.('__agent')),
      'firstName',
      'asc'
    ).map((agent) => ({
      label: `${agent.firstName} ${agent.lastName}`,
      value: agent.objectId.split('_')[1]
    }));

    setAgentOptions(agents);
    setAgentsLoading(false);
  };

  const addReference = () => {
    setReferences([...references, { id: `${Date.now()}`, name: '', url: '' }]);
  };

  const removeReference = (index) => () => {
    const newReferences = [...references];

    newReferences.splice(index, 1);

    setReferences(newReferences);
  };

  const onUpdateReference =
    (index, field) =>
    ({ target: { value } }) => {
      const newReferences = [...references];

      newReferences.splice(index, 1, { ...newReferences[index], [field]: value });

      setReferences(newReferences);
    };

  const handleDateTimeChange = (value, field) => {
    const updatedScheduledDateTimeFieldValues = {
      ...scheduledDateTimeFieldValues,
      [field]: value
    };

    setScheduledDateTimeFieldValues(updatedScheduledDateTimeFieldValues);

    if (!updatedScheduledDateTimeFieldValues.date && !updatedScheduledDateTimeFieldValues.time) {
      setInvalidDateTimeError('');
      return setScheduledDateTime(null);
    }

    if (!value && updatedScheduledDateTimeFieldValues.date && !updatedScheduledDateTimeFieldValues.time) {
      return setInvalidDateTimeError('Time is required');
    }

    if (!value) {
      return;
    }

    const scheduled = scheduledDateTime ? new Date(scheduledDateTime) : new Date();

    const newValue =
      field === 'date'
        ? new Date(value?.setMinutes(new Date().getMinutes() + 5))
        : new Date(new Date(scheduled.setMinutes(value.getMinutes())).setHours(value.getHours()));

    if (newValue?.toString() === 'Invalid Date') {
      return setInvalidDateTimeError(`Date/time should be in the format ${getDateFormat()} hh:mm AM/PM`);
    }

    if (newValue < new Date()) {
      return setInvalidDateTimeError('Date has to be in the future');
    }

    setInvalidDateTimeError('');
    setScheduledDateTime(newValue);
  };

  const areValidReferences = references.every(
    (reference) => (reference.name && reference.url) || (reference.name && isURL(reference.url))
  );

  const queueOptions = useMemo(
    () =>
      _orderBy(
        instanceQueues.map((queue) => ({
          label: queue.Name,
          value: queue.Name
        })),
        'label',
        'asc'
      ),
    [instanceQueues]
  );

  const blocks = [
    {
      title: 'Basics',
      summary: 'Information about the task',
      summaryAbbreviated: 'Basic Information',
      content: (
        <TaskInformation name={name} setName={setName} description={description} setDescription={setDescription} />
      )
    },
    {
      title: 'References',
      optional: true,
      actionIcon: faPlus,
      className: 'create-agent-task__block',
      actionIconOnClick: addReference,
      content: (
        <TaskReferences
          references={references}
          onUpdateReference={onUpdateReference}
          removeReference={removeReference}
          addReference={addReference}
        />
      )
    },
    {
      title: 'Where',
      summary: 'Where will the task go?',
      summaryAbbreviated: 'Where will it go?',
      className: 'create-agent-task__block',
      content: (
        <TaskLocation
          setSelectedTab={setSelectedTab}
          loading={selectedTab === Tab.AGENT && agentsLoading}
          selectedEntity={selectedEntity}
          selectedTab={selectedTab}
          optionsList={selectedTab === Tab.QUEUE ? queueOptions : agentOptions}
          setSelectedEntity={setSelectedEntity}
        />
      )
    },
    {
      title: 'When',
      optional: true,
      summary: 'What time will the task arrive?',
      summaryAbbreviated: '',
      className: 'create-agent-task__block',
      content: (
        <TaskDateTime
          scheduledDateTime={scheduledDateTime}
          handleDateTimeChange={handleDateTimeChange}
          invalidDateTimeError={invalidDateTimeError}
        />
      )
    }
  ];

  useEffect(() => {
    setSelectedEntity(null);
  }, [selectedTab]);

  useEffect(() => {
    if (open) {
      logger.info(LogEvents.AGENT_TASK.CREATE.OPEN);

      fetchAgents();
      setName(defaultName);
      setDescription(defaultDescription);

      return;
    }

    setName('');
    setDescription('');
    setReferences([]);
    setScheduledDateTime(null);
    setSelectedEntity(null);
    setInvalidDateTimeError('');
    setSelectedTab(Tab.QUEUE);
  }, [open]);

  return {
    blocks,
    saveDisabled: !name || !selectedEntity || !areValidReferences || Boolean(invalidDateTimeError),
    createTask: () =>
      createTask({
        fetch_,
        config,
        selectedTab,
        selectedEntity,
        references,
        agent,
        name,
        description,
        scheduledDateTime
      })
  };
}
