import { useState } from 'react';
import Iframe from 'react-iframe';

import cx from 'classnames';

import Loader from 'lib/common/components/Loader';

import './frame.scss';

export default function Frame({
  source,
  id,
  className,
  loaderSize,
  allow,
  title
}: {
  loaderSize?: number;
  source: string;
  id: string;
  className?: string;
  allow?: string;
  title?: string;
}) {
  const [loading, setLoading] = useState(true);

  const onLoad = () => {
    setLoading(false);
  };

  return (
    <>
      {loading && <Loader size={loaderSize} />}
      <div className={cx('frame__iframe-container', className)} data-testid={`${id}-container`} id={`${id}-container`}>
        <Iframe title={title} allow={allow} url={source} id={id} height="100%" frameBorder={0} onLoad={onLoad} />
      </div>
    </>
  );
}
