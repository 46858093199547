import { useEffect, useMemo, useState } from 'react';

import { useAgentContext } from 'lib/common/contexts/AgentContext';
import { usePresenceContext } from 'lib/common/contexts/PresenceContext';

import presenceStates, { PRESENCE_STATES } from 'lib/common/constants/presenceStates';

import getStatusColour from '../../utils/presence/getStatusColour';
import getStatusIcon from '../../utils/presence/getStatusIcon';
import getStatusIconColour from '../../utils/presence/getStatusIconColour';

function getPresenceOptions(agentStates) {
  const defaultStates = Object.values(presenceStates);
  const defaultStatesWithoutOffline = defaultStates.filter((state) => state !== PRESENCE_STATES.OFFLINE);
  const customStates = agentStates.filter(({ name: state }) => !defaultStates.includes(state)).map(({ name }) => name);
  const orderedStates = [...defaultStatesWithoutOffline, ...customStates, PRESENCE_STATES.OFFLINE];

  return {
    presenceOptions: orderedStates.map((state: string) => ({
      value: state,
      label: state,
      backgroundColor: getStatusColour(state),
      iconColor: getStatusIconColour(state),
      icon: getStatusIcon(state)
    }))
  };
}

export default function usePresence() {
  const { agentPresence, agentNextState, setConnectPresence } = usePresenceContext();
  const { states: agentStates } = useAgentContext();

  // Having a local state to overcome the lag caused by the context to re render
  const [selectedState, setSelectedState] = useState(agentPresence);

  const { presenceOptions } = getPresenceOptions(agentStates);

  const selectedValue = useMemo(() => {
    return presenceOptions.find((option) => option.value === selectedState);
  }, [selectedState]);

  const onChange = (selectedPresence) => {
    if (!selectedPresence) {
      return;
    }

    return setConnectPresence(selectedPresence.value);
  };

  useEffect(() => {
    setSelectedState(agentPresence);
  }, [agentPresence]);

  return {
    agentNextState,
    onChange,
    presenceOptions,
    selectedValue
  };
}
