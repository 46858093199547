import { faComment, faEdit, faEllipsisV, faTrash } from '@fortawesome/pro-regular-svg-icons';

import { ContactType, TContact, TDirectoryContact } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import { LogEvents, logger } from 'lib/common/components/LoggerController';
import PopoverMenu from 'lib/common/components/PopoverMenu';
import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';
import { PopoverMenuItemType } from 'lib/common/components/PopoverMenuItem/PopoverMenuItem';

import toast from 'lib/common/utils/toast';

import { deleteContact } from '../api';
import { TInternalContacts } from '../types';

const generateTeamsDeeplink = ({ email }: TDirectoryContact) => `https://teams.microsoft.com/l/chat/0/0?users=${email}`;

const handleDeleteContact = async (contact: TInternalContacts, config, fetch_, onDeleteContact) => {
  if (!contact?.contactId) {
    return;
  }

  const { contactId, type } = contact;
  const logEvent = type === ContactType.PERSONAL ? LogEvents.CONTACT.PERSONAL : LogEvents.CONTACT.COMPANY;

  try {
    const result = await deleteContact({ config, fetch_, contactId });

    logger.info(logEvent.DELETE.SUCCESS, { contactId });

    onDeleteContact?.();

    return result;
  } catch (error) {
    logger.error(logEvent.DELETE.FAIL, { contactId, error });

    toast('error', `Something went wrong deleting the contact. Please try again.`);
  }
};

const ContactActionsPopup = ({
  contact,
  isAdmin,
  onEditContactClick,
  isTeamsContact,
  onDeleteContact
}: {
  contact: TContact;
  isAdmin: boolean;
  onEditContactClick?: (contact: TInternalContacts) => void;
  isTeamsContact: boolean;
  onDeleteContact?: () => void;
}) => {
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const canEditContact =
    contact.type === ContactType.PERSONAL || (contact.type === ContactType.ORGANISATION && isAdmin);

  if (isTeamsContact && contact.type === ContactType.EXTERNAL_DIRECTORY) {
    return (
      <PopoverMenu anchor={<ClickableIcon aria-label="More options" icon={faEllipsisV} />}>
        <PopoverMenuItem
          text="Send Message"
          icon={faComment}
          onClick={() => window.open(generateTeamsDeeplink(contact), 'popup', 'width=400,height=600')}
        />
      </PopoverMenu>
    );
  }

  if (!canEditContact || !onEditContactClick) {
    return null;
  }

  return (
    <PopoverMenu anchor={<ClickableIcon aria-label="More options" icon={faEllipsisV} />}>
      <PopoverMenuItem
        text="Edit"
        icon={faEdit}
        onClick={() => {
          onEditContactClick(contact);
        }}
      />
      <PopoverMenuItem type={PopoverMenuItemType.SEPARATOR} />
      <PopoverMenuItem
        text="Delete"
        confirmActionText="Confirm"
        actionInProgressText="Deleting"
        icon={faTrash}
        type={PopoverMenuItemType.DANGER}
        onClick={() => handleDeleteContact(contact, config, fetch_, onDeleteContact)}
      />
    </PopoverMenu>
  );
};

export default ContactActionsPopup;
