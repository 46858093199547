import { ActionMeta } from 'react-select';
import sizeMe from 'react-sizeme';

import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { TreeNode } from 'primereact/treenode';
import { TreeSelect, TreeSelectEventNodeEvent } from 'primereact/treeselect';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import ClickableIcon from 'lib/common/components/ClickableIcon';

import IWrapUpCode from 'lib/common/types/WrapUpCode';

import PLACEHOLDER from '../../constants/placeholder';
import ACWCodeLabel from '../ACWCodeLabel';
import TreeDropdownIndicator from '../TreeDropdownIndicator';
import getTreeSelectStyles from './getTreeSelectStyles';

import styles from './tree.module.scss';

interface ITree {
  loaded: boolean;
  noResults: () => string;
  onChange: (option: unknown, actionMeta?: ActionMeta<unknown>) => void;
  selectedCode?: IWrapUpCode;
  width?: number;
  size: { width?: number };
  options: TreeNode[];
}

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

function Tree({ loaded, onChange, noResults, selectedCode, size: { width }, options }: ITree) {
  const { isSoftphone } = useLayout();

  const onChangeNode = (nodeEvent: TreeSelectEventNodeEvent) => {
    onChange({ wrapUpCode: nodeEvent.node.data });
  };

  const disabled = loaded && !options.length;
  const placeholder = disabled ? PLACEHOLDER.NO_CODES_FOR_QUEUE : PLACEHOLDER.SEARCHABLE;

  return (
    <TreeSelect
      data-testid="acw-tree-select"
      options={options}
      value={selectedCode?.id}
      onNodeSelect={onChangeNode}
      disabled={disabled || !loaded}
      placeholder={placeholder}
      emptyMessage={noResults}
      filter
      showClear
      clearIcon={
        <ClickableIcon
          className={styles['tree__clear-button']}
          icon={faTimes}
          size={15}
          onClick={(e) => {
            e.stopPropagation();
            onChange(null, { action: 'clear' } as ActionMeta<unknown>);
          }}
        />
      }
      dropdownIcon={<TreeDropdownIndicator loading={!loaded} />}
      valueTemplate={
        selectedCode
          ? (selectedNode: TreeNode | TreeNode[]) => {
              const code = Array.isArray(selectedNode) ? selectedNode[0]?.data : selectedNode.data;

              return code?.name ? <ACWCodeLabel isSoftphone={isSoftphone} wrapUpCode={code} /> : undefined;
            }
          : undefined
      }
      pt={getTreeSelectStyles({ width, loading: !loaded, isSoftphone })}
    />
  );
}

export default sizeMeHOC(Tree);
