import { faPlug, faVolumeSlash, faWaveformLines } from '@fortawesome/pro-regular-svg-icons';

import { useContactContext } from 'lib/common/contexts/ContactContext';

import useTaskTimer from 'lib/common/hooks/useTaskTimer';

import Badge from 'lib/common/components/Badge';
import { BadgeTypes } from 'lib/common/components/Badge/Badge';

import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import { getTaskDisplayProps } from 'lib/common/constants/taskStates';
import BADGE_LABEL from 'lib/common/constants/taskStatusBadgeLabels';

import connectGetter from 'lib/common/utils/connectGetter';

import styles from './task-status-badge.module.scss';

interface TaskStatusBadgeProps {
  connection?: connect.BaseConnection;
  callIsOnHold?: boolean;
  isNeglected?: boolean;
  isConnecting?: boolean;
  className?: string;
  isRow?: boolean;
  showBadgeText?: boolean;
}

function getBadgeProps({ isNeglected, isConnecting, task }) {
  if (isConnecting) {
    return {
      text: BADGE_LABEL.CONNECTING,
      icon: faPlug,
      badgeType: BadgeTypes.NEUTRAL
    };
  }

  if (isNeglected) {
    return {
      text: BADGE_LABEL[task.status],
      icon: getTaskDisplayProps(task).icon,
      badgeType: BadgeTypes.DANGER
    };
  }

  return { text: BADGE_LABEL.LIVE, icon: faWaveformLines, badgeType: BadgeTypes.SUCCESS };
}

const HoldStatusBadge = ({ connection, isRow }: { connection?: connect.BaseConnection; isRow?: boolean }) => {
  const holdTime = useTaskTimer(connectGetter(connection, 'getState')?.timestamp);

  return (
    <Badge
      isTimer
      ariaLabel="Hold time elapsed"
      label={holdTime}
      reverse={isRow}
      icon={faVolumeSlash}
      type={BadgeTypes.WARNING}
      bold
      tooltip={BADGE_LABEL.HOLD}
      className={styles['task-status-badge--on-hold']}
      minWidth={isRow ? undefined : 75}
    />
  );
};

export default function TaskStatusBadge({
  connection,
  callIsOnHold,
  isNeglected,
  isConnecting,
  isRow,
  showBadgeText = true
}: TaskStatusBadgeProps) {
  const {
    actions: { getSelectedTask }
  } = useContactContext();

  const task = getSelectedTask() ?? { type: CONTACT_TYPES.CALL };

  if (callIsOnHold) {
    return (
      <span>
        <HoldStatusBadge connection={connection} isRow={isRow} />
      </span>
    );
  }

  const badgeProps = getBadgeProps({ isNeglected, isConnecting, task });

  return (
    <span>
      <Badge
        label={showBadgeText && badgeProps.text}
        icon={badgeProps.icon}
        type={badgeProps.badgeType}
        bold
        tooltip={badgeProps.text}
        minWidth={isRow ? undefined : 75}
      />
    </span>
  );
}
