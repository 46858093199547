import { useHistory } from 'react-router-dom';

import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

import useIsolatedNavigate from 'lib/common/hooks/useIsolatedNavigate';

import Icon from 'lib/common/components/Icon';
import PopoverMenu from 'lib/common/components/PopoverMenu';
import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';

import { SoftphoneNavOption, SoftphoneNavOptionChild } from 'lib/common/types/SoftphoneNavOption';

const MORE_TASKS_MENU_OFFSET = 30;

type TSoftphoneNavMenuFolder = SoftphoneNavOption & {
  childOptions: SoftphoneNavOptionChild[];
  isReversedLayout?: boolean;
};

export default function SoftphoneNavMenuFolder({
  icon,
  name,
  childOptions,
  isReversedLayout
}: TSoftphoneNavMenuFolder) {
  const history = useHistory();
  const { hasPermission, isAdmin } = usePermissionsContext();

  const isolatedNavigate = useIsolatedNavigate();

  const openLink = ({ link, external, isolated }: { link: string; external?: boolean; isolated?: boolean }) => {
    if (external) {
      return void window.open(link, '_blank');
    }

    if (!isolated) {
      return void history.push(link);
    }

    isolatedNavigate(link);
  };

  const options = childOptions.filter(({ permission, permissionType, requiresAdmin }) => {
    const satisfiesAdmin = !requiresAdmin || (requiresAdmin && isAdmin);

    if (!satisfiesAdmin) {
      return false;
    }

    if (!permission) {
      return true;
    }

    return hasPermission({ type: permissionType || 'tenant', permission });
  });

  if (!options.length) {
    return null;
  }

  return (
    <PopoverMenu
      anchor={
        <button
          aria-label="Open app menu"
          className="softphone-nav-menu-item"
          data-testid="softphone-nav-menu-folder-button"
        >
          <Icon tooltip={name} icon={icon} size={18} tooltipPlacement="right" />
        </button>
      }
      offset={0}
      offsetLeft={isReversedLayout ? -MORE_TASKS_MENU_OFFSET : MORE_TASKS_MENU_OFFSET}
    >
      {options.map(({ link, icon: childIcon, name, isolated, external }) => (
        <PopoverMenuItem
          data-testid={name}
          key={link}
          onClick={() => openLink({ link, isolated, external })}
          text={name}
          icon={childIcon}
        />
      ))}
    </PopoverMenu>
  );
}
