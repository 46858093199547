import { MutableRefObject, useMemo, useState } from 'react';
import sizeMe from 'react-sizeme';

import { faPaperPlaneTop, faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import _takeRightWhile from 'lodash.takerightwhile';

import { TemplateType } from '@cloud-wave/neon-common-lib';

import chatEvents from 'lib/common/constants/chatEvents';

import TChatTask from 'lib/common/types/ChatTask';

import Button from '../../Button';
import ClickableIcon from '../../ClickableIcon';
import { ToolbarOptions } from '../../molecules/LexicalEditor';
import { TextFormatter } from '../../molecules/LexicalEditor/TextFormatter/TextFormatter';
import TemplatedResponse from '../../molecules/TemplatedResponse';
import { MAX_MESSAGE_LENGTH } from './constants';

import './chat-send-footer.scss';

// https://docs.aws.amazon.com/connect/latest/adminguide/feature-limits.html#feature-limits-chat
const MAX_NUMBER_OF_ATTACHMENTS = 35;

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

function ChatSendFooterActions({
  task,
  attachmentInputRef,
  size: { width = 0 } = {},
  onAddTemplate,
  queueIds,
  setSending,
  onSendMessage,
  disableSend,
  submitButtonRef,
  messageInputRef
}: {
  task?: TChatTask;
  attachmentInputRef: MutableRefObject<HTMLInputElement | null>;
  size: { width?: number; height?: number };
  onAddTemplate: (arg0: string) => void;
  queueIds: string[];
  setSending: (arg0: boolean) => void;
  onSendMessage: () => Promise<void>;
  disableSend: boolean;
  submitButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
  messageInputRef: React.MutableRefObject<HTMLTextAreaElement | null>;
}) {
  const [toolbarVisible, setToolbarVisible] = useState(false);
  const numberOfAttachments = useMemo(() => {
    const currentChatMessages = _takeRightWhile(
      task?.messages,
      (message) => message.ContentType !== chatEvents.PARTICIPANT_JOINED
    );

    return currentChatMessages.filter(({ Type }) => Type === 'ATTACHMENT').length;
  }, [task?.messages]);

  const attachmentsDisabled = numberOfAttachments >= MAX_NUMBER_OF_ATTACHMENTS;

  return (
    <div className="full-width">
      <div className="chat-send-footer__actions">
        <div className="chat-send-footer__actions__left">
          {!toolbarVisible && (
            <TemplatedResponse type={TemplateType.CHAT} onClick={onAddTemplate} queueIds={queueIds} />
          )}
          {task?.canUseRichText && (
            <TextFormatter
              toolbarVisible={toolbarVisible}
              setToolbarVisible={setToolbarVisible}
              small={width < 250}
              toolbarOptions={[
                ToolbarOptions.close,
                ToolbarOptions.bold,
                ToolbarOptions.italics,
                ToolbarOptions.bulletList,
                ToolbarOptions.numberList,
                ToolbarOptions.links
              ]}
              inlineOverlay
            />
          )}
          {task?.canUseAttachments && !toolbarVisible && (
            <ClickableIcon
              size={17}
              disabled={attachmentsDisabled}
              icon={faPaperclip}
              onClick={() => attachmentInputRef.current?.click()}
              data-testid="upload-files-button"
              tooltip={
                attachmentsDisabled
                  ? `You've reached the max number of attachments for this conversation (${MAX_NUMBER_OF_ATTACHMENTS})`
                  : `Attach file (${numberOfAttachments}/${MAX_NUMBER_OF_ATTACHMENTS} in this conversation)`
              }
            />
          )}
        </div>
        <Button
          round
          size="small"
          ref={submitButtonRef}
          disabled={disableSend}
          tooltip={`The max length of messages is ${MAX_MESSAGE_LENGTH} characters`}
          onClick={onSendMessage}
          asyncAction
          onFinally={() => {
            setSending(false);
            messageInputRef?.current?.focus();
          }}
          successTimeoutSeconds={0}
          icon={faPaperPlaneTop}
          data-testid="send-chat-message-button"
        />
      </div>
    </div>
  );
}

export default sizeMeHOC(ChatSendFooterActions);
