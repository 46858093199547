import Text from 'lib/common/components/Text';
import CopyToClipboardIcon from 'lib/common/components/atoms/CopyToClipboardIcon';

import toast from './toast';

export default function requestErrorToast({
  errorReference,
  pointOfContact,
  errorMessage
}: {
  errorReference?: string;
  pointOfContact?: string;
  errorMessage?: string;
}) {
  toast(
    'error',
    <>
      {errorMessage || 'Oops, something went wrong.'} If you keep having problems, get in touch
      {pointOfContact ? ` with your ${pointOfContact}` : ''}
      {errorReference ? (
        <>
          {!pointOfContact ? ' ' : ''}
          using the error code reference{' '}
          <Text inline bold type="extraSmall">
            {errorReference}
          </Text>
          .
          <CopyToClipboardIcon text={errorReference} size={12} className="ml-5" />
        </>
      ) : (
        '.'
      )}
    </>
  );
}
