import { createContext, useContext } from 'react';

import defaultContextValues from './constants/defaultContextValues';
import TContactContext from './types/ContactContext';

const Context = createContext<TContactContext>(defaultContextValues);

export const useContactContext = () => useContext(Context);

export default Context;
