import { useEffect, useState } from 'react';

import { FormHelperText, createStyles, makeStyles } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import _capitalize from 'lodash.capitalize';
import { v4 as uuid } from 'uuid';
import { useBooleanState } from 'webrix/hooks';
import * as Yup from 'yup';

import { useAgentContext } from 'lib/common/contexts/AgentContext';

import Button from 'lib/common/components/Button';
import { Input } from 'lib/common/components/Input';
import Loader from 'lib/common/components/Loader';
import Modal from 'lib/common/components/Modal';
import ReactSelect from 'lib/common/components/ReactSelect';

import { TConfig } from 'lib/common/types/Config';
import EventEmitter from 'lib/common/utils/EventEmitter';
import getDropdownOptionsFromValues from 'lib/common/utils/getDropdownOptionsFromValues';
import getUserName from 'lib/common/utils/getUserName';
import toast from 'lib/common/utils/toast';

const ROLE_OPTIONS = getDropdownOptionsFromValues(['agent', 'admin'], _capitalize);

const LOAD_USER_ERROR_MESSAGE =
  'Oops, something went wrong preparing this user to be added. Please close the modal window and try again.';

const useStyles = makeStyles(() =>
  createStyles({
    modalRoot: {
      display: 'flex',
      margin: 'auto',
      height: '100%'
    },
    textField: {
      marginBottom: '40px',
      width: '100%'
    },
    selectMenu: {
      '& > *': {
        width: '100%'
      },
      marginBottom: '40px'
    },
    selectHelper: {
      marginBottom: '1vw'
    },
    selectLabel: {
      marginTop: '0.5vw'
    },
    progressLoader: {
      marginTop: '10px'
    },
    title: { textAlign: 'center', marginBottom: '2vw' },
    successMessage: { color: 'green' },
    errorMessage: { color: 'red' },
    paper: {
      padding: '40px',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
      borderRadius: 16,
      margin: 'auto',
      height: 'fit-content',
      width: 'calc(100% - 20px)',
      maxWidth: '500px'
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',

      '&>.button:first-of-type': {
        marginRight: '20px'
      }
    }
  })
);

interface IProps {
  getAllUsers: (...args: any) => any;
  editMode: boolean;
  rowSelected: any;
  toggleAddUser: (...args: any) => any;
  show: boolean;
  fetch: (url: string, options?: any) => Promise<any>;
  config: TConfig;
}

export interface IUserForm {
  tenantId?: string;
  uuid?: string;
  objectKey: string;
  objectId: string;
  objectIdentifier: string;
  firstName?: string;
  lastName?: string;
  organization?: string;
  email?: string;
  phoneNumber?: string;
  routingProfileId?: string;
  routingProfileName?: string;
  securityProfileIds?: string[];
  status?: string;
  role?: string;
  name: string;
  isNeonUser: boolean;
}

type IFormStatus = null | 'ERROR' | 'SUCCESS';

const ManageUsersInner = (props: IProps) => {
  const { rowSelected, editMode, config, fetch, getAllUsers, toggleAddUser } = props;
  const { agent } = useAgentContext();

  const classes = useStyles();

  const [formStatus, setFormStatus] = useState<IFormStatus>();
  const [user, setUser] = useState(rowSelected);

  const { value: loadingUser, toggle: toggleLoadingUser } = useBooleanState(!editMode);

  useEffect(() => {
    if (editMode) {
      return;
    }

    const objectId = user.objectId.split('_')[user.objectId.split('_').length - 1];
    const url = `${config.AGENT_SERVICE_URL}/connect/${config.TENANT_ID}/user/${objectId}`;

    fetch(url)
      .then(async (res) => {
        setUser(await res.json());

        // Only stop loading when successful. If an error occurs, show a toast and leave the modal in loading state
        toggleLoadingUser();
      })
      .catch((e) => {
        console.error(e);

        toast('error', LOAD_USER_ERROR_MESSAGE);
      });
  }, [config, editMode, user.objectId, fetch]);

  const upsertUser = async (data: IUserForm, { setSubmitting }) => {
    // eslint-disable-next-line prefer-const, @typescript-eslint/no-unused-vars
    let { name, isNeonUser, ...item } = data;

    if (!editMode) {
      item = Object.assign(item, {
        tenantId: config.TENANT_ID,
        uuid: uuid()
      });
    }

    setSubmitting(true);

    const action = !editMode ? 'add' : 'update';
    const url = `${config.AGENT_SERVICE_URL}/agent?type=agent&action=${action}`;

    try {
      const res = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(item)
      });

      if (res.status !== 200) {
        throw new Error();
      }

      setFormStatus('SUCCESS');

      getAllUsers();
      toggleAddUser();

      const currentUsername = agent?.getConfiguration()?.username;
      const updatedUsername = rowSelected.objectKey.replace(`${rowSelected.organization}__`, '');

      if (currentUsername !== updatedUsername) {
        return;
      }

      EventEmitter.emit('initUserData', currentUsername);
    } catch {
      setFormStatus('ERROR');
      setSubmitting(false);
      setFormStatus(null);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={user}
      onSubmit={(data, actions) => {
        upsertUser(data, actions);
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required('Please enter a first name.'),
        lastName: Yup.string().required('Please enter a last name.'),
        role: Yup.string().required('Please select a user role.')
      })}
    >
      {(props: FormikProps<IUserForm>) => {
        const { setFieldValue, values, touched, errors, handleBlur, handleChange, isSubmitting } = props;

        return (
          <Modal
            open
            onClose={toggleAddUser}
            medium
            hideFooter
            contentTopPadded
            title={
              editMode
                ? `Edit ${getUserName({ ...rowSelected, fallback: 'a User' })}`
                : `Add a New User To ${config.BRAND.productName}`
            }
          >
            {loadingUser ? (
              <Loader relative small />
            ) : (
              <Form translate="yes">
                <div className={classes.textField}>
                  <Input
                    autoFocus
                    label="First Name"
                    name="firstName"
                    id="firstName"
                    value={values.firstName}
                    type="text"
                    helperText={errors.firstName && touched.firstName ? errors.firstName : void 0}
                    error={!!(errors.firstName && touched.firstName)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classes.textField}>
                  <Input
                    label="Last Name"
                    name="lastName"
                    id="lastName"
                    value={values.lastName}
                    type="text"
                    helperText={errors.lastName && touched.lastName ? errors.lastName : void 0}
                    error={!!(errors.lastName && touched.lastName)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classes.selectMenu}>
                  <ReactSelect
                    defaultValue={ROLE_OPTIONS.find(({ value }) => value === user.role)}
                    onChange={(option) => setFieldValue('role', (option as { value: string }).value)}
                    options={ROLE_OPTIONS}
                    label="Role"
                  />
                  <FormHelperText className={classes.selectHelper}>
                    {errors.role && touched.role ? errors.role : void 0}
                  </FormHelperText>
                </div>
                <div className={classes.footer}>
                  <Button styleType="SECONDARY" disabled={isSubmitting} onClick={toggleAddUser}>
                    Cancel
                  </Button>
                  <Button disabled={isSubmitting || formStatus === 'ERROR'} type="submit" busy={isSubmitting}>
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Modal>
        );
      }}
    </Formik>
  );
};

export default function ManageUsers(props: IProps) {
  if (!props.show) {
    return null;
  }

  return <ManageUsersInner {...props} />;
}
