import newBaseTask from './newBaseTask';
import newChatTask from './newChatTask';
import newTask from './newTask';
import reportableTasks from './reportableTasks';
import updatedBaseTask from './updatedBaseTask';
import updatedChatTask from './updatedChatTask';
import updatedTask from './updatedTask';

export default {
  newTask,
  updatedTask,
  newChatTask,
  newBaseTask,
  reportableTasks,
  updatedChatTask,
  updatedBaseTask
};
