import { THasPermission } from 'lib/common/contexts/PermissionsContext/Provider';

import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import CONTACT_STATES from 'lib/common/constants/contactStates';

import ChatTask from 'lib/common/types/ChatTask';
import Task from 'lib/common/types/Task';
import { THydratedChat } from 'lib/common/types/chat/HydratedChat';

import CONNECT_TYPE_MAP from '../constants/connectTypeMap';
import { TChatChanges } from '../types/ChatChanges';
import TCustomerProfile from '../types/CustomerProfile';
import THandleContactChange from '../types/HandleContactChange';
import taskBuilder from './taskBuilder';

type TGetUpdatedTasks = {
  contact?: connect.Contact;
  profile?: TCustomerProfile;
  status?: ValueOf<typeof CONTACT_STATES>;
  connectionState?: ValueOf<typeof CONNECTION_STATES>;
  isDestroy?: boolean;
  type?: ValueOf<typeof CONNECT_TYPE_MAP>;
  taskId?: string;
  handleContactChange: THandleContactChange;
  getTasks: () => (Task | ChatTask)[];
  getSelectedTaskId: () => string | undefined;
  icon: string;
  newChat?: TChatChanges;
  hydratedChat?: THydratedChat;
  hasPermission: THasPermission;
};

export default async function getUpdatedTasks({
  contact,
  isDestroy = false,
  getTasks,
  status,
  connectionState,
  profile,
  handleContactChange,
  taskId,
  getSelectedTaskId,
  icon,
  newChat,
  hydratedChat,
  hasPermission,
  ...taskProps
}: TGetUpdatedTasks) {
  const tasks = getTasks();

  if (!contact && !taskId) {
    return null;
  }

  const taskIndex = tasks.findIndex(
    ({ contact: existingContact, taskId: existingTaskId }) =>
      (taskId && existingTaskId === taskId) || (contact && existingContact.contactId === contact.contactId)
  );

  const taskExists = taskIndex >= 0;

  // If we're updating or deleting tasks after the contact has been destroyed
  if (!taskExists && (!status || status !== CONTACT_STATES.CONNECTING)) {
    return null;
  }

  if (isDestroy) {
    const newTasks = [...getTasks()];

    newTasks.splice(taskIndex, 1);

    return newTasks;
  }

  // When it's a new contact
  if (taskIndex < 0) {
    return taskBuilder.newTask({
      contact,
      status,
      hasPermission,
      getTasks
    });
  }

  return taskBuilder.updatedTask({
    taskIndex,
    tasks,
    status,
    connectionState,
    contact,
    profile,
    handleContactChange,
    icon,
    getTasks,
    getSelectedTaskId,
    newChat,
    hydratedChat,
    taskProps
  });
}
