import { ReactElement, cloneElement } from 'react';

import cx from 'classnames';

import Text from 'lib/common/components/Text';

import './label.scss';

type TLabel = {
  id: string;
  LabelWrapperComponent?: ReactElement | null;
  children: ReactElement;
  text: string;
  className?: string;
  required?: boolean;
};

export default function Label({ id, children, text, className, required, LabelWrapperComponent }: TLabel) {
  return (
    <div className={cx('label-wrapped', className)}>
      <Text type="label" htmlFor={id}>
        {text}
        {required && <span className="label-wrapped__required">*</span>}
        {LabelWrapperComponent && cloneElement(LabelWrapperComponent)}
      </Text>
      {cloneElement(children, { id })}
    </div>
  );
}
