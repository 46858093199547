import { useEffect, useRef } from 'react';
import sizeMe from 'react-sizeme';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useBooleanState } from 'webrix/hooks';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import Text from 'lib/common/components/Text';
import CreateTaskPopover from 'lib/common/components/atoms/CreateTaskPopover';
import Tooltip from 'lib/common/components/atoms/Tooltip';

import TTask from 'lib/common/types/Task';

import Task from './components/Task';
import TasksFilterMenu from './components/TasksFilterMenu';

import styles from './task-list.module.scss';

type TTaskList = {
  tasks: TTask[];
  selectedTaskId?: string;
  setSelectedTaskId: (taskId: string) => void;
  onFilter: (value: any) => void;
  selectedFilter: { label: string; value: string | null };
  setCreateAgentTaskOpen: (value: boolean) => void;
  setSendSmsModalOpen: (value: boolean) => void;
  filterOptions: { label: string; value: string | null }[];
  noTasksPlaceholder: string;
  size: { height: number };
};

const sizeMeHOC = sizeMe({ monitorHeight: true, monitorWidth: false, refreshRate: 16 });

// First task is the current task and the second task is always visible (factored in to minimum height)
const MINIMUM_NUM_TASKS = 2;

const MINIMUM_TASK_LIST_HEIGHT = 240;

function shouldShowScroll({ tasks, height }) {
  return tasks.length > MINIMUM_NUM_TASKS && height < MINIMUM_TASK_LIST_HEIGHT;
}

function TaskList({
  tasks,
  selectedTaskId,
  setSelectedTaskId,
  onFilter,
  selectedFilter,
  setCreateAgentTaskOpen,
  setSendSmsModalOpen,
  filterOptions,
  noTasksPlaceholder,
  size: { height }
}: TTaskList) {
  const taskListRef = useRef<null | HTMLDivElement>();

  const { value: showScroll, setValue: setScrollVisible } = useBooleanState(shouldShowScroll({ tasks: tasks, height }));

  const onSelectTask = (taskId) => {
    taskListRef?.current?.scroll({ top: 0, behavior: 'smooth' });

    setSelectedTaskId(taskId);
  };

  useEffect(() => {
    setScrollVisible(shouldShowScroll({ tasks: tasks, height }));
  }, [height, tasks]);

  return (
    <div className={styles['task-list']}>
      {selectedTaskId && (
        <div className={styles['task-list__selected-task']}>
          <div className={styles['task-list__header']}>
            <Text type="heading2">Current Task</Text>
            <CreateTaskPopover
              onCreateTask={() => setCreateAgentTaskOpen(true)}
              onSendSms={() => setSendSmsModalOpen(true)}
              anchor={<ClickableIcon icon={faPlus} tooltip="Create New" />}
            />
          </div>
          <div className={styles['task-list__selected-task__spacer']} />
        </div>
      )}
      {(tasks.length > 1 || selectedFilter.value) && (
        <div className={styles['task-list__header']}>
          <Text type="heading2">Other Tasks</Text>
          {showScroll && (
            <Tooltip
              enterDelay={1000}
              title="Your tasks column is not big enough to show all tasks. Scroll down to see all tasks."
              ariaHidden={false}
            >
              <div className={styles['task-list__scroll']} />
            </Tooltip>
          )}
          <TasksFilterMenu selectedFilter={selectedFilter} onFilter={onFilter} filterOptions={filterOptions} />
        </div>
      )}
      <TransitionGroup>
        {tasks.map(({ contact, ...taskProps }, index) => (
          <CSSTransition
            timeout={700}
            classNames={{
              enterActive: styles['task-enter-active'],
              exitActive: styles['task-exit-active'],
              exit: styles['task-exit'],
              enter: styles['task-enter']
            }}
            key={taskProps.taskId}
          >
            <Task
              key={contact.contactId}
              contact={contact}
              selected={contact.contactId === selectedTaskId}
              onSelect={onSelectTask}
              listIndex={index}
              selectedTaskIndex={tasks.findIndex((task) => task.taskId === selectedTaskId)}
              {...taskProps}
            />
          </CSSTransition>
        ))}
        {tasks.length === 1 && selectedFilter.value && (
          <CSSTransition
            timeout={700}
            classNames={{
              enterActive: styles['no-task-placeholder-enter-active'],
              exitActive: styles['no-task-placeholder-exit-active'],
              exit: styles['no-task-placeholder-exit'],
              enter: styles['no-task-placeholder-enter']
            }}
            key="tasks-filter-placeholder"
          >
            <Text type="body" className={styles['no-task-placeholder']}>
              {noTasksPlaceholder}
            </Text>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
}

export default sizeMeHOC(TaskList);
