import sizeMe, { SizeMeProps } from 'react-sizeme';

import cx from 'classnames';

import { ImageType } from 'lib/common/constants/imageBlockFields';

import BrandImage from '../BrandImage';

import styles from './responsive-logo.module.scss';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const SMALL_LOGO_WIDTH = 115;

interface IResponsiveLogo extends SizeMeProps {
  className?: string;
  containerClassName?: string;
  isSoftphone?: boolean;
  tooltip?: string;
  altText?: string;
}

function ResponsiveLogo({
  size: { width },
  containerClassName,
  className,
  isSoftphone,
  tooltip,
  altText
}: IResponsiveLogo) {
  const isSmall = (width || 0) < SMALL_LOGO_WIDTH;

  return (
    <div className={cx('grow next-to', styles['responsive-logo'], containerClassName)}>
      <BrandImage
        tooltip={tooltip}
        altText={altText}
        className={cx(
          styles['responsive-logo__image'],
          {
            [styles['responsive-logo__image--softphone']]: isSoftphone,
            [styles['responsive-logo__image--small']]: isSmall
          },
          className
        )}
        type={isSmall || isSoftphone ? ImageType.FAVICON : ImageType.LOGO}
      />
    </div>
  );
}

export default sizeMeHOC(ResponsiveLogo);
