import {
  faFile,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileWord
} from '@fortawesome/pro-regular-svg-icons';

const ICON_MAP = {
  '.document': faFileWord,
  '.presentation': faFilePowerpoint,
  '.spreadsheet': faFileExcel,
  pdf: faFilePdf,
  image: faFileImage
};

export default function getAttachmentIcon(type: string) {
  const fileIconKey = Object.keys(ICON_MAP).find((iconType) => type.includes(iconType));
  return fileIconKey ? ICON_MAP[fileIconKey] : faFile;
}
