import Text from 'lib/common/components/Text';
import ButtonPreviewIcon from 'lib/common/components/atoms/ButtonPreviewIcon';
import ACWOutcome from 'lib/common/components/molecules/ACW/components/ACWOutcome';
import OutboundPreviewClickToCall from 'lib/common/components/molecules/OutboundPreviewClickToCall';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import QueueNameAndTimer from 'lib/pages/AgentWorkspace/components/TaskDetail/components/QueueNameAndTimer';

import CONTACT_STATES from 'lib/common/constants/contactStates';

import TOutboundPreviewTask from 'lib/common/types/OutboundPreviewTask';

import styles from '../styles/outbound-preview-task.module.scss';

export default function OutboundPreviewTask({
  status,
  description,
  taskId,
  campaign,
  contact,
  connectionValue,
  time,
  queueName
}: TOutboundPreviewTask) {
  if (status !== CONTACT_STATES.CONNECTED) {
    return null;
  }

  return (
    <div className={styles['outbound-preview-task']}>
      <QueueNameAndTimer queueName={queueName} time={time} />
      <ACWOutcome />
      <div className={styles['outbound-preview-task__container']}>
        <div>
          <Text.LearnMore
            learnMoreText={
              <>
                Your contact centre admin has added this number to a call list for you to dial. Click the{' '}
                <ButtonPreviewIcon>Call</ButtonPreviewIcon> button below to make the outbound call.
              </>
            }
            type="heading3"
          >
            Campaign
          </Text.LearnMore>
          <Text className={styles['outbound-preview-task__container__text']}>{connectionValue}</Text>
        </div>
        <div>
          <Text type="heading3">Campaign Description</Text>
          <Text className={styles['outbound-preview-task__container__text']}>
            {description || "This outbound preview doesn't have a description."}
          </Text>
        </div>
        <OutboundPreviewClickToCall campaign={campaign} contact={contact} />
      </div>
      <TaskFooter.OutboundPreviewTask taskId={taskId} connectionValue={connectionValue} />
    </div>
  );
}
