import { ReactElement, ReactNode, cloneElement, useEffect, useState } from 'react';

import { Popover as MUIPopover, PopoverOrigin } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import cx from 'classnames';

import './popover.module.scss';

interface IPopover {
  children: ReactNode;
  anchor?: ReactElement | null;
  offset?: number;
  offsetLeft?: number;
  reverse?: boolean;
  className?: string;
  withTail?: boolean;
  transitionalProps?: TransitionProps;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export default function Popover({
  children,
  anchor,
  offset = 10,
  offsetLeft = 0,
  reverse,
  className = void 0,
  withTail,
  transitionalProps,
  anchorOrigin,
  transformOrigin
}: IPopover) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [reverseState, setReverseState] = useState(reverse);

  const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    const { bottom: lowestPartOfElementInViewPort } = event.currentTarget.getBoundingClientRect();
    const { height: viewPortHeight } = window?.visualViewport || { height: 0 };
    const bottomTenPercent = viewPortHeight * 0.9;
    withTail && lowestPartOfElementInViewPort > bottomTenPercent ? setReverseState(true) : setReverseState(false);

    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(null);

    event?.preventDefault();
    event?.stopPropagation();
  };

  useEffect(() => {
    if (anchor) {
      return;
    }

    setAnchorEl(null);
  }, [anchor]);

  return (
    <>
      {anchor &&
        cloneElement(anchor as ReactElement, {
          onClick: handleAnchorClick,
          'aria-label': `${(anchor as ReactElement).props['aria-label']} popover`
        })}
      <MUIPopover
        className={cx(
          className,
          {
            'popover__with-tail': withTail
          },
          {
            'popover__with-tail--up': withTail && !reverseState,
            'popover__with-tail--down': withTail && reverseState
          }
        )}
        anchorEl={anchorEl}
        anchorOrigin={
          anchorOrigin || {
            vertical: (offsetLeft && 'center') || (reverseState && 'top') || 'bottom',
            horizontal: 'center'
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: (offsetLeft && 'center') || (reverseState && 'bottom') || 'top',
            horizontal: 'center'
          }
        }
        PaperProps={{
          style: {
            marginTop: reverseState ? -offset : offset,
            marginLeft: reverseState ? -offsetLeft : offsetLeft
          }
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        getContentAnchorEl={null}
        TransitionProps={transitionalProps}
      >
        <div className="container">
          {typeof children === 'function' ? children({ onClose: handleClose }) : children}
        </div>
      </MUIPopover>
    </>
  );
}
