import { faLink } from '@fortawesome/pro-regular-svg-icons';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Accordion from 'lib/common/components/Accordion';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import connectGetter from 'lib/common/utils/connectGetter';

import styles from './task-references.module.scss';

const ReferencesWrapper = ({ children }) => (
  <div className={styles['task-references__list']}>
    <Text type="heading3" className={styles['task-references__list__title']}>
      <Icon icon={faLink} /> References
    </Text>
    {children}
  </div>
);

const ReferenceList = ({ list }: { list: connect.ReferenceDictionary }) => (
  <div className={styles['task-references']}>
    {Object.entries(list).map(([key, { value }], index) => (
      <div className={styles['task-references__item']} key={index}>
        <Text type="body" className={styles['task-references__item__title']}>
          <strong>{key}</strong>
        </Text>
        <Text type="link" className={styles['task-references__item__link']} rel="noreferrer" href={value}>
          {value}
        </Text>
      </div>
    ))}
  </div>
);

const TaskReferences = ({ contact }: { contact: connect.Contact }) => {
  const { isSoftphone } = useLayout();
  // @ts-ignore Connect has incorrectly typed toSnapshot's return as Contact, but it's actually { contactData: connect.Contact }
  const { references } = connectGetter(contact, 'toSnapshot')?.contactData || {};
  const hasReferences = references && Boolean(Object.entries(references).length);

  if (!hasReferences) {
    return null;
  }

  if (isSoftphone) {
    return (
      <Accordion title="References" className="mt-10">
        <ReferenceList list={references} />
      </Accordion>
    );
  }

  return (
    <ReferencesWrapper>
      <ReferenceList list={references} />
    </ReferencesWrapper>
  );
};

export default TaskReferences;
