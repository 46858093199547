import CoreDirectory from 'lib/common/components/molecules/Directory';

import getQueryParam from 'lib/common/utils/getQueryParam';

import styles from './directory.module.scss';

export default function Directory() {
  const directoryIndex = getQueryParam({ param: 'directory' });

  return (
    <div className={styles['softphone-directory']}>
      <CoreDirectory initialSelectedTab={directoryIndex} />
    </div>
  );
}
