import { faCheckCircle, faExclamationCircle, faInfoCircle, faWarning } from '@fortawesome/pro-regular-svg-icons';

import PanelTypes from './PanelTypes';

import COLOURS from 'css/export-vars.module.scss';

export default {
  [PanelTypes.SUCCESS]: {
    background: COLOURS.pastelGreen,
    defaultIcon: faCheckCircle,
    textColorName: 'black'
  },
  [PanelTypes.WARNING]: {
    background: COLOURS.pastelYellow,
    defaultIcon: faWarning,
    textColorName: 'black'
  },
  [PanelTypes.DANGER]: {
    background: COLOURS.pastelRed,
    defaultIcon: faExclamationCircle,
    textColorName: 'white'
  },
  [PanelTypes.INFO]: {
    background: COLOURS.lightGrey,
    defaultIcon: faInfoCircle,
    textColorName: 'black'
  },
  [PanelTypes.INFO_CONTRAST]: {
    background: COLOURS.midGrey,
    defaultIcon: faInfoCircle,
    textColorName: 'black'
  }
};
