import { Route, Switch, withRouter } from 'react-router-dom';

import 'what-input';

import { Permissions } from '@cloud-wave/neon-common-lib';
import { PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

import { useConfigContext } from 'lib/core/config';
import { useIsolatedAuthContext } from 'lib/core/context/IsolatedAuthProvider';

// contexts
import PermissionsContext from 'lib/common/contexts/PermissionsContext';

// components
import AuthorisedRoute from 'lib/common/components/AuthorisedRoute';
import NotFound from 'lib/common/components/NotFound';
import WithApiContexts from 'lib/common/components/atoms/WithApiContexts';

import { IsolatedLayout } from 'lib/common/layouts';

import REPORT_TYPES from '../../common/constants/reportTypes';
import Users from '../../pages/Users';
import Report from '../../pages/desktop/Report';

function Routes() {
  const { userData: user } = useIsolatedAuthContext();
  const { config } = useConfigContext();

  return (
    <IsolatedLayout>
      <PermissionsContext config={config} user={user}>
        <Switch>
          <AuthorisedRoute
            requiresAdmin
            exact
            permissionType="tenant"
            permission={Permissions.REPORTS_PERFORMANCE_TODAY}
            path={PAGE_ROUTES.REPORTS_PERFORMANCE_TODAY}
          >
            <WithApiContexts
              isIsolated
              component={Report}
              props={{ type: REPORT_TYPES.PERFORMANCE_TODAY }}
              user={user}
              key={REPORT_TYPES.PERFORMANCE_TODAY}
            />
          </AuthorisedRoute>
          <AuthorisedRoute requiresAdmin exact path={PAGE_ROUTES.USER_SETTINGS}>
            <WithApiContexts isIsolated component={Users} user={user} key={REPORT_TYPES.PERFORMANCE_TODAY} />
          </AuthorisedRoute>
          <AuthorisedRoute
            exact
            requiresAdmin
            permissionType="tenant"
            permission={Permissions.REPORTS_ANALYTICS}
            path={PAGE_ROUTES.REPORTS_ANALYTICS}
          >
            <WithApiContexts
              isIsolated
              component={Report}
              props={{ type: REPORT_TYPES.ANALYTICS }}
              user={user}
              key={REPORT_TYPES.ANALYTICS}
            />
          </AuthorisedRoute>
          <AuthorisedRoute
            exact
            permissionType="tenant"
            permission={Permissions.REPORTS_WALLBOARD}
            path={PAGE_ROUTES.REPORTS_WALLBOARD}
          >
            <WithApiContexts
              isIsolated
              component={Report}
              props={{ type: REPORT_TYPES.WALLBOARD }}
              user={user}
              key={REPORT_TYPES.WALLBOARD}
            />
          </AuthorisedRoute>
          <Route component={NotFound} />
        </Switch>
      </PermissionsContext>
    </IsolatedLayout>
  );
}

export default withRouter(Routes);
