import { faSquareList } from '@fortawesome/pro-regular-svg-icons';

import { TemplateType } from '@cloud-wave/neon-common-lib';
import { EditableFieldTypes } from '@cloud-wave/neon-common-lib/app';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import useHookForm from 'lib/common/hooks/useHookForm';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import Text from 'lib/common/components/Text';
import TemplatedResponse from 'lib/common/components/molecules/TemplatedResponse';

import getTemplatedResponseQueueIds from 'lib/common/utils/templatedResponse/getTemplatedResponseQueueIds';

import './sms-block.scss';

export default function SmsBlock({
  control,
  errors,
  clearErrors,
  loading,
  messageCharacterLimit,
  setCharacterCount,
  characterCount,
  hasPassedCharacterLimit,
  setValue,
  trigger,
  onAddTemplateContent,
  templatedResponsesOpen,
  setTemplatedResponsesOpen
}) {
  const { HookFormInput } = useHookForm();
  const { isSoftphone } = useLayout();
  const queueIds = getTemplatedResponseQueueIds();

  if (templatedResponsesOpen) {
    return <TemplatedResponse contentOnly type={TemplateType.SMS} onClick={onAddTemplateContent} queueIds={queueIds} />;
  }

  return (
    <div className="sms-block">
      <HookFormInput
        autoFocus
        label="Phone Number"
        InputLabelProps={{ shrink: true }}
        name="phoneNumber"
        validationError={errors['phoneNumber']}
        clearErrors={clearErrors}
        fullWidth
        control={control}
        disabled={loading}
        type={EditableFieldTypes.PHONE}
        ariaRequired
      />

      <HookFormInput
        ariaRequired
        label="Message"
        InputLabelProps={{ shrink: true }}
        name="message"
        placeholder="Say something ..."
        onChange={(e: any) => {
          setValue('message', e.target.value);
          setCharacterCount(e.target.value.length);
        }}
        validationError={errors['message']}
        clearErrors={clearErrors}
        disabled={loading}
        fullWidth
        control={control}
        multiline
        minRows={6}
        maxRows={6}
        ignoreRows={isSoftphone}
        className="mt-30 mb-20"
        inputProps={{ 'data-testid': 'sms-block-message' }}
        onBlur={() => {
          trigger('message');
        }}
      />
      <div className="sms-block__footer">
        {isSoftphone ? (
          <ClickableIcon
            icon={faSquareList}
            onClick={() => {
              setTemplatedResponsesOpen(true);
            }}
            size={20}
            data-testid="templated-response-item"
            tooltip="Templates"
          />
        ) : (
          <TemplatedResponse type={TemplateType.SMS} onClick={onAddTemplateContent} queueIds={queueIds} />
        )}
        <Text
          className="sms-block__footer__counter"
          color={hasPassedCharacterLimit ? 'danger' : 'text'}
          testId="sms-block-counter"
        >
          {characterCount} / {messageCharacterLimit}
        </Text>
      </div>
    </div>
  );
}
