import { LogEvents, logger } from 'lib/common/components/LoggerController';

import TUser from 'lib/common/types/User';
import connectGetter from 'lib/common/utils/connectGetter';
import getContactConnection from 'lib/common/utils/getContactConnection';

export default async function addInteractionHistoryItem({
  contact,
  fetch_,
  config,
  tokens,
  user
}: {
  contact: connect.Contact;
  fetch_: (url, options?, tokens_?, throwError?) => Promise<any>;
  config: any;
  tokens: any;
  user: TUser;
}) {
  try {
    const { TENANT_ID } = config;
    const contactConnection = getContactConnection(contact);
    const agentConnection = connectGetter(contact, 'getAgentConnection');

    if (!contactConnection || !agentConnection) {
      logger.error(LogEvents.INTERACTION.CREATE.FAIL, {
        contactId: contact.contactId,
        error: 'Missing contact or agent connection'
      });

      return;
    }

    const interactionHistory = {
      tenantId: TENANT_ID,
      contactId: contact.contactId,
      historyId: user.uuid,
      calledAt: connectGetter(agentConnection, 'getStatus')?.timestamp,
      connectionType: connectGetter(contactConnection, 'getType'),
      phoneNumber: connectGetter(contactConnection, 'getEndpoint')?.phoneNumber
    };

    await fetch_(
      `${config.CALL_SERVICE_URL}/callhistory`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(interactionHistory)
      },
      tokens
    );

    logger.info(LogEvents.INTERACTION.CREATE.SUCCESS, { contactId: contact.contactId });
  } catch (error) {
    logger.error(LogEvents.INTERACTION.CREATE.FAIL, { contactId: contact.contactId, error });
  }
}
