import { Link, useLocation } from 'react-router-dom';

import cx from 'classnames';

import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

import Icon from 'lib/common/components/Icon';
import Tooltip from 'lib/common/components/atoms/Tooltip';

import { SoftphoneNavOption } from 'lib/common/types/SoftphoneNavOption';

import './softphone-nav-menu-item.scss';

export default function SoftphoneNavMenuItem({
  permissionType,
  permission,
  link,
  icon,
  name,
  requiresAdmin
}: SoftphoneNavOption & { link: string }) {
  const location = useLocation();
  const { hasPermission, isAdmin } = usePermissionsContext();

  const satisfiesAdmin = !requiresAdmin || (requiresAdmin && isAdmin);

  return (
    <>
      {satisfiesAdmin && hasPermission({ type: permissionType || 'tenant', permission }) && (
        <div
          className={cx('softphone-nav-menu-item', {
            'softphone-nav-menu-item--active': location.pathname === link
          })}
        >
          <Tooltip title={name} aria-label={name} placement="right" enterDelay={1000} ariaHidden>
            <Link to={link}>
              <Icon icon={icon} size={18} />
            </Link>
          </Tooltip>
        </div>
      )}
    </>
  );
}
