import { ReactNode } from 'react';

import cx from 'classnames';

import usePageProps from '../../hooks/usePageProps';
import Header from './Header';
import Sidebar from './Sidebar';

import '../styles/core-layout.scss';

interface ICoreLayout {
  user: any;
  children: ReactNode;
}

export default function CoreLayout({ user, children }: ICoreLayout) {
  const { className, pageTitle } = usePageProps();

  return (
    <div className={cx('core-layout')}>
      <Header user={user} />
      <div className={cx('core-layout__content')}>
        <div className="sidebar">
          <Sidebar user={user} />
        </div>

        <div className={cx(className, 'core-layout__content__right')}>
          {pageTitle && (
            <div className="core-layout__content__right__title component-title" data-testid="page-title">
              {pageTitle}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
