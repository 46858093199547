import CONNECTION_TYPES from 'lib/common/constants/connectionType';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import TASK_CONTENT_TYPES from 'lib/common/constants/tasks/taskContentTypes';

import TTask from 'lib/common/types/Task';
import isConnectedVoice from 'lib/common/utils/isConnectedVoice';

interface IGetTaskContentTypeProps {
  selectedTask: TTask;
  isSoftphone?: boolean;
}

export default function getTaskContentType({ selectedTask, isSoftphone = false }: IGetTaskContentTypeProps) {
  const { type, status, connectionType } = selectedTask;

  if (
    isSoftphone &&
    selectedTask.status === CONTACT_STATES.CONNECTING &&
    selectedTask.type !== CONTACT_TYPES.CONFERENCE_CALL
  ) {
    return TASK_CONTENT_TYPES.INCOMING_TASK;
  }

  if (status === CONTACT_STATES.ACW) {
    return TASK_CONTENT_TYPES.AFTER_CONTACT_WORK;
  }

  if (status === CONTACT_STATES.MISSED || status === CONTACT_STATES.REJECTED) {
    return TASK_CONTENT_TYPES.NEGLECTED_TASK;
  }

  if (type === CONTACT_TYPES.OUTBOUND_PREVIEW) {
    return TASK_CONTENT_TYPES.OUTBOUND_PREVIEW_TASK;
  }

  if (type === CONTACT_TYPES.EMAIL) {
    return TASK_CONTENT_TYPES.EMAIL_TASK;
  }

  if (status === CONTACT_STATES.CONNECTED && type === CONTACT_TYPES.TASK) {
    return TASK_CONTENT_TYPES.AGENT_TASK;
  }

  if (status === CONTACT_STATES.CONNECTED && type === CONTACT_TYPES.CHAT) {
    return TASK_CONTENT_TYPES.CHAT_TASK;
  }

  const isOutboundCall = type === CONTACT_TYPES.CALL && connectionType === CONNECTION_TYPES.OUTBOUND;

  if (status === CONTACT_STATES.ACCEPTED && (type === CONTACT_TYPES.QUEUE_CALLBACK || isOutboundCall)) {
    return TASK_CONTENT_TYPES.CONNECTING_TASK;
  }

  if (isConnectedVoice(selectedTask)) {
    return TASK_CONTENT_TYPES.TIMED_TASK;
  }

  return null;
}
