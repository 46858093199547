import { v4 as uuid } from 'uuid';

import { BadgeTypes } from '../../components/Badge/Badge';
import { FUSION_DATA_BLOCK_TYPES } from '../../constants/fusionDataBlockTypes';
import { fusionDataMap } from '../../types/fusionData/fusionDataMap';
import normaliseAttributeKey from '../normaliseAttributeKey';

const NEON_ATTRIBUTE_IDENTIFIER = /CW-NEON_|CW-NEON-(.[^_]*)_/gi;

const badgeRegex = RegExp(`(${Object.values(BadgeTypes).join('|')})`);

export function getFusionDataFromMap(type: string, data: any) {
  const { type: blockType, label } = fusionDataMap[type] || {};
  const identifier = type.split('_')[0];
  const badgeType = identifier?.match(badgeRegex)?.pop();

  return {
    id: uuid(),
    type: blockType || FUSION_DATA_BLOCK_TYPES.DYNAMIC,
    label: normaliseAttributeKey((label || type).replace(NEON_ATTRIBUTE_IDENTIFIER, '')),
    badgeType,
    data
  };
}
