import { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';

import { faSidebarFlip, faWindowRestore, faXmark } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useModulesContext } from 'lib/common/contexts/ModulesContext';
import { WidgetsPlacement } from 'lib/common/contexts/ModulesContext/constants/WidgetsPlacement';
import { WidgetsState } from 'lib/common/contexts/ModulesContext/constants/WidgetsState';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import { LOCAL_STORAGE_KEYS } from 'lib/common/constants/localStorageKeys';

import ClickableIcon from '../../ClickableIcon';
import Text from '../../Text';
import WIDGETS_OVERLAY_MIN_CONSTRAINTS from './constants/WidgetsOverlayMinConstrains';
import TWidgetsOverlayDimensions from './types/WidgetsOverlayDimensions';
import getWidgetsOverlayDefaultDimensions from './utils/getWidgetsOverlayDefaultDimensions';
import getWidgetsOverlayDimensions from './utils/getWidgetsOverlayDimensions';

import styles from './draggable-widgets-column.module.scss';

export default function DraggableWidgetsColumn({ children, title, open }) {
  const { getStorageItem, setStorageItem } = useLocalStorage();
  const { isSoftphone } = useLayout();
  const {
    state: {
      widgetsConfig: { widgetsPlacement, widgetsState }
    },
    actions: { handleWidgetsConfigChange }
  } = useModulesContext();
  const draggableRef = useRef(null);
  const resizableRef = useRef(null);
  const widgetsOverlaySavedData = getStorageItem(LOCAL_STORAGE_KEYS.WIDGETS_OVERLAY_DIMENSIONS);
  const [widgetsOverlayDimensions, setWidgetsOverlayDimensions] = useState<TWidgetsOverlayDimensions>(
    getWidgetsOverlayDefaultDimensions(widgetsOverlaySavedData ? JSON.parse(widgetsOverlaySavedData) : null)
  );

  const updateSize = () => {
    setWidgetsOverlayDimensions((prevState) => {
      const newDimensions = getWidgetsOverlayDimensions(prevState);
      setStorageItem(LOCAL_STORAGE_KEYS.WIDGETS_OVERLAY_DIMENSIONS, JSON.stringify(newDimensions));
      return newDimensions;
    });
  };

  useEffect(() => {
    if (widgetsPlacement === WidgetsPlacement.PINNED || widgetsState === WidgetsState.CLOSED) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => updateSize());
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, [widgetsPlacement, widgetsState]);

  const handleWidgetsOverlayChange = (changes: Partial<TWidgetsOverlayDimensions>) => {
    const newDimensions = {
      ...widgetsOverlayDimensions,
      ...changes
    };

    setWidgetsOverlayDimensions(newDimensions);
    setStorageItem(LOCAL_STORAGE_KEYS.WIDGETS_OVERLAY_DIMENSIONS, JSON.stringify(newDimensions));
  };

  const handleDraggableResize = (_: any, { size }) => {
    const isResizingOutsideScreen =
      size.width + widgetsOverlayDimensions.position.x > window.innerWidth ||
      size.height + widgetsOverlayDimensions.position.y > window.innerHeight;

    if (isResizingOutsideScreen) {
      return;
    }

    handleWidgetsOverlayChange({ size });
  };

  const onFinishMove = (_: any, { x, y }) => {
    handleWidgetsOverlayChange({ position: { x, y } });
  };

  const onMove = (_: any, { x, y }) => {
    handleWidgetsOverlayChange({ position: { x, y } });
  };

  const isColumnPinned = widgetsPlacement === WidgetsPlacement.PINNED;
  const isColumnFloating = widgetsPlacement === WidgetsPlacement.FLOATING;

  return (
    <Draggable
      ref={draggableRef}
      handle=".draggable-widgets-column"
      position={isColumnFloating ? widgetsOverlayDimensions.position : { x: 0, y: 0 }}
      bounds="body"
      onStop={onFinishMove}
      onDrag={onMove}
    >
      <div
        data-testid="draggable-widgets-column"
        className={cx(styles['draggable-widgets-column'], {
          [styles['draggable-widgets-column--hidden']]: !open,
          [styles['draggable-widgets-column--pinned']]: isColumnPinned,
          [styles['draggable-widgets-column--softphone']]: isSoftphone,
          [styles['draggable-widgets-column--floating']]: isColumnFloating
        })}
        style={
          isColumnFloating
            ? {
                width: widgetsOverlayDimensions.size.width,
                height: widgetsOverlayDimensions.size.height
              }
            : {}
        }
      >
        <Resizable
          height={widgetsOverlayDimensions.size.height}
          width={widgetsOverlayDimensions.size.width}
          onResize={handleDraggableResize}
          minConstraints={[WIDGETS_OVERLAY_MIN_CONSTRAINTS.width, WIDGETS_OVERLAY_MIN_CONSTRAINTS.height]}
          maxConstraints={widgetsOverlayDimensions.maxConstraints}
          ref={resizableRef}
        >
          <>
            {isColumnFloating && (
              <div
                className={cx([styles['draggable-widgets-column__header']], 'draggable-widgets-column')}
                data-testid="draggable-widgets-column-drag-handle"
              >
                <div className={styles['draggable-widgets-column__header__title']}>
                  <Text type="heading2" data-testid="draggable-widgets-column-drag-title">
                    {title}
                  </Text>
                </div>
                <ClickableIcon
                  onClick={() => handleWidgetsConfigChange({ widgetsPlacement: WidgetsPlacement.PINNED })}
                  icon={faSidebarFlip}
                  tooltip="Pin to page"
                  size={15}
                />
                <ClickableIcon
                  onClick={() => handleWidgetsOverlayChange(getWidgetsOverlayDefaultDimensions())}
                  icon={faWindowRestore}
                  tooltip="Reset window"
                  size={15}
                />
                <ClickableIcon
                  aria-label="Close"
                  tooltip="Close"
                  onClick={() => handleWidgetsConfigChange({ widgetsState: WidgetsState.CLOSED })}
                  icon={faXmark}
                  data-testid="draggable-widgets-column-drag-close"
                  size={20}
                />
              </div>
            )}
            {children}
          </>
        </Resizable>
      </div>
    </Draggable>
  );
}
