import Label from 'lib/common/components/Label';
import ReactSelect from 'lib/common/components/ReactSelect';
import Text from 'lib/common/components/Text';

import QUEUE_ENDPOINT_TYPES from 'lib/common/constants/queueEndpointTypes';

import './transfer-to-queue.scss';

interface ITransferQueue {
  connectionValue: string;
  endpoints: any;
  selectedEndpoint: any;
  setSelectedEndpoint: any;
}

interface Queue {
  Id: string;
  Arn: string;
  Name: string;
  QueueType: ValueOf<typeof QUEUE_ENDPOINT_TYPES>;
}

const TransferToQueue = ({ connectionValue, endpoints, selectedEndpoint, setSelectedEndpoint }: ITransferQueue) => (
  <>
    <Text type="body" className="transfer-to-queue__agent-transfer-info">
      <span>We'll transfer</span>
      <strong> {connectionValue} </strong>
      <span>to the new queue for another agent to accept.</span>
    </Text>
    <ReactSelect
      id="transfer-queue-select"
      name="transfer-queue"
      isSearchable
      autoFocus
      placeholder={endpoints.length ? 'Search and select a queue...' : 'There are no valid, available queues'}
      options={endpoints}
      className="select__control__presence-states"
      classNamePrefix="select"
      onChange={(selected) => setSelectedEndpoint(selected as { value: Queue; label: string })}
      value={selectedEndpoint}
      aria-label="transfer-queue-select"
      noOptionsMessage={({ inputValue }) =>
        inputValue ? "There aren't any queues that match your search" : 'There are no valid, available queues'
      }
      label="Queue"
      isDisabled={!endpoints.length}
    />
    {!endpoints.length ? (
      <Text className="mt-10 flex" type="label">
        Expecting one or more queues to be available for transfer? Please contact your Administrator for assistance.
      </Text>
    ) : null}
  </>
);

export default TransferToQueue;
