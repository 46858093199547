import { faXmark } from '@fortawesome/pro-regular-svg-icons';

import { useContactContext } from 'lib/common/contexts/ContactContext';

import Button from 'lib/common/components/Button';

import TaskFooter from './TaskFooter';

export default function ClearTask({ taskId }: { taskId: string }) {
  const {
    actions: { removeTask }
  } = useContactContext();

  return (
    <TaskFooter>
      <Button
        onClick={() => removeTask(taskId)}
        icon={faXmark}
        styleType="DANGER"
        tooltip="Close task"
        round
        size="medium"
        asyncAction
        successTimeoutSeconds={0}
      />
    </TaskFooter>
  );
}
