import { createContext, useContext } from 'react';

import defaultContextValues from './constants/defaultContextValues';
import TWrapUpCodeContext from './types/WrapUpCodeContext';

export const Context = createContext<TWrapUpCodeContext>(defaultContextValues);

export default Context;

export const useWrapUpCodeContext = () => useContext(Context);
