import { useEffect, useState } from 'react';
import sizeMe from 'react-sizeme';

import cx from 'classnames';

import OverflowPopoverMenu from './components/OverflowPopoverMenu';
import Tab, { ITabProps } from './components/Tab';
import getSplicedTabs from './utils/getSlicedTabs';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const OFFSET_WIDTH = 50;
const TAB_GAP = 10;

function Tabs({
  tabs,
  orientation,
  className,
  align,
  size: { width },
  size,
  hideSingleTab
}: {
  size: { width: number };
  className?: string;
  align?: 'flex-start' | 'center';
  tabs: ITabProps[];
  hideSingleTab?: boolean;
} & Pick<ITabProps, 'orientation' | 'size'>) {
  const [tabWidths, setTabWidths] = useState(tabs.map(() => 0));

  useEffect(() => {
    setTabWidths(tabs.map(() => 0));
  }, [tabs.length]);

  const { visibleTabs, overflowTabs } = getSplicedTabs(tabs, width - OFFSET_WIDTH, tabWidths, TAB_GAP);

  if (hideSingleTab && tabs.length <= 1) {
    return null;
  }

  return (
    <div
      role="tablist"
      aria-label="Modules"
      className={cx(className, 'flex')}
      style={{ justifyContent: align || void 0 }}
    >
      {visibleTabs.map((tab, index) => (
        <Tab
          key={tab.id}
          orientation={orientation}
          setWidth={(width) => {
            tabWidths[index] = width;
            setTabWidths([...tabWidths]);
          }}
          marginRight={index < tabs.length - 1 ? TAB_GAP : 0}
          size={size}
          {...tab}
        />
      ))}
      {overflowTabs.length ? (
        <OverflowPopoverMenu tabProps={{ orientation, size, tooltip: 'More' }} overflowTabs={overflowTabs} />
      ) : null}
    </div>
  );
}

export default sizeMeHOC(Tabs);
