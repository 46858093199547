import { Permissions } from '@cloud-wave/neon-common-lib';

import { TPermissionsContext } from 'lib/common/contexts/PermissionsContext/Context';

import CONNECTION_TYPES from 'lib/common/constants/connectionType';

import TTask from 'lib/common/types/Task';
import getContactAttributes from 'lib/common/utils/getContactAttributes';
import isConnectedVoice from 'lib/common/utils/isConnectedVoice';

const CALL_RECORDING_ATTRIBUTE = 'NEONNOW_CALL_RECORDING';

export enum CallRecordingAttributeValues {
  OFF = 'OFF',
  PAUSE_STOP = 'PAUSE_STOP',
  PAUSE = 'PAUSE',
  STOP = 'STOP'
}

const getCallRecordingAttributeValueFromPermissions = (hasPermission) => {
  if (
    hasPermission({
      type: 'tenant',
      permission: Permissions.CALL_RECORDING_PAUSE_ONLY
    })
  ) {
    return CallRecordingAttributeValues.PAUSE;
  }
  if (
    hasPermission({
      type: 'tenant',
      permission: Permissions.CALL_RECORDING_STOP_ONLY
    })
  ) {
    return CallRecordingAttributeValues.STOP;
  }

  if (
    hasPermission({
      type: 'tenant',
      permission: Permissions.CALL_RECORDING
    })
  ) {
    return CallRecordingAttributeValues.PAUSE_STOP;
  }

  return CallRecordingAttributeValues.OFF;
};

export function getCallRecordingPermission({
  task,
  hasPermission
}: {
  task: TTask;
  hasPermission: TPermissionsContext['hasPermission'];
}): CallRecordingAttributeValues {
  const connectedVoice = isConnectedVoice(task);
  const isMonitoring = task.connectionType === CONNECTION_TYPES.MONITORING;

  if (!connectedVoice || isMonitoring) {
    return CallRecordingAttributeValues.OFF;
  }

  const { [CALL_RECORDING_ATTRIBUTE]: callRecordingAttributeValue } = getContactAttributes(
    { task },
    CALL_RECORDING_ATTRIBUTE
  );

  return (
    (callRecordingAttributeValue as CallRecordingAttributeValues | undefined) ||
    getCallRecordingAttributeValueFromPermissions(hasPermission)
  );
}
