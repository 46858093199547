import { ReactNode } from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faClock, faUsers } from '@fortawesome/pro-regular-svg-icons';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import convertMSToReadableTime from 'lib/common/utils/convertMSToReadableTime';

import AgentIcon from '../AgentIcon';

import styles from './softphone-queue.module.scss';

function TextIcon({
  icon,
  text,
  customIcon,
  tooltip,
  label
}: {
  label?: string;
  icon?: IconDefinition;
  text: string;
  customIcon?: ReactNode;
  tooltip?: string;
}) {
  return (
    <div className={styles['softphone-queue__container__details__detail']}>
      {customIcon || (icon ? <Icon size={14} icon={icon} tooltip={tooltip} /> : null)}
      <Text bold className="ml-5">
        <span className="sr-only">{label}</span>
        {text}
      </Text>
    </div>
  );
}
export default function SoftphoneQueue({
  id,
  name,
  contactsInQueue,
  oldestContactInQueue,
  agentsAvailable,
  agentsOnline
}) {
  return (
    <div className={styles['softphone-queue']} key={id}>
      <div className={styles['softphone-queue__container']} key={id}>
        <Text htmlElement="h3" ellipsis bold className={styles['softphone-queue__container__queue-name']}>
          {name}
        </Text>
        <div className={styles['softphone-queue__container__details']}>
          <TextIcon label="Contacts in queue" text={contactsInQueue} icon={faUsers} tooltip="Contacts in queue" />
          <TextIcon
            label="Oldest contact in queue"
            text={oldestContactInQueue ? convertMSToReadableTime(oldestContactInQueue * 1000) : '00:00'}
            icon={faClock}
            tooltip="Oldest contact in queue"
          />
          <TextIcon
            label="Agents available per agents online"
            text={`${agentsAvailable}/${agentsOnline}`}
            customIcon={<AgentIcon small />}
          />
        </div>
      </div>
    </div>
  );
}
