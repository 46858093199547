import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import { useAgentContext } from 'lib/common/contexts/AgentContext';

import usePresence from 'lib/common/hooks/usePresence/usePresence';

import Icon from 'lib/common/components/Icon';

import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';

import PopoverMenuItem from '../../components/PopoverMenuItem';
import { PopoverMenuItemType } from '../../components/PopoverMenuItem/PopoverMenuItem';
import Text from '../../components/Text';

import COLOURS from 'css/export-vars.module.scss';

import styles from './presence.module.scss';

const BUTTON_MIN_HEIGHT = 45;

export default function usePresenceComponent({ name, onFinally }: { name?: string; onFinally?: () => void }) {
  const { agentNextState, presenceOptions: initialPresenceOptions, selectedValue, onChange } = usePresence();

  const { states: agentStates } = useAgentContext();

  const presenceOptions = initialPresenceOptions
    .filter((option) => {
      if (option.value !== PRESENCE_STATES.BUSY) {
        return true;
      }

      // Only display busy state if it exists in the list of custom states added manually
      if (agentStates.find(({ name }) => name === PRESENCE_STATES.BUSY)) {
        return true;
      }

      return false;
    })
    .map((option) => (
      <PopoverMenuItem
        actionInProgressText={option.label}
        onClick={() => {
          return onChange(option);
        }}
        onSuccess={onFinally}
        icon={option.icon}
        iconColor={option.iconColor}
        text={option.label}
        key={option.value}
      />
    ));

  presenceOptions.splice(presenceOptions.length - 1, 0, <PopoverMenuItem type={PopoverMenuItemType.SEPARATOR} />);

  const selectedColor = selectedValue?.value === PRESENCE_STATES.BUSY ? 'white' : void 0;

  const getAnchorButton = (icon = faChevronDown) => (
    <button
      style={{
        minHeight: name ? BUTTON_MIN_HEIGHT : void 0,
        background: selectedValue ? COLOURS[selectedValue.backgroundColor] : void 0
      }}
      className={styles['presence__button']}
      aria-label={`${
        agentNextState ? `Next Status ${agentNextState?.name}` : `Status ${selectedValue?.label}`
      } Presence`}
    >
      <div>
        {name && (
          <Text type="extraSmall" color={selectedColor}>
            {name}
          </Text>
        )}
        <Text medium type={name ? 'body' : 'extraSmall'} color={selectedColor}>
          {agentNextState ? `Next Status: ${agentNextState?.name}` : selectedValue?.label}
        </Text>
      </div>
      <Icon icon={icon} size={name ? 15 : 12} color={selectedColor} />
    </button>
  );

  return {
    anchorButton: getAnchorButton(),
    anchorPopoverMenuItem: (onClick) => (
      <PopoverMenuItem
        ariaLabel={`${
          agentNextState ? `Next Status ${agentNextState?.name}` : `Status ${selectedValue?.label}`
        }. Press enter to change presence.`}
        autoFocus
        onClick={onClick}
        className={styles['presence__popover-menu-item']}
        customContent={getAnchorButton(faChevronRight)}
      />
    ),
    presenceOptions
  };
}
