import { useEffect, useState } from 'react';

import cx from 'classnames';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Accordion from 'lib/common/components/Accordion';
import Badge from 'lib/common/components/Badge';
import ClickToCall from 'lib/common/components/ClickToCall';
import Text from 'lib/common/components/Text';

import CONTACT_STATES from 'lib/common/constants/contactStates';
import { FUSION_DATA_BLOCK_TYPES } from 'lib/common/constants/fusionDataBlockTypes';

import TChatTask from 'lib/common/types/ChatTask';
import TTask from 'lib/common/types/Task';
import getAttachedDataContactAttributes from 'lib/common/utils/getAttachedDataContactAttributes';

import { DynamicAttributesBlock } from './DynamicAttributesBlock';
import getAccordionOpenRules from './utils/getAccordionOpenRules';

import styles from './fusion-data.module.scss';

interface FusionData {
  data: any;
  label: string;
  callback?: () => void;
  type: 'string' | 'phone-number' | 'badge-list' | 'accordion' | 'dynamic';
}

const AccordionBlock = ({ fusionData, isSoftphone }: { fusionData: FusionData[]; isSoftphone?: boolean }) => {
  const accordionDetails = fusionData.filter(({ type }) => type === FUSION_DATA_BLOCK_TYPES.ACCORDION);
  const defaultOpenRules = getAccordionOpenRules({ isSoftphone });

  const isAccordionOpenByDefault = (data: string[]) =>
    data.length < defaultOpenRules.LINE_LIMIT && data.join('').length < defaultOpenRules.CHAR_LIMIT;

  if (!accordionDetails.length) {
    return null;
  }

  return (
    <>
      {accordionDetails.map(({ data, label }) => (
        <Accordion
          key={label}
          title={label}
          defaultOpen={isAccordionOpenByDefault(data)}
          className={cx(styles['fusion-data__block'], styles['fusion-data__accordion'], {
            [styles['fusion-data__accordion--softphone']]: isSoftphone
          })}
          contrast={isSoftphone}
        >
          {data.map((dataLine) => (
            <div
              key={dataLine}
              className={cx(styles['fusion-data__accordion-line'], {
                [styles['fusion-data__accordion-line--softphone']]: isSoftphone
              })}
            >
              {dataLine}
            </div>
          ))}
        </Accordion>
      ))}
    </>
  );
};

const PhoneNumberBlock = ({ fusionData, isSoftphone }: { fusionData: FusionData[]; isSoftphone?: boolean }) => {
  const phoneNumberDetails = fusionData.filter(({ type }) => type === FUSION_DATA_BLOCK_TYPES.PHONE_NUMBER);

  if (!phoneNumberDetails.length) {
    return null;
  }

  return (
    <>
      {phoneNumberDetails.map(({ data, label }) => (
        <div
          key={label}
          className={cx(styles['fusion-data__block'], styles['fusion-data__block__phone'], {
            [styles['fusion-data__block__phone--softphone']]: isSoftphone
          })}
        >
          <Text
            inline={isSoftphone}
            color="darkBlue"
            className={cx(styles['fusion-data__label'], {
              [styles['fusion-data__label--softphone']]: isSoftphone
            })}
          >
            {label}
          </Text>
          <ClickToCall textProps={{ inline: isSoftphone, type: isSoftphone ? 'extraSmall' : void 0 }}>
            {data}
          </ClickToCall>
        </div>
      ))}
    </>
  );
};

const BadgeListBlock = ({
  fusionData,
  invert,
  isSoftphone
}: {
  fusionData: FusionData[];
  invert?: boolean;
  isSoftphone?: boolean;
}) => {
  const badgeDetails = fusionData.filter(({ type }) => type === FUSION_DATA_BLOCK_TYPES.BADGE_LIST);

  if (!badgeDetails.length) {
    return null;
  }

  return (
    <>
      {badgeDetails.map(({ data, label }, index) => (
        <div key={`${index}-${label}`} className={styles['fusion-data__block']}>
          <Text
            inline={isSoftphone}
            color="darkBlue"
            className={cx(styles['fusion-data__label'], {
              [styles['fusion-data__label--softphone']]: isSoftphone
            })}
          >
            {label}
          </Text>
          <div
            className={cx(styles['fusion-data__block__badges'], {
              [styles['fusion-data__block__badges--softphone']]: isSoftphone
            })}
          >
            {data.map((item) => (
              <Badge key={item} label={item} type="NEUTRAL" />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

const FusionData = ({ task, invert = false }: { task?: TTask | TChatTask; invert?: boolean }) => {
  const { isSoftphone } = useLayout();
  const [fusionData, setFusionData] = useState<any[]>([]);

  useEffect(() => {
    if (!task || !task.contact) {
      return;
    }

    const attributes = getAttachedDataContactAttributes(task?.contact, task.type);

    if (!attributes) {
      setFusionData([]);
      return;
    }

    setFusionData(attributes);
  }, [task, setFusionData]);

  if (!fusionData.length) {
    return null;
  }

  const attachedData = (
    <div className={cx(styles['fusion-data'], { [styles['fusion-data--softphone']]: isSoftphone })}>
      <PhoneNumberBlock fusionData={fusionData} isSoftphone={isSoftphone} />
      <BadgeListBlock fusionData={fusionData} invert={invert} isSoftphone={isSoftphone} />
      <DynamicAttributesBlock fusionData={fusionData} isSoftphone={isSoftphone} />
      <AccordionBlock fusionData={fusionData} isSoftphone={isSoftphone} />
    </div>
  );

  if (isSoftphone) {
    const sessionStorageKey = `attachedData${task?.taskId}`;
    // Attached data is always open by default on softphone for interactions in progress
    // Don't open attached data by default in ACW
    const isOpen = sessionStorage.getItem(sessionStorageKey) || 'true';

    const defaultOpen = task?.status === CONTACT_STATES.ACW ? false : JSON.parse(isOpen);

    return (
      <Accordion
        title="Attached Data"
        className="mt-10"
        defaultOpen={defaultOpen}
        onToggle={({ open }) => {
          sessionStorage.setItem(sessionStorageKey, Boolean(open).toString());
        }}
      >
        {attachedData}
      </Accordion>
    );
  }

  return attachedData;
};

export default FusionData;
