import Accordion from 'lib/common/components/Accordion';
import Text from 'lib/common/components/Text';
import TaskReferences from 'lib/common/components/atoms/TaskReferences';
import ACWOutcome from 'lib/common/components/molecules/ACW/components/ACWOutcome';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';

import TTask from 'lib/common/types/Task';
import getUserName from 'lib/common/utils/getUserName';

import AttachedData from '../AttachedData';
import InfoPanel from '../InfoPanel';

const AgentTask = (props: TTask) => {
  const { profile, queueName, time, connectionValue, taskId, description, contact } = props;
  const name = profile && profile.firstName ? getUserName(profile) : connectionValue;

  return (
    <>
      <InfoPanel queue={queueName} timestamp={time}>
        <InfoPanel.Row text={name} testId={name} />
      </InfoPanel>
      <Accordion title="Description" className="mt-10">
        <Text>{description || "This task doesn't have a description."}</Text>
      </Accordion>
      <ACWOutcome />
      <TaskReferences contact={contact} />
      <AttachedData task={props} />
      <TaskFooter.AgentTask taskId={taskId} connectionValue={connectionValue} />
    </>
  );
};

export default AgentTask;
