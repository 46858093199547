import { useEffect, useState } from 'react';

import { useAgentContext } from 'lib/common/contexts/AgentContext';
import THandleContactChange from 'lib/common/contexts/ContactContext/types/HandleContactChange';
import { useRequirementsContext } from 'lib/common/contexts/RequirementsContext';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import TTask from 'lib/common/types/Task';
import { isConference, isInitialConnectionDisconnected } from 'lib/common/utils/conferenceConnections';
import connectAction from 'lib/common/utils/connectAction';
import connectGetter from 'lib/common/utils/connectGetter';
import toast from 'lib/common/utils/toast';

import destroyTask from '../utils/destroyTask';
import useClearContact from './useClearContact';

interface IPendingOutboundCall {
  phoneNumber: string;
  queueArn?: string;
  contactId: string;
}

const OUTBOUND_CALL_ERROR_MESSAGE =
  "Sorry, there was a problem making your outbound call. If you have an incoming call you'll need to action it and then try again.";

const getConnectedCallContact = (tasks: TTask[]) => {
  return tasks.find(
    (task) =>
      (task.type === CONTACT_TYPES.CALL ||
        task.type === CONTACT_TYPES.CONFERENCE_CALL ||
        task.type === CONTACT_TYPES.QUEUE_CALLBACK) &&
      task.status === CONTACT_STATES.CONNECTED
  )?.contact;
};

const useAgentActions = ({
  tasks,
  handleContactChange
}: {
  tasks: TTask[];
  handleContactChange: THandleContactChange;
}) => {
  const [pendingOutboundCall, setPendingOutboundCall] = useState<null | IPendingOutboundCall>(null);

  const clearContact = useClearContact({ handleContactChange });
  const { agent } = useAgentContext();
  const { isPermissionGranted } = useRequirementsContext();

  useEffect(() => {
    (async () => {
      // Only make the pending outbound call when there is one queued and the contact/task has been cleared
      if (!pendingOutboundCall || tasks.find((task) => task.contact.contactId === pendingOutboundCall.contactId)) {
        return;
      }

      setPendingOutboundCall(null);

      try {
        await connectAction('connect', agent, connect.Endpoint.byPhoneNumber(pendingOutboundCall.phoneNumber), {
          extraCallbacks: { errorMsg: OUTBOUND_CALL_ERROR_MESSAGE, queueARN: pendingOutboundCall.queueArn }
        });

        logger.info(LogEvents.OUTBOUND_CALL.INITIATED.SUCCESS);
      } catch (e) {
        logger.error(LogEvents.OUTBOUND_CALL.INITIATED.FAIL, { error: e });
      }
    })();
  }, [tasks, pendingOutboundCall]);

  const hangupActiveContact = async (contact: connect.Contact) => {
    try {
      const inConference = isConference(contact);
      const initialConnectionDisconnected = isInitialConnectionDisconnected(contact);
      const thirdPartyConnection = connectGetter(contact, 'getSingleActiveThirdPartyConnection');
      const agentConnection = connectGetter(contact, 'getAgentConnection');

      // Destroy third party connection when agent was in conference but initial connection was disconnected
      const connectionToDestroy =
        inConference && initialConnectionDisconnected && thirdPartyConnection ? thirdPartyConnection : agentConnection;

      await connectAction('destroy', connectionToDestroy);
      await clearContact(contact);
    } catch (e: any) {
      destroyTask({ contact, handleContactChange });
    }
  };

  const makeOutboundCall = async (phoneNumber: string, queueArn?: string) => {
    const connectedCallContact = getConnectedCallContact(tasks);

    if (!isPermissionGranted('microphone')) {
      toast('error', "We don't have microphone access, so you aren't able to make outbound calls.");

      return Promise.resolve();
    }

    if (!agent) {
      logger.error(LogEvents.OUTBOUND_CALL.INITIATED.FAIL, { error: 'No agent object' });

      return Promise.resolve();
    }

    try {
      if (!connectedCallContact) {
        await connectAction('connect', agent, connect.Endpoint.byPhoneNumber(phoneNumber), {
          errorMsg: OUTBOUND_CALL_ERROR_MESSAGE,
          extraCallbacks: { queueARN: queueArn }
        });

        logger.info(LogEvents.OUTBOUND_CALL.INITIATED.SUCCESS);

        return;
      }

      // Queue and wait for the task/contact to be cleared, otherwise we can get a QuotaExceededException
      setPendingOutboundCall({ phoneNumber, queueArn, contactId: connectedCallContact.contactId });

      logger.info(LogEvents.OUTBOUND_CALL.QUEUED);

      await hangupActiveContact(connectedCallContact);
    } catch (e) {
      setPendingOutboundCall(null);

      logger.error(LogEvents.OUTBOUND_CALL.INITIATED.FAIL);

      return;
    }
  };

  const onTransfer = async (phoneNumber: string) => {
    const connectedCallContact = getConnectedCallContact(tasks);

    if (!connectedCallContact) {
      return Promise.resolve();
    }

    handleContactChange({ contact: connectedCallContact, connectionState: CONNECTION_STATES.HOLD });

    return connectAction('addConnection', connectedCallContact, connect.Endpoint.byPhoneNumber(phoneNumber), {
      ignoreError: () => connectGetter(connectedCallContact, 'getState')?.type === connect.ContactStateType.ENDED
    });
  };

  return { makeOutboundCall, onTransfer };
};

export default useAgentActions;
