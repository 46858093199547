import { ReactNode } from 'react';
import { ActionMeta, components } from 'react-select';

import ReactSelect from 'lib/common/components/ReactSelect';

import IWrapUpCode from 'lib/common/types/WrapUpCode';

import PLACEHOLDER from '../../constants/placeholder';

import './acw-react-select.scss';

interface IList {
  options: { value: string; label: ReactNode; wrapUpCode: IWrapUpCode }[];
  loaded: boolean;
  noResults: () => string;
  onChange: (option: unknown, actionMeta?: ActionMeta<unknown>) => void;
  selectedCode?: IWrapUpCode;
}

function SingleValue(props) {
  const { children, selectProps, ...rest } = props;

  if (selectProps.menuIsOpen) {
    return null;
  }

  return <components.SingleValue {...rest}>{children}</components.SingleValue>;
}

export default function List({ options, loaded, noResults, onChange, selectedCode }: IList) {
  const filterOption = (candidate, input) => {
    if (!input) {
      return true;
    }

    const { name, pathname } = candidate?.label?.props?.wrapUpCode || { name: '', pathname: '' };

    return (
      name.toLowerCase().includes(input.toLowerCase()) || pathname.toLowerCase().includes(input.toLowerCase()) || false
    );
  };

  const disabled = loaded && !options.length;
  const selectedOption = options.find((option) => option.value === selectedCode?.name) || null;
  const placeholder = disabled ? PLACEHOLDER.NO_CODES_FOR_QUEUE : PLACEHOLDER.SEARCHABLE;

  return (
    <ReactSelect
      aria-label="Outcomes"
      isSearchable
      name="outcomes"
      components={{ SingleValue }}
      options={options}
      classNamePrefix="acw-react-select"
      onChange={onChange}
      isDisabled={disabled || !loaded}
      isClearable
      placeholder={placeholder}
      value={selectedOption}
      isLoading={!loaded}
      noOptionsMessage={noResults}
      filterOption={filterOption}
      id="acw-react-select"
    />
  );
}
