import { useState } from 'react';

import _sortBy from 'lodash.sortby';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import WRAP_UP_CODE_TYPES from 'lib/common/constants/ACW/wrapUpCodeTypes';

import IWrapUpCode from 'lib/common/types/WrapUpCode';

import { useContactContext } from '../ContactContext';
import { Context } from './Context';
import getACWWrapUpCodes from './utils/getACWWrapUpCodes';

function WrapUpCodeProvider({ children }) {
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();

  const {
    state: { tasks, selectedTaskId }
  } = useContactContext();

  const [wrapUpCodes, setWrapUpCodes] = useState<IWrapUpCode[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const fetchWrapUpCodes = async () => {
    setLoaded(false);

    const response = await getACWWrapUpCodes({ fetch_, config });

    setWrapUpCodes(_sortBy(response, 'name'));
    setLoaded(true);
  };

  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);

  const queueWrapUpCodes = selectedTask
    ? wrapUpCodes.filter((wrapUpCode) => {
        return (
          wrapUpCode.type === WRAP_UP_CODE_TYPES.GROUP ||
          (wrapUpCode.type === WRAP_UP_CODE_TYPES.CODE &&
            wrapUpCode.assignedQueueIds?.includes(selectedTask?.queueId || ''))
        );
      })
    : [];

  return (
    <Context.Provider
      value={{
        state: { wrapUpCodes, queueWrapUpCodes, loaded },
        actions: { fetchWrapUpCodes }
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default WrapUpCodeProvider;
