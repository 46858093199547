import sizeMe from 'react-sizeme';

import cx from 'classnames';
import { format } from 'date-fns';

import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Text from 'lib/common/components/Text';
import ChatMessage from 'lib/common/components/atoms/ChatMessage';

import CONTACT_STATES from 'lib/common/constants/contactStates';

import { TChatMessage } from 'lib/common/types/chat/ChatMessage';
import getDateFormat from 'lib/common/utils/getDateFormat';

import ChatHeader from './components/ChatHeader';

import styles from './chat-messages.module.scss';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const SMALL_WIDTH_PX = 400;

type TChatMessages = {
  customerName: string;
  messages: TChatMessage[];
  size: { width: number };
  customerTyping?: boolean;
  showEmptyPlaceholder?: boolean;
  saveScrollPosition?: () => void;
  nextToken?: string | boolean;
  status: ValueOf<typeof CONTACT_STATES>;
};

const ChatMessages = ({
  customerName,
  messages,
  showEmptyPlaceholder,
  size: { width },
  customerTyping,
  saveScrollPosition,
  nextToken,
  status
}: TChatMessages) => {
  const isSmall = width <= SMALL_WIDTH_PX;
  const dateFormat = getDateFormat();
  const today = format(new Date(), dateFormat);
  const { isSoftphone } = useLayout();
  const { user } = useAuthContext();
  const currentUserDisplayName = user?.firstName;

  const isACW = status !== CONTACT_STATES.CONNECTED;

  const sortedMessages = messages.reduce((sorted, message) => {
    const date = format(new Date(message.AbsoluteTime), dateFormat);

    if (!sorted[date]) {
      return { ...sorted, [date]: [message] };
    }

    return { ...sorted, [date]: [...sorted[date], message] };
  }, {});

  // Be very very cautious when changing this file
  // you must test every scenario as this has been known to break the scroll
  // bar in many areas of the application. you must test:
  // active chat (desktop, softphone, small softphone)
  // acw chat history (desktop, softphone, small softphone)
  return (
    <div
      className={cx(styles['chat-messages__container'], {
        [styles['chat-messages__container--softphone']]: isSoftphone,
        [styles['chat-messages__container--softphone--scrollable']]: isSoftphone && isACW
      })}
    >
      <div
        className={cx(styles['chat-messages__messages'], {
          [styles['chat-messages__messages--softphone--acw']]: isSoftphone && isACW
        })}
      >
        {status === CONTACT_STATES.CONNECTED && (
          <div className={styles['chat-messages__start']}>
            <ChatHeader
              messages={messages}
              showEmptyPlaceholder={showEmptyPlaceholder}
              nextToken={nextToken}
              saveScrollPosition={saveScrollPosition}
            />
          </div>
        )}
        {Object.entries(sortedMessages).map(([date, messagesForDate]: any) => (
          <div className={styles['chat-messages__messages__block']} key={date}>
            <div className={styles['chat-messages__messages__block__date']}>
              <span />
              <Text>{today === date ? 'TODAY' : date}</Text>
              <span />
            </div>
            {messagesForDate.map((message, index) => (
              <ChatMessage
                key={message.Id}
                message={message}
                customerName={customerName}
                currentUserDisplayName={currentUserDisplayName}
                lastMessageOfRole={
                  (!messagesForDate[index + 1] ||
                    messagesForDate[index + 1].ParticipantRole !== message.ParticipantRole) &&
                  (!customerTyping || message.ParticipantRole === 'AGENT')
                }
                isSmall={isSmall}
                invert
                hideDownloadButton={isACW}
              />
            ))}
            {customerTyping && <ChatMessage key="typing" customerName={customerName} lastMessageOfRole invert />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default sizeMeHOC(ChatMessages);
