import { useEffect, useState } from 'react';

import _orderBy from 'lodash.orderby';

interface TUseSort {
  data: any[];
  defaultSortKey?: string;
  defaultSortDirection?: 'asc' | 'desc';
}

function applySort(data, sortKey, sortDirection) {
  return _orderBy(data, sortKey || [], sortDirection);
}

export default function useSort({ data, defaultSortKey, defaultSortDirection = 'desc' }: TUseSort) {
  const [sortedData, setSortedData] = useState([]);
  const [currentSort, setCurrentSort] = useState<undefined | string>(defaultSortKey);
  const [currentSortDirection, setCurrentSortDirection] = useState<'asc' | 'desc'>(defaultSortDirection);

  useEffect(() => {
    setSortedData(applySort(data, currentSort, currentSortDirection));
  }, [currentSort, currentSortDirection, data]);

  const setSort = (sortKey) => {
    setCurrentSort(sortKey);
  };

  const setSortDirection = (direction) => {
    setCurrentSortDirection(direction);
  };

  return {
    setSort,
    setSortDirection,
    data: sortedData,
    currentSort,
    currentSortDirection
  };
}
