import { useState } from 'react';

import CustomerProfileForm, { ProfileActions } from 'lib/common/components/CustomerProfileForm';
import Modal from 'lib/common/components/Modal';

import PROFILE_TYPES from 'lib/common/constants/customerProfileTypes';

import TProfile from 'lib/common/types/CustomerProfile';

type TProfileState = {
  profile: TProfile;
  profileValid: boolean;
};

const getInitialProfile = (existingAttribute) => ({
  ...existingAttribute,
  partyType: PROFILE_TYPES.INDIVIDUAL
});

interface ICreateProfileProps {
  existingAttribute: { [key: string]: string } | null;
  onSuccess: (_: TProfile) => void;
  onSave: (_: TProfile) => void;
  onClose: () => void;
  open: boolean;
}

export default function CreateProfileModal({
  open,
  onClose,
  onSave,
  onSuccess,
  existingAttribute
}: ICreateProfileProps) {
  const [{ profile, profileValid }, setProfileValues] = useState<TProfileState>({
    profile: getInitialProfile(existingAttribute || {}),
    profileValid: true
  });

  const handleSave = () => onSave(profile);

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSave={handleSave}
      onSuccess={onSuccess}
      title="Create A Customer Profile"
      saveDisabled={!profileValid}
    >
      <CustomerProfileForm
        compact
        profile={profile}
        onChangeProfile={setProfileValues}
        action={ProfileActions.CREATE}
      />
    </Modal>
  );
}
