import { TUpdateAgentPreferenceBodySchema } from '@cloud-wave/neon-common-lib';

import { IApiRequestArgs } from 'lib/common/contexts/AgentPreferenceContext/api/IApiRequestArgs';

export const updateAgentPreference = async ({
  config,
  fetch_,
  agentPreference
}: IApiRequestArgs & { agentPreference: TUpdateAgentPreferenceBodySchema }) => {
  const result = await fetch_(`${config.AGENT_SERVICE_V2_URL}/agent/${config.TENANT_ID}/preference`, {
    body: JSON.stringify(agentPreference),
    method: 'PATCH'
  });

  return (await result.json()).data;
};
