import { Dispatch, useState } from 'react';

import { faEllipsis, faUsers } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import { EmailSendTypes } from 'lib/common/hooks/useEmail/api/sendEmail';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import { Input } from 'lib/common/components/Input';
import PopoverMenu from 'lib/common/components/PopoverMenu';
import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';
import { EmailTagInput } from 'lib/common/components/TagInput';
import { TTagInputState } from 'lib/common/components/TagInput/useTagInputReducer';
import Text from 'lib/common/components/Text';

import TEmailTask from 'lib/common/types/EmailTask';
import SendableEmailAddress from 'lib/common/types/email/SendableEmailAddress';

import { EmailModalTypes } from '../EmailModal/EmailModal';
import EmailPopover from '../EmailPopover';
import FromAddresses from './components/FromAddresses';

import styles from './create-email-header.module.scss';

interface ICreateEmailHeader {
  availableFromAddresses: SendableEmailAddress[] | null;
  to: TTagInputState;
  cc: TTagInputState;
  bcc: TTagInputState;
  fromAddressId: string | null;
  subject?: string;
  setFromAddressId: (addressId: string) => void;
  setSubject: (subject: string) => void;
  setCc: Dispatch<Partial<TTagInputState>>;
  setBcc: Dispatch<Partial<TTagInputState>>;
  setTo: Dispatch<Partial<TTagInputState>>;
  task?: TEmailTask;
  setModalVisible: (modalType: null | EmailModalTypes) => void;
}

export default function CreateEmailHeader({
  availableFromAddresses,
  setFromAddressId,
  fromAddressId,
  cc,
  bcc,
  to,
  subject,
  setSubject,
  setCc,
  setBcc,
  setTo,
  setModalVisible,
  task
}: ICreateEmailHeader) {
  const { isSoftphone } = useLayout();
  const [ccBccVisible, setCcBccVisible] = useState(Boolean(cc.tags.length || bcc.tags.length));

  const headerBlockClass = cx(styles['create-email-header'], {
    [styles['create-email-header--softphone']]: isSoftphone
  });

  const emailType = task?.email?.type;
  const isDraftOrReply = emailType && emailType !== EmailSendTypes.NEW;

  return (
    <>
      <div className={headerBlockClass}>
        <Text className={styles['create-email-header__text']}>From:</Text>
        <FromAddresses
          availableFromAddresses={availableFromAddresses}
          setFromAddressId={setFromAddressId}
          fromAddressId={fromAddressId}
        />
        {isSoftphone && (!ccBccVisible || isDraftOrReply) && (
          <PopoverMenu
            closeOnClick
            anchor={<ClickableIcon aria-label="More options" icon={faEllipsis} className="ml-auto" />}
          >
            {!ccBccVisible ? (
              <PopoverMenuItem
                text="Cc / Bcc"
                icon={faUsers}
                onClick={() => {
                  setCcBccVisible(true);
                }}
              />
            ) : (
              void 0
            )}
            {isDraftOrReply ? <EmailPopover setModalVisible={setModalVisible} task={task} /> : void 0}
          </PopoverMenu>
        )}
      </div>
      <div className={headerBlockClass}>
        <Text className={styles['create-email-header__text']}>To:</Text>
        <EmailTagInput className="grow" state={to} setState={setTo} id="to-tag-input" />
        {!ccBccVisible && !isSoftphone && (
          <Text
            className={styles['create-email-header__link']}
            onClick={() => setCcBccVisible(true)}
            testId="expand-cc-bcc-link"
          >
            Cc / Bcc
          </Text>
        )}
      </div>
      {ccBccVisible && (
        <>
          <div className={headerBlockClass}>
            <Text className={styles['create-email-header__text']}>Cc:</Text>
            <EmailTagInput className="grow" state={cc} setState={setCc} id="cc-tag-input" />
          </div>
          <div className={headerBlockClass}>
            <Text className={styles['create-email-header__text']}>Bcc:</Text>
            <EmailTagInput className="grow" state={bcc} setState={setBcc} id="bcc-tag-input" />
          </div>
        </>
      )}
      {isSoftphone && <hr className={styles['create-email-header__separator']} />}
      {isSoftphone ? (
        <div className={headerBlockClass}>
          <Input
            noBorder
            noPadding
            placeholder="Subject"
            small
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            inputProps={{
              'data-testid': 'subject-text-field'
            }}
          />
        </div>
      ) : (
        <div className={headerBlockClass}>
          <Text className={styles['create-email-header__text']}>Subject:</Text>
          <Input
            aria-label="Subject"
            className="grow"
            small
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            inputProps={{
              'data-testid': 'subject-text-field'
            }}
          />
        </div>
      )}
    </>
  );
}
