import { useRef, useState } from 'react';

import useEmail from 'lib/common/hooks/useEmail';

import Email from 'lib/common/components/atoms/Email';
import RichTextEditor from 'lib/common/components/molecules/RichTextEditor';
import { EditorUseCase } from 'lib/common/components/molecules/RichTextEditor/Editor';
import UploadFilesEmail from 'lib/common/components/molecules/UploadFilesEmail';
import { UploadFilesTypesEmail } from 'lib/common/components/molecules/UploadFilesEmail';

import UploadedFileType from 'lib/common/constants/files/UploadedFileType';

import TEmailTask from 'lib/common/types/EmailTask';
import getAttachmentsTotalSize from 'lib/common/utils/email/getAttachmentsTotalSize';

import EmailFooter from './Footer';

import styles from './email.module.scss';

export default function CreateEmail({ selectedTask }: { selectedTask: TEmailTask }) {
  const {
    state: {
      subject,
      to,
      cc,
      bcc,
      content,
      fromAddressId,
      availableFromAddresses,
      originalAttachments,
      originalEmailHtml
    },
    actions: {
      setBcc,
      setCc,
      setTo,
      onSendEmail,
      setSubject,
      setHtmlContent,
      setContent,
      setFromAddressId,
      discardEmail,
      onRemoveOriginalAttachment,
      setModalVisible,
      onSendEmailSuccess
    },
    modal
  } = useEmail(selectedTask);
  const [toolbarVisible, setToolbarVisible] = useState(false);

  const hasInvalidEmailAddress = !to.tags.length || !to.allValid || !cc.allValid || !bcc.allValid;
  const baseFileSizeTotal = getAttachmentsTotalSize(originalAttachments);
  const invalidEmail = hasInvalidEmailAddress || !fromAddressId;
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      {modal}
      <UploadFilesEmail
        contextId={selectedTask.taskId}
        uploadedFileType={UploadedFileType.EMAIL_ATTACHMENT}
        type={UploadFilesTypesEmail.DROP_ZONE}
        className={styles['softphone-email-task__upload-files-dropzone']}
        baseFileSizeTotal={baseFileSizeTotal}
      >
        <RichTextEditor.LexicalComposer initialConfig={RichTextEditor.defaultEditorConfig}>
          <div className={styles['softphone-email-task']}>
            <Email.CreateEmailHeader
              fromAddressId={fromAddressId}
              availableFromAddresses={availableFromAddresses}
              subject={subject}
              bcc={bcc}
              cc={cc}
              to={to}
              setTo={setTo}
              setBcc={setBcc}
              setCc={setCc}
              setSubject={setSubject}
              setFromAddressId={setFromAddressId}
              setModalVisible={setModalVisible}
              task={selectedTask}
            />
            <hr className={styles['softphone-email-task__separator']} />
            <RichTextEditor.Editor
              editorUseCase={EditorUseCase.EmailMessage}
              onHtmlChange={setHtmlContent}
              onEditorStateChange={setContent}
              content={content}
              html={originalEmailHtml}
              onSubmit={() => submitButtonRef?.current?.click()}
            />
          </div>
          <RichTextEditor.Toolbar className={styles['softphone-email-task__toolbar']} open={toolbarVisible} />
          <EmailFooter
            submitButtonRef={submitButtonRef}
            taskId={selectedTask.taskId as any}
            toggleToolbar={() => setToolbarVisible(!toolbarVisible)}
            saveDisabled={invalidEmail}
            onSend={onSendEmail}
            discardEmail={discardEmail}
            originalAttachments={originalAttachments}
            onRemoveOriginalAttachment={onRemoveOriginalAttachment}
            baseFileSizeTotal={baseFileSizeTotal}
            onSendSuccess={onSendEmailSuccess}
          />
        </RichTextEditor.LexicalComposer>
      </UploadFilesEmail>
    </>
  );
}
