import { useHotkeys } from 'react-hotkeys-hook';

import { faPause, faPhoneHangup, faPlay } from '@fortawesome/pro-regular-svg-icons';

import { useAgentContext } from 'lib/common/contexts/AgentContext';
import { useContactContext } from 'lib/common/contexts/ContactContext';

import Button from 'lib/common/components/Button';

import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import { getAgentVoiceConnection, isInitialConnectionDisconnected } from 'lib/common/utils/conferenceConnections';
import agentIsMonitoring from 'lib/common/utils/connect/agentIsMonitoring';
import connectGetter from 'lib/common/utils/connectGetter';
import { getTaskContact } from 'lib/common/utils/getTask';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';

import TaskFooterRow from '../../../TaskFooterRow';
import TransferFooterButtons from '../../../TransferFooterButtons';
import MuteButton from '../MuteButton';

interface ICallProps {
  taskId: string;
  connectionValue: string;
  connectionState: string;
  type: string;
  isSmallWidth: boolean;
}

export default function Call({ taskId, connectionValue, connectionState, type, isSmallWidth }: ICallProps) {
  const { agent } = useAgentContext();
  const {
    actions: { resumeCall, holdCall, hangupAgent },
    state: { tasks }
  } = useContactContext();

  const callIsOnHold = connectionState === CONNECTION_STATES.HOLD;
  useHotkeys(
    AGENT_WORKSPACE_HOTKEYS.IN_CALL_HOLD,
    () => {
      (async () => {
        await toggleHoldStatus();
      })();
    },
    [callIsOnHold, taskId, resumeCall, holdCall]
  );

  const contact = getTaskContact(tasks, taskId);

  if (!contact) {
    return null;
  }

  const agentConnection = getAgentVoiceConnection(contact);
  const isMonitoring = agentIsMonitoring(contact);
  const disableTransfer = isInitialConnectionDisconnected(contact) || isMonitoring;

  // When the agent is on a desk phone, they can only mute/unmute the call on it (not in NEON)
  const isOnDeskPhone =
    !connectGetter(agent, 'isSoftphoneEnabled') &&
    (type === CONTACT_TYPES.CALL || type === CONTACT_TYPES.QUEUE_CALLBACK);

  const toggleHoldStatus = () => (callIsOnHold ? resumeCall({ taskId }) : holdCall({ taskId }));

  if (isMonitoring) {
    return (
      <TaskFooterRow>
        <Button
          size="medium"
          onClick={() => hangupAgent(taskId)}
          icon={faPhoneHangup}
          styleType="DANGER"
          tooltip="End monitoring"
          round
          asyncAction
          successTimeoutSeconds={0}
          preventDoubleClick
        />
      </TaskFooterRow>
    );
  }

  return (
    <>
      <TaskFooterRow isSmallWidth={isSmallWidth}>
        <TransferFooterButtons
          taskId={taskId}
          connectionValue={connectionValue}
          disableTransfer={disableTransfer}
          showDialpadTransfer
        />
      </TaskFooterRow>
      <TaskFooterRow primaryButtonIndex={1} isSmallWidth={isSmallWidth}>
        <Button
          size="small"
          icon={callIsOnHold ? faPlay : faPause}
          onClick={toggleHoldStatus}
          tooltip={callIsOnHold ? 'Resume call' : 'Put call on hold'}
          styleType="NEUTRAL"
          round
          asyncAction
          successTimeoutSeconds={0}
          preventDoubleClick
        />
        <Button
          size="medium"
          onClick={() => hangupAgent(taskId)}
          icon={faPhoneHangup}
          styleType="DANGER"
          ariaLabel="Hang up call"
          tooltip="Hang up"
          round
          asyncAction
          successTimeoutSeconds={0}
          preventDoubleClick
        />
        {!isOnDeskPhone && <MuteButton disabled={callIsOnHold} agentConnection={agentConnection} taskId={taskId} />}
      </TaskFooterRow>
    </>
  );
}
