import { ReactNode } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { faPeopleArrows, faRectangleHistoryCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { useBooleanState } from 'webrix/hooks';

import { useContactContext } from 'lib/common/contexts/ContactContext';

import Button from 'lib/common/components/Button';

import { AVAILABLE_TRANSFER_QUEUE_ARNS } from 'lib/common/constants/contactAttributes';

import { faDialpad } from 'lib/common/customIcons';
import getContactAttributes from 'lib/common/utils/getContactAttributes';
import { getTask } from 'lib/common/utils/getTask';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';

import TransferToQueueModal from '../../../TransferToQueue';
import useTransferModal from './hooks/useTransferModal';

function getQueueTransferTooltip({ queueTransferPrevented, disableTransfer }) {
  if (!queueTransferPrevented && !disableTransfer) {
    return 'Transfer to another queue';
  }

  if (queueTransferPrevented) {
    return 'This task cannot be transferred to a queue';
  }

  return 'This task cannot be transferred as the initial participant has disconnected';
}

export default function TransferFooterButtons({
  taskId,
  connectionValue,
  disableTransfer,
  showDialpadTransfer,
  showAgentAndQueueTransfer = true,
  children
}: {
  taskId: string;
  connectionValue: string;
  disableTransfer?: boolean;
  showDialpadTransfer?: boolean;
  showAgentAndQueueTransfer?: boolean;
  children?: ReactNode;
}) {
  const {
    value: showTransferToQueue,
    setFalse: closeTransferToQueue,
    setTrue: openTransferToQueue
  } = useBooleanState();

  const {
    state: { tasks }
  } = useContactContext();

  const { onTransferToAgent, onTransferToDialpad } = useTransferModal();

  const task = getTask(tasks, taskId);

  const { [AVAILABLE_TRANSFER_QUEUE_ARNS]: availableTransferQueueArns } = getContactAttributes(
    { task },
    AVAILABLE_TRANSFER_QUEUE_ARNS
  );

  // If availableQueueArns is an empty string, we disable the button below
  const queueTransferPrevented = availableTransferQueueArns?.trim() === '';

  useHotkeys(AGENT_WORKSPACE_HOTKEYS.IN_CALL_TRANSFER_CONTACT, onTransferToAgent, [onTransferToAgent]);
  useHotkeys(AGENT_WORKSPACE_HOTKEYS.IN_CALL_TRANSFER_QUEUE, openTransferToQueue, [openTransferToQueue]);
  useHotkeys(
    AGENT_WORKSPACE_HOTKEYS.IN_CALL_TRANSFER_DIAL_PAD,
    (e) => {
      if (!showDialpadTransfer) {
        return;
      }
      // Stop the letter from being typed in the input
      e.preventDefault();
      e.stopPropagation();

      onTransferToDialpad();
    },
    [onTransferToDialpad]
  );

  return (
    <>
      {showAgentAndQueueTransfer && (
        <>
          <Button
            data-testid="directory-transfer-btn"
            icon={faPeopleArrows}
            tooltip={
              !disableTransfer
                ? 'Transfer to a directory contact'
                : 'Cannot transfer as the initial participant has been disconnected'
            }
            styleType="NEUTRAL"
            round
            onClick={onTransferToAgent}
            size="small"
            disabled={disableTransfer}
          />
          <Button
            data-testid="queue-transfer-btn"
            tooltip={getQueueTransferTooltip({ queueTransferPrevented, disableTransfer })}
            icon={faRectangleHistoryCircleUser}
            styleType="NEUTRAL"
            round
            onClick={openTransferToQueue}
            size="small"
            disabled={disableTransfer || queueTransferPrevented}
          />
        </>
      )}
      {showDialpadTransfer && (
        <Button
          data-testid="dialpad-transfer-btn"
          icon={faDialpad}
          onClick={onTransferToDialpad}
          tooltip="Dialpad"
          styleType="NEUTRAL"
          round
          size="small"
        />
      )}
      {children}
      {showTransferToQueue && (
        <TransferToQueueModal
          open={showTransferToQueue}
          onClose={closeTransferToQueue}
          connectionValue={connectionValue}
          taskId={taskId}
        />
      )}
    </>
  );
}
