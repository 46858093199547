import { TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $getSelection } from 'lexical';

import addProtocolToUrl from './addProtocolToUrl';
import toggleImageLink from './toggleImageLink';

export default function updateLink(editor, link) {
  editor.update(() => {
    const selection = $getSelection();

    // If all nodes are images, toggle the link on the image
    if (selection?.getNodes()?.every((node) => node.getType() === 'image')) {
      return toggleImageLink(addProtocolToUrl(link));
    }
  });

  return editor.dispatchCommand(TOGGLE_LINK_COMMAND, addProtocolToUrl(link));
}
