import { ReactNode } from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import cx from 'classnames';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import { Colour } from 'lib/common/components/Text/types';

import PanelIconSizeTypes from './constants/PanelIconSizeTypes';
import PanelTypes from './constants/PanelTypes';
import PANEL_ICON_SIZES from './constants/panelIconSizes';
import PANEL_PROPS from './constants/panelProps';

import COLOURS from 'css/export-vars.module.scss';

import styles from './coloured-panel.module.scss';

export interface IColouredPanel {
  icon?: IconDefinition;
  children: ReactNode;
  headerTitle?: string;
  type?: PanelTypes;
  iconSize?: PanelIconSizeTypes;
  className?: string;
  tooltip?: boolean;
  id?: string;
}

export default function ColouredPanel({
  icon,
  headerTitle,
  children,
  type = PanelTypes.INFO,
  className,
  tooltip,
  iconSize = PanelIconSizeTypes.MEDIUM,
  id
}: IColouredPanel) {
  const { isSoftphone } = useLayout();
  const iconActualSize = isSoftphone ? PanelIconSizeTypes.SMALL : iconSize;
  const panelProps = PANEL_PROPS[type];

  const panel = (
    <div
      role={type === PanelTypes.WARNING || type === PanelTypes.DANGER ? 'alert' : void 0}
      className={cx(styles['coloured-panel'], { [className || '']: !tooltip })}
      style={{ background: panelProps.background, color: COLOURS[panelProps.textColorName] || COLOURS.black }}
      data-testid={id || 'coloured-panel'}
    >
      <div className={styles['coloured-panel__inner']}>
        <div
          className={cx(styles['coloured-panel__inner__icon'], {
            [styles['coloured-panel__inner__icon--small']]: iconActualSize === PanelIconSizeTypes.SMALL
          })}
        >
          <Icon
            icon={icon || panelProps.defaultIcon}
            size={PANEL_ICON_SIZES[iconActualSize]}
            color={panelProps.textColorName}
          />
        </div>
        <div className={styles['coloured-panel__container']}>
          {headerTitle && (
            <div className={styles['coloured-panel__container__header']}>
              <Text type="heading2" className="mr-10" color={panelProps.textColorName as Colour}>
                {headerTitle}
              </Text>
            </div>
          )}
          {typeof children === 'string' ? <Text color={panelProps.textColorName as Colour}>{children}</Text> : children}
        </div>
      </div>
    </div>
  );

  if (!tooltip) {
    return panel;
  }

  return (
    <div className={cx(styles['coloured-panel-with-tooltip'], className)}>
      {panel}
      {tooltip && (
        <div
          className={styles['coloured-panel__arrow']}
          style={{
            borderColor: panelProps.background
          }}
        />
      )}
    </div>
  );
}
