import React, { useEffect, useState } from 'react';

import { getFormattedStackVersion, normaliseUrl } from '@cloud-wave/neon-common-lib';

import { ImageType } from 'lib/common/constants/imageBlockFields';

import { TConfig } from 'lib/common/types/Config';

import { getDefaultBrandImageUrl, getFileUrl } from '../utils';
import ConfigContext, { configObj } from './Context';

type Props = {
  children: React.ReactNode;
};

const ConfigProvider = ({ children }: Props) => {
  const [config, setConfig] = useState(configObj);
  const [configLoaded, setConfigLoaded] = useState(false);

  const initConfig = async () => {
    try {
      const cfgJson = await fetch(
        `${config.ADMIN_SERVICE_URL}/cfg/unsecure/${config.TENANT_ID}/${config.DEPLOYMENT_ENVIRONMENT}`
      );

      const cfg = await cfgJson.json();

      const baseConfig: TConfig = {
        ...config,
        ...cfg,
        BRAND: {
          ...cfg.BRAND,
          productName: cfg.BRAND?.productName || config.BRAND.productName,
          helpDocsUrl: normaliseUrl(cfg.BRAND?.helpDocsUrl || config.BRAND.helpDocsUrl)
        }
      };

      setConfig(baseConfig);
      setConfigLoaded(true);

      console.log(
        `~~~ [${baseConfig.BRAND.productName} Version ${
          getFormattedStackVersion(config.STACK_VERSION).presentation
        }] ~~~`
      );

      document
        .querySelector(`link[rel~='icon']`)
        ?.setAttribute(
          'href',
          baseConfig.BRAND.favicon?.fileKey
            ? getFileUrl(baseConfig.BRAND.favicon?.fileKey, baseConfig)
            : getDefaultBrandImageUrl(ImageType.FAVICON)
        );

      document.title = baseConfig.BRAND.productName;
    } catch {
      setConfigLoaded(false);
    }
  };

  useEffect(() => {
    initConfig();
  }, []);

  return configLoaded ? (
    <ConfigContext.Provider value={{ config, configLoaded }}>{children}</ConfigContext.Provider>
  ) : null;
};

export default ConfigProvider;
