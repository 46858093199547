import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { Backdrop, Modal as MUIModal, Slide } from '@material-ui/core';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import Text from 'lib/common/components/Text';

import styles from './drawer.module.scss';

interface DrawerProps {
  testId?: string;
  open: boolean;
  title?: string;
  onClose: () => void;
  children?: ReactNode;
  headerContent?: ReactElement;
}

export default function Drawer({ children, onClose, open, title: defaultTitle, testId, headerContent }: DrawerProps) {
  const [title, setTitle] = useState(defaultTitle);

  useEffect(() => {
    setTitle(defaultTitle);
  }, [defaultTitle]);

  const onCloseHandler = () => {
    setTitle(defaultTitle);
    return onClose();
  };

  const onResetTitle = () => {
    setTitle(defaultTitle);
  };

  return (
    <MUIModal
      open={open}
      onClose={onCloseHandler}
      BackdropProps={{ timeout: 500 }}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <Slide direction="left" in={open} timeout={500}>
        <div data-testid={testId} className={styles['drawer']}>
          <div className={styles['drawer__header']}>
            <div className={styles['drawer__header__body']}>
              <Text type="heading2" className={styles['drawer__header__body__title']}>
                {title}
              </Text>
              {headerContent}
            </div>
            <ClickableIcon
              onClick={onCloseHandler}
              icon={faXmark}
              size={25}
              tooltipPlacement="bottom"
              tooltip="Close"
            />
          </div>
          <div className={styles['drawer__content']}>
            {typeof children === 'function' ? children({ setTitle, resetTitle: onResetTitle }) : children}
          </div>
        </div>
      </Slide>
    </MUIModal>
  );
}
