import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import cx from 'classnames';

import styles from './layout.module.scss';

interface ILayout {
  isSoftphone?: boolean;
  isForcedSoftphone?: boolean;
  children: ReactNode;
}

export default function Layout({ isSoftphone, isForcedSoftphone, children }: ILayout) {
  return (
    <div
      className={cx(styles['layout'], {
        [styles['layout--softphone']]: isSoftphone || isForcedSoftphone,
        [styles['layout--softphone--forced']]: isForcedSoftphone
      })}
    >
      {isForcedSoftphone && createPortal(<div className={styles['layout--softphone__background']} />, document.body)}
      {children}
    </div>
  );
}
