import VALIDATION_TEXT from 'lib/common/constants/validationText';

import { SEARCHABLE_FIELDS } from '../types/CustomerProfileForm';

const getDuplicateMessage = (field: string) => `This ${field} is already being used in another profile`;

export const VALIDATION_ERROR_STATES = {
  [SEARCHABLE_FIELDS.PHONE_NUMBER]: {
    invalid: VALIDATION_TEXT.PHONE_NUMBER,
    duplicate: getDuplicateMessage('phone number')
  },
  [SEARCHABLE_FIELDS.EMAIL_ADDRESS]: {
    invalid: 'Email address is not valid',
    duplicate: getDuplicateMessage('email address')
  },
  [SEARCHABLE_FIELDS.ACCOUNT_NUMBER]: {
    invalid: void 0,
    duplicate: getDuplicateMessage('account number')
  }
};
