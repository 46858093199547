import { useEffect, useState } from 'react';

import { Permissions } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import useTrigger from 'lib/common/hooks/useTrigger';

import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';

import connectAction from 'lib/common/utils/connectAction';
import connectGetter from 'lib/common/utils/connectGetter';

import { LogEvents, logger } from '../../components/LoggerController';
import { useAgentContext } from '../AgentContext';
import { useContactContext } from '../ContactContext';
import { usePermissionsContext } from '../PermissionsContext';
import Context from './Context';
import storeAgentStatus from './api/storeAgentStatus';
import useUnload from './hooks/useUnload';
import getMappedConnectState from './utils/getMappedConnectState';

const REMOTE_UPDATE_INTERVAL_S = 120;

function PresenceProvider({ children }) {
  const { agent } = useAgentContext();
  const {
    state: { tasks }
  } = useContactContext();

  const { hasPermission } = usePermissionsContext();
  const [connectState, setConnectState] = useState<string | null>(null);
  const [agentPresence, setAgentPresence] = useState<PRESENCE_STATES | null>(null);
  const [agentNextState, setAgentNextState] = useState<connect.AgentState | undefined>(
    connectGetter(agent, 'getNextState')
  );

  const [storeStatusTrigger, updateStoreStatusTrigger] = useTrigger({ initialValueNull: true });

  const { fetch_: fetch, tokens } = useAuthContext();
  const { config } = useConfigContext();

  const isBusyFromConnect = Boolean(agentPresence === PRESENCE_STATES.BUSY && tasks.length);

  const hasMissedCallPermission = hasPermission({
    type: 'tenant',
    permission: Permissions.AGENT_MISSED_CALL
  });

  const onConnectPresenceChange = ({ agent, newState }: { agent: connect.Agent; newState: string }) => {
    // An unadulterated version of connect state, used in AgentStatusTimer
    setConnectState(newState);

    if (!tasks.length || newState === PRESENCE_STATES.AVAILABLE) {
      setAgentNextState(undefined);
    }

    if (!newState || newState === agentPresence) {
      return;
    }

    logger.info(LogEvents.AGENT_STATUS_CHANGED.BY_CONNECT, { newState }); // agentPresence always returns undefined when called in this method

    setAgentPresence(getMappedConnectState(newState as PRESENCE_STATES));
    updateStoreStatusTrigger();
    setAgentNextState(connectGetter(agent, 'getNextState') || undefined);
  };

  const setConnectPresence = async (presence) => {
    if (!agent) {
      return;
    }

    const agentStates = connectGetter(agent, 'getAgentStates');
    const agentState = agentStates?.find((state) => state?.name === presence);

    if (!agentState) {
      return;
    }

    try {
      // This sends the request and returns success if the request is done. Then the server handles it async.
      await connectAction('setState', agent, agentState, {
        optionalPayloads: [{ enqueueNextState: true }],
        ignoreError: () => agent.getState().name === agentState.name
      });

      const currentState = getMappedConnectState(agentState.name as PRESENCE_STATES);

      if (currentState === PRESENCE_STATES.AVAILABLE) {
        setAgentNextState(undefined);
      }

      if (currentState === agentPresence) {
        return;
      }

      logger.info(LogEvents.AGENT_STATUS_CHANGED.MANUALLY.SUCCESS, { oldState: agentPresence, newState: currentState });

      setAgentPresence(currentState);
      updateStoreStatusTrigger();
    } catch (error) {
      logger.error(LogEvents.AGENT_STATUS_CHANGED.MANUALLY.FAIL, { error });
    }
  };

  useUnload({ fetch, tokens, config });

  // The interval will update the value of storeStatusTrigger, which will cause this effect to run and store our agent status
  useEffect(() => {
    if (!storeStatusTrigger) {
      return;
    }

    storeAgentStatus({
      status: agentPresence,
      fetch,
      tokens,
      config
    });
  }, [storeStatusTrigger]);

  useEffect(() => {
    if (!agent) {
      return;
    }

    // Set initial status
    const agentStatus = connectGetter(agent, 'getState')?.name;

    if (!agentStatus) {
      return;
    }

    setConnectState(agentStatus);
    setAgentPresence(getMappedConnectState(agentStatus as PRESENCE_STATES));
    updateStoreStatusTrigger();

    agent.onStateChange(onConnectPresenceChange);
    agent.onEnqueuedNextState(() => setAgentNextState(connectGetter(agent, 'getNextState')));
  }, [agent]);

  useEffect(() => {
    const connect = (window as any).getConnect();

    const contactSubscription = connect.contact((contact) => {
      contact.onMissed(() => {
        if (hasMissedCallPermission) {
          return;
        }

        setConnectPresence(PRESENCE_STATES.AVAILABLE);
      });
    });

    const statusInterval = setInterval(() => {
      updateStoreStatusTrigger();
    }, REMOTE_UPDATE_INTERVAL_S * 1000);

    return () => {
      clearInterval(statusInterval);
      contactSubscription.unsubscribe();
    };
  }, []);

  return (
    <Context.Provider
      value={{
        setConnectPresence,
        connectState,
        agentPresence: agentPresence || PRESENCE_STATES.OFFLINE,
        isBusyFromConnect,
        agentStates: connectGetter(agent, 'getAgentStates') || [],
        agentNextState
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default PresenceProvider;
