import { useRef, useState } from 'react';

import { faStop } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { RecordingState } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import Loader from 'lib/common/components/Loader';
import { LogEvents, logger } from 'lib/common/components/LoggerController';
import Text from 'lib/common/components/Text';

import TaskContentTypes from 'lib/common/types/TaskContentTypes';
import toast from 'lib/common/utils/toast/toast';

import updateRecordingState from './api/updateRecordingState';
import PauseTimer from './components/PauseTimer/PauseTimer';
import StopRecordingModal from './components/StopRecordingModal';
import CALL_RECORDING_STATES from './constants/CallRecordingStates';
import ERROR_MESSAGES from './constants/ErrorMessages';
import { CallRecordingAttributeValues, getCallRecordingPermission } from './utils/getCallRecordingPermission';

import styles from './call-recording.module.scss';

export default function CallRecording({ className }: { className?: string }) {
  const {
    state: { selectedTaskId, tasks },
    actions: { setTaskContent }
  } = useContactContext();
  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);
  const { state: callRecordingState = RecordingState.RESUME, startPauseTime } = selectedTask?.callRecording || {};
  const { hasPermission } = usePermissionsContext();
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const { isSoftphone } = useLayout();
  const [loading, setLoading] = useState(false);
  const [stopRecordingModalOpen, setStopRecordingModalOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  if (!selectedTask || !selectedTaskId) {
    return null;
  }

  const callRecordingPermission = getCallRecordingPermission({ task: selectedTask, hasPermission });

  if (callRecordingPermission === CallRecordingAttributeValues.OFF) {
    return null;
  }

  const handleOnActionClick = async () => {
    const action = callRecordingState === RecordingState.RESUME ? RecordingState.SUSPEND : RecordingState.RESUME;
    const isPauseCall = action === RecordingState.SUSPEND;

    try {
      setLoading(true);

      await updateRecordingState({
        fetch_,
        config,
        contactId: selectedTaskId,
        action
      });

      setTaskContent(selectedTaskId, TaskContentTypes.CALL_RECORDING, {
        state: action,
        startPauseTime: isPauseCall ? new Date() : void 0
      });

      logger.info(isPauseCall ? LogEvents.CALL_RECORDING.PAUSE.SUCCESS : LogEvents.CALL_RECORDING.RESUME.SUCCESS, {
        contactId: selectedTaskId
      });
      setLoading(false);
    } catch (error) {
      toast('error', ERROR_MESSAGES.ACTION_FAIL_ERROR);
      logger.error(isPauseCall ? LogEvents.CALL_RECORDING.PAUSE.FAIL : LogEvents.CALL_RECORDING.RESUME.FAIL, {
        contactId: selectedTaskId,
        error
      });
      setLoading(false);
    } finally {
      // Usually handled in the button, but since asyncAction flag is disabled, need to handle this manually
      buttonRef?.current?.focus();
    }
  };

  const handleOnEndRecordingClick = async () => {
    try {
      await updateRecordingState({
        fetch_,
        config,
        contactId: selectedTaskId,
        action: RecordingState.STOP
      });

      setTaskContent(selectedTaskId, TaskContentTypes.CALL_RECORDING, {
        state: RecordingState.STOP,
        startPauseTime: void 0
      });
      logger.info(LogEvents.CALL_RECORDING.STOP.SUCCESS, {
        contactId: selectedTaskId
      });
      setStopRecordingModalOpen(false);
    } catch (error) {
      toast('error', ERROR_MESSAGES.ACTION_FAIL_ERROR);
      logger.error(LogEvents.CALL_RECORDING.STOP.FAIL, { contactId: selectedTaskId, error });
      throw new Error();
    }
  };

  const { classNameSuffix, icon, text, iconAriaLabel } = CALL_RECORDING_STATES[callRecordingState];
  const iconSize = isSoftphone ? 17 : 20;

  return (
    <div
      className={cx(styles['call-recording'], styles[`call-recording--${classNameSuffix}`], className)}
      data-testid="call-recording"
    >
      <div className={cx(styles['call-recording__body'], { [styles['call-recording__body--softphone']]: isSoftphone })}>
        <span
          className={cx(styles['call-recording__body__dot'], styles[`call-recording__body__dot--${classNameSuffix}`])}
        />
        <Text
          className={cx(
            styles['call-recording__body__text'],
            styles[`call-recording__body__text--${classNameSuffix}`],
            {
              [styles['call-recording__body__text--softphone']]: isSoftphone
            }
          )}
          type="extraSmall"
          medium
          color="darkBlue"
        >
          {text}
        </Text>
        {callRecordingState === RecordingState.SUSPEND && (
          <PauseTimer time={startPauseTime} isSoftphone={isSoftphone} />
        )}
      </div>
      {callRecordingState !== RecordingState.STOP && (
        <div className={styles['call-recording__actions']}>
          {loading ? (
            <div className={styles['call-recording__actions__loader']}>
              <Loader relative minimised size={20} />
            </div>
          ) : callRecordingPermission !== CallRecordingAttributeValues.STOP ? (
            <ClickableIcon
              ref={buttonRef}
              aria-label={iconAriaLabel}
              icon={icon}
              onClick={handleOnActionClick}
              size={iconSize}
            />
          ) : null}
          {callRecordingPermission !== CallRecordingAttributeValues.PAUSE && (
            <ClickableIcon
              aria-label="Stop recording"
              icon={faStop}
              className="ml-10"
              onClick={() => setStopRecordingModalOpen(true)}
              size={iconSize}
            />
          )}
        </div>
      )}
      <StopRecordingModal
        isOpen={stopRecordingModalOpen}
        isSoftphone={isSoftphone}
        onClose={() => setStopRecordingModalOpen(false)}
        onDelete={handleOnEndRecordingClick}
        callRecordingPermission={callRecordingPermission}
      />
    </div>
  );
}
