import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useConfigContext } from 'lib/core/config';

import { useAgentContext } from 'lib/common/contexts/AgentContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Icon from 'lib/common/components/Icon';
import Loader from 'lib/common/components/Loader';
import SupportFooter from 'lib/common/components/SupportFooter';
import Text from 'lib/common/components/Text';
import ResponsiveLogo from 'lib/common/components/atoms/ResponsiveLogo';

import { HELP_LINKS } from 'lib/common/constants/help';

import { ReactComponent as LoginImage } from './assets/login.svg';

import './login.scss';

const LOGIN_HELP_TIMEOUT_S = 10000;

export default function Login() {
  const { isSoftphone } = useLayout();
  const { agent } = useAgentContext();
  const { config } = useConfigContext();

  const [extendedLoad, setExtendedLoad] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setExtendedLoad(true);
    }, LOGIN_HELP_TIMEOUT_S);

    return () => {
      clearTimeout(timeout);
    };
  });

  // If we have the agent, we're logged in. Show a loader while we fetch the user
  if (agent) {
    return <Loader elevated fixed />;
  }

  return (
    <>
      <div className="login">
        <div className={cx('login__content', { 'login__content--softphone': isSoftphone })}>
          <div className="login__content__left">
            <ResponsiveLogo
              containerClassName={cx('login__content__left__logo', {
                'login__content__left__logo--softphone': isSoftphone
              })}
            />
            <Text type="heading1">Sign In</Text>
            <Text type="heading2">We're opening another window for you to sign in using Amazon Connect.</Text>
            <Text>
              <strong>You’ll need to allow popups.</strong> If you’re already signed in to Connect, we’ll sign you in to{' '}
              {config.BRAND.productName} automatically.
            </Text>
            <CSSTransition in={extendedLoad} timeout={300} classNames="login__content__left__extended-load">
              <div className="login__content__left__extended-load">
                <Icon icon={faInfoCircle} size={25} className="login__content__left__extended-load__info-icon" />
                <div className="login__content__left__extended-load__text">
                  <Text>We're still working hard to sign you in. It may take a few more moments.</Text>
                  <Text>
                    Sign in not working? Try{' '}
                    <Text type="link" href={window.location.origin}>
                      reloading the page
                    </Text>
                    .
                  </Text>
                </div>
              </div>
            </CSSTransition>
            <Text type="link" href={`${config.BRAND.helpDocsUrl}${HELP_LINKS.CONNECT.SIGN_IN}`} rel="noreferrer">
              Why do I need to sign in with Amazon Connect?
            </Text>
            <Text
              type="link"
              href={`${config.BRAND.helpDocsUrl}${HELP_LINKS.CONNECT.WINDOW_NOT_APPEARING}`}
              rel="noreferrer"
            >
              Sign in window not appearing?
            </Text>
          </div>
          <div className="login__content__right">
            <LoginImage className="login__content__right__login-image" />
          </div>
        </div>
      </div>
      <SupportFooter />
    </>
  );
}
