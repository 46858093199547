import cx from 'classnames';

import { getFormattedStackVersion } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';

import { useContactContext } from 'lib/common/contexts/ContactContext';

import Text from 'lib/common/components/Text';
import ResponsiveLogo from 'lib/common/components/atoms/ResponsiveLogo';
import AgentStatusTimer from 'lib/common/components/molecules/AgentStatusTimer';

import { TASK_TYPES } from 'lib/common/constants/taskStates';

import './softphone-header.scss';

interface ISoftphoneHeaderProps {
  pageTitle: string;
  isReversedLayout: boolean;
}

const SoftphoneHeader = ({ pageTitle, isReversedLayout }: ISoftphoneHeaderProps) => {
  const {
    state: { tasks, selectedTaskId }
  } = useContactContext();
  const { config } = useConfigContext();

  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);
  const taskTitle = selectedTask
    ? TASK_TYPES(selectedTask.connectionType)[selectedTask.status]?.[selectedTask.type]?.subtitle
    : '';

  const title = pageTitle || taskTitle;

  return (
    <header className={cx('softphone-header', { 'softphone-header--reversed': isReversedLayout })}>
      <ResponsiveLogo
        isSoftphone
        className="mr-10"
        altText={`${config.BRAND.productName}-version-${getFormattedStackVersion(config.STACK_VERSION).presentation}`}
      />
      {<AgentStatusTimer className="softphone-header__agent-status-timer" />}
      {title && (
        <div className="softphone-header__title">
          <Text htmlElement="h1" className="softphone-header__title__text" color="darkBlue" type="heading2">
            {title}
          </Text>
        </div>
      )}
    </header>
  );
};

export default SoftphoneHeader;
