import { ReactElement, useState } from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCookie,
  faMicrophone,
  faSquareMinus,
  faSquarePlus,
  faTriangleExclamation
} from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useConfigContext } from 'lib/core/config';

import { useRequirementsContext } from 'lib/common/contexts/RequirementsContext';
import type { TBrowserPermissions } from 'lib/common/contexts/RequirementsContext';

import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import { HELP_LINKS } from 'lib/common/constants/help';

import styles from './warning-banner.module.scss';

type TWarningBannerProps = {
  title: ReactElement | string;
  body: ReactElement | string;
  icon?: IconDefinition;
  className?: string;
};

const WarningBanner = ({ title, body, icon, className }: TWarningBannerProps) => {
  const [open, setOpen] = useState(false);
  const isSoftphone = useIsSoftphoneQuery();

  return (
    <div role="alert" className={cx(styles['warning-banner'], className)} data-testid="warning-banner">
      <div
        className={cx(styles['warning-banner__content'], {
          [styles['warning-banner__content--softphone']]: isSoftphone,
          [styles['warning-banner__content--open']]: open
        })}
      >
        <div
          className={cx(styles['warning-banner__content__titlebar'], {
            [styles['warning-banner__content__titlebar--softphone']]: isSoftphone
          })}
        >
          <Icon icon={icon || faTriangleExclamation} size={17} />
          {typeof title === 'string' ? (
            <Text className={cx(styles['warning-banner__content__titlebar__title'])} type="heading3">
              {title}
            </Text>
          ) : (
            title
          )}
          <ClickableIcon
            aria-label={`${open ? 'Shrink' : 'Expand'} banner`}
            className={cx(styles['warning-banner__content__titlebar__expander'])}
            icon={open ? faSquareMinus : faSquarePlus}
            onClick={() => setOpen((state) => !state)}
          ></ClickableIcon>
        </div>
        <div className={cx(styles['warning-banner__content__body'])}>{body}</div>
      </div>
    </div>
  );
};

const RequirementItem = ({ icon, label, link }: { icon: IconDefinition; label: string; link: string }) => {
  return (
    <div className={styles['requirement-item']}>
      <Icon icon={icon} size={12} />
      <Text type="helper" bold>
        {label}
      </Text>
      <Text onClick={() => window.open(link)}>Learn More</Text>
    </div>
  );
};

const RequirementsBody = ({
  isPermissionGranted
}: {
  isPermissionGranted: (permission: TBrowserPermissions) => boolean;
}) => {
  const { config } = useConfigContext();

  return (
    <div>
      <Text type="helper" className={styles['requirements-body']}>
        There’s a few things we need to access to be able to run properly.
        <Text type="helper" bold inline>
          {' '}
          You may notice some unexpected behaviour.{' '}
        </Text>
        You’ll need to enable access to:
      </Text>
      {!isPermissionGranted('microphone') && (
        <RequirementItem
          icon={faMicrophone}
          label="Your Microphone"
          link={`${config.BRAND.helpDocsUrl}${HELP_LINKS.REQUIREMENTS.MICROPHONE_ACCESS}`}
        />
      )}
      {!isPermissionGranted('persistent-storage') && (
        <RequirementItem
          icon={faCookie}
          label="Cookies"
          link={`${config.BRAND.helpDocsUrl}${HELP_LINKS.REQUIREMENTS.STORAGE_ACCESS}`}
        />
      )}
    </div>
  );
};

const RequirementsBanner = ({ className }: { className?: string }) => {
  const { isAccessRequired, isReloadRequired, isPermissionGranted } = useRequirementsContext();

  const title = (
    <Text className={cx(styles['warning-banner__content__titlebar__title'], ['ml-10'])} type="heading3">
      We're Missing Some Access{' '}
      {isReloadRequired() && (
        <Text className={styles['warning-banner__content__titlebar__subtitle']} type="small">
          (Reload Required)
        </Text>
      )}
    </Text>
  );

  if (!isAccessRequired()) {
    return <></>;
  }

  return (
    <WarningBanner
      className={className}
      title={title}
      body={<RequirementsBody isPermissionGranted={isPermissionGranted} />}
    />
  );
};

export default RequirementsBanner;
