import React from 'react';
import Lottie from 'react-lottie';

import cx from 'classnames';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import loaderAnimation from './loader-animation.json';

import './loader.scss';

interface LoaderProps {
  elevated?: boolean;
  minimised?: boolean;
  size?: string | number;
  fixed?: boolean;
  small?: boolean;
  relative?: boolean;
  className?: string;
}

function getSize({ small, size }) {
  if (small) {
    return '50px';
  }

  return typeof size === 'string' || !size ? size : `${size}px`;
}

export default function Loader({ minimised, size, elevated, fixed, small, relative, className }: LoaderProps) {
  const { isSoftphone } = useLayout();

  const shouldUseSoftphoneSize = isSoftphone && !size;
  const normalisedSize = getSize({ small: shouldUseSoftphoneSize || small, size });

  const animation = (
    <div
      className={cx('loader', className && { [className]: minimised })}
      style={{ height: normalisedSize, width: normalisedSize }}
      data-testid="loader"
    >
      <span className="sr-only">Loading</span>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loaderAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
      />
    </div>
  );

  if (minimised) {
    return animation;
  }

  return (
    <div
      className={cx('loader-container', className, {
        'loader-container--elevated': elevated,
        'loader-container--fixed': fixed,
        'loader-container--small': shouldUseSoftphoneSize || small,
        'loader-container--relative': relative
      })}
    >
      {animation}
    </div>
  );
}
