import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faApple,
  faFacebookMessenger,
  faInstagram,
  faLine,
  faViber,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons';
import {
  faCheckSquare,
  faCheckToSlot,
  faComment,
  faEnvelope,
  faEye,
  faMessageLines,
  faMessageSmile,
  faPaperPlane,
  faPhone,
  faPhoneArrowDown,
  faPhoneArrowUpRight,
  faPhoneHangup,
  faPhoneMissed,
  faPhoneXmark,
  faQuestionCircle,
  faUserGroup,
  faUsers
} from '@fortawesome/pro-regular-svg-icons';
import _startCase from 'lodash.startcase';

import { ChatPlatform, ConstValues, TChatPlatform } from '@cloud-wave/neon-common-lib';

import CONNECTION_TYPES from 'lib/common/constants/connectionType';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import faCalendarPhone from 'lib/common/customIcons/faCalendarPhone';
import TChatTask from 'lib/common/types/ChatTask';
import TTask from 'lib/common/types/Task';

export const TASK_COLOURS = {
  [CONTACT_STATES.MISSED]: 'danger',
  [CONTACT_STATES.REJECTED]: 'danger',
  [CONTACT_STATES.CONNECTING]: 'darkBlue',
  [CONTACT_STATES.CONNECTED]: 'darkBlue',
  [CONTACT_STATES.ACW]: 'darkBlue',
  [CONTACT_STATES.DRAFT]: 'darkBlue'
};

type TChatTypeProps = Record<TChatPlatform, { icon: IconDefinition; subtitle: string }>;

const SUB_TITLE_TEMPLATES = (TYPE: keyof typeof CONTACT_TYPES) => {
  const type = _startCase(TYPE.toLowerCase());
  return {
    [CONNECTION_TYPES.INBOUND]: `Incoming ${type}`,
    [CONNECTION_TYPES.MONITORING]: `Monitoring ${type}`,
    [CONNECTION_TYPES.OUTBOUND]: `Outgoing ${type}`,
    [CONTACT_STATES.MISSED]: `Missed ${type}`,
    [CONTACT_STATES.ACCEPTED]: `Incoming ${type}`,
    [CONTACT_STATES.ACW]: `After ${type} Work`,
    [CONTACT_STATES.CONNECTED]: `Connected ${type}`,
    [CONTACT_STATES.REJECTED]: `Rejected ${type}`,
    [CONTACT_STATES.DRAFT]: 'Draft'
  };
};

type chatSubIcons = Record<string, IconDefinition>;

const CHAT_SUB_TYPES_ICONS: chatSubIcons = {
  [ChatPlatform.DEFAULT]: faComment,
  [ChatPlatform.MESSENGER]: faFacebookMessenger,
  [ChatPlatform.WHATSAPP]: faWhatsapp,
  [ChatPlatform.INSTAGRAM]: faInstagram,
  [ChatPlatform.VIBER]: faViber,
  [ChatPlatform.VIBERBM]: faViber,
  [ChatPlatform.TELEGRAM]: faPaperPlane,
  [ChatPlatform.LINE]: faLine,
  [ChatPlatform.RCS]: faMessageLines,
  [ChatPlatform.SMS]: faMessageLines,
  [ChatPlatform.MMS]: faMessageLines,
  [ChatPlatform.APPLE]: faApple,
  FALLBACK: faMessageSmile
} as const;

export const CHAT_SUB_TYPES_TOOLTIPS = {
  [ChatPlatform.DEFAULT]: 'Web Chat',
  [ChatPlatform.MESSENGER]: 'Messenger',
  [ChatPlatform.WHATSAPP]: 'WhatsApp',
  [ChatPlatform.INSTAGRAM]: 'Instagram',
  [ChatPlatform.VIBER]: 'Viber',
  [ChatPlatform.VIBERBM]: 'Viber',
  [ChatPlatform.TELEGRAM]: 'Telegram',
  [ChatPlatform.LINE]: 'LINE',
  [ChatPlatform.RCS]: 'RCS',
  [ChatPlatform.SMS]: 'SMS',
  [ChatPlatform.MMS]: 'MMS',
  [ChatPlatform.APPLE]: 'Apple',
  FALLBACK: 'External Chat'
} as const;

export const chatPlatformHasCustomIcon = (neonChatPlatform) =>
  Object.keys(CHAT_SUB_TYPES_ICONS).includes(neonChatPlatform);

export const getChatProps = ({ neonChatPlatform, status, type }: TChatTask, props) => {
  const chatPlatform = chatPlatformHasCustomIcon(neonChatPlatform) ? neonChatPlatform : 'FALLBACK';
  const prop = {
    ...(props?.[status]?.[type]?.[chatPlatform] ?? {}),
    tooltip: CHAT_SUB_TYPES_TOOLTIPS[neonChatPlatform] || CHAT_SUB_TYPES_TOOLTIPS.FALLBACK
  };

  return prop as { icon: IconDefinition; subtitle: string; tooltip: string };
};

export const getTaskDisplayProps = (task?: TChatTask | TTask) => {
  if (!task) {
    return { icon: faQuestionCircle, subtitle: '' };
  }
  const isChat = task.type === CONTACT_TYPES.CHAT;
  const allTaskProps = TASK_TYPES(task.connectionType);
  return isChat
    ? getChatProps(task as TChatTask, allTaskProps)
    : allTaskProps?.[task.status]?.[task.type] ?? { icon: faQuestionCircle, subtitle: 'Unknown Task Type' };
};

const getChatSubTypePropsForUseCase = (
  state: keyof typeof CONTACT_STATES | ConstValues<typeof CONNECTION_TYPES>
): TChatTypeProps => {
  const subtitles = SUB_TITLE_TEMPLATES('CHAT');
  const entriesNeedingIconSubtitle = Object.entries(CHAT_SUB_TYPES_ICONS) as [TChatPlatform, IconDefinition][];

  return entriesNeedingIconSubtitle.reduce<TChatTypeProps>((accumulator, [key, value]) => {
    return { ...accumulator, [key]: { icon: value, subtitle: subtitles[state] } };
  }, {} as TChatTypeProps);
};

const DEFAULT_INCOMING_TASK_PROPS = {
  [CONNECTION_TYPES.INBOUND]: {
    [CONTACT_TYPES.CALL]: { icon: faPhoneArrowDown, subtitle: `Incoming Call` },
    [CONTACT_TYPES.QUEUE_CALLBACK]: {
      icon: faCalendarPhone,
      subtitle: 'Incoming Callback'
    },
    [CONTACT_TYPES.CHAT]: getChatSubTypePropsForUseCase(CONNECTION_TYPES.INBOUND),
    [CONTACT_TYPES.EMAIL]: { icon: faEnvelope, subtitle: `Incoming Email` },
    [CONTACT_TYPES.TASK]: { icon: faCheckSquare, subtitle: `Incoming Agent Task` },
    [CONTACT_TYPES.OUTBOUND_PREVIEW]: { icon: faEye, subtitle: `Incoming Outbound Preview` },
    [CONTACT_TYPES.CONFERENCE_CALL]: { icon: faUsers, subtitle: 'Connecting Transfer' }
  },
  [CONNECTION_TYPES.AGENT]: {
    [CONTACT_TYPES.CALL]: { icon: faPhoneArrowDown, subtitle: `Incoming Call` },
    [CONTACT_TYPES.CHAT]: getChatSubTypePropsForUseCase(CONNECTION_TYPES.AGENT),
    [CONTACT_TYPES.EMAIL]: { icon: faEnvelope, subtitle: `Incoming Email` },
    [CONTACT_TYPES.OUTBOUND_PREVIEW]: { icon: faEye, subtitle: `Incoming Outbound Preview` },
    [CONTACT_TYPES.TASK]: { icon: faCheckSquare, subtitle: `Incoming Agent Task` }
  },
  [CONNECTION_TYPES.MONITORING]: {
    [CONTACT_TYPES.CALL]: { icon: faPhoneArrowDown, subtitle: `Monitoring Call` },
    [CONTACT_TYPES.CHAT]: getChatSubTypePropsForUseCase(CONNECTION_TYPES.MONITORING),
    [CONTACT_TYPES.CONFERENCE_CALL]: { icon: faPhoneArrowDown, subtitle: `Monitoring Call` },
    [CONTACT_TYPES.EMAIL]: { icon: faEnvelope, subtitle: `Monitoring Email` },
    [CONTACT_TYPES.OUTBOUND_PREVIEW]: { icon: faEye, subtitle: `Monitoring Outbound Preview` },
    [CONTACT_TYPES.TASK]: { icon: faCheckSquare, subtitle: `Monitoring Task` }
  },
  [CONNECTION_TYPES.OUTBOUND]: {
    [CONTACT_TYPES.CALL]: {
      icon: faPhoneArrowUpRight,
      subtitle: 'Outbound Call'
    },
    [CONTACT_TYPES.CONFERENCE_CALL]: { icon: faUsers, subtitle: 'Connecting Transfer' },
    [CONTACT_TYPES.EMAIL]: { icon: faEnvelope, subtitle: 'Email' }
  }
};

const DEFAULT_ACCEPTED_TASK_PROPS = {
  ...DEFAULT_INCOMING_TASK_PROPS,
  [CONNECTION_TYPES.INBOUND]: {
    ...DEFAULT_INCOMING_TASK_PROPS[CONNECTION_TYPES.INBOUND],
    [CONTACT_TYPES.QUEUE_CALLBACK]: {
      icon: faCalendarPhone,
      subtitle: 'Connecting Callback'
    }
  },
  [CONNECTION_TYPES.OUTBOUND]: {
    ...DEFAULT_INCOMING_TASK_PROPS[CONNECTION_TYPES.OUTBOUND],
    [CONTACT_TYPES.CALL]: {
      icon: faPhone,
      subtitle: 'Connecting Call'
    }
  }
};

const getConnectedTaskPropsForConnectionType = (connectionType: ValueOf<typeof CONNECTION_TYPES>) => {
  if (connectionType === CONNECTION_TYPES.MONITORING) {
    return {
      [CONTACT_TYPES.CALL]: { icon: faPhone, subtitle: 'Monitoring Call' },
      [CONTACT_TYPES.QUEUE_CALLBACK]: { icon: faPhone, subtitle: 'Monitoring Call' },
      [CONTACT_TYPES.CHAT]: getChatSubTypePropsForUseCase(CONNECTION_TYPES.MONITORING),
      [CONTACT_TYPES.TASK]: { icon: faCheckSquare, subtitle: 'Active Agent Task' },
      [CONTACT_TYPES.OUTBOUND_PREVIEW]: { icon: faEye, subtitle: 'Outbound Preview' },
      [CONTACT_TYPES.CONFERENCE_CALL]: { icon: faUserGroup, subtitle: 'Monitoring Call' }
    };
  }

  return {
    [CONTACT_TYPES.CALL]: { icon: faPhone, subtitle: 'Connected Call' },
    [CONTACT_TYPES.QUEUE_CALLBACK]: { icon: faPhone, subtitle: 'Connected Call' },
    [CONTACT_TYPES.CHAT]: getChatSubTypePropsForUseCase(CONTACT_STATES.CONNECTED),
    [CONTACT_TYPES.TASK]: { icon: faCheckSquare, subtitle: 'Active Agent Task' },
    [CONTACT_TYPES.OUTBOUND_PREVIEW]: { icon: faEye, subtitle: 'Outbound Preview' },
    [CONTACT_TYPES.EMAIL]: { icon: faEnvelope, subtitle: 'Active Email' },
    [CONTACT_TYPES.CONFERENCE_CALL]: { icon: faUserGroup, subtitle: 'Connected Transfer' }
  };
};

export const TASK_TYPES = (connectionType: ValueOf<typeof CONNECTION_TYPES>) => ({
  [CONTACT_STATES.MISSED]: {
    [CONTACT_TYPES.CALL]: { icon: faPhoneMissed, subtitle: 'Missed Call' },
    [CONTACT_TYPES.QUEUE_CALLBACK]: { icon: faPhoneMissed, subtitle: 'Missed Callback' },
    [CONTACT_TYPES.CHAT]: getChatSubTypePropsForUseCase(CONTACT_STATES.MISSED),
    [CONTACT_TYPES.EMAIL]: { icon: faEnvelope, subtitle: 'Missed Email' },
    [CONTACT_TYPES.TASK]: { icon: faCheckSquare, subtitle: 'Missed Agent Task' },
    [CONTACT_TYPES.OUTBOUND_PREVIEW]: { icon: faEye, subtitle: 'Missed Outbound Preview' }
  },
  [CONTACT_STATES.CONNECTING]: DEFAULT_INCOMING_TASK_PROPS[connectionType],
  [CONTACT_STATES.ACCEPTED]: DEFAULT_ACCEPTED_TASK_PROPS[connectionType],
  [CONTACT_STATES.ACW]: {
    [CONTACT_TYPES.CALL]: { icon: faPhoneHangup, subtitle: 'After Call Work' },
    [CONTACT_TYPES.QUEUE_CALLBACK]: { icon: faPhoneHangup, subtitle: 'After Call Work' },
    [CONTACT_TYPES.CHAT]: getChatSubTypePropsForUseCase(CONTACT_STATES.ACW),
    [CONTACT_TYPES.TASK]: { icon: faCheckToSlot, subtitle: 'After Task Work' },
    [CONTACT_TYPES.OUTBOUND_PREVIEW]: { icon: faEye, subtitle: 'After Outbound Preview Work' },
    [CONTACT_TYPES.EMAIL]: { icon: faEnvelope, subtitle: 'After Email Work' },
    [CONTACT_TYPES.CONFERENCE_CALL]: { icon: faUserGroup, subtitle: 'After Call Work' }
  },
  [CONTACT_STATES.CONNECTED]: getConnectedTaskPropsForConnectionType(connectionType),
  [CONTACT_STATES.REJECTED]: {
    [CONTACT_TYPES.CALL]: { icon: faPhoneXmark, subtitle: 'Rejected Call' },
    [CONTACT_TYPES.QUEUE_CALLBACK]: { icon: faPhoneXmark, subtitle: 'Rejected Callback' },
    [CONTACT_TYPES.CHAT]: getChatSubTypePropsForUseCase(CONTACT_STATES.REJECTED),
    [CONTACT_TYPES.TASK]: { icon: faCheckSquare, subtitle: 'Rejected Agent Task' },
    [CONTACT_TYPES.OUTBOUND_PREVIEW]: { icon: faEye, subtitle: 'Rejected Outbound Preview' },
    [CONTACT_TYPES.EMAIL]: { icon: faEnvelope, subtitle: 'Rejected Email' },
    [CONTACT_TYPES.CONFERENCE_CALL]: { icon: faUserGroup, subtitle: 'Rejected Transfer' }
  },
  [CONTACT_STATES.DRAFT]: {
    [CONTACT_TYPES.EMAIL]: { icon: faEnvelope, subtitle: 'Draft' }
  }
});
