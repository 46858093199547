import { MutableRefObject, useEffect } from 'react';

import { faFont, faPaperclip, faSend, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import cx from 'classnames';
import { $getSelection } from 'lexical';

import { TemplateType } from '@cloud-wave/neon-common-lib';

import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';

import Button from 'lib/common/components/Button';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import TemplatedResponse from 'lib/common/components/molecules/TemplatedResponse';
import { EmailSignatureMenu } from 'lib/pages/AgentWorkspace/components/CreateEmail/EmailSignatureMenu/EmailSignatureMenu';

import TEmailTask from 'lib/common/types/EmailTask';
import { TAttachment } from 'lib/common/types/email/TAttachment';
import getTemplatedResponseQueueIds from 'lib/common/utils/templatedResponse/getTemplatedResponseQueueIds';

import AttachmentsModal from './components/AttachmentsModal';

import styles from './footer.module.scss';

interface IFooter {
  taskId: string;
  toggleToolbar: () => void;
  saveDisabled?: boolean;
  onSend: () => Promise<void | boolean>;
  discardEmail: () => void;
  originalAttachments?: TAttachment[];
  onRemoveOriginalAttachment: (hash: string) => void;
  baseFileSizeTotal?: number;
  task?: TEmailTask;
  onSendSuccess: () => Promise<void>;
  submitButtonRef: MutableRefObject<HTMLButtonElement | null>;
}

export default function Footer({
  taskId,
  toggleToolbar,
  saveDisabled,
  onSend,
  discardEmail,
  onRemoveOriginalAttachment,
  originalAttachments,
  baseFileSizeTotal,
  onSendSuccess,
  task,
  submitButtonRef
}: IFooter) {
  const {
    actions: { openModal, updateModalOptions }
  } = useSoftphoneModalContext();
  const [editor] = useLexicalComposerContext();
  const queueIds = getTemplatedResponseQueueIds(task);

  const onClickAttachmentsIcon = () => {
    openModal({
      id: 'attachments',
      title: 'Attachments',
      content: (
        <AttachmentsModal
          onRemoveOriginalAttachment={onRemoveOriginalAttachment}
          taskId={taskId}
          originalAttachments={originalAttachments}
          baseFileSizeTotal={baseFileSizeTotal}
        />
      )
    });
  };

  useEffect(() => {
    updateModalOptions({
      id: 'attachments',
      content: (
        <AttachmentsModal
          onRemoveOriginalAttachment={onRemoveOriginalAttachment}
          taskId={taskId}
          originalAttachments={originalAttachments}
          baseFileSizeTotal={baseFileSizeTotal}
        />
      )
    });
  }, [originalAttachments, baseFileSizeTotal]);

  const onAddTemplateContent = (templateContent: string) => {
    editor.update(() => {
      const stateToChange = editor.getEditorState().toJSON();
      const currentStateChildren = stateToChange.root.children;
      const stuffToAppendChildren = JSON.parse(templateContent).root.children;
      stateToChange.root.children = [...stuffToAppendChildren, ...currentStateChildren];

      const newState = editor.parseEditorState(stateToChange);

      editor.setEditorState(newState);
    });
  };

  const onInsertSignature = (signature: string) => {
    editor.update(() => {
      const selection = $getSelection();
      const parser = new DOMParser();
      const dom = parser.parseFromString(signature, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      if (selection) {
        selection.insertNodes(nodes, true);
      }
    });
  };

  return (
    <TaskFooter.SoftphoneContainer>
      <div className={cx('task-footer__container task-footer--softphone', styles['footer__container'])}>
        <div className="next-to-20">
          <ClickableIcon icon={faFont} size={17} onClick={toggleToolbar} tooltip="Text formatting" />
          <ClickableIcon icon={faPaperclip} size={17} onClick={onClickAttachmentsIcon} tooltip="Attach files" />
          <TemplatedResponse type={TemplateType.EMAIL} queueIds={queueIds} onClick={onAddTemplateContent} />
          <EmailSignatureMenu onClick={onInsertSignature} />
        </div>
        <div className={styles['footer__container__right-buttons']}>
          <Button icon={faTrash} tooltip="Discard" styleType="NEUTRAL" size="small" round onClick={discardEmail} />
          <Button
            ref={submitButtonRef}
            size="medium"
            icon={faSend}
            tooltip="Send"
            styleType="PRIMARY"
            round
            disabled={saveDisabled}
            onClick={onSend}
            asyncAction
            onSuccess={(result) => {
              if (!result) {
                return;
              }

              return onSendSuccess();
            }}
          />
        </div>
      </div>
    </TaskFooter.SoftphoneContainer>
  );
}
