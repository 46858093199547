import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { IFetch } from 'lib/core/context/AuthProvider/Context';

import { TConfig } from 'lib/common/types/Config';

export const getContactPresence = async ({
  fetch_,
  config,
  contactId
}: {
  fetch_: IFetch;
  config: TConfig;
  contactId: string;
}) => {
  try {
    const response = await fetch_(`${config.DIRECTORY_SERVICE_URL}/user/presence/contact/${contactId}`);

    return (await response.json()).data as { availability: string };
  } catch (e) {
    if (e instanceof AxiosError && e?.code === '500') {
      captureException(e);
    }
  }
  return { availability: void 0 };
};
