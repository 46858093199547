import { createContext, useContext } from 'react';

import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';

type PresenceContextType = {
  agentPresence: PRESENCE_STATES;
  connectState: string | null;
  setConnectPresence: (presence: PRESENCE_STATES) => Promise<void>;
  isBusyFromConnect: boolean;
  agentStates: connect.AgentStateDefinition[];
  agentNextState?: connect.AgentState;
};

const Context = createContext<PresenceContextType>({
  setConnectPresence: () => Promise.resolve(),
  agentPresence: PRESENCE_STATES.OFFLINE,
  connectState: null,
  isBusyFromConnect: false,
  agentStates: [],
  agentNextState: undefined
});

export default Context;

// export the context hook
export const usePresenceContext = () => useContext(Context);
