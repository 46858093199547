import { faSearch } from '@fortawesome/pro-regular-svg-icons';

import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import Text from 'lib/common/components/Text';

const SearchPlaceholder = ({ searchTerm, onClick }) => (
  <EmptyPlaceholder
    text={'We couldn’t find anything that matches'}
    subText={
      <Text color="darkGrey">
        There aren’t any contacts that match <Text.Bold>{searchTerm}</Text.Bold>.
      </Text>
    }
    icon={faSearch}
    callToAction={{
      text: 'Clear Search',
      onClick
    }}
  />
);

export default SearchPlaceholder;
