import { useCallback, useEffect, useState } from 'react';

import { stringifyUrl } from 'query-string';

import { useAuthContext } from 'lib/core/context/AuthProvider';

type TPaginatedLoadParams = {
  fetchUrl: string;
  pageSize?: number;
};

type TPaginatedLoadReturn<TData> = {
  data: TData[]; // contains all loaded values
  loadNext: () => Promise<TData[]>; // returns only newly fetched values
  exhausted: boolean;
  refreshData: () => void; // refreshes data for agents presence
};

const usePaginatedLoad = <TData>({ fetchUrl, pageSize = 50 }: TPaginatedLoadParams): TPaginatedLoadReturn<TData> => {
  const { fetch_ } = useAuthContext();

  const [data, setData] = useState<TData[]>([]);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [exhausted, setExhausted] = useState(false);

  useEffect(() => {
    setData([]);
    setNextToken(null);
    setExhausted(false);
  }, [fetchUrl]);

  const queryUrl = stringifyUrl({ url: fetchUrl, query: { limit: pageSize, nextToken } });

  const loadNext = useCallback(async () => {
    if (exhausted) {
      return null;
    }

    const res = await (await fetch_(queryUrl)).json();

    const {
      data: { nextToken: newNextToken, records: newData }
    } = res;

    setNextToken((_old) => newNextToken ?? null);
    setData((oldData) => [...oldData, ...(newData ?? [])]);

    if (!newNextToken) {
      setExhausted(true);
    }

    return newData;
  }, [queryUrl, nextToken, data, exhausted]);

  const refreshData = async () => {
    const res = await (await fetch_(queryUrl)).json();
    const {
      data: { records }
    } = res;

    setData(records ?? []);
  };

  return { data, loadNext, exhausted, refreshData };
};

export default usePaginatedLoad;
