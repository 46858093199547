import { Dispatch, useReducer } from 'react';

import _cloneDeep from 'lodash.clonedeep';

type TagData = { value: string; valid: boolean };

export type TagInputProps = {
  id?: string;
  className?: string;
  defaultText?: string;
  placeholder?: string;
  validateTag?: (value: string) => Promise<boolean> | boolean;
  errorMessage?: string;
  disabled?: boolean;
  /** Array of single-character strings that trigger the creation of a tag.
   * Defaults to [' ', ',', ';'], CR/LF also creates tag. */
  delimiters?: string[];
  /** Function to transform text on paste.
   * Call addTag with each tag to be inserted from pasted content.
   * Access pasted content from event.clipboardData.getData('Text') */
  handlePaste?: (event: React.ClipboardEvent<HTMLInputElement>, addTag: (tag: string) => Promise<void>) => void;

  /** State object for TagInput, create with useTagInputReducer() */
  state: TTagInputState;
  /** State dispatch function for TagInput, create with useTagInputReducer() */
  setState: Dispatch<Partial<TTagInputState>>;
  label?: string;
  errorHidden?: boolean;
};

export type TTagInputState = {
  allValid: boolean;
  tags: TagData[];
};

export const defaultTagInputState = { allValid: true, tags: [] };

export const useTagInputReducer = (defaultState?: TTagInputState) => {
  return useReducer(
    (oldState: TTagInputState, newState: Partial<TTagInputState>) => ({
      ...oldState,
      ...newState,
      allValid: (newState.tags?.filter(({ valid }) => valid === false)?.length ?? 0) === 0
    }),
    defaultState || _cloneDeep(defaultTagInputState)
  );
};
