import {
  faAddressCard,
  faBrowser,
  faCalendarDay,
  faChartLine,
  faChartSimple,
  faClockRotateLeft,
  faLayerGroup,
  faPaintbrushPencil,
  faPersonDigging
} from '@fortawesome/pro-regular-svg-icons';

import { Permissions } from '@cloud-wave/neon-common-lib';
import { PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

import { TConfig } from '../types/Config';
import isDevEnvironment from './isDevEnvironment';

export default (config: TConfig) => [
  {
    link: PAGE_ROUTES.REPORTS,
    icon: faChartSimple,
    name: 'Reports',
    permission: [Permissions.REPORTS_CUSTOM, Permissions.REPORTS_ANALYTICS, Permissions.REPORTS_PERFORMANCE_TODAY],
    requiresAdmin: true,
    pageHasSubMenu: true,
    items: [
      {
        link: PAGE_ROUTES.REPORTS_PERFORMANCE_TODAY,
        icon: faCalendarDay,
        name: 'Performance Today',
        activeClassName: 'nav-active',
        permission: Permissions.REPORTS_PERFORMANCE_TODAY
      },
      {
        link: PAGE_ROUTES.REPORTS_ANALYTICS,
        icon: faChartLine,
        name: 'Analytics',
        activeClassName: 'nav-active',
        permission: Permissions.REPORTS_ANALYTICS
      },
      {
        link: `https://${config.CONNECT_REGION}.quicksight.aws.amazon.com/sn/start/dashboards`,
        external: true,
        icon: faPaintbrushPencil,
        name: 'Custom',
        activeClassName: 'nav-active',
        permission: Permissions.REPORTS_CUSTOM
      }
    ]
  },
  {
    link: PAGE_ROUTES.WORKSPACE,
    icon: faLayerGroup,
    name: 'Agent Workspace',
    activeClassName: 'nav-active'
  },
  {
    link: PAGE_ROUTES.CUSTOMER_PROFILES,
    icon: faAddressCard,
    name: 'Customer Profiles',
    permission: Permissions.CUSTOMER_PROFILES,
    activeClassName: 'nav-active'
  },
  {
    link: PAGE_ROUTES.INTERACTION_HISTORY,
    icon: faClockRotateLeft,
    name: 'My Interaction History',
    activeClassName: 'nav-active',
    permission: Permissions.CALL_HISTORY
  },
  {
    link: PAGE_ROUTES.REPORTS_WALLBOARD,
    icon: faBrowser,
    name: 'Wallboard',
    permission: Permissions.REPORTS_WALLBOARD,
    activeClassName: 'nav-active'
  },
  ...(isDevEnvironment
    ? [
        {
          link: PAGE_ROUTES.PLAYGROUND,
          icon: faPersonDigging,
          name: 'Playground',
          activeClassName: 'nav-active'
        }
      ]
    : [])
];
