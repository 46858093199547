import { ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { IModule, ModuleType, WidgetConnectAppSuffix } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import { LOCAL_STORAGE_KEYS } from 'lib/common/constants/localStorageKeys';

import getInstanceUrl from 'lib/common/utils/getInstanceUrl';

import { LogEvents, logger } from '../../components/LoggerController';
import Context from './Context';
import { WidgetsPlacement } from './constants/WidgetsPlacement';
import { WidgetsState } from './constants/WidgetsState';
import { TWidgetsConfig } from './types/WidgetsConfig';

interface IModulesContextProps {
  children: ReactNode;
}

const defaultWidgetsConfig: TWidgetsConfig = {
  widgetsPlacement: WidgetsPlacement.FLOATING,
  widgetsState: WidgetsState.CLOSED
};

const ModulesContext = ({ children }: IModulesContextProps) => {
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const { setStorageItem, getStorageItem } = useLocalStorage();
  const widgetConfigData = getStorageItem(LOCAL_STORAGE_KEYS.WIDGETS_CONFIG);
  const instanceUrl = getInstanceUrl(config);
  const CustomViewsUrl = `${instanceUrl}${WidgetConnectAppSuffix.CUSTOM_VIEWS}`;

  const [pageModules, setPageModules] = useState<Array<IModule>>([]);
  const [widgetModules, setWidgetModules] = useState<Partial<IModule>[]>([]);
  const [customViewsModuleEnabled, setCustomViewsModuleEnabled] = useState<boolean>(false);
  const [taskModules, setTaskModules] = useState<Partial<IModule>[]>([]);
  const [externalLibraryModules, setExternalLibraryModules] = useState<Partial<IModule>[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [widgetsConfig, setWidgetsConfig] = useState<TWidgetsConfig>({
    ...defaultWidgetsConfig,
    ...(widgetConfigData ? JSON.parse(widgetConfigData) : {})
  });

  const handleWidgetsConfigChange = (changes: Partial<TWidgetsConfig>) => {
    const newConfig = {
      ...widgetsConfig,
      ...changes
    };

    setWidgetsConfig(newConfig);
    setStorageItem(LOCAL_STORAGE_KEYS.WIDGETS_CONFIG, JSON.stringify(newConfig));
  };

  useEffect(() => {
    (async () => {
      try {
        const modulesJson = await fetch_(`${config.MODULE_SERVICE_URL}/customer/tenant/${config.TENANT_ID}/module`);
        const modules = await modulesJson.json();

        setPageModules(
          modules.data?.filter((module) => [ModuleType.LANDING, ModuleType.PAGE].includes(module?.type)) || []
        );
        setWidgetModules(modules.data?.filter((module) => module?.type === ModuleType.WIDGET) || []);
        setCustomViewsModuleEnabled(modules.data?.some((module) => module?.source === CustomViewsUrl) || false);
        setTaskModules(modules.data?.filter((module) => module?.type === ModuleType.TASK) || []);
        setExternalLibraryModules(modules.data?.filter((module) => module?.type === ModuleType.EXTERNAL_LIBRARY) || []);
      } catch (error) {
        logger.error(LogEvents.WIDGET_LOAD_FAILED, { error });
      } finally {
        setLoaded(true);
      }
    })();
  }, []);

  return (
    <Context.Provider
      value={{
        state: {
          pageModules,
          widgetModules,
          externalLibraryModules,
          taskModules,
          widgetsConfig,
          customViewsModuleEnabled
        },
        actions: {
          handleWidgetsConfigChange
        }
      }}
    >
      {loaded && children}
      {externalLibraryModules && (
        <Helmet>
          {externalLibraryModules.map((module, index) => (
            <script
              key={index}
              onError={(error) => logger.error(LogEvents.EXTERNAL_SCRIPT_LOAD_FAILED, { error })}
              src={(module.source as string) || ''}
              type="text/javascript"
            />
          ))}
        </Helmet>
      )}
    </Context.Provider>
  );
};

export default ModulesContext;
