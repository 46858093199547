import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { faEdit, faLink } from '@fortawesome/pro-regular-svg-icons';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import cx from 'classnames';
import { $getSelection, $isRangeSelection, SELECTION_CHANGE_COMMAND } from 'lexical';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import { getSelectedNode } from './/getSelectedNode';
import { positionEditorElement } from './/positionEditorElement';

import styles from './/text-formatter.module.scss';

const LowPriority = 1;

export function LinkButton({ size, className }: { size?: number; className?: string }) {
  const [editor] = useLexicalComposerContext();
  const [link, setLink] = useState(false);

  const insertLink = useCallback(() => {
    setLink(!link);
    if (link) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, link]);

  return (
    <div id="link-element">
      <ClickableIcon
        onClick={insertLink}
        className={cx(styles['toolbar__action'], className, { [styles['toolbar__action--active']]: link })}
        aria-label="Insert Link"
        icon={faLink}
        color="grey"
        size={size || 17}
      />
      {link && createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
    </div>
  );
}

function FloatingLinkEditor({ editor }) {
  const editorRef = useRef(null);
  const inputRef: any = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    const selection: any = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection() as any;
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        //casting as only need Element properties from the first lookup
        let inner = rootElement as Element;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== styles['link-input']) {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl('');
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <div ref={editorRef} className={styles['link-editor']}>
      {isEditMode ? (
        <input
          ref={inputRef}
          className={styles['link-input']}
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== '') {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              }
            } else if (event.key === 'Escape') {
              event.preventDefault();
              setEditMode(false);
            }
          }}
        />
      ) : (
        <>
          <div className={styles['link-input']}>
            <Text type="link" href={linkUrl} rel="noopener noreferrer">
              {linkUrl}
            </Text>
            <button
              className={cx('no-styles-button', styles['link-edit'])}
              role="button"
              autoFocus
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                setEditMode(true);
              }}
            >
              <div className={styles['link-edit__container']}>
                <Icon icon={faEdit} color="grey" size={15} />
              </div>
            </button>
          </div>
        </>
      )}
    </div>
  );
}
