import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Icon from 'lib/common/components/Icon';
import ReactSelect from 'lib/common/components/ReactSelect';
import Text from 'lib/common/components/Text';

import ISendableEmailAddress from 'lib/common/types/email/SendableEmailAddress';

import styles from './from-addresses.module.scss';

interface IFromAddresses {
  availableFromAddresses: ISendableEmailAddress[] | null;
  setFromAddressId: (id: string) => void;
  fromAddressId?: string | null;
}

const DESKTOP_ICON_SIZE = 17;
const SOFTPHONE_ICON_SIZE = 15;

export default function FromAddresses({ availableFromAddresses, setFromAddressId, fromAddressId }: IFromAddresses) {
  const { isSoftphone } = useLayout();
  const availableFromAddressesOptions =
    availableFromAddresses && availableFromAddresses.map(({ email, id }) => ({ label: email, value: id }));

  if (availableFromAddresses === null || availableFromAddresses.length) {
    return (
      <ReactSelect
        aria-label="From addresses"
        className={cx(styles['from-addresses'], { [styles['from-addresses--softphone']]: isSoftphone })}
        small
        autoFocus
        isLoading={!availableFromAddresses}
        noOptionsMessage={() => 'No available email addresses'}
        value={availableFromAddressesOptions?.find(({ value }) => value === fromAddressId)}
        options={availableFromAddressesOptions || []}
        onChange={(newValue) => setFromAddressId((newValue as { value: string }).value)}
        placeholder={!availableFromAddresses ? 'Finding your email addresses' : 'Select an email address ...'}
        id="from-addresses"
        isSearchable={false}
      />
    );
  }

  return (
    <div className={styles['from-addresses__empty-placeholder']}>
      <Text
        className={cx(styles['from-addresses__empty-placeholder__label'], {
          [styles['from-addresses__empty-placeholder__label--large']]: !isSoftphone
        })}
        type="label"
      >
        No email addresses available
      </Text>
      <Icon
        tooltip={
          <Text type="tooltip">
            There are no valid email addresses available for sending emails. If this is unexpected, please{' '}
            <Text type="tooltip" bold inline>
              reach out to your contact center Administrator
            </Text>{' '}
            for assistance.
          </Text>
        }
        icon={faCircleQuestion}
        size={isSoftphone ? SOFTPHONE_ICON_SIZE : DESKTOP_ICON_SIZE}
      />
    </div>
  );
}
