import { useEffect, useState } from 'react';

import { faEdit, faPlus, faUserMinus, faUsers } from '@fortawesome/pro-regular-svg-icons';

import Badge from 'lib/common/components/Badge';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import DataTable from 'lib/common/components/DataTable';
import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import { IconSizeConstraints } from 'lib/common/components/Icon/Icon';
import Loader from 'lib/common/components/Loader';
import Modal from 'lib/common/components/Modal';
import Text from 'lib/common/components/Text';

import { TConfig } from 'lib/common/types/Config';
import TUser from 'lib/common/types/User';
import getUserName from 'lib/common/utils/getUserName';
import toast from 'lib/common/utils/toast';

import ManageUsersModal from './components/ManageUsers';

import styles from './users.module.scss';

interface IUsers {
  fetch: (url: string, options?: any) => Promise<any>;
  config: TConfig;
}

export default function Users({ fetch, config }: IUsers) {
  const [loading, setLoading] = useState<boolean>(true);
  const [showManageUsers, setShowManageUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<TUser[]>([]);
  const [rowSelected, setRowSelected] = useState<TUser | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [deletingUser, setDeletingUser] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getAllUsers();
  }, []);

  const updateGetUsersResponse = (result: any) =>
    result.items.map((item) => ({
      ...item,
      name: getUserName(item),
      isNeonUser: Boolean(item.tenantId) && Boolean(item.uuid),
      role: item.role || ''
    }));

  const getRowSelected = (selected: any) => {
    const [objectId, objectKey] = selected.data;

    return users.filter((u) => u.objectKey === objectKey && u.objectId === objectId)[0];
  };

  const toggleAddUser = () => {
    setShowManageUsers(!showManageUsers);
    setRowSelected(null);
    setEditMode(false);
  };

  const getAllUsers = async () => {
    const url = `${config.AGENT_SERVICE_URL}/agents?tenantId=${config.TENANT_ID}&includeConnect=true&includeConfig=true`;

    try {
      const res = await fetch(url);
      const users = await res.json();

      setUsers(updateGetUsersResponse(users));
      setLoading(false);
    } catch (e) {
      setLoading(false);

      toast('error', `Something went wrong fetching the list of contact centre users. Please try again.`);
    }
  };

  const getOptions = () => {
    return {
      filterType: 'checkbox',
      selectableRows: 'single',
      responsive: 'vertical',
      sortOrder: {
        name: 'objectKey',
        direction: 'asc'
      },
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: 10,
      rowsSelected: rowSelected,
      customToolbarSelect: (selectedRows, displayData) => {
        const selected = displayData[selectedRows.data[0].index];
        const rowSelected = getRowSelected(selected);

        return (
          <div className={styles['users__inner-toolbar']}>
            {!rowSelected.isNeonUser && (
              <ClickableIcon className="mr-20" tooltip="Add User" onClick={() => addUser(rowSelected)} icon={faPlus} />
            )}
            {rowSelected.isNeonUser && (
              <ClickableIcon
                className="mr-20"
                onClick={() => editUser(rowSelected)}
                tooltip="Edit User"
                icon={faEdit}
              />
            )}
            {rowSelected.isNeonUser && (
              <ClickableIcon
                className="mr-20"
                onClick={() => deleteUser(rowSelected)}
                tooltip="Remove User"
                icon={faUserMinus}
                color="danger"
                onlyConstrain={IconSizeConstraints.HEIGHT}
              />
            )}
          </div>
        );
      },
      downloadOptions: {
        filename: 'users.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: false
        }
      }
    };
  };

  const handleDeleteUser = async () => {
    if (!rowSelected) {
      return void setDeletingUser(false);
    }

    const item = {
      tenantId: rowSelected.tenantId,
      uuid: rowSelected.uuid,
      objectKey: rowSelected.objectKey,
      objectId: rowSelected.objectId
    };
    const url = `${config.AGENT_SERVICE_URL}/agent?type=agent`;

    try {
      const res = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'DELETE',
        body: JSON.stringify(item)
      });

      if (res.status === 200) {
        setLoading(true);
        getAllUsers();

        return void toast('success', 'User was successfully deleted.');
      }

      toast('error', 'There was an error deleting the user. Please try again.');
    } catch {
      toast('error', 'There was an error deleting the user. Please try again.');
    } finally {
      setDeletingUser(false);
    }
  };

  const deleteUser = (rowSelected) => {
    setDeletingUser(true);
    setRowSelected(rowSelected);
  };

  const addUser = (rowSelected) => {
    setEditMode(false);
    setRowSelected(rowSelected);
    setShowManageUsers(true);
  };

  const editUser = (rowSelected) => {
    setEditMode(true);
    setRowSelected(rowSelected);
    setShowManageUsers(true);
  };

  const getColumns = () => {
    const columns: any = [
      {
        name: 'objectId',
        label: 'Object Id',
        options: {
          display: false,
          filter: false,
          viewColumns: false
        }
      },
      {
        name: 'objectKey',
        label: 'Object Key',
        options: {
          display: false,
          filter: false,
          viewColumns: false
        }
      },
      {
        name: 'objectKey',
        label: 'Username',
        options: {
          customBodyRender: (value: string) => <span>{value.split('__')[value.split('__').length - 1]}</span>,
          filter: false
        }
      },
      {
        name: 'name',
        label: 'Agent Name',
        options: {
          filter: false,
          sortCompare:
            (order: 'asc' | 'desc') =>
            ({ data: a }, { data: b }) =>
              (a ? (b ? a.localeCompare(b) : -1) : 1) * (order === 'asc' ? 1 : -1)
        }
      },
      {
        name: 'isNeonUser',
        label: `Added To ${config.BRAND.productName}`,
        options: {
          customBodyRender: (value: string) => (
            <div>
              <Badge label={value ? 'Yes' : 'No'} type={value ? 'SUCCESS' : 'NEUTRAL'} minWidth={30} />
            </div>
          ),
          filterOptions: {
            renderValue: (isNeonUser: boolean) => (isNeonUser ? 'Yes' : 'No'),
            fullWidth: true
          },
          filterType: 'dropdown',
          customFilterListOptions: {
            render: (isNeonUser: boolean) => `${config.BRAND.productName} User: ${isNeonUser ? 'Yes' : 'No'}`
          }
        }
      }
    ];

    return columns;
  };

  return loading ? (
    <Loader />
  ) : !users.length ? (
    <EmptyPlaceholder subText="You need to add some users in Amazon Connect before they appear here." icon={faUsers} />
  ) : (
    <>
      <div className="panel">
        <DataTable data={users} columns={getColumns()} options={getOptions()} />
      </div>
      <ManageUsersModal
        show={showManageUsers}
        getAllUsers={getAllUsers}
        editMode={editMode}
        rowSelected={rowSelected}
        toggleAddUser={toggleAddUser}
        config={config}
        fetch={fetch}
      />
      <Modal
        open={deletingUser}
        onDelete={handleDeleteUser}
        onClose={() => setDeletingUser(false)}
        title="Are You Sure?"
        deleteButtonText="Remove"
      >
        <Text>
          Confirm you want to remove <Text.Bold>{rowSelected ? getUserName(rowSelected) : 'this user'}</Text.Bold> from
          your contact centre.
        </Text>
      </Modal>
    </>
  );
}
