import React, { useRef, useState } from 'react';

import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import _memoize from 'lodash.memoize';
import { useBooleanState } from 'webrix/hooks';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import Text from 'lib/common/components/Text';

import { TagInputProps } from './useTagInputReducer';

import styles from './tag-input.module.scss';

const BACKSPACE = 'Backspace';
const RETURN = 'Enter';
const SELECT_ALL = 'a';

const Tag = ({
  text,
  className,
  disabled,
  onClick
}: {
  text: string;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
}) => (
  <Text className={cx(styles['tag-input__tag'], className)} type="helper" testId={`tag-input__tag--${text}`}>
    {text}
    <ClickableIcon
      aria-label="Remove email"
      className={styles['tag-input__tag__delete']}
      icon={faXmark}
      onClick={disabled ? () => {} : onClick}
      size={10}
    />
  </Text>
);

const TagInput = ({
  state,
  setState,
  className,
  placeholder,
  validateTag = () => true,
  defaultText = '',
  errorMessage,
  disabled,
  handlePaste,
  delimiters = [' ', ',', ';'],
  id,
  label,
  errorHidden
}: TagInputProps) => {
  const { tags } = state;
  const [text, setText] = useState(defaultText);
  const selectAll = useBooleanState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const deleteTag = (tagName: string) => () => {
    const index = tags.findIndex(({ value }) => value === tagName);

    if (index <= -1) {
      return;
    }

    tags.splice(index, 1);

    setState({ tags });
  };

  const addTag = async (text: string) => {
    const trimmedValue = text.trim();

    // No text to be turned into tag
    if (!trimmedValue) {
      return;
    }

    // Remove tag if it already exists - case-insensitive
    const upperCaseTags = tags.map(({ value }) => value.toUpperCase());
    const index = upperCaseTags.indexOf(trimmedValue.toUpperCase());

    if (index > -1) {
      tags.splice(index, 1);
    }

    // Add new tag
    tags.push({ value: trimmedValue, valid: await validateTag(trimmedValue) });

    // reset textarea content
    setState({ tags });
  };

  const addCurrentTag = async () => {
    await Promise.all(
      delimiters
        .reduce((splitText, delimiter) => splitText.flatMap((value) => value.split(delimiter)), [text])
        .map((splitText) => addTag(splitText))
    );
    setText('');
  };

  const isDelimiter = _memoize((key: string) => delimiters.includes(key));

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { currentTarget, key, ctrlKey, metaKey } = event;
    const { selectionEnd } = currentTarget;

    if ((ctrlKey || metaKey) && key === SELECT_ALL) {
      return void selectAll.setTrue();
    }

    selectAll.setFalse();

    // Not a special character
    if (key !== RETURN && key !== BACKSPACE && !isDelimiter(key)) {
      return;
    }

    // Backspacing with everything selected
    if (key === BACKSPACE && selectAll.value) {
      selectAll.setFalse();
      return void setState({ tags: [] });
    }

    // Backspacing within text
    if (key === BACKSPACE && selectionEnd !== 0) {
      return;
    }

    // Backspacing over tag - delete tag
    if (key === BACKSPACE && tags.length) {
      event.preventDefault();
      setState({ tags: tags.slice(0, tags.length - 1) });
      return void setText(tags.pop()!.value);
    }

    // Return - add tag and clear
    if (key === RETURN || isDelimiter(key)) {
      await addCurrentTag();
    }
  };

  const handleInputChange = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const text = value
      // These symbols are word boundaries
      .replace(/[,;:?! ]+/gi, '')
      // Accept only alphanumeric, at sign, dot, hyphen, and apostrophe
      .replace(/[^a-zA-Z0-9 @.'-_+]+/gi, '');

    setText(text);
  };

  const handlePasteWrapped = (event: React.ClipboardEvent<HTMLInputElement>) => {
    if (handlePaste) {
      handlePaste(event, addTag);
    }
  };

  const invalidTag = tags?.find(({ valid }) => !valid);

  return (
    <>
      <div
        data-testid="tag-input"
        className={cx(styles['tag-input'], className, {
          [styles['tag-input--disabled']]: disabled,
          [styles['tag-input--select-all']]: selectAll.value,
          [styles['tag-input--error']]: invalidTag
        })}
        onClick={() => {
          inputRef?.current?.focus();
        }}
      >
        {tags && tags.length
          ? tags.map(({ value, valid: tagValid }) => (
              <Tag
                key={value}
                className={cx(styles['tag-input__tag'], { [styles['tag-input__tag--invalid']]: !tagValid })}
                text={value}
                onClick={deleteTag(value)}
                disabled={disabled}
              />
            ))
          : null}

        <input
          data-testid={id || 'tag-input__input'}
          ref={inputRef}
          placeholder={tags.length === 0 ? placeholder : undefined}
          type="text"
          value={text}
          aria-describedby="tag-input-helper-text"
          className={styles['tag-input__input']}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={() => {
            selectAll.setFalse();
            addCurrentTag();
          }}
          {...(handlePaste ? { onPasteCapture: handlePasteWrapped } : {})}
          disabled={disabled}
          aria-label={label}
        />
      </div>

      {invalidTag && errorMessage && (
        <Text
          id="tag-input-helper-text"
          className={cx(styles['tag-input__error'], { 'sr-only': errorHidden })}
          type="helper"
        >
          {errorMessage} {invalidTag?.value}
        </Text>
      )}
    </>
  );
};

export default TagInput;
