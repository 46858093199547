import { useState } from 'react';

import { faItalic } from '@fortawesome/pro-regular-svg-icons';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import cx from 'classnames';
import { FORMAT_TEXT_COMMAND } from 'lexical';

import ClickableIcon from 'lib/common/components/ClickableIcon';

import styles from './/text-formatter.module.scss';

export function ItalicButton({ size, className }: { size?: number; className?: string }) {
  const [editor] = useLexicalComposerContext();
  const [italic, setItalic] = useState(false);
  const makeItalic = () => {
    setItalic(!italic);
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
  };

  return (
    <ClickableIcon
      onClick={makeItalic}
      className={cx(styles['toolbar__action'], className, { [styles['toolbar__action--active']]: italic })}
      aria-label="Format Italics"
      icon={faItalic}
      color="grey"
      size={size || 17}
    />
  );
}
