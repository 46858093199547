import { $isAtNodeEnd } from '@lexical/selection';
import { $isNodeSelection, GridSelection, NodeSelection, RangeSelection } from 'lexical';

export default function getSelectedNode(selection: RangeSelection | GridSelection | NodeSelection | null) {
  // Images are considered as a single node
  if ($isNodeSelection(selection)) {
    return selection.getNodes()[0];
  }

  if (!selection || !('anchor' in selection) || !('focus' in selection)) {
    return null;
  }

  const { anchor, focus } = selection;
  const anchorNode = anchor.getNode();
  const focusNode = focus.getNode();

  if (anchorNode === focusNode) {
    return anchorNode;
  }

  const isBackward = selection.isBackward();

  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  }

  return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
}
