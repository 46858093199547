import { faPenRuler } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import useTaskTimer from 'lib/common/hooks/useTaskTimer';

import Icon from 'lib/common/components/Icon';
import { IconSizeConstraints } from 'lib/common/components/Icon/Icon';
import { NotificationDot } from 'lib/common/components/NotificationDot';
import Text from 'lib/common/components/Text';

import CONTACT_STATES from 'lib/common/constants/contactStates';
import { TASK_COLOURS, getTaskDisplayProps } from 'lib/common/constants/taskStates';

import TTask from 'lib/common/types/Task';
import getUserName from 'lib/common/utils/getUserName';

import styles from './task-menu-item.module.scss';

interface TTasTaskMenuItem extends TTask {
  selected: boolean;
  onSelect?: (contactId: string) => void;
  unreadMessage?: boolean;
}

function SubtitleSuffix({ time }: { time?: Date }) {
  const taskTime = useTaskTimer(time);

  return (
    <Text type="inline" color="text">
      {' '}
      | {taskTime}
    </Text>
  );
}

export default function TaskMenuItem(task: TTasTaskMenuItem) {
  const { status, contact, selected, profile, time, connectionValue, onSelect, unreadMessage } = task;
  const taskProps = getTaskDisplayProps(task);
  const title = profile && profile.firstName ? getUserName(profile) : connectionValue;
  const subtitleToDisplay = taskProps?.subtitle || '';

  const onSelectTask = () => {
    if (!onSelect) {
      return;
    }

    onSelect(contact.contactId);
  };

  const isIncoming = status === CONTACT_STATES.CONNECTING;
  const isDraft = status === CONTACT_STATES.DRAFT;
  const selectedNotIncoming = selected && !isIncoming;
  const isNeglected = status === CONTACT_STATES.REJECTED || status === CONTACT_STATES.MISSED;

  return (
    <div
      className={cx(styles['task-menu-item'], {
        [styles['task-menu-item--incoming']]: isIncoming,
        [styles['task-menu-item--selected']]: selectedNotIncoming
      })}
      onClick={!selected && !isIncoming ? onSelectTask : void 0}
    >
      <div
        className={cx(styles['task-menu-item__icon'], {
          [styles['task-menu-item__icon--selected']]: selected,
          [styles['task-menu-item__icon--neglected']]: isNeglected
        })}
      >
        {unreadMessage && <NotificationDot />}
        <Icon
          icon={taskProps?.icon}
          color={selected || isNeglected ? 'white' : void 0}
          size={15}
          onlyConstrain={IconSizeConstraints.HEIGHT}
        />
      </div>
      <div className={styles['task-menu-item__text']} data-testid={title}>
        <Text ellipsis bold>
          {title}
        </Text>
        <Text
          className={styles['task-menu-item__text__subtitle']}
          ellipsis
          color={selectedNotIncoming ? void 0 : TASK_COLOURS[status]}
        >
          {subtitleToDisplay}
          {(!selected || isIncoming) && <SubtitleSuffix time={time} />}
        </Text>
      </div>
      {isDraft && <Icon icon={faPenRuler} size={17} />}
    </div>
  );
}
