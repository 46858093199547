import { faCheck, faPhoneHangup, faSpinnerThird, faXmark } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useContactContext } from 'lib/common/contexts/ContactContext';

import Button from 'lib/common/components/Button';
import Icon from 'lib/common/components/Icon';

import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import Task from 'lib/common/types/Task';

import { useAgentContext } from '../../contexts/AgentContext';
import connectGetter from '../../utils/connectGetter';

import './task-actions.scss';

type TTaskActions = Pick<Task, 'status' | 'type' | 'taskId' | 'connectionType'> & {
  round?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
};

export default function TaskActions({
  status,
  type,
  taskId,
  connectionType,
  round,
  isDisabled,
  autoFocus
}: TTaskActions) {
  const { agent } = useAgentContext();
  const {
    actions: { rejectTask, acceptTask, hangupAgent }
  } = useContactContext();

  // When the agent is on a desk phone, they can only answer the call on it (not in NEON)
  // For queue callbacks, they need to be able to accept the task, even if they are on desk phone
  const willAcceptOnDeskPhone = !connectGetter(agent, 'isSoftphoneEnabled') && type === CONTACT_TYPES.CALL;

  const isOutbound = connectionType === connect.ConnectionType.OUTBOUND;

  const hasNoActions =
    (status === CONTACT_STATES.CONNECTED &&
      type !== CONTACT_TYPES.CALL &&
      type !== CONTACT_TYPES.CHAT &&
      type !== CONTACT_TYPES.QUEUE_CALLBACK) ||
    (status !== CONTACT_STATES.CONNECTED && status !== CONTACT_STATES.CONNECTING && status !== CONTACT_STATES.ACCEPTED);

  if (hasNoActions) {
    return null;
  }

  return (
    <div className={cx('task-actions', { 'task-actions__expanded': !round })}>
      {status === CONTACT_STATES.CONNECTING && type !== CONTACT_TYPES.CONFERENCE_CALL && (
        <>
          {!isOutbound && !willAcceptOnDeskPhone && (
            <Button
              autoFocus={autoFocus}
              ariaLabel="Accept task"
              disabled={isDisabled}
              size="small"
              round={round}
              fullWidth
              onClick={() => acceptTask(taskId)}
              icon={faCheck}
              styleType="SUCCESS"
              asyncAction
              successTimeoutSeconds={0}
              preventDoubleClick
            >
              {round ? '' : 'Accept'}
            </Button>
          )}
          <Button
            ariaLabel="Cancel/decline task"
            size="small"
            round={round}
            fullWidth
            onClick={() => rejectTask(taskId)}
            icon={faXmark}
            styleType="DANGER"
            asyncAction
            successTimeoutSeconds={0}
            preventDoubleClick
          >
            {round ? '' : isOutbound ? 'Cancel' : 'Decline'}
          </Button>
        </>
      )}
      {status === CONTACT_STATES.CONNECTED &&
        (type === CONTACT_TYPES.CALL || type === CONTACT_TYPES.CHAT || type === CONTACT_TYPES.QUEUE_CALLBACK) && (
          <>
            <Button
              ariaLabel="End task"
              size="small"
              round
              onClick={() => hangupAgent(taskId)}
              icon={type === CONTACT_TYPES.CALL || type === CONTACT_TYPES.QUEUE_CALLBACK ? faPhoneHangup : faXmark}
              styleType="DANGER"
              asyncAction
              successTimeoutSeconds={0}
              preventDoubleClick
            />
          </>
        )}
      {status === CONTACT_STATES.ACCEPTED && (
        <>
          <Icon icon={faSpinnerThird} spin />
        </>
      )}
    </div>
  );
}
