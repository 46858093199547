import { ExportToCsv } from 'export-to-csv';
import _startCase from 'lodash.startcase';

import TProfile from 'lib/common/types/CustomerProfile';
import FIELD_PARSERS from 'lib/common/utils/customerProfiles/profileFieldParsers';
import getUserName from 'lib/common/utils/getUserName';

const NOTES = 'notes';

const EXPORT_FIELDS = ['firstName', 'lastName', 'accountNumber', 'phoneNumber', 'emailAddress', 'address', NOTES];

function getValue(profile, fieldName) {
  if (!profile[fieldName]) {
    return '-';
  }

  if (fieldName === NOTES) {
    const editorState = FIELD_PARSERS.notes(profile[fieldName]);
    return editorState ? editorState.getCurrentContent().getPlainText(' ') : '-';
  }

  return FIELD_PARSERS[fieldName] ? FIELD_PARSERS[fieldName](profile[fieldName]) : profile[fieldName];
}

function buildExportDataSet(profile) {
  return [
    EXPORT_FIELDS.reduce(
      (dataSet, fieldName) => ({
        ...dataSet,
        [_startCase(fieldName)]: getValue(profile, fieldName)
      }),
      {}
    )
  ];
}

export default function saveProfileCSV(profile: TProfile) {
  const fileId = profile.firstName
    ? getUserName({ ...profile, spaceChar: '_' }).toLowerCase()
    : profile.emailAddress || profile.accountNumber || profile.profileId;

  const options = {
    filename: `${fileId}_export`,
    useKeysAsHeaders: true
  };

  new ExportToCsv(options).generateCsv(buildExportDataSet(profile));
}
