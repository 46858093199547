import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { useSoftphoneLayout } from 'lib/common/contexts/layout/SoftphoneLayoutContext';

/* Task footer on softphone is rendered by the task content but needs to be hoisted up and under the task module wrapped content */
/* Use a portal below to render it's content in the container defined in softphone/Task */
export default function SoftphoneContainer({ children }: { children: ReactNode }) {
  const { taskFooterContainer } = useSoftphoneLayout();

  if (!taskFooterContainer) {
    return null;
  }

  return createPortal(children, taskFooterContainer);
}
