import { ReactNode } from 'react';

import cx from 'classnames';

type TTaskFooterRow = {
  children: ReactNode | ReactNode[];
  className?: string;
  primaryButtonIndex?: number;
  isSmallWidth?: boolean;
  isSingleRow?: boolean;
};

export default function TaskFooterRow({
  children,
  className,
  primaryButtonIndex,
  isSmallWidth,
  isSingleRow
}: TTaskFooterRow) {
  const hasPrimaryButton = primaryButtonIndex !== void 0;

  return (
    <>
      {isSingleRow ? (
        <div className={cx('task-footer__container__row', className)}>{children}</div>
      ) : (
        <div
          className={cx('task-footer__container__row', className)}
          style={{ gap: !hasPrimaryButton && isSmallWidth ? '12.5px' : void 0 }}
        >
          {Array.isArray(children)
            ? children?.filter(Boolean).map((child, index) => (
                <div
                  key={index}
                  style={{
                    order: primaryButtonIndex === index && !isSmallWidth ? children.length : 0
                  }}
                >
                  {child}
                </div>
              ))
            : children}
        </div>
      )}
    </>
  );
}
