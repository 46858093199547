import { LogEvents, logger } from 'lib/common/components/LoggerController';

import type { TConfig } from 'lib/common/types/Config';

interface IArgs {
  config: TConfig;
  reportId: string;
  fetch: (url: string, options: any) => Promise<any>;
}

export default async function getQuicksightDashboardUrl({ config, reportId, fetch }: IArgs) {
  const apiEndpoint = `${config.AGENT_SERVICE_URL}/quicksight/${config.TENANT_ID}/dashboard/${reportId}`;

  try {
    const response = await fetch(apiEndpoint, {
      method: 'GET'
    });

    return response.json();
  } catch (error) {
    logger.error(LogEvents.REPORT_LOAD_FAILED, { apiEndpoint, error });

    throw error;
  }
}
