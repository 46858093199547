import { $createLinkNode, $isLinkNode } from '@lexical/link';
import { $getSelection, $isElementNode, LexicalNode } from 'lexical';

export default function toggleImageLink(url, attributes: any = {}) {
  const { target } = attributes;
  const rel = attributes.rel === undefined ? 'noopener' : attributes.rel;
  const selection = $getSelection();

  if (!selection) {
    return;
  }

  const nodes = selection.extract();

  if (url === null) {
    // Remove LinkNodes
    return void nodes.forEach((node) => {
      const parent = node.getParent();

      if ($isLinkNode(parent)) {
        const children = parent.getChildren();

        for (let i = 0; i < children.length; i++) {
          parent.insertBefore(children[i]);
        }

        parent.remove();
      }
    });
  }

  // Add or merge LinkNodes
  if (nodes.length === 1) {
    const firstNode = nodes[0]; // if the first node is a LinkNode or if its
    // parent is a LinkNode, we update the URL, target and rel.

    const linkNode = $isLinkNode(firstNode) ? firstNode : $getLinkAncestor(firstNode);

    if (linkNode !== null) {
      linkNode.setURL(url);

      if (target !== undefined) {
        linkNode.setTarget(target);
      }

      if (rel !== null) {
        linkNode.setRel(rel);
      }

      return;
    }
  }

  let prevParent: LexicalNode | null = null;
  let linkNode: LexicalNode | null = null;
  nodes.forEach((node) => {
    const parent = node.getParent();

    if (parent === linkNode || parent === null || ($isElementNode(node) && !node.isInline())) {
      return;
    }

    if ($isLinkNode(parent)) {
      linkNode = parent;
      parent.setURL(url);

      if (target !== undefined) {
        parent.setTarget(target);
      }

      if (rel !== null) {
        linkNode.setRel(rel);
      }

      return;
    }

    if (!parent.is(prevParent)) {
      prevParent = parent;
      linkNode = $createLinkNode(url, {
        rel,
        target
      });

      if ($isLinkNode(parent)) {
        if (node.getPreviousSibling() === null) {
          parent.insertBefore(linkNode);
        } else {
          parent.insertAfter(linkNode);
        }
      } else {
        node.insertBefore(linkNode);
      }
    }

    if ($isLinkNode(node)) {
      if (node.is(linkNode)) {
        return;
      }

      if (linkNode !== null) {
        const children = node.getChildren();

        for (let i = 0; i < children.length; i++) {
          linkNode.append(children[i]);
        }
      }

      node.remove();
      return;
    }

    if (linkNode !== null) {
      linkNode.append(node);
    }
  });
}

function $getLinkAncestor(node) {
  return $getAncestor(node, (ancestor) => $isLinkNode(ancestor));
}

function $getAncestor(node, predicate) {
  while (node !== null && (node = node.getParent()) !== null && !predicate(node));

  return node;
}
