import { createContext, useContext } from 'react';

import UploadedFileType from 'lib/common/constants/files/UploadedFileType';

import IUploadedFile from 'lib/common/types/UploadedFile';

export interface IFileUploadMetadata {
  key: string;
  url: string;
  filename: string;
}

interface IUploadContext {
  state: {
    files: Record<string, IUploadedFile>;
  };
  actions: {
    uploadFiles: (type: UploadedFileType, files: File[], contextId: string) => Promise<IFileUploadMetadata[]>;
    deleteFile: (type: UploadedFileType, key: string) => Promise<void>;
    clearFiles: (contextId: string) => void;
    getFilesForContext: (contextId: string) => Record<string, IUploadedFile>;
    addFiles: (files: Record<string, IUploadedFile>) => void;
  };
}

const Context = createContext<IUploadContext>({
  state: {
    files: {}
  },
  actions: {
    uploadFiles: () => Promise.resolve([]),
    deleteFile: () => Promise.resolve(),
    clearFiles: () => {},
    getFilesForContext: () => ({}),
    addFiles: () => {}
  }
});

export default Context;

export const useEmailUploadContext = () => useContext(Context);
