import { RefObject, useEffect } from 'react';

import { useContactContext } from 'lib/common/contexts/ContactContext';

import { useScrollManagedContainer } from 'lib/common/hooks/useScrollManagedContainer';

import ChatSendFooter from 'lib/common/components/atoms/ChatSendFooter';
import ACWOutcome from 'lib/common/components/molecules/ACW/components/ACWOutcome';
import ChatMessages from 'lib/common/components/molecules/ChatMessages';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import QueueNameAndTimer from 'lib/pages/AgentWorkspace/components/TaskDetail/components/QueueNameAndTimer';

import ChatTask from 'lib/common/types/ChatTask';
import getUserName from 'lib/common/utils/getUserName';

import '../styles/chat.scss';

type TChat = Pick<
  ChatTask,
  'messages' | 'profile' | 'connectionValue' | 'taskId' | 'time' | 'customerTyping' | 'nextToken' | 'status'
> & {
  minimised?: boolean;
  showEmptyPlaceholder?: boolean;
  queueName?: string;
  scrollContainerRef?: RefObject<HTMLDivElement>;
};

function Chat({
  messages,
  connectionValue,
  profile,
  time,
  customerTyping,
  taskId,
  minimised,
  showEmptyPlaceholder,
  queueName,
  nextToken,
  status,
  scrollContainerRef
}: TChat) {
  const customerName = profile && profile.firstName ? getUserName(profile) : connectionValue;

  const {
    actions: { sendMessage, sendTypingEvent, sendAttachment },
    state: { tasks }
  } = useContactContext();
  const task = tasks.find((item) => item.taskId === taskId);

  const { scrollToBottom, saveScrollPosition } = useScrollManagedContainer(scrollContainerRef);

  useEffect(() => scrollToBottom(), [messages.length]);

  return (
    <div className="chat">
      <div className="chat__scroll-section">
        <div className="chat__task-details">
          {!minimised && <QueueNameAndTimer queueName={queueName} time={time} />}
          <ACWOutcome />
        </div>
        <ChatMessages
          customerName={customerName}
          messages={messages}
          customerTyping={customerTyping}
          showEmptyPlaceholder={showEmptyPlaceholder}
          saveScrollPosition={saveScrollPosition}
          nextToken={nextToken}
          status={status}
        />
      </div>

      {!minimised && (
        <div className="chat__footer">
          <ChatSendFooter
            sendMessage={(message, isMarkdown) => {
              sendMessage(message, isMarkdown);
              scrollToBottom({ force: true });
            }}
            sendTypingEvent={sendTypingEvent}
            task={task}
            sendAttachment={sendAttachment}
          />
          <TaskFooter.Chat taskId={taskId} connectionValue={connectionValue} />
        </div>
      )}
    </div>
  );
}

export default Chat;
