import cx from 'classnames';

import './incoming-task-placement-icon.scss';

export default function IncomingTaskPlacementIcon({ top, selected }: { top?: boolean; selected?: boolean }) {
  return (
    <div
      className={cx('incoming-task-placement-icon', {
        'incoming-task-placement-icon--selected': selected
      })}
    >
      <span
        className={cx('incoming-task-placement-icon__window', {
          'incoming-task-placement-icon__window--top': top
        })}
      >
        <div
          className={cx('incoming-task-placement-icon__notification', {
            'incoming-task-placement-icon__notification--selected': selected
          })}
        ></div>
      </span>
    </div>
  );
}
