import { useState } from 'react';

import CustomerProfileForm, { ProfileActions } from 'lib/common/components/CustomerProfileForm';
import Modal from 'lib/common/components/Modal';

export default function EditProfileModal({ open, profile, onClose, onSave, onSuccess }) {
  const [{ profile: updatedProfile, profileValid: updatedProfileValid }, setProfile] = useState(profile);

  const handleSave = () => onSave(updatedProfile);

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSave={handleSave}
      onSuccess={onSuccess}
      title="Change A Customer Profile"
      saveDisabled={!updatedProfileValid}
    >
      <CustomerProfileForm compact profile={profile} onChangeProfile={setProfile} action={ProfileActions.EDIT} />
    </Modal>
  );
}
