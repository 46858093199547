import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faList, faPhone, faUser } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import Icon from '../Icon';

import './layout-columns-icon.scss';

function Column({
  icon,
  showIcon,
  doubleWidth,
  inverseColours
}: {
  icon: IconDefinition;
  showIcon?: boolean;
  doubleWidth?: boolean;
  inverseColours?: boolean;
}) {
  return (
    <span className={cx('layout-columns-icon__column', { 'layout-columns-icon__column--double-width': doubleWidth })}>
      {showIcon && <Icon icon={icon} color={inverseColours ? void 0 : 'white'} />}
    </span>
  );
}

export default function LayoutColumnsIcon({
  twoColumns,
  selected,
  large,
  inverseColours
}: {
  twoColumns?: boolean;
  selected?: boolean;
  large?: boolean;
  inverseColours?: boolean;
}) {
  const numberOfColumns = twoColumns ? 2 : 3;
  const taskColumn = <Column icon={faList} showIcon={large} inverseColours={inverseColours} />;
  const controlsColumn = <Column icon={faPhone} showIcon={large} inverseColours={inverseColours} />;
  const profileColumn = (
    <Column icon={faUser} showIcon={large} doubleWidth={numberOfColumns === 2} inverseColours={inverseColours} />
  );

  if (numberOfColumns === 3) {
    return (
      <div
        className={cx('layout-columns-icon', {
          'layout-columns-icon--selected': selected,
          'layout-columns-icon--large': large,
          'layout-columns-icon--inverse': inverseColours
        })}
      >
        {taskColumn}
        {controlsColumn}
        {profileColumn}
      </div>
    );
  }

  return (
    <div
      className={cx('layout-columns-icon', {
        'layout-columns-icon--selected': selected,
        'layout-columns-icon--large': large,
        'layout-columns-icon--inverse': inverseColours
      })}
    >
      <div className="layout-columns-icon__split-column">
        {taskColumn}
        {controlsColumn}
      </div>
      {profileColumn}
    </div>
  );
}
