import { IconDefinition, faCheckCircle, faXmarkCircle } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from '@material-ui/core';
import cx from 'classnames';

import Icon from 'lib/common/components/Icon';

import MESSAGE_STATUS from 'lib/common/constants/chat/MessageStatus';

import { TChatMessage } from 'lib/common/types/chat/ChatMessage';

import styles from './chat-message.module.scss';

const READ_ICON_SIZE = 13;
const DEFAULT_ICON_SIZE = 12;

function MessageContainer({ children, tooltip, messageStatus }) {
  return (
    <Tooltip title={tooltip} placement="top">
      <div
        className={cx(styles['chat-message-status-container'])}
        data-testid={`message-receipt-${messageStatus.toLowerCase()}`}
      >
        {children}
      </div>
    </Tooltip>
  );
}

function StatusIcon({
  icon = faCheckCircle,
  isRead = false,
  isFailed = false,
  iconSide = 'left',
  iconColor = void 0
}: {
  icon?: IconDefinition;
  isRead?: boolean;
  isFailed?: boolean;
  iconSide?: 'left' | 'right';
  iconColor?: string;
}) {
  return (
    <div
      className={cx(styles[`chat-message-status-container__icon-${iconSide}`], {
        [styles[`chat-message-status-container__icon-${iconSide}--read`]]: isRead,
        [styles[`chat-message-status-container__icon-${iconSide}--failed`]]: isFailed
      })}
    >
      <Icon icon={icon} size={isRead ? READ_ICON_SIZE : DEFAULT_ICON_SIZE} color={iconColor} />
    </div>
  );
}

export default function MessageStatusIcon({ message }: { message: TChatMessage }) {
  const isRead = message.messageStatus === MESSAGE_STATUS.READ;

  if (!message.messageStatus) {
    return null;
  }

  if (message.messageStatus === MESSAGE_STATUS.FAILED) {
    return (
      <MessageContainer
        tooltip={`Failed to send ${message.Type === 'ATTACHMENT' ? 'attachment' : 'message'} ${
          message.errorCode === 'MEDIA_TYPE_UNSUPPORTED' ? '- this attachment type is not supported' : ''
        }`}
        messageStatus={message.messageStatus}
      >
        <StatusIcon icon={faXmarkCircle} iconColor="danger" isFailed />
      </MessageContainer>
    );
  }

  if (message.messageStatus === MESSAGE_STATUS.SENT) {
    return (
      <MessageContainer tooltip="Sent" messageStatus={message.messageStatus}>
        <StatusIcon />
      </MessageContainer>
    );
  }

  return (
    <MessageContainer tooltip={isRead ? 'Read' : 'Delivered'} messageStatus={message.messageStatus}>
      <>
        <StatusIcon isRead={isRead} iconColor={isRead ? 'pastelGreen' : void 0} />
        <StatusIcon iconSide="right" isRead={isRead} iconColor={isRead ? 'pastelGreen' : void 0} />
      </>
    </MessageContainer>
  );
}
