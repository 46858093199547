import { Fragment, ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import cx from 'classnames';

import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import './resizable-group.scss';

type LayoutOption = {
  preventResize?: boolean;
  id: string;
  minSize: number;
  defaultSizePercent: number;
  hide?: boolean;
  forceDefaultSize?: boolean;
  minimised?: boolean;
  maximised?: boolean;
  className?: string;
};

const getBodyDraggingClass = ({ vertical }) => `body--dragging${vertical ? '-vertical' : ''}`;

function getColumnSizePercent(sizePercent) {
  if (sizePercent > 100) {
    return 100;
  }

  if (sizePercent < 0) {
    return 0;
  }

  return sizePercent;
}

function columnResizeLessThanThreshold({ sizePercent, workspaceSize, columnIndex, layoutOptions }) {
  return (sizePercent / 100) * workspaceSize < layoutOptions[columnIndex]?.minSize;
}

function buildSizesArray({ currentSizes, columnIndex, columnSizePercent, adjacentColumnSizePercent }) {
  return currentSizes.map((size, index) => {
    if (index === columnIndex) {
      return getColumnSizePercent(columnSizePercent);
    }

    if (index === columnIndex + 1) {
      return getColumnSizePercent(adjacentColumnSizePercent);
    }

    return size;
  });
}

export default forwardRef(function ResizableGroup(
  {
    children: allChildren,
    layoutOptions,
    containerClassName,
    vertical,
    verticalClassname,
    additionalResizerContent,
    resizerClassname
  }: {
    children: ReactNode[];
    layoutOptions: LayoutOption[];
    containerClassName: string;
    vertical?: boolean;
    verticalClassname?: string;
    resizerClassname?: string;
    additionalResizerContent?: ReactNode;
  },
  ref
) {
  const cssClientProp = vertical ? 'clientY' : 'clientX';
  const cssSizeProperty = vertical ? 'height' : 'width';

  const { getStorageItem, setStorageItem } = useLocalStorage();

  function getInitialSize({ defaultSizePercent, id }) {
    const storedValue = getStorageItem(id);

    if (!storedValue) {
      return defaultSizePercent;
    }

    return parseFloat(storedValue);
  }

  const children = allChildren.filter(Boolean);
  const [sizes, updateSizes] = useState(children.map((_, index) => getInitialSize(layoutOptions[index])));
  const [currentDragProps, setDragProps] = useState<null | {
    columnIndex: number;
    initialPosition: number;
    adjacentColumnSizePercent: number;
  }>(null);

  const staticSizes: { current: number[] } = useRef(sizes);

  const setSizes = (sizes) => {
    staticSizes.current = sizes;
    updateSizes(sizes);
  };

  const onMouseMove = useCallback(
    (e) => {
      if (!currentDragProps) {
        return;
      }

      const workspaceEl = document.querySelector(`.${containerClassName}`);

      if (!workspaceEl) {
        return;
      }

      const adjacentColumnIndex = currentDragProps.columnIndex + 1;
      const workspaceSize = workspaceEl.getBoundingClientRect()[cssSizeProperty];
      const originalSizePercent = sizes[currentDragProps.columnIndex];

      const pxDifference = e[cssClientProp] - currentDragProps.initialPosition;
      const percentChange = (pxDifference / workspaceEl.getBoundingClientRect()[cssSizeProperty]) * 100;

      const columnSizePercent = originalSizePercent + percentChange;
      const adjacentColumnSizePercent = currentDragProps.adjacentColumnSizePercent - percentChange;

      if (
        columnResizeLessThanThreshold({
          sizePercent: columnSizePercent,
          workspaceSize,
          columnIndex: currentDragProps.columnIndex,
          layoutOptions
        }) ||
        columnResizeLessThanThreshold({
          sizePercent: adjacentColumnSizePercent,
          workspaceSize,
          columnIndex: adjacentColumnIndex,
          layoutOptions
        })
      ) {
        return;
      }

      setSizes(
        buildSizesArray({
          currentSizes: staticSizes.current,
          adjacentColumnSizePercent,
          columnSizePercent,
          columnIndex: currentDragProps.columnIndex
        })
      );
    },
    [currentDragProps]
  );

  const onMouseUp = useCallback(() => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);

    document.body.classList.remove(getBodyDraggingClass({ vertical }));

    setDragProps(null);
  }, [onMouseMove]);

  const onMouseDown = (columnIndex) => (e) => {
    const adjacentColumnIndex = columnIndex + 1;

    setDragProps({
      columnIndex,
      initialPosition: e[cssClientProp],
      adjacentColumnSizePercent: sizes[adjacentColumnIndex]
    });
  };

  useEffect(() => {
    setSizes(children.map((_, index) => getInitialSize(layoutOptions[index])));
  }, [layoutOptions]);

  useEffect(() => {
    if (layoutOptions.length !== sizes.length) {
      return;
    }

    sizes.forEach((_, columnIndex) => setStorageItem(layoutOptions[columnIndex].id, `${sizes[columnIndex]}`));
  }, [sizes]);

  const onDoubleClickResize = (columnIndex) => (e) => {
    // Check for 2 clicks in a short period of time because onDoubleClick does nothing
    if (e.detail !== 2) {
      return;
    }

    const adjacentColumnIndex = columnIndex + 1;
    const columnSizePercentChange = staticSizes.current[columnIndex] - layoutOptions[columnIndex].defaultSizePercent;

    setSizes(
      buildSizesArray({
        currentSizes: staticSizes.current,
        adjacentColumnSizePercent: staticSizes.current[adjacentColumnIndex] + columnSizePercentChange,
        columnSizePercent: layoutOptions[columnIndex].defaultSizePercent,
        columnIndex: columnIndex
      })
    );
    return;
  };

  useImperativeHandle(ref, () => ({
    onMinimise: ({ minimiseSize }) => {
      const adjacentColumnSizePercent = (minimiseSize / window.innerWidth) * 100;
      const columnSizePercent = 100 - adjacentColumnSizePercent;

      return void setSizes([columnSizePercent, adjacentColumnSizePercent]);
    }
  }));

  const getSize = (index) => {
    const layoutProps = layoutOptions[index];

    if (layoutProps.minimised) {
      return layoutProps.minSize;
    }

    if (layoutProps.maximised) {
      return '100%';
    }

    return `${!layoutProps.forceDefaultSize ? sizes[index] : layoutProps.defaultSizePercent}%`;
  };

  useEffect(() => {
    if (!currentDragProps) {
      return;
    }

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);

    document.body.classList.add(getBodyDraggingClass({ vertical }));
  }, [currentDragProps]);

  const group = children.map((child, index) => {
    const layoutProps = layoutOptions[index];
    const isLastChild = children.length - 1 === index;
    const cssMinProp = `min${vertical ? 'Height' : 'Width'}`;

    return (
      <Fragment key={layoutProps.id}>
        <div
          data-testid={`${layoutProps.id}-container`}
          className={cx(
            'resizable-group__container',
            {
              'resizable-group__container--vertical': vertical,
              'resizable-group__container--hide': layoutProps.hide
            },
            layoutProps?.className
          )}
          style={{
            [cssMinProp]: layoutOptions[index]?.minSize || 0,
            [cssSizeProperty]: getSize(index)
          }}
        >
          {child}
          {!layoutOptions[index].preventResize && !isLastChild && (
            <div
              data-testid="resizable-group-resizer"
              className={cx('resizable-group__resizer', resizerClassname, {
                'resizable-group__resizer--vertical': vertical,
                // Hide the handle if the next column is hidden
                'resizable-group__resizer--hide': layoutOptions[index + 1]?.hide
              })}
              onMouseDown={onMouseDown(index)}
              onClick={onDoubleClickResize(index)}
            >
              <span
                className={cx('resizable-group__resizer__line', {
                  'resizable-group__resizer__line--vertical': vertical
                })}
              />
              {additionalResizerContent && <div className="ml-auto">{additionalResizerContent}</div>}
            </div>
          )}
        </div>
      </Fragment>
    );
  });

  return (
    <div
      className={cx('resizable-group', verticalClassname, {
        'resizable-group--vertical': vertical
      })}
    >
      {group}
    </div>
  );
});
