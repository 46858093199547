import { LogEvents, logger } from 'lib/common/components/LoggerController';

import CONTACT_STATES from 'lib/common/constants/contactStates';

import {
  getAllActiveConnections,
  getAllActiveThirdPartyConnections,
  isMultiPartyConferenceEnabled
} from 'lib/common/utils/conferenceConnections';
import connectAction from 'lib/common/utils/connectAction';
import connectGetter from 'lib/common/utils/connectGetter';
import { getTask, getTaskContact } from 'lib/common/utils/getTask';
import redactTask from 'lib/common/utils/redactTask';

import { useAgentContext } from '../../AgentContext';
import { TAnyTask } from '../types/ContactContext';
import destroyTask from '../utils/destroyTask';
import doesContactExist from '../utils/doesContactExist';
import endInitialConnectionHelper from '../utils/endInitialConnectionHelper';
import hangupAgentHelper from '../utils/hangupAgentHelper';
import useClearContact from './useClearContact';
import useMoveTaskToACW from './useMoveTaskToACW';

function holdThirdPartyConnections(contact) {
  const activeThirdPartyConnections = getAllActiveThirdPartyConnections(contact).filter((connection) => {
    return connectGetter(connection, 'getState')?.type === connect.ConnectionStateType.CONNECTED;
  });

  if (!activeThirdPartyConnections.length) {
    return Promise.resolve();
  }

  return activeThirdPartyConnections.forEach((thirdPartyConnection) => connectAction('hold', thirdPartyConnection));
}

async function holdInitialConnection(contact: connect.Contact) {
  const initialConnection = connectGetter(contact, 'getActiveInitialConnection');

  if (connectGetter(initialConnection, 'getState')?.type !== connect.ConnectionStateType.CONNECTED) {
    return Promise.resolve();
  }

  return connectAction('hold', initialConnection);
}

async function resumeAllActiveConnections({ contact, includeAgent }) {
  const activeConnections = getAllActiveConnections(contact, { includeAgent }).filter((connection) => {
    return connectGetter(connection, 'getState')?.type === connect.ConnectionStateType.HOLD;
  });

  if (!activeConnections.length) {
    return Promise.resolve();
  }

  return activeConnections.forEach((connection) => connectAction('resume', connection));
}

export default function useTaskActions({ tasks, handleContactChange, setSelectedTaskId, setTasks }) {
  const { agent } = useAgentContext();

  const moveTaskToACW = useMoveTaskToACW({ handleContactChange });
  const clearContact = useClearContact({ handleContactChange });

  const rejectTask = async (taskId: string) => {
    const contact = getTaskContact(tasks, taskId);

    if (!doesContactExist(agent, contact?.contactId)) {
      destroyTask({ contact, handleContactChange });

      return;
    }

    await connectAction('reject', contact);

    handleContactChange({ taskId, status: CONTACT_STATES.REJECTED });
  };

  const acceptTask = async (taskId: string) => {
    const contact = getTaskContact(tasks, taskId);

    if (!doesContactExist(agent, contact?.contactId)) {
      destroyTask({ contact, handleContactChange });

      return;
    }

    setSelectedTaskId(taskId);

    await connectAction('accept', contact);
  };

  const endInitialConnection = async (taskId: string) =>
    endInitialConnectionHelper(getTaskContact(tasks, taskId), handleContactChange);

  const hangupAgent = async (taskId: string) => {
    const contact = getTaskContact(tasks, taskId);

    await hangupAgentHelper({ agent, contactId: contact?.contactId });

    return moveTaskToACW(contact);
  };

  const removeTask = async (taskId: string) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      logger.info(LogEvents.TASK.CLOSED.FAIL, { taskId });

      return handleContactChange({ taskId, isDestroy: true });
    }

    await clearContact(contact);

    logger.info(LogEvents.TASK.CLOSED.SUCCESS, { task: redactTask(getTask(tasks, taskId)) });
  };

  const removeDraftTask = (taskId: string) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return;
    }

    destroyTask({ contact, handleContactChange });
  };

  const onJoinConference = async ({ taskId, includeAgent }: { taskId: string; includeAgent?: boolean }) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    try {
      if (isMultiPartyConferenceEnabled(contact)) {
        return await resumeAllActiveConnections({ contact, includeAgent });
      }

      await connectAction('conferenceConnections', contact);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * https://github.com/amazon-connect/amazon-connect-streams/issues/307#issuecomment-933321559
   * Need to make sure we hold the third party/outbound connection first and then hold the inbound connection
   * Else the APIs behave inconsistently. Sometimes the inbound hold fails or the outbound hold.
   */
  const onHoldAll = async (taskId: string) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    await holdThirdPartyConnections(contact);

    await holdInitialConnection(contact);
  };

  const onSwapConferenceCall = async (taskId: string) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    const activeConnections = getAllActiveConnections(contact);

    if (isMultiPartyConferenceEnabled(contact)) {
      return activeConnections.forEach((connection) =>
        connectGetter(connection, 'getState')?.type === connect.ConnectionStateType.CONNECTED
          ? connectAction('hold', connection)
          : connectAction('resume', connection)
      );
    }

    await connectAction('toggleActiveConnections', contact);
  };

  const addDraftTask = (newTask: TAnyTask) => {
    setTasks([newTask, ...tasks]);
  };

  return {
    rejectTask,
    acceptTask,
    endInitialConnection,
    removeTask,
    hangupAgent,
    onJoinConference,
    onHoldAll,
    onSwapConferenceCall,
    addDraftTask,
    removeDraftTask
  };
}
