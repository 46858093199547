import { default as ACW } from './components/ACW';
import { default as AgentTask } from './components/AgentTask';
import { default as Chat } from './components/Chat';
import { default as ClearTask } from './components/ClearTask';
import { default as ConferenceSubFooter } from './components/ConferenceSubFooter';
import { default as ConnectedCall } from './components/ConnectedCall';
import { default as Email } from './components/Email';
import { default as HangUpTask } from './components/HangUpTask';
import { default as OutboundPreviewTask } from './components/OutboundPreviewTask';
import { default as SoftphoneContainer } from './components/SoftphoneContainer';

export default {
  ConnectedCall,
  ClearTask,
  HangUpTask,
  ACW,
  Chat,
  AgentTask,
  Email,
  OutboundPreviewTask,
  SoftphoneContainer,
  ConferenceSubFooter
};
