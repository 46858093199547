import { Ref, forwardRef } from 'react';

import { TSignatureEditParams } from 'lib/common/contexts/AgentPreferenceContext/EmailSignatureModal/typesEmailSignatureModal';

import RichTextEditor from 'lib/common/components/molecules/RichTextEditor';
import { EditorUseCase, TEditorRef } from 'lib/common/components/molecules/RichTextEditor/Editor';

import styles from './email-signature-modal.module.scss';

const EmailSignatureEditor = ({ emailSignature }: TSignatureEditParams, ref: Ref<TEditorRef>) => {
  return (
    <>
      <RichTextEditor.LexicalComposer initialConfig={RichTextEditor.defaultEditorConfig}>
        <div className={styles['email-signature']}>
          <RichTextEditor.Toolbar className={'email-signature__toolbar'} open={true} invertLocation />
          <RichTextEditor.Editor
            ref={ref}
            className={styles['email-signature__editor']}
            placeHolder={''}
            editorUseCase={EditorUseCase.EmailMessage}
            onSubmit={() => {}}
            content={emailSignature}
            html={emailSignature}
          />
        </div>
      </RichTextEditor.LexicalComposer>
    </>
  );
};

export default forwardRef(EmailSignatureEditor);
