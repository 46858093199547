import _omit from 'lodash.omit';
import _omitBy from 'lodash.omitby';

import {
  ContactAttributes,
  DYNAMIC_CW_NEON,
  DYNAMIC_CW_NEON_ACTIVE,
  DYNAMIC_CW_NEON_INCOMING
} from 'lib/common/constants/contactAttributes';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import CONNECT_TYPE_MAP from '../contexts/ContactContext/constants/connectTypeMap';
import connectGetter from './connectGetter';
import normaliseAttributeNames from './normaliseAttributeNames';

//TODO refactor this convoluted file
export interface ContactAttributesValue {
  value: string | string[];
  isActive: boolean;
  isIncoming: boolean;
}

const valueParser = (value?: string, delimiter?: string) => {
  if (!value || !delimiter) {
    return [];
  }

  return value.split(delimiter);
};

const stringParser = (value?: string) => value;

const getIsActiveOrIsIncomingForDynamicAttributes = (attributeKey: string) => {
  if (attributeKey.match(DYNAMIC_CW_NEON_ACTIVE)) {
    return { isActive: true, isIncoming: false };
  }

  if (attributeKey.match(DYNAMIC_CW_NEON_INCOMING)) {
    return { isActive: false, isIncoming: true };
  }

  if (attributeKey.match(DYNAMIC_CW_NEON)) {
    return { isActive: true, isIncoming: true };
  }

  return { isActive: false, isIncoming: false };
};

const CONTACT_STATIC_ATTRIBUTE_MAPPER = {
  [ContactAttributes.INTENTS]: { delimiter: '|', parser: valueParser, isActive: true, isIncoming: true },
  [ContactAttributes.TRANSCRIPT]: { delimiter: '|', parser: valueParser, isActive: true, isIncoming: true },
  [ContactAttributes.PHONE_NUMBER]: { parser: stringParser, isActive: true, isIncoming: false },
  [ContactAttributes.OUTBOUND_CAMPAIGN]: { parser: stringParser, isActive: true, isIncoming: false },
  [ContactAttributes.OUTBOUND_CAMPAIGN_DESCRIPTION]: { parser: stringParser, isActive: true, isIncoming: false }
};

const getFilteredContactStaticAttributeMapper = (taskType?: ValueOf<typeof CONNECT_TYPE_MAP>) => {
  if (taskType === CONTACT_TYPES.OUTBOUND_PREVIEW) {
    return _omit(CONTACT_STATIC_ATTRIBUTE_MAPPER, [
      ContactAttributes.PHONE_NUMBER,
      ContactAttributes.OUTBOUND_CAMPAIGN,
      ContactAttributes.OUTBOUND_CAMPAIGN_DESCRIPTION
    ]);
  }

  return CONTACT_STATIC_ATTRIBUTE_MAPPER;
};

export default function getMappedContactAttributes(
  contact?: connect.Contact,
  taskType?: ValueOf<typeof CONNECT_TYPE_MAP>
): Record<Extract<keyof typeof CONTACT_STATIC_ATTRIBUTE_MAPPER, string>, ContactAttributesValue> | undefined {
  if (!contact || !taskType) {
    return;
  }

  const contactAttributes = connectGetter(contact, 'getAttributes') || {};

  const staticContactAttributes = _omitBy(
    contactAttributes,
    (attribute) =>
      attribute?.name?.match(DYNAMIC_CW_NEON_ACTIVE) ||
      attribute?.name?.match(DYNAMIC_CW_NEON_INCOMING) ||
      attribute?.name?.match(DYNAMIC_CW_NEON)
  );

  const normalisedStaticAttributes = normaliseAttributeNames(staticContactAttributes);

  const staticAttributes = Object.keys(getFilteredContactStaticAttributeMapper(taskType)).reduce(
    (result, attribute) => {
      const { parser, delimiter, isActive, isIncoming } = CONTACT_STATIC_ATTRIBUTE_MAPPER[attribute];

      return {
        ...result,
        [attribute]: {
          value: parser(normalisedStaticAttributes[attribute]?.value, delimiter),
          isActive,
          isIncoming
        }
      };
    },
    {}
  ) as Record<Extract<keyof typeof CONTACT_STATIC_ATTRIBUTE_MAPPER, string>, ContactAttributesValue>;

  const dynamicContactAttributes = _omit(contactAttributes, Object.keys(staticContactAttributes));

  const dynamicAttributes = Object.keys(dynamicContactAttributes).reduce((result, attribute) => {
    const { isActive, isIncoming } = getIsActiveOrIsIncomingForDynamicAttributes(attribute);

    if (!isActive && !isIncoming) {
      return result;
    }

    return {
      ...result,
      [attribute]: {
        value: contactAttributes[attribute]?.value,
        isActive,
        isIncoming
      }
    };
  }, {}) as Record<Extract<keyof typeof CONTACT_STATIC_ATTRIBUTE_MAPPER, string>, ContactAttributesValue>;

  return { ...staticAttributes, ...dynamicAttributes };
}
