import { TSetContactAttributesBody } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { OUTBOUND_CAMPAIGN_QUEUE_ARN } from 'lib/common/constants/contactAttributes';

import TOutboundPreviewTask from 'lib/common/types/OutboundPreviewTask';
import connectGetter from 'lib/common/utils/connectGetter';

import ClickToCall from '../ClickToCall';

const SKIPPED_COPYABLE_ATTRIBUTES = [OUTBOUND_CAMPAIGN_QUEUE_ARN];

function getTransformedContactAttributesArray(contact: connect.Contact): TSetContactAttributesBody {
  const contactAttributes = connectGetter(contact, 'getAttributes') ?? {};

  return {
    attributes: Object.keys(contactAttributes)
      .filter((key) => !SKIPPED_COPYABLE_ATTRIBUTES.includes(key) && contactAttributes[key].value?.length)
      .map((key) => ({
        key,
        value: contactAttributes[key].value
      }))
  };
}

export default function OutboundPreviewClickToCall({
  campaign: { phoneNumber, queueArn },
  contact
}: Pick<TOutboundPreviewTask, 'campaign' | 'contact'>) {
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();

  const onCallClick = () => {
    const contactAttributes = getTransformedContactAttributesArray(contact);

    // Currently no way to access contactId after creating a new agent.connection, so using the method suggested by https://github.com/amazon-connect/amazon-connect-streams/issues/216#issuecomment-582579179
    const connect = (window as any).getConnect();

    connect.contact(function (contact) {
      this.unsubscribe();

      fetch_(`${config.AGENT_SERVICE_V2_URL}/contact/${contact.contactId}/contact-attributes`, {
        method: 'PATCH',
        body: JSON.stringify(contactAttributes)
      });
    });
  };

  return (
    <ClickToCall beforeOnClick={onCallClick} button queueArn={queueArn}>
      {phoneNumber}
    </ClickToCall>
  );
}
