import { useState } from 'react';

import cx from 'classnames';
import { v4 as uuid } from 'uuid';

import { WidgetConnectAppSuffix } from '@cloud-wave/neon-common-lib';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useModulesContext } from 'lib/common/contexts/ModulesContext';

import CONNECT_APP_CONFIG from 'lib/common/constants/connectAppConfig';
import CONTACT_STATES from 'lib/common/constants/contactStates';

import TTask from 'lib/common/types/Task';
import getContactAttributes from 'lib/common/utils/getContactAttributes';

import styles from './custom-views-app.module.scss';

const CUSTOM_VIEWS_ATTRIBUTE = 'DefaultFlowForAgentUI';

export default function CustomViewsApp({
  task,
  source,
  instanceUrl
}: {
  task: TTask;
  source: string;
  instanceUrl: string;
}) {
  const {
    state: { selectedTaskId }
  } = useContactContext();
  const {
    state: { customViewsModuleEnabled }
  } = useModulesContext();

  const iframeSuffix = uuid();
  const connectAppSuffix = source.replace(instanceUrl, '');
  const { containerId, initApp } = CONNECT_APP_CONFIG[connectAppSuffix];
  const [isCustomViewsAppInitialized, setIsCustomViewsAPPInitialized] = useState(false);
  const contactHasCustomViewsAttribute = getContactAttributes({ task }, CUSTOM_VIEWS_ATTRIBUTE)[CUSTOM_VIEWS_ATTRIBUTE];
  const isContactConnectedOrACW = task.status === CONTACT_STATES.CONNECTED || task.status === CONTACT_STATES.ACW;

  const onRefChange = (el: HTMLDivElement) => {
    if (!el || !isContactConnectedOrACW || isCustomViewsAppInitialized) {
      return;
    }

    connect.agentApp.initApp(
      initApp,
      `${containerId}-${task.taskId}`,
      `${instanceUrl}${WidgetConnectAppSuffix.CUSTOM_VIEWS}`,
      {
        customViewsParams: {
          contact: task.contact,
          contactFlowId: contactHasCustomViewsAttribute,
          iframeSuffix
        }
      }
    );
    setIsCustomViewsAPPInitialized(true);
  };

  if (!customViewsModuleEnabled || !contactHasCustomViewsAttribute) {
    return null;
  }

  return (
    <div
      id={`${containerId}-${task.taskId}`}
      key={`${containerId}-${task.taskId}`}
      className={cx(styles['custom-views-app'], {
        [styles['custom-views-app--hidden']]: task.taskId !== selectedTaskId
      })}
      ref={onRefChange}
    />
  );
}
