import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { faCloudSlash } from '@fortawesome/pro-regular-svg-icons';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import cx from 'classnames';
import { useBooleanState, usePrevious } from 'webrix/hooks';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Icon from '../Icon';
import Text from '../Text';

import './offline.scss';

export default function Offline({ children }) {
  const { isSoftphone } = useLayout();

  const { value: online, setFalse: setOffline, setTrue: setOnline } = useBooleanState(navigator.onLine);
  const previousOnline = usePrevious(online);

  useEffect(() => {
    if (!online) {
      return void disableBodyScroll(document.body);
    }

    enableBodyScroll(document.body);
  }, [online]);

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return (
    <>
      <div
        className={cx(
          'offline',
          { 'offline--softphone': isSoftphone },
          'animate__animated',
          'animate__faster',
          `animate__${online ? 'slideOutUp' : 'slideInDown'}`
        )}
        style={previousOnline === online && online ? { display: 'none' } : void 0}
      >
        {!online && (
          <Helmet>
            <meta name="theme-color" content="#000000" />
          </Helmet>
        )}
        <div className="offline__content">
          <Icon icon={faCloudSlash} size={40} />
          <div className="offline__content__text">
            <Text type="heading2" htmlElement="h3">
              You're Not Online
            </Text>
            <Text>Check your internet connection.</Text>
          </div>
        </div>
      </div>
      <div className={cx('offline__overlay', { 'offline__overlay--visible': !online })} />
      {children}
    </>
  );
}
