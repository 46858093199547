import { Permissions } from '@cloud-wave/neon-common-lib';

import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

import CONTACT_STATES from 'lib/common/constants/contactStates';

import TimedTask from './TimedTask';

export default function NeglectedTask({ selectedTask }) {
  const { hasPermission } = usePermissionsContext();
  const hasMissedCallPermission = hasPermission({
    type: 'tenant',
    permission: Permissions.AGENT_MISSED_CALL
  });

  if (!hasMissedCallPermission && selectedTask.status === CONTACT_STATES.MISSED) {
    return null;
  }

  return <TimedTask {...selectedTask} />;
}
