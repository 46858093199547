import PostalMime, { Attachment } from 'postal-mime';

import IEmailMetadata from 'lib/common/types/email/EmailMetadata';
import { TAttachment } from 'lib/common/types/email/TAttachment';
import { getUnit8ArrayHash } from 'lib/common/utils/getUnit8ArrayHash';

export default async function getEmailContent({ fetch_, config, id }) {
  const res = await fetch_(`${config.EMAIL_URL}/user/${config.TENANT_ID}/email/${id}`);

  const { data } = await res.json();
  const rawEmail = await (await fetch(data.emailURL)).text();

  const parser = new PostalMime();
  const email = await parser.parse(rawEmail);
  const attachments: TAttachment[] = await Promise.all(
    email.attachments.map(async (attachment: Attachment) => {
      const hash = await getUnit8ArrayHash('SHA-1', attachment.content);
      return { ...attachment, hash };
    })
  );

  return { email: { ...email, attachments }, metadata: data.metadata as IEmailMetadata };
}
