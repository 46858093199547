import { createContext, useContext } from 'react';

import { IModule } from '@cloud-wave/neon-common-lib';

import { WidgetsPlacement } from './constants/WidgetsPlacement';
import { WidgetsState } from './constants/WidgetsState';
import { TWidgetsConfig } from './types/WidgetsConfig';

interface IModulesContext {
  state: {
    pageModules: Array<IModule>;
    widgetModules: Partial<IModule>[];
    externalLibraryModules: Partial<IModule>[];
    taskModules: Partial<IModule>[];
    widgetsConfig: TWidgetsConfig;
    customViewsModuleEnabled: boolean;
  };
  actions: {
    handleWidgetsConfigChange: (changes: Partial<TWidgetsConfig>) => void;
  };
}

const Context = createContext<IModulesContext>({
  state: {
    pageModules: [],
    widgetModules: [],
    externalLibraryModules: [],
    taskModules: [],
    widgetsConfig: {
      widgetsPlacement: WidgetsPlacement.FLOATING,
      widgetsState: WidgetsState.CLOSED
    },
    customViewsModuleEnabled: false
  },
  actions: {
    handleWidgetsConfigChange: () => {}
  }
});

export default Context;

// export the context hook
export const useModulesContext = () => useContext(Context);
