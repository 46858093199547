import { faBuilding, faHeadset, faMobileButton } from '@fortawesome/pro-regular-svg-icons';

import { TransferDestination } from 'lib/common/contexts/OverlayContext/Context';

import DIRECTORY_TABS_LAYOUT from '../constants/DirectoryTabsLayout';
import { DIRECTORY_TAB } from '../constants/directoryTabs';

const options = {
  [DIRECTORY_TABS_LAYOUT.DESKTOP]: [
    { label: DIRECTORY_TAB.AGENTS, icon: faHeadset },
    { label: DIRECTORY_TAB.PERSONAL, icon: faMobileButton },
    { label: DIRECTORY_TAB.ORGANISATION, icon: faBuilding }
  ],
  [DIRECTORY_TABS_LAYOUT.SOFTPHONE]: [
    { label: DIRECTORY_TAB.AGENTS },
    { label: DIRECTORY_TAB.PERSONAL },
    { label: DIRECTORY_TAB.ORGANISATION }
  ]
};

const getTabOptions = ({
  hasPersonalContactPermission,
  isSoftphone,
  transferTo
}: {
  hasPersonalContactPermission: boolean;
  isSoftphone: boolean;
  transferTo?: keyof typeof TransferDestination;
}) => {
  const tabOptions = options[isSoftphone ? DIRECTORY_TABS_LAYOUT.SOFTPHONE : DIRECTORY_TABS_LAYOUT.DESKTOP];

  if (transferTo === TransferDestination.AGENTS) {
    return tabOptions.filter((option) => option.label === DIRECTORY_TAB.AGENTS);
  }

  if (!hasPersonalContactPermission) {
    return tabOptions.filter((option) => option.label !== DIRECTORY_TAB.PERSONAL);
  }

  return tabOptions;
};

export default getTabOptions;
