import { faWarning, faXmark } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { filesize } from 'filesize';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import UploadedFileStatus from 'lib/common/constants/files/UploadedFileStatus';
import UploadStatus from 'lib/common/constants/files/UploadedFileStatus';

import UploadedFile from 'lib/common/types/UploadedFile';
import getAttachmentIcon from 'lib/common/utils/email/getAttachmentIcon';

import styles from './file-card-email.module.scss';

const DOT_CHAR = '\u2B24';

function getFileIconColour({ status }: UploadedFile, { highlighted }: { highlighted?: boolean } = {}) {
  if (highlighted) {
    return 'darkGrey';
  }

  if (status === UploadStatus.ERROR) {
    return 'pastelRed';
  }

  if (status === UploadStatus.COMPLETE) {
    return 'success';
  }

  return 'darkGrey';
}

export default function FileCardEmail({
  file,
  onDelete,
  highlighted,
  suffix,
  className,
  id
}: {
  file: UploadedFile;
  onDelete: () => Promise<void> | void;
  highlighted?: boolean;
  suffix?: string;
  className?: string;
  id?: string;
}) {
  const hasError = file.status === UploadedFileStatus.ERROR;

  return (
    <div
      data-testid={id}
      className={cx(styles['file-card'], className, {
        [styles['file-card--success']]: file.status === UploadedFileStatus.COMPLETE && !highlighted,
        [styles['file-card--error']]: hasError && !highlighted,
        [styles['file-card--highlighted']]: highlighted
      })}
    >
      <Icon
        icon={hasError ? faWarning : getAttachmentIcon(file.type)}
        color={getFileIconColour(file, { highlighted })}
        tooltip={hasError ? 'Failed to upload this file. Try removing it and re-adding.' : void 0}
      />
      <div className={styles['file-card__text-container']}>
        <Text type="extraSmall" ellipsis bold>
          {file.name}
        </Text>
        <Text type="helper" color="darkGrey" className={cx('next-to', styles['file-card__text-container__helper'])}>
          {filesize(file.size, { round: 0 })}
          {suffix && (
            <>
              <Text color="darkGrey" inline className={styles['file-card__suffix-dot']}>
                {DOT_CHAR}
              </Text>
              {suffix}
            </>
          )}
        </Text>
      </div>
      <ClickableIcon
        aria-label="Delete attachment"
        className={styles['file-card__close-button']}
        icon={faXmark}
        size={12}
        onClick={onDelete}
        id="delete-attachment-button"
      />
      {file.status === UploadedFileStatus.UPLOADING && (
        <span className={styles['file-card__progress-bar']} style={{ width: `${file.uploadPercent}%` }} />
      )}
    </div>
  );
}
