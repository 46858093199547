import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useAgentContext } from 'lib/common/contexts/AgentContext';

import Avatar from 'lib/common/components/Avatar';
import Button from 'lib/common/components/Button';
import Icon from 'lib/common/components/Icon';
import SupportFooter from 'lib/common/components/SupportFooter';
import Text from 'lib/common/components/Text';

import connectGetter from 'lib/common/utils/connectGetter';

import { ReactComponent as AuthErrorImage } from './assets/auth-error.svg';
import HELP_ITEMS from './constants/helpItems';

import './auth-error.scss';

export default function AuthError() {
  const { agent } = useAgentContext();
  const { signOut } = useAuthContext();
  const { config } = useConfigContext();

  const agentConfig = connectGetter(agent, 'getConfiguration');

  return (
    <>
      <div className="auth-error">
        <div className="auth-error__user">
          <Avatar name={agentConfig?.name || agentConfig?.username} />
          <div className="auth-error__user__info">
            {agentConfig?.name && <Text type="heading2">{agentConfig?.name}</Text>}
            {agentConfig?.username && <Text>{agentConfig?.username}</Text>}
          </div>
          <Button onClick={() => signOut()} className="auth-error__user__sign-out">
            Sign Out
          </Button>
        </div>
        <div className="auth-error__content">
          <div className="auth-error__content__right">
            <Text type="heading1">Houston, we have a problem.</Text>
            <Text type="heading2">Here's some things you can try to get you going:</Text>
            <div className="auth-error__content__right__help">
              {HELP_ITEMS(config.BRAND.productName).map(({ icon, content, key }) => (
                <div key={key} className="auth-error__content__right__help__item">
                  <Icon icon={icon} />
                  {content}
                </div>
              ))}
            </div>
          </div>
          <AuthErrorImage />
        </div>
      </div>
      <SupportFooter />
    </>
  );
}
