import { useHistory } from 'react-router-dom';

import { faSquareArrowUpRight } from '@fortawesome/pro-regular-svg-icons';

import { PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

import useTaskTimer from 'lib/common/hooks/useTaskTimer';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import { TASK_COLOURS, getTaskDisplayProps } from 'lib/common/constants/taskStates';

import TChatTask from 'lib/common/types/ChatTask';
import TTask from 'lib/common/types/Task';
import getUserName from 'lib/common/utils/getUserName';

import styles from './header-task-banner.module.scss';

export default function HeaderTaskBanner({ task, hideSubtitle }: { task: TTask | TChatTask; hideSubtitle?: boolean }) {
  const time = useTaskTimer(task?.time);
  const history = useHistory();

  const pushToTask = () => {
    history.push(`${PAGE_ROUTES.WORKSPACE}?taskId=${task.taskId}`);
  };

  if (!task) {
    return null;
  }

  const userName = task.profile?.firstName ? getUserName(task?.profile) : task?.connectionValue;

  const taskProps = getTaskDisplayProps(task);

  return (
    <div className={styles['header-task-banner']}>
      <Icon color={TASK_COLOURS[task.status]} icon={taskProps.icon} size={20} />
      <div className={styles['header-task-banner__text']}>
        {!hideSubtitle && (
          <Text type="heading2" htmlElement="h3">
            {userName}
          </Text>
        )}
        <div className={styles['header-task-banner__text__connection']}>
          <span>
            <strong>{hideSubtitle ? userName : taskProps.subtitle}</strong>
          </span>
          <span>| {time}</span>
        </div>
      </div>
      <ClickableIcon
        aria-label="Go to task"
        className={styles['header-task-banner__action']}
        icon={faSquareArrowUpRight}
        onClick={pushToTask}
      />
    </div>
  );
}
