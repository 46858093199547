import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Modal from 'lib/common/components/Modal';

import ACWOutcome from '../ACWOutcome/ACWOutcome';

interface IACWOutcomeModal {
  onClose: () => void;
  isACWModalOpen?: boolean;
  onSuccess?: () => void;
}

const MODAL_PROPS = {
  title: 'Wrap Up Code / Notes',
  subtitle: 'You’ll still be able to select a wrap code and fill out notes after the interaction.'
};

export default function ACWOutcomeModal({ onClose, onSuccess, isACWModalOpen }: IACWOutcomeModal) {
  const { isSoftphone } = useLayout();
  const {
    state: { isModalOpen },
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();

  if ((isSoftphone && isModalOpen) || !isACWModalOpen) {
    return null;
  }

  if (isSoftphone) {
    openModal({
      ...MODAL_PROPS,
      onClose,
      onSuccess: async () => {
        await onSuccess?.();

        closeModal();
      },
      content: <ACWOutcome withoutAccordion />
    });

    return null;
  }

  return (
    <Modal primaryButtonText="Close" open={isACWModalOpen} onClose={onClose} onSave={onClose} hideSecondaryButton>
      <ACWOutcome withoutAccordion {...MODAL_PROPS} />
    </Modal>
  );
}
