import { OUTBOUND_CAMPAIGN_ATTRIBUTE } from 'lib/common/constants/contactAttributes';
import CONTACT_TYPES, { TContactTypes } from 'lib/common/constants/contactTypes';

import connectGetter from 'lib/common/utils/connectGetter';

import CONNECT_TYPE_MAP from '../constants/connectTypeMap';

export default function getTaskType(contact: connect.Contact | undefined): TContactTypes | null {
  const type = connectGetter(contact, 'getType');
  const contactAttributes = connectGetter(contact, 'getAttributes');

  if (!type) {
    return null;
  }

  if (type === connect.ContactType.TASK && contactAttributes?.neonEmailId) {
    return CONTACT_TYPES.EMAIL;
  }

  if (
    (type === connect.ContactType.CHAT || type === connect.ContactType.TASK) &&
    contactAttributes?.[OUTBOUND_CAMPAIGN_ATTRIBUTE]
  ) {
    return CONTACT_TYPES.OUTBOUND_PREVIEW;
  }

  return CONNECT_TYPE_MAP[type];
}
