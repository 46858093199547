import { createContext, useContext } from 'react';

import { TPermissions } from '@cloud-wave/neon-common-lib';

import { TConnectPermissions, TPermissionTypes, TPermissionsConfig } from 'lib/common/types/Permissions';

export type TPermissionsContext = {
  hasPermission: ({
    type,
    permission
  }: {
    type?: TPermissionTypes;
    permission?: TPermissions | TConnectPermissions | null;
  }) => boolean;
  hasAtLeastOnePermission: ({
    type,
    permissionsToCheck
  }: {
    type?: TPermissionTypes;
    permissionsToCheck: (TPermissions | TConnectPermissions)[];
  }) => boolean;
  hasAllPermissions: ({
    type,
    permissionsToCheck
  }: {
    type?: TPermissionTypes;
    permissionsToCheck: (TPermissions | TConnectPermissions)[];
  }) => boolean;
  permissions: TPermissionsConfig;
  isAdmin: boolean;
};

const Context = createContext<TPermissionsContext>({
  hasPermission: () => false,
  hasAtLeastOnePermission: () => false,
  hasAllPermissions: () => false,
  permissions: { organisation: [], tenant: [], user: [], connect: [] },
  isAdmin: false
});

export default Context;

export const usePermissionsContext = () => useContext(Context);
