import { faPause, faPlay, faXmark } from '@fortawesome/pro-regular-svg-icons';

import Button from '../components/Button';

interface IConferenceActions {
  onDisconnect?: () => void;
  isConnectionOnHold: boolean;
  holdConnection: () => void;
  resumeConnection: () => void;
  isAnyConnectionConnecting: boolean;
  participantName: string;
}

export default function ConferenceActions({
  onDisconnect,
  isConnectionOnHold,
  holdConnection,
  resumeConnection,
  isAnyConnectionConnecting,
  participantName
}: IConferenceActions) {
  return (
    <>
      <Button
        onClick={isConnectionOnHold ? resumeConnection : holdConnection}
        icon={isConnectionOnHold ? faPlay : faPause}
        round
        size="small"
        tooltip={isConnectionOnHold ? `Resume participant ${participantName}` : `Hold participant ${participantName}`}
        styleType="SECONDARY"
        className="ml-10"
        preventDoubleClick
        disabled={isAnyConnectionConnecting}
      />
      <Button
        onClick={onDisconnect}
        icon={faXmark}
        round
        size="small"
        tooltip={
          onDisconnect
            ? `Remove participant ${participantName}`
            : 'Use the leave call control button to exit the conference'
        }
        styleType="SECONDARY_DANGER"
        className="ml-5"
        preventDoubleClick
        disabled={!onDisconnect}
      />
    </>
  );
}
