import { useEffect, useState } from 'react';

import { faInboxIn, faMinus, faPlus, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import Collapse from '@kunukn/react-collapse';
import cx from 'classnames';

import { useRequirementsContext } from 'lib/common/contexts/RequirementsContext';

import useIsSmallSoftphone from 'lib/common/hooks/useIsSmallSoftphone';
import useTaskTimer from 'lib/common/hooks/useTaskTimer';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import Icon from 'lib/common/components/Icon';
import TaskActions from 'lib/common/components/TaskActions';
import Text from 'lib/common/components/Text';

import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import { getTaskDisplayProps } from 'lib/common/constants/taskStates';

import ChatTask from 'lib/common/types/ChatTask';
import Task from 'lib/common/types/Task';
import { ContactAttributesValue } from 'lib/common/utils/getMappedContactAttributes';
import getUserName from 'lib/common/utils/getUserName';

import ContactAttributes from './components/ContactAttributes';

import './task-overlay-banner.scss';

type TTaskOverlayBanner = {
  task: ChatTask | Task;
  attributes?: Record<string, ContactAttributesValue>;
  showExpandAction?: boolean;
  overlayOpen?: boolean;
  showQueueDetails?: boolean;
  showContactAttributes?: boolean;
  onOverlayOpen?: (taskId: string) => void;
  isSoftphone?: boolean;
};

export default function TaskOverlayBanner({
  task,
  attributes,
  showExpandAction = false,
  overlayOpen = false,
  showQueueDetails = false,
  showContactAttributes = false,
  onOverlayOpen,
  isSoftphone
}: TTaskOverlayBanner) {
  const isSmallSoftphone = useIsSmallSoftphone();
  const { isPermissionGranted } = useRequirementsContext();

  const [open, setIsOpen] = useState(false);
  const time = useTaskTimer(task?.time);

  useEffect(() => {
    setIsOpen(overlayOpen);
  }, [overlayOpen]);

  const onExpand = () => {
    setIsOpen(!open);

    if (open || !onOverlayOpen) {
      return;
    }

    onOverlayOpen(task.taskId);
  };

  if (!task) {
    return null;
  }

  const taskProps = getTaskDisplayProps(task);

  const userName = task.profile?.firstName ? getUserName(task?.profile) : task?.connectionValue;
  const { queueName } = task;

  const hasQueue = showQueueDetails && queueName;
  const showInlineButtons = !isSoftphone || (!isSmallSoftphone && !hasQueue);
  const isDisabledCall = !isPermissionGranted('microphone') && task.type === CONTACT_TYPES.CALL;

  return (
    <div
      aria-label={`${taskProps.subtitle} for ${userName}${queueName ? `in ${queueName}` : ''}`}
      role="alertdialog"
      data-testid={`task-overlay-banner-${task.taskId}`}
      className="task-overlay-banner"
    >
      <div
        className={cx('task-overlay-banner__header', {
          'task-overlay-banner__header--open': open,
          'task-overlay-banner__header--closed': !open
        })}
      >
        <Icon
          tooltip={
            isDisabledCall
              ? 'We don’t seem to have access to your microphone, so you won’t be able to accept this call.'
              : undefined
          }
          color="white"
          icon={isDisabledCall ? faTriangleExclamation : taskProps.icon}
          size={isSoftphone ? 17 : 20}
        />
        <div
          className={cx('task-overlay-banner__header__text', {
            'task-overlay-banner__header__text--softphone': isSoftphone
          })}
        >
          <Text type="heading2" htmlElement="h3">
            {userName}
          </Text>
          {isSoftphone ? (
            <div className="task-overlay-banner__header__text__connection task-overlay-banner__header__text__connection--softphone">
              <span className="task-overlay-banner__header__text__time">{time}</span>
              <span>
                {hasQueue && (
                  <>
                    |
                    <span className="task-overlay-banner__header__text__queue task-overlay-banner__header__text__queue--softphone">
                      <span>
                        <Icon color="darkGrey" icon={faInboxIn} size={12} />
                      </span>
                      <span>{queueName}</span>
                    </span>
                  </>
                )}
              </span>
            </div>
          ) : (
            <>
              <div className="task-overlay-banner__header__text__connection">
                <span>
                  <strong>{taskProps.subtitle}</strong>
                </span>
                <span>| {time}</span>
              </div>
              {hasQueue && (
                <div className="task-overlay-banner__header__text__queue">
                  <span>
                    <Icon color="darkGrey" icon={faInboxIn} size={12} />
                  </span>
                  <span>{queueName}</span>
                </div>
              )}
            </>
          )}
        </div>

        {showInlineButtons && (
          <div
            className={cx('task-overlay-banner__actions', 'task-overlay-banner__actions--inline', {
              ['task-overlay-banner__actions--softphone']: isSoftphone
            })}
          >
            {task && <TaskActions {...task} autoFocus isDisabled={isDisabledCall} round />}
            {showExpandAction && (
              <ClickableIcon
                data-testid={`task-overlay-banner-actions-expand-${task.taskId}`}
                color="primary"
                size={20}
                aria-label={`${open ? 'Shrink' : 'Expand'} actions`}
                icon={open ? faMinus : faPlus}
                onClick={onExpand}
              />
            )}
          </div>
        )}
      </div>

      {!showInlineButtons && (
        <>
          <hr className={cx({ ['task-overlay-banner--no-margin-top']: !isSoftphone })} />
          <div
            className={cx('task-overlay-banner__actions', {
              ['task-overlay-banner__actions--softphone']: isSoftphone
            })}
          >
            {task && <TaskActions {...task} autoFocus isDisabled={isDisabledCall} />}
          </div>
        </>
      )}

      {showContactAttributes && (
        <>
          <hr className={cx({ ['task-overlay-banner--no-margin-top']: !isSoftphone })} />
          <Collapse
            overflowOnExpanded
            className={cx('task-overlay-banner__attributes', {
              'task-overlay-banner__attributes--open': open
            })}
            transition={`height 300ms cubic-bezier(.4, 0, .2, 1)`}
            isOpen={open}
          >
            <ContactAttributes attributes={attributes} />
          </Collapse>
        </>
      )}
    </div>
  );
}
