import { NEONNOW_BRANDING, NeonDeployRegions, Services, TServices, getBaseUrl } from '@cloud-wave/neon-common-lib';
import { getServiceUrlForFrontend } from '@cloud-wave/neon-common-lib/app';

import { getTenantId } from 'lib/common/utils/getTenantId';

const DEPLOY_REGION_CODE = process.env.VITE_DEPLOY_REGION_CODE!;
const DEPLOYMENT_ENVIRONMENT = process.env.VITE_DEPLOYMENT_ENVIRONMENT!;
const DEPLOY_REGION = NeonDeployRegions[DEPLOY_REGION_CODE];
const IS_PROD = DEPLOYMENT_ENVIRONMENT === 'prod';
const STACK_VERSION = process.env.VITE_STACK_VERSION!;

const BASE_SERVICE_URL = getBaseUrl({ DEPLOYMENT_ENVIRONMENT, DEPLOY_REGION_CODE });

const ADMIN_SERVICE_URL = getServiceUrlForFrontend({
  service: Services.ADMIN,
  baseUrl: BASE_SERVICE_URL,
  stackVersion: STACK_VERSION
});
const EMAIL_URL = getServiceUrlForFrontend({
  service: Services.EMAIL,
  baseUrl: BASE_SERVICE_URL,
  stackVersion: STACK_VERSION
});
const MODULE_SERVICE_URL = getServiceUrlForFrontend({
  service: Services.MODULE,
  baseUrl: BASE_SERVICE_URL,
  stackVersion: STACK_VERSION
});
const QUEUES_SERVICE_URL = getServiceUrlForFrontend({
  service: Services.QUEUE,
  baseUrl: BASE_SERVICE_URL,
  stackVersion: STACK_VERSION
});
const AGENT_SERVICE_URL = getServiceUrlForFrontend({
  service: Services.AGENT,
  baseUrl: BASE_SERVICE_URL,
  stackVersion: STACK_VERSION
});
const AGENT_SERVICE_V2_URL = getServiceUrlForFrontend({
  service: Services.AGENT_V2,
  baseUrl: BASE_SERVICE_URL,
  stackVersion: STACK_VERSION
});
const DIRECTORY_SERVICE_URL = getServiceUrlForFrontend({
  service: Services.DIRECTORY,
  baseUrl: BASE_SERVICE_URL,
  stackVersion: STACK_VERSION
});
const CALL_SERVICE_URL = getServiceUrlForFrontend({
  service: Services.CALL,
  baseUrl: BASE_SERVICE_URL,
  stackVersion: STACK_VERSION
});
const MODULES_S3_DOMAIN = `https://public-neon-adapters-${DEPLOYMENT_ENVIRONMENT}-${DEPLOY_REGION_CODE}.s3.${DEPLOY_REGION}.amazonaws.com`;
const BRAND_S3_DOMAIN = `https://public-neon-brand-${DEPLOYMENT_ENVIRONMENT}-${DEPLOY_REGION_CODE}.s3.${DEPLOY_REGION}.amazonaws.com`;

export default {
  IS_PROD,
  DEPLOYMENT_ENVIRONMENT,
  STACK_VERSION,
  DEPLOY_REGION,
  DEPLOY_REGION_CODE,
  DIRECTORY_SERVICE_URL,
  CALL_SERVICE_URL,
  AGENT_SERVICE_URL,
  AGENT_SERVICE_V2_URL,
  TENANT_ID: getTenantId(),
  ADMIN_SERVICE_URL,
  EMAIL_URL,
  MODULE_SERVICE_URL,
  MODULES_S3_DOMAIN,
  BRAND_S3_DOMAIN,
  QUEUES_SERVICE_URL,
  BASE_SERVICE_URL,
  REPORT_IDS: {
    ANALYTICS: 'neon-analytics',
    PERFORMANCE_TODAY: 'neon-performance-today',
    WALLBOARD: 'neon-wallboard'
  },
  BRAND: {
    helpDocsUrl: NEONNOW_BRANDING.helpDocsUrl,
    productName: NEONNOW_BRANDING.productName
  }
};
