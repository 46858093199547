import { TAnyTask } from 'lib/common/contexts/ContactContext';

import connectGetter from 'lib/common/utils/connectGetter';

const TEMPLATE_QUEUE_ID_ATTRIBUTE = 'CW-NEON-TEMPLATE-QUEUEID';

export default function getTemplatedResponseQueueIds(task?: TAnyTask) {
  const contactAttributes = connectGetter(task?.contact, 'getAttributes');

  const contactAttributeQueue = contactAttributes?.[TEMPLATE_QUEUE_ID_ATTRIBUTE]?.value;

  return [task?.queueId, contactAttributeQueue].filter(Boolean) as string[];
}
