import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';

import Modal from 'lib/common/components/Modal';
import Text from 'lib/common/components/Text';
import ColouredPanel, { PanelTypes } from 'lib/common/components/atoms/ColouredPanel';

import { CallRecordingAttributeValues } from '../../utils/getCallRecordingPermission';

const ModalContent = ({ callRecordingPermission }: { callRecordingPermission: CallRecordingAttributeValues }) => (
  <>
    <ColouredPanel type={PanelTypes.WARNING}>
      <Text>
        Once a recording has been ended it{' '}
        <Text inline bold>
          cannot be started again
        </Text>
        .
      </Text>
    </ColouredPanel>
    {callRecordingPermission !== CallRecordingAttributeValues.STOP && (
      <Text className="mt-20">
        Using the{' '}
        <Text inline bold>
          pause
        </Text>{' '}
        action will allow you to resume the recording when you’re ready.
      </Text>
    )}
  </>
);

export default function StopRecordingModal({
  isOpen,
  isSoftphone,
  onClose,
  onDelete,
  callRecordingPermission
}: {
  isOpen: boolean;
  isSoftphone: boolean;
  onClose: () => void;
  onDelete: () => void;
  callRecordingPermission: CallRecordingAttributeValues;
}) {
  const {
    state: { isModalOpen: isSoftphoneModalOpen },
    actions: { openModal }
  } = useSoftphoneModalContext();

  if (isSoftphone && isSoftphoneModalOpen) {
    return null;
  }

  const modalProps = {
    title: 'Are You Sure?',
    deleteButtonText: 'End Recording',
    onDelete: onDelete,
    onClose: onClose
  };

  if (isSoftphone && isOpen) {
    openModal({
      ...modalProps,
      content: <ModalContent callRecordingPermission={callRecordingPermission} />
    });

    return null;
  }

  return (
    <Modal open={isOpen} {...modalProps}>
      <ModalContent callRecordingPermission={callRecordingPermission} />
    </Modal>
  );
}
