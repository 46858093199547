import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import { PanelTypes } from 'lib/common/components/atoms/ColouredPanel';

import faDialpad from 'lib/common/customIcons/faDialpad';

import IPanel from '../types/Panel';
import PanelContent from './PanelContent';

const PANELS = ({ dialNumber, maxAttendeesNumber }: { dialNumber?: string; maxAttendeesNumber?: number } = {}): Record<
  PanelContent,
  IPanel
> => ({
  [PanelContent.CURRENT_TASK_ENDED]: {
    type: PanelTypes.WARNING,
    canMakeOutboundCall: false,
    content: <Text>You can no longer transfer or send DMTF tones as the call has ended.</Text>,
    id: 'current-task-ended-error'
  },
  [PanelContent.CALL_IN_ACW]: {
    type: PanelTypes.INFO,
    canMakeOutboundCall: true,
    content: <Text>Dialing this number will clear your current call ACW.</Text>,
    id: 'call-in-acw-warning'
  },
  [PanelContent.MICROPHONE_ACCESS_ERROR]: {
    type: PanelTypes.WARNING,
    canMakeOutboundCall: false,
    content: (
      <Text>
        <Text inline bold>
          We don’t have microphone access
        </Text>
        . You won’t be able to make outbound calls.{' '}
      </Text>
    ),
    id: 'microphone-access-error'
  },
  [PanelContent.INCOMING_VOICE_TASK_ERROR]: {
    type: PanelTypes.WARNING,
    canMakeOutboundCall: false,
    content: (
      <Text>
        You currently have an incoming call. You'll need to close the dialpad and action it before you can make an
        outbound call.
      </Text>
    ),
    id: 'incoming-voice-task-error'
  },
  [PanelContent.CONFERENCE_TRANSFER_CONNECTION_ERROR]: {
    type: PanelTypes.WARNING,
    canMakeOutboundCall: false,
    content: <Text>Cannot transfer as the initial participant has been disconnected.</Text>,
    id: 'conference-transfer-connection-error'
  },
  [PanelContent.CONFERENCE_TRANSFER_MAX_ATTENDEES_ERROR]: {
    type: PanelTypes.WARNING,
    canMakeOutboundCall: false,
    content: <Text>The maximum amount of {maxAttendeesNumber} conference participants has been reached.</Text>,
    id: 'conference-transfer-max-attendees-error'
  },
  [PanelContent.CALL_HANGUP_INFO]: {
    type: PanelTypes.INFO,
    canMakeOutboundCall: true,
    content: (
      <Text>
        Dialling this number will hang up the currently active call with{' '}
        <Text inline bold>
          {dialNumber}
        </Text>
        . Use the <Icon icon={faDialpad} size={14} />{' '}
        <Text inline bold>
          call control button
        </Text>{' '}
        if you want to transfer to an external number or dial numbers in your current call.
      </Text>
    ),
    id: 'call-hangup-info'
  },
  [PanelContent.CONFERENCE_HANGUP_INFO]: {
    type: PanelTypes.INFO,
    canMakeOutboundCall: true,
    content: <Text>Dialling this number will remove you from the currently active conference call.</Text>,
    id: 'conference-hangup-info'
  }
});

export default PANELS;
