import { useHistory } from 'react-router-dom';

import {
  faCloudDownloadAlt,
  faPhone,
  faSquareArrowUpRight,
  faTrash,
  faUserEdit
} from '@fortawesome/pro-regular-svg-icons';

import { Permissions } from '@cloud-wave/neon-common-lib';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import RequiresPermission from 'lib/common/components/RequiresPermission';

import TProfile from 'lib/common/types/CustomerProfile';

import saveProfileCSV from './utils/saveProfileCSV';

import './styles/actions.scss';

type TActions = {
  showOnCall: boolean;
  showExternalLink?: boolean;
  profile?: TProfile | null;
  profileNotes?: string;
  onCall?: () => void;
  onDelete: () => void;
  onEdit: () => void;
};

export default function Actions({
  showOnCall,
  showExternalLink,
  profile,
  profileNotes,
  onCall,
  onDelete,
  onEdit
}: TActions) {
  const history = useHistory();

  const onClickExternal = () => {
    history.push(`/profiles/${profile?.profileId}`);
  };

  if (!profile) {
    return null;
  }

  return (
    <div className="actions">
      {showExternalLink && (
        <ClickableIcon
          onClick={onClickExternal}
          icon={faSquareArrowUpRight}
          size={22}
          tooltip="Navigate to Customer Profile"
        />
      )}
      {showOnCall && (
        <ClickableIcon data-testid="call-contact" onClick={onCall} icon={faPhone} tooltip="Call customer" />
      )}
      <ClickableIcon
        data-testid="save-profile-csv"
        onClick={() => saveProfileCSV({ ...profile, notes: profileNotes })}
        icon={faCloudDownloadAlt}
        size={20}
        tooltip="Download the customer profile in a CSV file"
      />
      <RequiresPermission permission={Permissions.CUSTOMER_PROFILES_WRITE}>
        <>
          <ClickableIcon
            data-testid="edit-profile"
            onClick={onEdit}
            icon={faUserEdit}
            size={20}
            tooltip="Edit the customer profile"
          />
          <ClickableIcon
            data-testid="delete-profile"
            icon={faTrash}
            color="danger"
            onClick={onDelete}
            tooltip="Delete the customer profile"
          />
        </>
      </RequiresPermission>
    </div>
  );
}
