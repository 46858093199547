import { ReactNode, useState } from 'react';

import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import DesktopLayoutContext, { defaultLayout } from './Context';

type Props = {
  children: ReactNode;
};

const DesktopLayoutProvider = ({ children }: Props) => {
  const { getStorageItem, setStorageItem } = useLocalStorage();

  const [isSidebarOpen, setIsSidebarOpen] = useState(getStorageItem('nav-expanded') === 'true');

  const toggleIsSidebarOpen = () => {
    const newState = !isSidebarOpen;
    setStorageItem('nav-expanded', newState ? 'true' : '');
    setIsSidebarOpen(newState);
  };

  return (
    <DesktopLayoutContext.Provider
      value={{
        ...defaultLayout,
        isSidebarOpen,
        toggleIsSidebarOpen
      }}
    >
      {children}
    </DesktopLayoutContext.Provider>
  );
};

export default DesktopLayoutProvider;
