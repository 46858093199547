import {
  ContactAttributes,
  OUTBOUND_CAMPAIGN_ATTRIBUTE,
  OUTBOUND_CAMPAIGN_DESCRIPTION_ATTRIBUTE,
  OUTBOUND_CAMPAIGN_QUEUE_ARN
} from 'lib/common/constants/contactAttributes';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import TOutboundPreviewTask from 'lib/common/types/OutboundPreviewTask';
import connectGetter from 'lib/common/utils/connectGetter';
import normaliseAttributeNames from 'lib/common/utils/normaliseAttributeNames';

import CONNECT_TYPE_MAP from '../constants/connectTypeMap';
import { TAnyTask } from '../types/ContactContext';

export default function getTaskContent(
  type: ValueOf<typeof CONNECT_TYPE_MAP>,
  contact: connect.Contact
): Partial<TAnyTask> | Record<string, never> {
  const contactAttributes = connectGetter(contact, 'getAttributes');

  if (type === CONTACT_TYPES.OUTBOUND_PREVIEW) {
    return {
      connectionValue: contactAttributes?.[OUTBOUND_CAMPAIGN_ATTRIBUTE].value,
      description: contactAttributes?.[OUTBOUND_CAMPAIGN_DESCRIPTION_ATTRIBUTE]?.value || '',
      campaign: {
        phoneNumber: normaliseAttributeNames(contactAttributes ?? {})[ContactAttributes.PHONE_NUMBER]?.value || '',
        queueArn: contactAttributes?.[OUTBOUND_CAMPAIGN_QUEUE_ARN]?.value || ''
      }
    } as TOutboundPreviewTask;
  }
  return {};
}
