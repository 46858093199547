import { parsePhoneNumber } from 'awesome-phonenumber';
import { object, string } from 'yup';

import { GSM7BitRegex } from '@cloud-wave/neon-common-lib';

import VALIDATION_TEXT from 'lib/common/constants/validationText';

const sendSmsSchema = object({
  phoneNumber: string()
    .required()
    .test('is-valid-phone-number', VALIDATION_TEXT.PHONE_NUMBER, (phoneNumber) => {
      if (!phoneNumber) {
        return true;
      }

      return parsePhoneNumber(phoneNumber).isValid();
    }),
  message: string()
    .required()
    .test('is-valid-message', VALIDATION_TEXT.MESSAGE, (message) => {
      if (!message) {
        return true;
      }

      return GSM7BitRegex.test(message);
    })
});

export default sendSmsSchema;
