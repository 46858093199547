import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons';

import { usePresenceContext } from 'lib/common/contexts/PresenceContext';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import UserProfile from 'lib/common/components/UserProfile';

import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';

import TUser from 'lib/common/types/User';

import COLOURS from 'css/export-vars.module.scss';

import './no-tasks-placeholder.scss';

type TNoTasksPlaceholder = {
  user: TUser;
};

function getPlaceholderText({ isBusyFromConnect, agentPresence }) {
  if (agentPresence === PRESENCE_STATES.AVAILABLE || isBusyFromConnect) {
    return 'You are ready to start receiving tasks.';
  }

  return 'Change your user status to start receiving tasks.';
}

export default function NoTasksPlaceholder({ user }: TNoTasksPlaceholder) {
  const { agentPresence, isBusyFromConnect } = usePresenceContext();

  return (
    <div className="no-tasks-placeholder">
      <Icon icon={faLayerGroup} size={60} />
      <div className="no-tasks-placeholder__text">
        <Text type="heading2">No Active Tasks</Text>
        <Text>{getPlaceholderText({ agentPresence, isBusyFromConnect })}</Text>
      </div>
      <UserProfile isLandingPage user={user} statusBackgroundColor={COLOURS.lightGrey} />
    </div>
  );
}
