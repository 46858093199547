import { ReactNode } from 'react';

import cx from 'classnames';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import SoftphoneContainer from './SoftphoneContainer';

import '../styles/task-footer.scss';

interface ITaskFooterProps {
  children: ReactNode;
  isSmallWidth?: boolean;
  className?: string;
}

function TaskFooter({ children, isSmallWidth, className }: ITaskFooterProps) {
  const { isSoftphone } = useLayout();

  const footer = (
    <section
      aria-label="footer"
      className={cx('task-footer__container', className)}
      style={{
        flexDirection: isSmallWidth ? 'column' : 'row',
        gap: !isSmallWidth ? '10px' : '5px'
      }}
      data-testid="task-footer"
    >
      {children}
    </section>
  );

  if (!isSoftphone) {
    return footer;
  }

  return <SoftphoneContainer>{footer}</SoftphoneContainer>;
}

export default TaskFooter;
