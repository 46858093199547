import { ReactNode, RefObject, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import cx from 'classnames';

import { SOFTPHONE_PAGE_CONFIGURATIONS } from '@cloud-wave/neon-common-lib/app';

import { useModulesContext } from 'lib/common/contexts/ModulesContext';

import WarningBanner from 'lib/common/components/WarningBanner';

import TUser from 'lib/common/types/User';

import NotificationBanner from './components/NotificationBanner';
import SoftphoneHeader from './components/SoftphoneHeader';
import SoftphoneSidebar from './components/SoftphoneSidebar';

import './softphone-layout.scss';

interface ISoftphoneLayoutProps {
  children: ReactNode;
  user: TUser;
  isReversedLayout: boolean;
  contentRef: RefObject<HTMLDivElement>;
}

export default function SoftphoneLayout({ children, user, isReversedLayout, contentRef }: ISoftphoneLayoutProps) {
  const { pathname } = useLocation();
  const {
    state: { pageModules }
  } = useModulesContext();

  const { pageTitle = '' } = useMemo(() => {
    const pageModulesNavOptions = pageModules
      ? pageModules?.reduce(
          (result, pageModule) => ({
            ...result,
            ...(pageModule?.pathName
              ? {
                  [pageModule.pathName]: {
                    pageTitle: pageModule.type === 'PAGE' ? pageModule.name : ''
                  }
                }
              : {})
          }),
          {}
        )
      : {};

    return SOFTPHONE_PAGE_CONFIGURATIONS[pathname] || pageModulesNavOptions[pathname] || {};
  }, [pathname]);

  if (true) {
    return (
      <div className="softphone-layout">
        <SoftphoneHeader pageTitle={pageTitle} isReversedLayout={isReversedLayout} />
        <div className={cx('softphone-layout__content', { 'softphone-layout__content--reversed': isReversedLayout })}>
          <SoftphoneSidebar user={user} isReversedLayout={isReversedLayout} />
          <main className="softphone-layout__container" ref={contentRef}>
            <WarningBanner className="softphone-layout__warning-banner" />
            <NotificationBanner className="softphone-layout__notification-banner" />
            <div className="softphone-layout__container__children">{children}</div>
          </main>
        </div>
      </div>
    );
  }
}
