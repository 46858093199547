import UploadedFileStatus from 'lib/common/constants/files/UploadedFileStatus';

import UploadedFile from 'lib/common/types/UploadedFile';

const WARNINGS = {
  UPLOADING_FILES:
    "There are still some files being uploaded. If you continue, we'll skip them. Do you want to send anyway?",
  EMPTY_SUBJECT: "You haven't entered a subject. Do you want to send anyway?"

  // Not currently implemented for phase 1
  // [NO_CONTENT]: "You haven't entered any text. Do you want to send anyway?"
};

export default function getEmailWarning({
  attachments,
  subject
}: // content
{
  attachments: Record<string, UploadedFile>;
  subject?: string;
}) {
  const isUploadingFiles = Object.values(attachments).some(
    (uploadedFile) => uploadedFile.status === UploadedFileStatus.UPLOADING
  );

  if (isUploadingFiles) {
    return WARNINGS.UPLOADING_FILES;
  }

  if (!subject) {
    return WARNINGS.EMPTY_SUBJECT;
  }

  // Not currently implemented for phase 1
  // if (!content) {
  //   return WarningTypes.NO_CONTENT;
  // }

  return null;
}
