import cx from 'classnames';

import NoTasksPlaceholder from '../../../NoTasksPlaceholder';
import CreateTaskButton from './components/CreateTaskButton';

import './no-task-view.scss';

export default function NoTaskView({ user, setCreateAgentTaskOpen, setSendSmsModalOpen, width }) {
  return (
    <div className={cx('no-task-view', { 'no-task-view--small': width < 900 })}>
      <NoTasksPlaceholder user={user} />
      <CreateTaskButton setCreateAgentTaskOpen={setCreateAgentTaskOpen} setSendSmsModalOpen={setSendSmsModalOpen} />
    </div>
  );
}
