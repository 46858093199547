import FusionData from 'lib/common/components/atoms/FusionData';
import ACWOutcome from 'lib/common/components/molecules/ACW/components/ACWOutcome';
import PureEmail from 'lib/common/components/molecules/Email';
import CustomerProfile from 'lib/pages/AgentWorkspace/components/CustomerProfile';

import TEmailTask from 'lib/common/types/EmailTask';
import getContactAttributes from 'lib/common/utils/getContactAttributes';

import { EmailModalTypes } from './EmailModal';

const EmailModalChildren = ({
  type,
  task,
  isSoftphone
}: {
  type: EmailModalTypes;
  task: TEmailTask;
  isSoftphone: boolean;
}) => {
  const { neonEmailCcRecipients = '', neonEmailAllRecipients = '' } = getContactAttributes(
    { task },
    'neonEmailCcRecipients',
    'neonEmailAllRecipients'
  );
  if (type === EmailModalTypes.ORIGINAL_EMAIL) {
    return (
      <PureEmail
        metadata={{
          from: task.email?.originalEmail?.emailAddress || '',
          to: task.email?.originalEmail?.neonEmailTo || ''
        }}
        neonEmailCcRecipients={neonEmailCcRecipients}
        neonEmailAllRecipients={neonEmailAllRecipients}
        content={task.email?.originalEmail?.content}
        loading={false}
        error={false}
        attachments={task.email?.originalEmail?.content?.attachments || []}
        isSoftphone={isSoftphone}
      />
    );
  }

  if (type === EmailModalTypes.CUSTOMER_PROFILE) {
    return <CustomerProfile profileId={task?.profile?.profileId} task={task} />;
  }

  if (type === EmailModalTypes.ATTACHED_DATA) {
    return <FusionData task={task} />;
  }

  if (type === EmailModalTypes.WRAP_UP_CODES_AND_NOTES) {
    return <ACWOutcome withoutAccordion />;
  }

  return null;
};

export default EmailModalChildren;
