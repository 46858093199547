import TaskContentTypes from 'lib/common/types/TaskContentTypes';

import TaskContentToKeyMap from '../constants/TaskContentToKeyMap';
import { TAnyTask } from '../types/ContactContext';
import { TSetTaskContentOptions, TSetTaskContentTaskId, TSetTaskContentValue } from '../types/SetTaskContent';

export default function useSetTaskContent({
  getTasks,
  setTasks
}: {
  getTasks: () => TAnyTask[];
  setTasks: (tasks: TAnyTask[]) => void;
}) {
  return (
    taskId: TSetTaskContentTaskId,
    type: TaskContentTypes,
    content: TSetTaskContentValue,
    { replace }: TSetTaskContentOptions = { replace: false }
  ) => {
    if (!taskId) {
      return;
    }

    const newTasks = [...getTasks()];
    const taskIndex = newTasks.findIndex((task) => task.taskId === taskId);

    if (taskIndex === -1) {
      return;
    }

    const originalContent = newTasks[taskIndex][TaskContentToKeyMap[type]];

    // merge, don't replace
    newTasks[taskIndex][TaskContentToKeyMap[type]] =
      !replace && typeof content === 'object' ? { ...originalContent, ...content } : content;

    setTasks(newTasks);
  };
}
