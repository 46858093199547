import { useEffect, useState } from 'react';

import cx from 'classnames';

import { TAgentContact, TContact } from '@cloud-wave/neon-common-lib';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useOverlayContext } from 'lib/common/contexts/OverlayContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import useInfiniteScroll from 'lib/common/hooks/useInfiniteScroll';
import useInterval from 'lib/common/hooks/useInterval';
import usePaginatedLoad from 'lib/common/hooks/usePaginatedLoad';

import Loader from 'lib/common/components/Loader';
import { LogEvents, logger } from 'lib/common/components/LoggerController';

import requestErrorToast from 'lib/common/utils/toast/requestErrorToast';

import { DIRECTORY_TAB } from '../../constants/directoryTabs';
import { TInternalContacts } from '../../types';
import Contact from '../Contact/Contact';
import SearchPlaceholder from '../SearchPlaceholder';
import TabsPlaceholder from '../TabsPlaceholder';

import styles from './contact-list.module.scss';

const LOAD_INTERVAL_S = 5;

const ContactList = ({
  searchTerm,
  selectedTab,
  fetchUrl,
  isAdmin,
  isTransfer,
  onCreateOrUpdateClick,
  onClearSearchTerm,
  transferringToId,
  transferDisabled,
  onDeleteContact,
  onTransfer
}: {
  searchTerm: string;
  selectedTab: DIRECTORY_TAB;
  fetchUrl: (type: string, searchTerm: string) => string;
  isAdmin: boolean;
  isTransfer: boolean;
  onCreateOrUpdateClick?: (contact?: TInternalContacts) => void;
  onClearSearchTerm: () => void;
  transferringToId?: string;
  onDeleteContact?: () => void;
  transferDisabled?: boolean;
  onTransfer: (contact: TContact | TAgentContact) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(true);

  const {
    actions: { makeOutboundCall }
  } = useContactContext();
  const { isSoftphone } = useLayout();

  const { closeOverlay } = useOverlayContext();

  const { rootRef, sentryElement, updateTrigger } = useInfiniteScroll({ rootMargin: 300 });

  const filterLimit = searchTerm.length > 2 && selectedTab === DIRECTORY_TAB.ORGANISATION ? 500 : 100;

  const errorMessage = `Something went wrong and we couldn't fetch ${
    selectedTab === DIRECTORY_TAB.AGENTS ? 'agent information' : 'your contacts'
  }. Please try again.`;

  const {
    data: contacts,
    loadNext: loadContacts,
    refreshData,
    exhausted
  } = usePaginatedLoad<TContact>({
    fetchUrl: fetchUrl(selectedTab, searchTerm),
    pageSize: filterLimit
  });

  useEffect(() => {
    if (!updateTrigger) {
      return;
    }

    (async () => {
      await loadData();
    })();
  }, [updateTrigger]);

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, [selectedTab, searchTerm]);

  useInterval(() => {
    if (selectedTab !== DIRECTORY_TAB.AGENTS) {
      return;
    }

    (async () => {
      try {
        await refreshData();
      } catch (e: any) {
        logger.error(LogEvents.CONTACT.REFRESH.FAIL, { error: e, searchTerm, selectedTab });
      }
    })();
  }, LOAD_INTERVAL_S * 1000);

  const loadData = async () => {
    setLoading(true);

    try {
      const loadedContacts = await loadContacts();

      logger.info(LogEvents.CONTACT.LOAD.SUCCESS, {
        searchTerm,
        selectedTab,
        numberReturned: loadedContacts.length
      });
      setLoading(false);
    } catch (e: any) {
      requestErrorToast({ errorMessage, errorReference: e.requestId });

      logger.error(LogEvents.CONTACT.LOAD.FAIL, { error: e, searchTerm, selectedTab });

      setLoading(false);
    }
  };

  const onCallClick = async (row: TContact) => {
    if (!('phoneNumber' in row)) {
      return;
    }

    await makeOutboundCall(row.phoneNumber);
    closeOverlay();
  };

  const formatName = (...nameParts: string[]) =>
    nameParts
      ? nameParts
          ?.map((part) => part?.trim())
          .join(' ')
          .trim()
      : 'Unnamed Contact';

  const generateContactElements = (
    contact: TContact | TAgentContact,
    index: number,
    array: TContact[] | TAgentContact[]
  ) => {
    const fullName = formatName(contact?.firstName, contact?.lastName);
    const prevName = formatName(array[index - 1]?.firstName, array[index - 1]?.lastName);
    const showDropCap = !index || prevName[0]?.toLowerCase() !== fullName[0]?.toLowerCase();

    return (
      <Contact
        key={contact.contactId}
        contact={contact}
        showDropCap={showDropCap}
        onCallClick={onCallClick}
        transferDisabled={transferDisabled}
        isTransfer={isTransfer}
        searchTerm={searchTerm}
        isSoftphone={isSoftphone}
        fullName={fullName}
        onTransfer={onTransfer}
        isAdmin={isAdmin}
        onCreateOrUpdateClick={onCreateOrUpdateClick}
        onDeleteContact={onDeleteContact}
        transferringToId={transferringToId}
      />
    );
  };

  const contactElements = contacts.map(generateContactElements);

  const searching = Boolean(searchTerm);

  return (
    <>
      {!loading && !contacts.length && searching && (
        <div role="tabpanel" className="full-height">
          <SearchPlaceholder searchTerm={searchTerm} onClick={onClearSearchTerm} />
        </div>
      )}
      {!loading && !contacts.length && !searching && (
        <div role="tabpanel" className="full-height">
          <TabsPlaceholder type={selectedTab} isAdmin={isAdmin} createContact={onCreateOrUpdateClick} />
        </div>
      )}
      {loading && !contacts.length && (
        <div role="tabpanel" className="full-height flex">
          <Loader className={styles['contact-list__loader']} relative minimised small />
        </div>
      )}
      {Boolean(contacts.length) && (
        <div
          role="tabpanel"
          ref={rootRef}
          className={cx(styles['contact-list__container'], {
            [styles['contact-list__container--softphone']]: isSoftphone,
            [styles['contact-list__container--searching']]: Boolean(searchTerm) && selectedTab !== DIRECTORY_TAB.AGENTS
          })}
        >
          {contactElements}
          {!exhausted && (
            <>
              {sentryElement}
              <Loader className={styles['contact-list__container__loader']} relative minimised small />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ContactList;
