import { memo, useState } from 'react';

import { faNote } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { ContactType, DirectoryType, TAgentContact, TContact } from '@cloud-wave/neon-common-lib';

import { useInstanceContext } from 'lib/common/contexts/InstanceContext';

import Avatar from 'lib/common/components/Avatar';
import ClickToCall from 'lib/common/components/ClickToCall';
import Icon from 'lib/common/components/Icon';
import Loader from 'lib/common/components/Loader';
import Text from 'lib/common/components/Text';
import HighlightText from 'lib/common/components/atoms/HighlightText';
import ContactPopover from 'lib/common/components/molecules/Directory/components/ContactPopover/ContactPopover';

import getStatusIconColour from 'lib/common/utils/presence/getStatusIconColour';

import { ContactTypeLabels, TInternalContacts } from '../../types';
import getAvatarNameFromContact from '../../utils/getAvatarNameFromContact';
import ContactActionsPopup from '../ContactActionsPopup';
import ContactDirectoryIcon from './components/ContactDirectoryIcon';

import COLOURS from 'css/export-vars.module.scss';

import styles from './contact.module.scss';

type TContactProps = {
  contact: TContact | TAgentContact;
  showDropCap?: boolean;
  onCallClick: (contact: TContact) => Promise<void>;
  isTransfer?: boolean;
  transferringToId?: string;
  fullName: string;
  isSoftphone?: boolean;
  isAdmin: boolean;
  searchTerm: string;
  onCreateOrUpdateClick?: (contact?: TInternalContacts) => void;
  onDeleteContact?: () => void;
  onTransfer: (contact: TContact | TAgentContact) => void;
  transferDisabled?: boolean;
};

function Wrapper({ children, canTransfer, ...props }) {
  if (canTransfer) {
    return <button {...props}>{children}</button>;
  }

  return <div {...props}>{children}</div>;
}

const Contact = memo(
  ({
    contact,
    showDropCap,
    onCallClick,
    isTransfer,
    fullName,
    isSoftphone,
    transferringToId,
    isAdmin,
    onCreateOrUpdateClick,
    searchTerm,
    onDeleteContact,
    onTransfer,
    transferDisabled
  }: TContactProps) => {
    const { instanceDirectories } = useInstanceContext();
    const externalDirectory =
      contact.type === ContactType.EXTERNAL_DIRECTORY ? instanceDirectories[contact.directoryId] : null;

    const contactDirectory = externalDirectory
      ? {
          ...externalDirectory,
          type: externalDirectory?.presence ? DirectoryType.MICROSOFT_ENTRA_ID : DirectoryType.EXTERNAL_DIRECTORY
        }
      : null;
    const isTeamsContact =
      contactDirectory?.type === DirectoryType.MICROSOFT_ENTRA_ID && contactDirectory?.presence === true;

    const [dialing, setDialing] = useState(false);

    const handleOnCallClick = async () => {
      if (isTransfer || dialing || contact.type === ContactType.AGENT) {
        return;
      }

      setDialing(true);
      await onCallClick(contact);
      setDialing(false);
    };

    const avatarSizes = Avatar.Sizes;
    const canTransfer = !transferDisabled && isTransfer && !transferringToId;
    const hasJobTitle = 'jobTitle' in contact && contact.jobTitle;

    return (
      <div className={styles['contact']}>
        {showDropCap && !isSoftphone ? (
          <div className={styles['contact__dropcap']}>
            <Text inline type="heading2">
              {fullName[0].toUpperCase()}
            </Text>
          </div>
        ) : (
          <div />
        )}
        <Wrapper
          canTransfer={canTransfer}
          disabled={!canTransfer}
          className={cx('no-styles-button', styles['contact__body'], {
            [styles['contact__body--transfer']]: canTransfer,
            [styles['contact__body--transfer--softphone']]: canTransfer && isSoftphone
          })}
          onClick={canTransfer ? () => onTransfer(contact) : void 0}
        >
          <div className={styles['contact__avatar']}>
            <ContactPopover
              anchor={
                <Avatar
                  name={getAvatarNameFromContact(contact)}
                  size={isSoftphone ? avatarSizes.SMALL : avatarSizes.MEDIUM}
                  statusColour={
                    'presenceStatus' in contact && contact.presenceStatus
                      ? COLOURS[getStatusIconColour(contact.presenceStatus)]
                      : void 0
                  }
                  testId={
                    'presenceStatus' in contact ? `avatar-status-${contact.presenceStatus?.toLowerCase()}` : void 0
                  }
                />
              }
              contactDirectory={contactDirectory}
              contact={contact}
              fullName={fullName}
              handleOnCallClick={handleOnCallClick}
            />
          </div>

          <div
            className={cx(styles['contact__details'], {
              [styles['contact__details--softphone']]: isSoftphone,
              [styles['contact__details--searching']]: Boolean(searchTerm) && contact.type !== ContactType.AGENT
            })}
          >
            <span className={styles['contact__details__name']}>
              <HighlightText
                htmlElement="h3"
                highlight={searchTerm}
                text={fullName}
                className={cx(styles['contact__details__name__text'], {
                  [styles['contact__details__name__text--softphone']]: isSoftphone
                })}
                bold
                ellipsis
              />

              {'notes' in contact && contact.notes && (
                <Icon
                  ariaHidden={false}
                  button
                  title="Notes"
                  size={15}
                  icon={faNote}
                  tooltip={contact.notes}
                  tooltipArrow
                  tooltipPlacement="top"
                  tooltipDelay={200}
                />
              )}
            </span>

            {searchTerm && contact.type !== ContactType.AGENT && (
              <HighlightText
                htmlElement="h3"
                ellipsis
                highlight={searchTerm}
                text={hasJobTitle ? `${contact.jobTitle}` : 'No Job Title'}
                type={isSoftphone ? 'extraSmall' : 'small'}
                className={styles['contact__details__job-title']}
                color={hasJobTitle ? void 0 : 'darkGrey'}
              />
            )}

            <div className={styles['contact__details__info-contact']}>
              <ContactDirectoryIcon contact={contact} isSoftphone={isSoftphone} isTeamsContact={isTeamsContact} />

              {contact.type !== ContactType.AGENT && contact.phoneNumber?.trim() ? (
                !isTransfer ? (
                  <ClickToCall
                    textProps={{
                      ellipsis: true,
                      color: 'darkGrey',
                      type: isSoftphone ? 'extraSmall' : 'small',
                      ariaTitle: `Call ${contact.firstName} ${contact.lastName} on ${contact.phoneNumber}`
                    }}
                    onClick={handleOnCallClick}
                  >
                    {contact.phoneNumber}
                  </ClickToCall>
                ) : (
                  <Text type="label" className={styles['contact__details__info-contact__phone-number']}>
                    {contact.phoneNumber}
                  </Text>
                )
              ) : (
                <Text ellipsis color="darkGrey" type={isSoftphone ? 'extraSmall' : 'small'}>
                  {isTeamsContact
                    ? ContactTypeLabels.MICROSOFT_ENTRA_ID
                    : ContactTypeLabels[contact.type.toUpperCase()]}
                </Text>
              )}
            </div>
          </div>

          {contact.type !== ContactType.AGENT && !isTransfer ? (
            <div className={styles['contact__actions']}>
              <ContactActionsPopup
                isTeamsContact={isTeamsContact}
                contact={contact}
                isAdmin={isAdmin}
                onEditContactClick={onCreateOrUpdateClick}
                onDeleteContact={onDeleteContact}
              />
            </div>
          ) : (
            <div className={styles['contact__actions']}>
              {isTransfer && transferringToId === contact.contactId && (
                <Loader relative minimised size={isSoftphone ? 25 : 30} className={isSoftphone ? 'mr-10' : 'mr-20'} />
              )}
            </div>
          )}
        </Wrapper>
      </div>
    );
  }
);

export default Contact;
