import { useState } from 'react';

import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Icon from 'lib/common/components/Icon';
import Loader from 'lib/common/components/Loader';
import Text from 'lib/common/components/Text';

import { TChatMessage } from 'lib/common/types/chat/ChatMessage';

import styles from './chat-header.module.scss';

export default function ChatHeader({
  messages,
  showEmptyPlaceholder,
  saveScrollPosition,
  nextToken
}: {
  messages: TChatMessage[];
  showEmptyPlaceholder?: boolean;
  saveScrollPosition?: () => void;
  nextToken?: string | boolean;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const { isSoftphone } = useLayout();
  const {
    actions: { getChatTranscripts }
  } = useContactContext();

  const onLoadMessages = async () => {
    setLoading(true);

    // Pinning scroll to current position not functional, will revisit
    // saveScrollPosition?.();

    await getChatTranscripts();

    setLoading(false);
  };

  if (loading) {
    return <Loader relative size={35} className={isSoftphone ? styles['chat-header__loader--softphone'] : void 0} />;
  }

  if (nextToken) {
    return (
      <>
        <Icon icon={faClockRotateLeft} size={17} className="mb-10" color="darkGrey" />
        <Text onClick={onLoadMessages}>Load Older Messages</Text>
      </>
    );
  }

  if (!showEmptyPlaceholder || messages.length) {
    return null;
  }

  return <Text>There weren't any messages in this chat.</Text>;
}
