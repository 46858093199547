import _flatten from 'lodash.flatten';
import _uniq from 'lodash.uniq';

import { TPermissions } from '@cloud-wave/neon-common-lib';

import { TConnectPermissions, TPermissionTypes, TPermissionsConfig } from 'lib/common/types/Permissions';
import connectGetter from 'lib/common/utils/connectGetter';

import userIsAdmin from '../../utils/userIsAdmin';
import { useAgentContext } from '../AgentContext';
import Context from './Context';

export type THasPermission = ({
  type,
  permission
}: {
  type?: TPermissionTypes;
  permission?: TPermissions | TConnectPermissions | null;
}) => boolean;

function convertPermissionObjectToArray(permissions) {
  if (!permissions) {
    return [];
  }

  return Object.entries(permissions)
    .filter(([, hasPermission]) => hasPermission)
    .map(([permissionName]) => permissionName);
}

function PermissionsProvider({ children, config, user }) {
  const { agent } = useAgentContext();
  const isAdmin = userIsAdmin(user);

  const { PERMISSIONS: tenantPermissions = [] } = config ?? {};

  const permissions: TPermissionsConfig = {
    organisation: [],
    tenant: tenantPermissions,
    user: [
      ..._uniq(_flatten(Object.values(user?.permissions || {}).map(convertPermissionObjectToArray))),
      'active'
    ].filter(Boolean),
    connect: connectGetter(agent, 'getPermissions') || []
  };

  const hasPermission: THasPermission = ({ type, permission }) => {
    if (!permission) {
      return true;
    }

    if (!type) {
      return Object.values(permissions).some((permissionArray) =>
        permissionArray.some((setPermission) => setPermission === permission)
      );
    }

    return permissions[type]?.some((setPermission) => setPermission === permission);
  };

  const hasAtLeastOnePermission = ({
    type,
    permissionsToCheck
  }: {
    type?: TPermissionTypes;
    permissionsToCheck: (TPermissions | TConnectPermissions)[];
  }) => {
    if (!permissionsToCheck.length) {
      return true;
    }

    return permissionsToCheck.some((permission) => hasPermission({ type, permission }));
  };

  const hasAllPermissions = ({
    type,
    permissionsToCheck
  }: {
    type?: TPermissionTypes;
    permissionsToCheck: (TPermissions | TConnectPermissions)[];
  }) => {
    if (!permissionsToCheck.length) {
      return true;
    }

    return permissionsToCheck.every((permission) => hasPermission({ type, permission }));
  };

  return (
    <Context.Provider value={{ hasPermission, hasAtLeastOnePermission, hasAllPermissions, permissions, isAdmin }}>
      {children}
    </Context.Provider>
  );
}

export default PermissionsProvider;
