import { Children, ReactNode, cloneElement, useState } from 'react';

import Accordion from '../Accordion';

interface AccordionGroupProps {
  defaultOpenIndex?: number;
  onlyOneOpen?: boolean;
  children: ReactNode;
}

function AccordionGroup({ children, defaultOpenIndex, onlyOneOpen }: AccordionGroupProps) {
  const [openIndex, setOpenIndex] = useState(defaultOpenIndex || 0);

  const accordionChildren = Children.toArray(children);

  return (
    <>
      {Children.map(accordionChildren, (child: any, index) =>
        cloneElement(child, {
          key: index,
          className: child.props.className,
          defaultOpen: index === defaultOpenIndex,
          onToggle: onlyOneOpen
            ? () => {
                setOpenIndex(index === openIndex ? -1 : index);
              }
            : undefined,
          forceOpen: onlyOneOpen ? openIndex === index : undefined
        })
      )}
    </>
  );
}

AccordionGroup.Accordion = Accordion;

export default AccordionGroup;
