import { faFilterList } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import ReactSelect from 'lib/common/components/ReactSelect';
import Text from 'lib/common/components/Text';
import DateSelector from 'lib/common/components/atoms/DateSelector';
import Popover from 'lib/common/components/molecules/Popover';

import styles from './header.module.scss';

const SORT_TYPE_OPTIONS = [
  { label: 'Time', value: 'timestamp' },
  { label: 'Type', value: 'type' },
  { label: 'Talked To', value: 'talkedTo' }
];

const SORT_DIRECTION_OPTIONS = [
  { label: 'Descending', value: 'desc' },
  { label: 'Ascending', value: 'asc' }
];

export default function Header({
  onDateChange,
  currentPage,
  onSelectPage,
  pages,
  setSort,
  setSortDirection,
  startDate,
  endDate,
  currentSort,
  currentSortDirection
}) {
  return (
    <div className={styles['softphone-interaction-history-header']}>
      {Boolean(pages.length) && (
        <div className={styles['softphone-interaction-history-header__page']}>
          <Text>Page</Text>
          <ReactSelect
            aria-label="Page"
            className={styles['softphone-interaction-history-header__page__options']}
            options={pages.map((pageNumber) => ({ label: pageNumber, value: pageNumber }))}
            value={{ label: currentPage, value: currentPage }}
            onChange={(newValue) => onSelectPage((newValue as { value: string }).value)}
            isSearchable={false}
            small
          />
        </div>
      )}
      <Popover anchor={<ClickableIcon aria-label="Filter" className="ml-auto" icon={faFilterList} />}>
        <div className={styles['softphone-interaction-history-header__options-popover']}>
          <Text bold className="mb-20">
            Filter
          </Text>
          <div className={styles['softphone-interaction-history-header__options-popover__date-selector']}>
            <DateSelector onDateChange={onDateChange} defaultStartDate={startDate} defaultEndDate={endDate} />
          </div>
          <Text bold className="mt-30 mb-20">
            Sort By
          </Text>
          <ReactSelect
            aria-label="Sort By"
            className={cx(styles['softphone-interaction-history-header__options-popover__sort'], 'mb-15')}
            options={SORT_TYPE_OPTIONS}
            onChange={(option) => setSort((option as { value: string }).value)}
            defaultValue={SORT_TYPE_OPTIONS.find((sort) => sort.value === currentSort)}
            isSearchable={false}
          />
          <ReactSelect
            aria-label="Sort Direction"
            className={styles['softphone-interaction-history-header__options-popover__sort-direction']}
            options={SORT_DIRECTION_OPTIONS}
            defaultValue={SORT_DIRECTION_OPTIONS.find((direction) => direction.value === currentSortDirection)}
            onChange={(option) => setSortDirection((option as { value: string }).value)}
            isSearchable={false}
          />
        </div>
      </Popover>
    </div>
  );
}
