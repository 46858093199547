import { useEffect, useRef, useState } from 'react';

import { useAgentPreferencesContext } from 'lib/common/contexts/AgentPreferenceContext/Context';

import Modal from 'lib/common/components/Modal';
import { TEditorRef } from 'lib/common/components/molecules/RichTextEditor/Editor';

import SoftphoneModal from '../../SoftphoneModalContext/components/SoftphoneModal';
import { useLayout } from '../../layout/LayoutContext';
import EmailSignatureEditor from './EmailSignatureEditor';
import ViewEmailSignatureModal from './ViewEmailSignatureModal';

import styles from './email-signature-modal.module.scss';

interface IEmailSignatureModal {
  open: boolean;
  closeModal: () => void;
}

const EmailSignatureModal = ({ open, closeModal }: IEmailSignatureModal) => {
  const [editMode, setEditMode] = useState(false);
  const {
    state: { agentPreference },
    actions: { updatePreference }
  } = useAgentPreferencesContext();
  const { isSoftphone } = useLayout();
  const [emailSignature, setEmailSignature] = useState(agentPreference?.emailSignature || '');
  const signatureEditor = useRef<TEditorRef>(null);

  const titleWord = !editMode ? 'Manage' : agentPreference?.emailSignature ? 'Edit' : 'Create';

  useEffect(() => {
    setEmailSignature(agentPreference?.emailSignature || '');
    setEditMode(false);
  }, [agentPreference]);

  const closeAndClear = () => {
    setEmailSignature(agentPreference?.emailSignature || '');
    closeModal();
    setEditMode(false);
  };

  const clearAndBack = () => {
    setEmailSignature(agentPreference?.emailSignature || '');
    setEditMode(false);
  };

  const modalContent = editMode ? (
    <EmailSignatureEditor
      setEditMode={setEditMode}
      emailSignature={emailSignature}
      setEmailSignature={setEmailSignature}
      ref={signatureEditor}
    />
  ) : (
    <ViewEmailSignatureModal
      emailSignatureInsertOn={agentPreference?.emailSignatureInsetOn}
      setEditMode={setEditMode}
      emailSignature={emailSignature}
      updatePreference={updatePreference}
    />
  );

  const softphoneButtons = editMode
    ? {
        onCancel: clearAndBack,
        onSave: async () => updatePreference({ emailSignature: await signatureEditor?.current?.getHTMLContent() }),
        onSuccess: () => {
          setEditMode(false);
        },
        secondaryButtonText: 'Back'
      }
    : {};

  return isSoftphone ? (
    <SoftphoneModal
      title={`${titleWord} Email Signature`}
      isModalOpen={open}
      handleClearContent={() => {}}
      isReversedLayout={false}
      {...softphoneButtons}
      closeModal={closeAndClear}
    >
      <div className={styles['email-signature-modal']}>{modalContent}</div>
    </SoftphoneModal>
  ) : (
    <Modal
      large
      open={open}
      onClose={closeAndClear}
      onSecondary={clearAndBack}
      secondaryButtonText="Back"
      hideFooter={!editMode}
      onSave={async () => updatePreference({ emailSignature: await signatureEditor?.current?.getHTMLContent() })}
      onSuccess={() => {
        setEditMode(false);
      }}
      title={`${titleWord} Email Signature`}
    >
      <div className={styles['email-signature-modal']}>{modalContent}</div>
    </Modal>
  );
};

export default EmailSignatureModal;
