import { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';

import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';

import Context from './Context';
import Layout from './components/Layout';

const TOAST_LIMIT = 4;
const TOAST_AUTO_CLOSE = 30000;

const LayoutProvider = ({ children, isForcedSoftphone }: { children: ReactNode; isForcedSoftphone: boolean }) => {
  const isSoftphone = useIsSoftphoneQuery();

  return (
    <Context.Provider value={{ isSoftphone: isForcedSoftphone || isSoftphone }}>
      <Layout isSoftphone={isSoftphone} isForcedSoftphone={isForcedSoftphone}>
        {children}
      </Layout>
      <ToastContainer
        draggable={false}
        // We have request ids in toasts, so they need to be able to click/highlight
        closeOnClick={false}
        theme="colored"
        position="bottom-left"
        limit={TOAST_LIMIT}
        className={isSoftphone ? 'Toastify__toast-container--softphone' : void 0}
        autoClose={TOAST_AUTO_CLOSE}
      />
    </Context.Provider>
  );
};

export default LayoutProvider;
