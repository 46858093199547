import { LogEvents, logger } from 'lib/common/components/LoggerController';

import CONTACT_STATES from 'lib/common/constants/contactStates';

export default function handleMissedContact({ contact, hasMissedCallPermission, handleContactChange }) {
  if (!hasMissedCallPermission) {
    return;
  }

  logger.info(LogEvents.TASK.MISSED, { contact });

  handleContactChange({ contact, status: CONTACT_STATES.MISSED });
}
