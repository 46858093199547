import { useAgentContext } from 'lib/common/contexts/AgentContext';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import connectAction from 'lib/common/utils/connectAction';

import THandleContactChange from '../types/HandleContactChange';
import destroyTask from '../utils/destroyTask';
import doesContactExist from '../utils/doesContactExist';

export default function useClearContact({ handleContactChange }: { handleContactChange: THandleContactChange }) {
  const { agent } = useAgentContext();

  return async (contact: connect.Contact, { skipDestroy }: { skipDestroy?: boolean } = {}) => {
    const contactExists = doesContactExist(agent, contact.contactId);

    if (contactExists) {
      try {
        await connectAction('clear', contact);
      } catch (e) {
        logger.info(LogEvents.TASK.CLOSED.FAIL, { contact });
      }
    }

    if (!contactExists) {
      logger.info(LogEvents.TASK.CLOSED.FAIL, { contact });
    }

    if (skipDestroy) {
      return;
    }

    destroyTask({ contact, handleContactChange });
  };
}
