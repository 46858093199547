import useSendSms from 'lib/common/hooks/useSendSms/useSendSms';

import { LogEvents, logger } from 'lib/common/components/LoggerController';
import Modal from 'lib/common/components/Modal';

export default function SendSmsModal({
  setSendSmsModalOpen = (_: boolean) => {},
  open = false
}: {
  setSendSmsModalOpen?: (_: boolean) => void;
  open?: boolean;
}) {
  const { sendSmsForm, isDisabled, handleSendSms, handleResetSmsForm } = useSendSms();

  const handleOnModalClose = () => {
    logger.info(LogEvents.OUTBOUND_SMS_SEND.CLOSE);
    handleResetSmsForm();
    setSendSmsModalOpen(false);
  };

  const handleOnSuccess = () => {
    handleResetSmsForm();
    setSendSmsModalOpen(false);
  };

  return (
    <Modal
      small
      title="Send A New SMS"
      onClose={handleOnModalClose}
      open={open}
      onSuccess={handleOnSuccess}
      onSave={handleSendSms}
      primaryButtonText="Send"
      saveDisabled={isDisabled}
    >
      {sendSmsForm}
    </Modal>
  );
}
