import useSendSms from 'lib/common/hooks/useSendSms';

import Button from 'lib/common/components/Button';
import { LogEvents, logger } from 'lib/common/components/LoggerController';

import styles from './softphone-send-sms.module.scss';

export default function SoftphoneSendSms({ onClose }) {
  const { sendSmsForm, isDisabled, handleSendSms, templatedResponsesOpen } = useSendSms();

  return (
    <div className={styles['softphone-send-sms']}>
      <div className={styles['softphone-send-sms__content']}>{sendSmsForm}</div>
      {!templatedResponsesOpen && (
        <section aria-label="footer" className={styles['softphone-send-sms__actions']}>
          <Button
            styleType="SECONDARY"
            onClick={() => {
              logger.info(LogEvents.OUTBOUND_SMS_SEND.CLOSE);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button disabled={isDisabled} onClick={handleSendSms} onSuccess={onClose} asyncAction>
            Send
          </Button>
        </section>
      )}
    </div>
  );
}
