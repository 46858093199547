import { faAddressCard, faEnvelope, faList, faNotes } from '@fortawesome/pro-regular-svg-icons';

import { Permissions } from '@cloud-wave/neon-common-lib';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

import useHasOutcomesAccess from 'lib/common/hooks/useHasOutcomesAccess';

import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';

import TEmailTask from 'lib/common/types/EmailTask';
import getAttachedDataContactAttributes from 'lib/common/utils/getAttachedDataContactAttributes';

import { EmailModalTypes } from '../EmailModal/EmailModal';

export default function EmailPopover({
  setModalVisible,
  task
}: {
  setModalVisible: (modalType: EmailModalTypes | null) => void;
  task?: TEmailTask;
}) {
  const { hasPermission } = usePermissionsContext();

  const {
    actions: { getSelectedTask }
  } = useContactContext();

  const selectedTask = getSelectedTask();

  const canAgentAccessOutcomes = useHasOutcomesAccess(selectedTask);
  const hasAttachedData = Boolean(getAttachedDataContactAttributes(task?.contact, task?.type)?.length);
  const hasCustomerProfilePermission = hasPermission({ permission: Permissions.CUSTOMER_PROFILES });

  return (
    <>
      <PopoverMenuItem
        text="View Original Email"
        icon={faEnvelope}
        onClick={() => {
          setModalVisible(EmailModalTypes.ORIGINAL_EMAIL);
        }}
      />
      {hasCustomerProfilePermission && (
        <PopoverMenuItem
          text="Customer Profile"
          icon={faAddressCard}
          onClick={() => {
            setModalVisible(EmailModalTypes.CUSTOMER_PROFILE);
          }}
        />
      )}
      {canAgentAccessOutcomes && (
        <PopoverMenuItem
          text="Wrap Up Codes / Notes"
          icon={faNotes}
          onClick={() => {
            setModalVisible(EmailModalTypes.WRAP_UP_CODES_AND_NOTES);
          }}
        />
      )}
      {hasAttachedData ? (
        <PopoverMenuItem
          text="Attached Data"
          icon={faList}
          onClick={() => {
            setModalVisible(EmailModalTypes.ATTACHED_DATA);
          }}
        />
      ) : null}
    </>
  );
}
