import { faUser } from '@fortawesome/pro-regular-svg-icons';

import TaskFooter from 'lib/common/components/molecules/TaskFooter';

import TTask from 'lib/common/types/Task';
import getUserName from 'lib/common/utils/getUserName';

import InfoPanel from './InfoPanel';

export default function ConnectingTask(props: TTask) {
  const { profile, queueName, time, connectionValue, taskId } = props;

  const name = profile && profile.firstName ? getUserName(profile) : connectionValue;

  return (
    <>
      <InfoPanel queue={queueName} timestamp={time}>
        <InfoPanel.Row
          text={name}
          testId={name}
          icon={faUser}
          badgeProps={{
            isConnecting: true,
            status: 'CONNECTING'
          }}
        />
      </InfoPanel>
      <TaskFooter.HangUpTask taskId={taskId} />
    </>
  );
}
