import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faChevronUp,
  faHeading,
  faItalic,
  faLink,
  faList,
  faListOl,
  faRedo,
  faStrikethrough,
  faText,
  faUnderline,
  faUndo
} from '@fortawesome/pro-regular-svg-icons';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createHeadingNode, $isHeadingNode } from '@lexical/rich-text';
import { $patchStyleText, $wrapNodes } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import cx from 'classnames';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_LOW,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  LexicalNode,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND
} from 'lexical';
import isEmail from 'validator/lib/isEmail';

import Icon from 'lib/common/components/Icon';

import ColorPicker from './ColorPicker';
import FloatingLinkEditor from './FloatingLinkEditor';
import OPEN_LINK_EDITOR_COMMAND from './constants/openLinkEditorCommand';
import addProtocolToUrl from './utils/addProtocolToUrl';
import getSelectedNode from './utils/getSelectedNode';
import toggleImageLink from './utils/toggleImageLink';

const supportedBlockTypes = new Set(['paragraph', 'h1', 'h2', 'ul', 'ol']);

const blockTypeToBlockName = {
  h1: 'Large Heading',
  h2: 'Small Heading',
  h3: 'Heading',
  h4: 'Heading',
  h5: 'Heading',
  ol: 'Numbered List',
  paragraph: 'Normal Text',
  ul: 'Bulleted List'
};

function Divider() {
  return <div className="divider" />;
}

function BlockOptionsDropdownList({
  editor,
  blockType,
  toolbarRef,
  setShowBlockOptionsDropDown,
  className,
  invertLocation = false
}) {
  const dropDownRef = useRef(null);
  const translateY = invertLocation ? '13%' : '-100%';

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown: any = dropDownRef.current;

    if (toolbar !== null && dropDown !== null) {
      const { top, left } = toolbar.getBoundingClientRect();
      dropDown.style.top = `${top}px`;
      dropDown.style.left = `${left}px`;
      dropDown.style.transform = `translate(0px, ${translateY})`;
    }
  }, [dropDownRef, toolbarRef]);

  useEffect(() => {
    const dropDown: any = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event) => {
        const target = event.target;

        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener('click', handle);

      return () => {
        document.removeEventListener('click', handle);
      };
    }
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatLargeHeading = () => {
    if (blockType !== 'h1') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h1'));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatSmallHeading = () => {
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h2'));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatBulletList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatNumberedList = () => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  return (
    <div className={`dropdown ${className}__dropdown`} ref={dropDownRef}>
      <button aria-label="Normal text" className="item" onClick={formatParagraph}>
        <Icon icon={faText} size={15} color="grey" className="icon" />
        <span className="text">Normal text</span>
        {blockType === 'paragraph' && <span className="active" />}
      </button>
      <button aria-label="Large Heading" className="item" onClick={formatLargeHeading}>
        <Icon icon={faHeading} size={15} color="grey" className="icon" />
        <span className="text">Large Heading</span>
        {blockType === 'h1' && <span className="active" />}
      </button>
      <button aria-label="Small Heading" className="item" onClick={formatSmallHeading}>
        <Icon icon={faHeading} size={12} className="icon mr-15" />
        <span className="text">Small Heading</span>
        {blockType === 'h2' && <span className="active" />}
      </button>
      <button aria-label="Bullet List" className="item" onClick={formatBulletList}>
        <Icon icon={faList} size={15} color="grey" className="icon" />
        <span className="text">Bullet List</span>
        {blockType === 'ul' && <span className="active" />}
      </button>
      <button aria-label="Numbered List" className="item" onClick={formatNumberedList}>
        <Icon icon={faListOl} size={15} color="grey" className="icon" />
        <span className="text">Numbered List</span>
        {blockType === 'ol' && <span className="active" />}
      </button>
    </div>
  );
}

export default function ToolbarPlugin({
  className,
  open,
  invertLocation = false
}: {
  className?: string;
  open?: boolean;
  invertLocation?: boolean;
}) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [fontColor] = useState<string>('#000');
  const [blockType, setBlockType] = useState('paragraph');
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(false);
  const [currentNode, setCurrentNode] = useState<LexicalNode | null>(null);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isLink, setIsLink] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    const selectedNode = getSelectedNode(selection);

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey: any = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsLink($isListNode(element));
    }

    setCurrentNode(selectedNode);
    setIsLink($isLinkNode(selectedNode) || $isLinkNode(selectedNode?.getParent()));
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload) => {
          updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, updateToolbar]);

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, styles);
        }
      });
    },
    [editor]
  );

  const onFontColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ color: value });
    },
    [applyStyleText]
  );

  const insertLink = useCallback(() => {
    try {
      editor.update(() => {
        const selection = $getSelection();
        const isImageSelection = currentNode?.__type === 'image';
        const nodeParent = currentNode?.getParent();
        const isLink = $isLinkNode(currentNode) || $isLinkNode(nodeParent);

        if (isLink && isImageSelection) {
          return void toggleImageLink(null);
        }

        if (isLink) {
          return void editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }

        const text = selection?.getTextContent()?.trim() || currentNode?.getTextContent()?.trim() || '';

        if (isEmail(text)) {
          const mailtoLink = `mailto:${text}`;

          editor.dispatchCommand(TOGGLE_LINK_COMMAND, mailtoLink);

          return editor.dispatchCommand(OPEN_LINK_EDITOR_COMMAND, mailtoLink);
        }

        const urlLink = !isImageSelection ? addProtocolToUrl(text) : nodeParent?.__url || '';

        editor.dispatchCommand(TOGGLE_LINK_COMMAND, urlLink);
        editor.dispatchCommand(OPEN_LINK_EDITOR_COMMAND, urlLink);
      });
    } catch {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, '');
      editor.dispatchCommand(OPEN_LINK_EDITOR_COMMAND, '');
    }
  }, [editor, currentNode]);

  return (
    <>
      <FloatingLinkEditor currentNode={currentNode} editor={editor} />
      {open ? (
        <div className={cx('toolbar', className)} ref={toolbarRef} data-testid="email-formatting-toolbar">
          <button
            disabled={!canUndo}
            onClick={() => {
              editor.dispatchCommand(UNDO_COMMAND, undefined);
            }}
            className="toolbar-item spaced"
            aria-label="Undo"
          >
            <Icon icon={faUndo} color={!canUndo ? 'midGrey' : 'grey'} size={15} />
          </button>
          <button
            disabled={!canRedo}
            onClick={() => {
              editor.dispatchCommand(REDO_COMMAND, undefined);
            }}
            className="toolbar-item"
            aria-label="Redo"
          >
            <Icon icon={faRedo} color={!canRedo ? 'midGrey' : 'grey'} size={15} />
          </button>
          <Divider />
          {supportedBlockTypes.has(blockType) && (
            <>
              <button
                className="toolbar-item block-controls"
                onClick={() => setShowBlockOptionsDropDown(!showBlockOptionsDropDown)}
                aria-label="Formatting Options"
              >
                <span className="text">{blockTypeToBlockName[blockType]}</span>
                <Icon icon={faChevronUp} className="icon" color="grey" size={15} />
              </button>
              {showBlockOptionsDropDown &&
                createPortal(
                  <BlockOptionsDropdownList
                    invertLocation={invertLocation}
                    className={className}
                    editor={editor}
                    blockType={blockType}
                    toolbarRef={toolbarRef}
                    setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
                  />,
                  document.body
                )}
              <Divider />
            </>
          )}
          <>
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
              }}
              className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
              aria-label="Format Bold"
            >
              <Icon icon={faBold} color="grey" size={15} />
            </button>
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
              }}
              className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
              aria-label="Format Italics"
            >
              <Icon icon={faItalic} color="grey" size={15} />
            </button>
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
              }}
              className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
              aria-label="Format Underline"
            >
              <Icon icon={faUnderline} color="grey" size={15} />
            </button>
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
              }}
              className={'toolbar-item spaced ' + (isStrikethrough ? 'active' : '')}
              aria-label="Format Strikethrough"
            >
              <Icon icon={faStrikethrough} color="grey" size={15} />
            </button>
            <ColorPicker
              buttonClassName="toolbar-item color-picker"
              buttonAriaLabel="Formatting text color"
              buttonIconClassName="icon font-color"
              dropdownClassName={className}
              invertLocation={invertLocation}
              color={fontColor}
              onChange={onFontColorSelect}
              title="text color"
            />

            <button
              onClick={insertLink}
              className={'toolbar-item spaced ' + (isLink ? 'active' : '')}
              aria-label="Insert Link"
            >
              <Icon icon={faLink} color="grey" size={15} />
            </button>

            <Divider />
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
              }}
              className="toolbar-item spaced"
              aria-label="Left Align"
            >
              <Icon icon={faAlignLeft} color="grey" size={15} />
            </button>
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
              }}
              className="toolbar-item spaced"
              aria-label="Center Align"
            >
              <Icon icon={faAlignCenter} color="grey" size={15} />
            </button>
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
              }}
              className="toolbar-item spaced"
              aria-label="Right Align"
            >
              <Icon icon={faAlignRight} color="grey" size={15} />
            </button>
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
              }}
              className="toolbar-item"
              aria-label="Justify Align"
            >
              <Icon icon={faAlignJustify} color="grey" size={15} />
            </button>
          </>
        </div>
      ) : null}
    </>
  );
}
