import CONNECTION_TYPES from 'lib/common/constants/connectionType';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import TTask from 'lib/common/types/Task';
import isVoice from 'lib/common/utils/isVoice';

function filterOnlyInboundTasks(tasks: TTask[]) {
  return tasks.filter((task) => task.connectionType === CONNECTION_TYPES.INBOUND);
}

function filterOnlyVoiceTasks(tasks: TTask[]) {
  return tasks.filter((task) => task.type === CONTACT_TYPES.CALL || task.type === CONTACT_TYPES.QUEUE_CALLBACK);
}

export default function useTaskUtils(tasks: TTask[], selectedTaskId?: string) {
  // Actions //

  const getActiveCallTask = () => {
    // Any task with contact of type call and it is connected
    return tasks.find(
      (task) =>
        (task.type === CONTACT_TYPES.CALL ||
          task.type === CONTACT_TYPES.CONFERENCE_CALL ||
          task.type === CONTACT_TYPES.QUEUE_CALLBACK) &&
        task.status === CONTACT_STATES.CONNECTED
    );
  };

  const getSelectedTask = () => tasks.find((task) => task.taskId === selectedTaskId);

  // States //

  const connectingTasks = tasks.filter(
    (task) => task.status === CONTACT_STATES.CONNECTING || task.status === CONTACT_STATES.ACCEPTED
  );

  const isCallInACW = Boolean(
    tasks.find((task: TTask) => isVoice({ type: task.type }) && task.status === CONTACT_STATES.ACW)
  );

  return {
    // Actions
    getActiveCallTask,
    getSelectedTask,

    // States
    onActiveCall: Boolean(getActiveCallTask()),
    hasConnectingTask: Boolean(connectingTasks.length),
    hasConnectingInboundTask: Boolean(filterOnlyInboundTasks(connectingTasks).length),
    hasConnectingVoiceTask: Boolean(filterOnlyVoiceTasks(connectingTasks).length),
    hasConnectingInboundVoiceTask: Boolean(filterOnlyInboundTasks(filterOnlyVoiceTasks(connectingTasks)).length),
    isCallInACW
  };
}
