import { Children, Fragment, ReactElement, ReactNode, cloneElement, useEffect, useState } from 'react';

import { Menu, PopoverOrigin } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import cx from 'classnames';

import styles from './popover-menu.module.scss';

interface IPopoverMenu {
  children: ReactElement | Array<ReactElement | undefined>;
  anchor?: ReactNode | null;
  offset?: number;
  offsetLeft?: number;
  reverse?: boolean;
  className?: string;
  containerClassName?: string;
  withTail?: boolean;
  transitionalProps?: TransitionProps;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  closeOnClick?: boolean;
  disabled?: boolean;
  maxHeight?: number | string;
  onClose?: () => void;
  childrenDepth?: number;
}

function getChildren(children, childrenDepth) {
  if (!childrenDepth) {
    return children;
  }

  return getChildren(children.props.children, childrenDepth - 1);
}

export default function PopoverMenu({
  children,
  anchor,
  offset = 10,
  offsetLeft = 0,
  reverse,
  className = void 0,
  withTail,
  transitionalProps,
  containerClassName = void 0,
  anchorOrigin,
  transformOrigin,
  closeOnClick,
  disabled,
  maxHeight,
  onClose,
  childrenDepth = 0
}: IPopoverMenu) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [reverseState, setReverseState] = useState(reverse);

  const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    const { bottom: lowestPartOfElementInViewPort } = event.currentTarget.getBoundingClientRect();
    const { height: viewPortHeight } = window?.visualViewport || { height: 0 };
    const bottomTenPercent = viewPortHeight * 0.9;
    withTail && lowestPartOfElementInViewPort > bottomTenPercent ? setReverseState(true) : setReverseState(false);

    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(null);
    onClose?.();

    e?.preventDefault();
    e?.stopPropagation();
  };

  useEffect(() => {
    if (anchor) {
      return;
    }

    setAnchorEl(null);
  }, [anchor]);

  const popoverMenuChildren = Children.toArray(getChildren(children, childrenDepth));

  return (
    <>
      {anchor &&
        cloneElement(anchor as ReactElement, {
          onClick: disabled ? void 0 : handleAnchorClick,
          className: cx((anchor as ReactElement).props.className, { [styles['anchor-disabled']]: disabled })
        })}
      <Menu
        className={cx(
          className,
          {
            'popover-menu__with-tail': withTail
          },
          {
            'popover-menu__with-tail--up': withTail && !reverseState,
            'popover-menu__with-tail--down': withTail && reverseState
          }
        )}
        anchorEl={anchorEl}
        anchorOrigin={
          anchorOrigin || {
            vertical: (offsetLeft && 'center') || (reverseState && 'top') || 'bottom',
            horizontal: 'center'
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: (offsetLeft && 'center') || (reverseState && 'bottom') || 'top',
            horizontal: 'center'
          }
        }
        PaperProps={{
          style: {
            marginTop: reverseState ? -offset : offset,
            marginLeft: reverseState ? -offsetLeft : offsetLeft,
            overflow: 'auto',
            maxHeight
          }
        }}
        MenuListProps={{
          className: cx(styles['popover-menu'], containerClassName),
          style: {
            display: 'block',
            maxHeight
          }
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        getContentAnchorEl={null}
        disableAutoFocusItem
        TransitionProps={transitionalProps}
      >
        {Children.map(popoverMenuChildren, (child: any) =>
          cloneElement(child, {
            onSuccess: child.props.onSuccess || (closeOnClick ? handleClose : void 0)
          })
        )}
      </Menu>
    </>
  );
}
