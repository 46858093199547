import { Dtmf, PhoneTonePlayer } from 'play-dtmf';

const TONE_DURATION_S = 0.25;
const TIME_BETWEEN_TONES_MS = 25;

// Default is 0,1
const VOLUME = 0.05;

const audioContext = new AudioContext();
const phoneTonePlayer = new PhoneTonePlayer(audioContext, { dtmf: VOLUME });

export function playDtmfTone(digit: string) {
  const tone = phoneTonePlayer.playDtmf(digit as Dtmf);

  tone?.stop(TONE_DURATION_S);
}

export default async function playDtmfTones(digits: string) {
  if (digits.length === 1) {
    return playDtmfTone(digits);
  }

  const playableDigits = digits.replace('+', '').split('');

  for (const digit of playableDigits) {
    playDtmfTone(digit);

    await new Promise((resolve) => setTimeout(resolve, TIME_BETWEEN_TONES_MS + TONE_DURATION_S * 1000));
  }
}
