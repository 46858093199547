import { FC, forwardRef } from 'react';
import { Link, NavLink } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import cx from 'classnames';

export interface AppMenuItemComponentProps {
  testId: string;
  className?: string;
  link?: string | null;
  activeClassName?: string;
  pageHasSubMenu?: boolean;
  external?: boolean;
}

const NavLinkButton = (link, external, testId) => (props, ref) => {
  if (external) {
    return <Link {...props} to={{ pathname: link }} innerRef={ref} target="_blank" data-testid={testId} />;
  }

  return <NavLink exact {...props} to={link} innerRef={ref} data-testid={testId} />;
};

const SidebarMenuComponent: FC<AppMenuItemComponentProps> = (props) => {
  const { className, link, children, activeClassName, external, testId } = props;

  return (
    <ListItem
      button
      className={cx(className, 'nav-item')}
      component={forwardRef(NavLinkButton(link, external, testId))}
      activeClassName={activeClassName}
      isActive={(_, location) => location.pathname.indexOf(link) === 0}
      disableRipple
    >
      {children}
    </ListItem>
  );
};

export default SidebarMenuComponent;
