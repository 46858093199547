import { faAddressBook, faBuilding, faHeadset, faMobileButton } from '@fortawesome/pro-regular-svg-icons';

import { ContactType } from '@cloud-wave/neon-common-lib';

export const ICON_MAP = {
  [ContactType.EXTERNAL_DIRECTORY]: faAddressBook,
  [ContactType.ORGANISATION]: faBuilding,
  [ContactType.PERSONAL]: faMobileButton,
  [ContactType.AGENT]: faHeadset
};
