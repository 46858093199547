import { ReactNode } from 'react';

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import './content-panel.scss';

interface IContentPanel {
  isEmpty?: boolean | null;
  children: any;
  title: string;
  subtitle?: string;
  emptyMessage?: string;
  emptyIcon?: IconDefinition;
  icon?: IconDefinition;
  alwaysShowChildren?: boolean;
  className?: string;
  actions?: ReactNode;
}

export default function ContentPanel({
  isEmpty,
  children,
  title,
  subtitle,
  emptyMessage,
  emptyIcon,
  icon,
  alwaysShowChildren,
  className,
  actions
}: IContentPanel) {
  return (
    <div className={cx('content-panel', className)}>
      <div className="content-panel__header">
        <div className="content-panel__header__title">
          <Text type="heading2" htmlElement="h3">
            {title} {icon && <Icon className="ml-10" icon={icon} />}
          </Text>
          {actions}
        </div>
        {subtitle && (
          <Text type="body" className="content-panel__subtitle">
            {subtitle}
          </Text>
        )}
      </div>
      {isEmpty && (emptyIcon || emptyMessage) && (
        <EmptyPlaceholder className="mt-30 mb-30" icon={emptyIcon} text={emptyMessage} />
      )}
      {(!isEmpty || (isEmpty && alwaysShowChildren)) && children}
    </div>
  );
}
