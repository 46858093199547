import { Letter } from 'react-letter';

import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import SignatureInsertSettings from 'lib/common/contexts/AgentPreferenceContext/EmailSignatureModal/ViewEmailSignatureModal/SignatureInsertSettings';
import ViewEmailSignatureActions from 'lib/common/contexts/AgentPreferenceContext/EmailSignatureModal/ViewEmailSignatureModal/ViewEmailSignatureActions';
import styles from 'lib/common/contexts/AgentPreferenceContext/EmailSignatureModal/email-signature-modal.module.scss';
import { TSignatureViewParams } from 'lib/common/contexts/AgentPreferenceContext/EmailSignatureModal/typesEmailSignatureModal';

import Button from 'lib/common/components/Button';

const ViewEmailSignatureModal = ({
  setEditMode,
  emailSignature,
  emailSignatureInsertOn,
  updatePreference
}: TSignatureViewParams) => {
  const hasEmailSignature = emailSignature !== '' && emailSignature !== undefined;
  const signaturePreviewContent = hasEmailSignature ? emailSignature : '';

  return (
    <div className={styles['email-signature-modal__preview']}>
      <div className={styles['email-signature-modal__preview__content']}>
        {hasEmailSignature && (
          <ViewEmailSignatureActions updatePreference={updatePreference} setEditMode={setEditMode} />
        )}
        {!hasEmailSignature && (
          <div className={styles['email-signature-modal__create-button']}>
            <Button
              styleType="SECONDARY"
              icon={faPlus}
              onClick={() => setEditMode(true)}
              data-testid="add-signature-button"
            >
              Create New
            </Button>
          </div>
        )}
        <Letter
          html={signaturePreviewContent}
          allowedSchemas={['http', 'https', 'mailto', 'data']}
          className={cx(styles['email-signature-modal__content'], {
            [styles['email-signature-modal__content--empty']]: !hasEmailSignature
          })}
        />
      </div>
      {hasEmailSignature && (
        <SignatureInsertSettings emailSignatureInsertOn={emailSignatureInsertOn} updatePreference={updatePreference} />
      )}
    </div>
  );
};

export default ViewEmailSignatureModal;
