import { useEffect, useState } from 'react';
import { useTime } from 'react-time-sync';

import convertMSToReadableTime from 'lib/common/utils/convertMSToReadableTime';

export default function useTaskTimer(time?: Date) {
  const currentTime = useTime();
  const [timer, setTimer] = useState(Date.now());

  useEffect(() => {
    setTimer(Date.now());
  }, [currentTime]);

  const timeDifference = timer - (time?.getTime() || 0);

  return convertMSToReadableTime(timeDifference);
}
