import { useEffect, useState } from 'react';

import { P, match } from 'ts-pattern';

import { ModuleType, Permissions, TModuleType } from '@cloud-wave/neon-common-lib';

import { useModulesContext } from 'lib/common/contexts/ModulesContext';
import { WidgetsState } from 'lib/common/contexts/ModulesContext/constants/WidgetsState';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

import Tabs from 'lib/common/components/atoms/Tabs';

import { LOCAL_STORAGE_KEYS } from 'lib/common/constants/localStorageKeys';

import TTask from 'lib/common/types/Task';
import getAttachedDataContactAttributes from 'lib/common/utils/getAttachedDataContactAttributes';

import { useLocalStorage } from '../useLocalStorage';
import getTabs from './utils/getTabs';

import styles from './styles/modules.module.scss';

export default function useModules({
  selectedTask,
  tabsClassName,
  renderDefaultComponent,
  moduleType,
  onTabChange,
  isSoftphone
}: {
  selectedTask?: TTask;
  tabsClassName?: string;
  renderDefaultComponent?: () => JSX.Element;
  moduleType?: Extract<TModuleType, 'TASK' | 'WIDGET'>;
  onTabChange?: () => void;
  isSoftphone?: boolean;
}) {
  const { getStorageItem, setStorageItem } = useLocalStorage();
  const {
    state: { taskModules, widgetModules, widgetsConfig }
  } = useModulesContext();
  const { hasPermission } = usePermissionsContext();

  const hasCustomerProfilePermission = hasPermission({ permission: Permissions.CUSTOMER_PROFILES });
  const hasAttachedData = Boolean(getAttachedDataContactAttributes(selectedTask?.contact, selectedTask?.type)?.length);

  const modules = match({ moduleType, selectedTask })
    .with({ moduleType: ModuleType.TASK }, () => taskModules)
    .with({ moduleType: ModuleType.WIDGET }, () => widgetModules)
    .with({ moduleType: undefined, selectedTask: undefined }, () => widgetModules)
    .with({ moduleType: undefined, selectedTask: P._ }, () => [...widgetModules, ...taskModules])
    .exhaustive();

  const defaultTabIndex = modules.findIndex(
    (module) => module.moduleId === getStorageItem(LOCAL_STORAGE_KEYS.DEFAULT_SELECTED_WIDGET_ID)
  );
  const defaultTab = defaultTabIndex === -1 ? 0 : defaultTabIndex;

  const [selectedTab, setSelectedTab] = useState(defaultTab);

  useEffect(() => {
    if (moduleType !== ModuleType.WIDGET) {
      return;
    }

    setSelectedTab(defaultTab);
  }, [moduleType]);

  const selectTab = (tabIndex: number) => {
    onTabChange?.();
    setSelectedTab(tabIndex);

    const isWidgetSelected = modules[tabIndex]?.type === ModuleType.WIDGET;

    if (!isWidgetSelected) {
      return;
    }

    setStorageItem(LOCAL_STORAGE_KEYS.DEFAULT_SELECTED_WIDGET_ID, modules[tabIndex].moduleId ?? '');
  };

  const getSelectedTab = () => {
    if (isSoftphone && widgetsConfig.widgetsState === WidgetsState.CLOSED) {
      return -1;
    }

    return selectedTab;
  };

  const tabs = getTabs({
    modules,
    renderDefaultComponent,
    selectedTab: getSelectedTab(),
    selectedTask,
    setSelectedTab: selectTab,
    hasDefaultTab: Boolean(renderDefaultComponent && (hasAttachedData || hasCustomerProfilePermission))
  });

  return {
    modules,
    tabs: (
      <Tabs
        hideSingleTab={!isSoftphone}
        align={isSoftphone ? 'center' : 'flex-start'}
        className={tabsClassName}
        orientation={isSoftphone ? 'below' : 'above'}
        tabs={tabs}
      />
    ),
    content: (
      <div role="tabpanel" className={styles['modules__content']}>
        {tabs.map(({ render, isSelected }) => render({ selected: isSelected }))}
      </div>
    )
  };
}
