import { ReactNode } from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import cx from 'classnames';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import Text from 'lib/common/components/Text';

import './heading-label.scss';

type HeadingLabelProps = {
  smallTitle?: boolean;
  primary: string | ReactNode;
  secondary?: string | ReactNode;
  optional?: boolean;
  actionIcon?: IconDefinition;
  actionIconOnClick?: (_: any) => void;
  noMargin?: boolean;
};

const Dot = () => <span className="heading-label__dot">&bull;</span>;

export default function HeadingLabel({
  primary,
  secondary,
  smallTitle,
  optional,
  actionIconOnClick,
  actionIcon,
  noMargin = false
}: HeadingLabelProps) {
  return (
    <div className={cx('heading-label', { 'heading-label--small': smallTitle, 'heading-label--no-margin': noMargin })}>
      {typeof primary === 'string' ? <Text type={smallTitle ? 'heading3' : 'heading2'}>{primary}</Text> : primary}
      {optional && (
        <>
          <Dot />
          <Text>Optional</Text>
        </>
      )}
      {secondary ? <Dot /> : null}
      {typeof secondary === 'string' ? <Text>{secondary}</Text> : secondary}
      {actionIcon && <ClickableIcon className="ml-auto" icon={actionIcon} onClick={actionIconOnClick} />}
    </div>
  );
}
