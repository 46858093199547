import { KeyboardEvent, MutableRefObject, useEffect, useState } from 'react';

export enum NewLineShortCutMap {
  CTRL = 'ctrlKey',
  ALT = 'altKey'
}

const ENTER_KEY = 'Enter';
const NEW_LINE_STRING = '\n';

export default function useTextAreaNewLine({
  shortcuts,
  inputValue,
  setInputValue,
  inputRef
}: {
  shortcuts: NewLineShortCutMap[];
  inputValue: string;
  setInputValue: (value: string) => void;
  inputRef: MutableRefObject<HTMLTextAreaElement | null>;
}) {
  const [caretPosition, setCaretPosition] = useState(null);

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key !== ENTER_KEY || !inputRef || !shortcuts.find((shortcut) => Boolean(e[shortcut]))) {
      return;
    }

    //@ts-expect-error
    const caretPos = e.target.selectionStart;

    setCaretPosition(caretPos);

    setInputValue(inputValue.substring(0, caretPos) + NEW_LINE_STRING + inputValue.substring(caretPos));
  };

  useEffect(() => {
    if (caretPosition === null) {
      return;
    }

    if (inputValue[caretPosition] !== NEW_LINE_STRING) {
      setCaretPosition(null);

      return;
    }

    inputRef?.current?.setSelectionRange(caretPosition + 1, caretPosition + 1);

    inputRef?.current?.scrollBy?.({ top: inputRef?.current?.scrollHeight / inputRef?.current?.rows });

    inputRef?.current?.focus();

    setCaretPosition(null);
  }, [inputValue]);

  return onKeyDown;
}
